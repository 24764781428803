<div class="fixActionRow">
  <div class="close-button">
    <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="top-logo">
      <img src="/assets/images/topbar/new-logo@2x.png" alt="Rame rame logo" />
    </div>
    <div class=" text-center">
      <img src="/assets/images/icons/aman.svg" alt="accepted term" />
      <h1 class="h3 mt-3 mb-4">Terima Kasih</h1>
      <p>Anda telah menerima Syarat dan Ketentuan untuk mengakses dan menjadi member ramerame.co.id</p>
      <p>Selamat Datang Di RAMERAME.CO.ID</p>

    </div>
  </mat-dialog-content>
</div>
