<div class="fixActionRow">
  <div class="close-button d-flex justify-content-between">
    <b>ID: {{data.tanya.ticketHash}} </b> <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <h5>Kategori</h5>
            <p>{{data.tanya.category}}</p>
          </div>
          <div class="col-6">
            <h5>Tanggal Pertanyaan</h5>
            <p>{{date.localeDate(data.tanya.createdAt, locale)}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h5>{{data.tanya.title}}</h5>
          </div>
        </div>
      </div>
      <div class="container"></div>
      <hr>
      <div class="ticket card mt-1" *ngFor="let t of data.tanya.details">
        <div class="card-content p-3">
          <div class="row">
            <div class="col-12">
              <h5 *ngIf="userProfile.basic.email != t.email">{{t.username}} (Project Manager)</h5>
              <h5 *ngIf="userProfile.basic.email == t.email">Saya</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <blockquote [innerHTML]="t.message | keepHtml"></blockquote>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              {{date.completeLocaleDateTime(t.createdAt, locale)}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <div class="spacer"></div>
  <mat-dialog-actions class="d-flex justify-content-between" style="flex-flow: row; min-height: 120px;">
    <textarea [(ngModel)]="replyMessage" class="form-control" rows="2" style="margin-right: 5px"></textarea>
    <button [disabled]="replyMessage.length == 0" (click)="sendReply()" class="primary-button" style="width: 65px; height: 65px; margin-right: 0">Kirim Pesan</button>
  </mat-dialog-actions>
</div>
