import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-map-merchant',
  templateUrl: 'map-merchant.dialog.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class MapMerchantDialog implements OnInit{

  show = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    let elm = document.createElement('div');
    elm.innerHTML = this.data;

    let iframe = elm.getElementsByTagName('iframe');
    this.data = iframe[0].src;

  }
}
