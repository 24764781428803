<div class="main-content full-screen grey-background cart-panel pb-0">
  <div class="cart confirm">
    <div class="item row bg-gradient">
      <div class="image col-4 col-md-2">
        <img
          *ngIf="cart.imageUrl"
          [src]="env + cart.imageUrl"
          class="img-fluid"
          alt="Product image"
        />
      </div>
      <div class="description col-8 col-md-10">
        <h6 class="mb-2">{{cart.productName}}</h6>
        <small class="text-grey"></small>
        <p class="mt-2">
          <span class="h6"
            >Rp {{ui.currencyFormat(cart.amount / cart.quantity, locale)}}</span
          >
          <span class="h5 text-grey">/ SLOT</span>
        </p>
      </div>
      <hr class="form-separator" />
      <div class="col-12 d-flex justify-content-between p-0">
        <h6 class="text-grey text-weight-regular">Kuantitas</h6>
        <h6 class="text-weight-bold">{{cart.quantity}} SLOT</h6>
      </div>
      <div class="col-12 d-flex justify-content-between p-0">
        <h6 class="text-grey text-weight-regular">Subtotal</h6>
        <h6 class="text-weight-bold">
          Rp. {{ui.currencyFormat(cart.amount, locale)}}
        </h6>
      </div>
      <div class="col-12 d-flex insurance-chk" *ngIf="cart.insuranceId">
        <div class="insurance-opt">
          <mat-checkbox (change)="toggleInsurances($event)" #filterWithInsurance
            >{{btnInsuranceLabel}}
          </mat-checkbox>
          <span
            class="icon-information insurance-info"
            (click)="showInfos()"
          ></span>
        </div>
      </div>
      <div
        class="col-12 d-flex justify-content-between with-insurance"
        *ngIf="withInsurance && productInsurance"
      >
        <div class="d-flex">
          <img [src]="insuranceLogo" alt="mail sent" />
          <span class="change-insurance">{{insuranceLabel}}</span>
        </div>
        <span class="text-green change-insurance" (click)="openDocumentDialog()"
          >Ganti</span
        >
      </div>
    </div>
    <div class="item row summary">
      <div class="col-12 p-0">
        <h6>Ringkasan Pembelian</h6>
        <div class="d-flex justify-content-between">
          <span class="h6 text-weight-regular text-grey"
            >Total ({{cart.quantity}} SLOT)</span
          >
          <span class="h6 text-weight-bold text-black"
            >Rp {{ui.currencyFormat(cart.amount, locale)}}</span
          >
        </div>

        <div class="d-flex justify-content-between">
          <span class="h6 text-weight-regular text-grey"
            >Biaya Admin ({{cart.platformFee * 100}}%)</span
          >
          <span class="h6 text-weight-bold text-black"
            >Rp {{ui.currencyFormat(cart.platformFee * cart.amount,
            locale)}}</span
          >
        </div>

        <div
          class="d-flex justify-content-between"
          *ngIf="withInsurance && productInsurance"
        >
          <span class="h6 text-weight-regular text-grey">Penjaminan</span>
          <span class="h6 text-weight-bold text-black"
            >{{ui.currencyFormat(productInsurance ? (cart.amount *
            productInsurance.percentage) : 0, locale)}}</span
          >
        </div>

        <div class="d-flex justify-content-between">
          <span class="h6 text-weight-regular text-grey">Angka Unik</span>
          <span class="h6 text-weight-bold text-black"
            >{{ui.currencyFormat(cart.uniqueNumbers, locale)}}</span
          >
        </div>
      </div>
      <hr class="form-separator mt-3 mb-3" />
      <div class="col-12 p-0">
        <p>
          {{cart.transferNote}}<span
            *ngIf="cart && cart.transferNote"
            class="btn-link"
            (click)="copyToClipboard(cart.accountNo)"
            >[copy nomer rekening]</span
          >
        </p>
        <p>{{cart.paymentNoteLine1}}</p>
        <p>{{cart.paymentNoteLine2}}</p>
      </div>
    </div>
  </div>
  <div class="cart-co row no-gutters">
    <div class="total p-0 col-6">
      <h6 class="text-grey text-weight-regular">Total Sisa Bayar</h6>
      <h4 class="font-weight-bold">
        Rp. {{ui.currencyFormat((cart.uniqueNumbers + (cart.platformFee *
        cart.amount) + cart.amount + (productInsurance ? cart.amount *
        productInsurance.percentage : 0)) - minimumWalletUsed, locale)}}
      </h4>
    </div>
    <button
      [disabled]="payNowDisable"
      class="primary-button p-0 col-6"
      (click)="payNow()"
      mat-flat-button
    >
      Bayar sekarang
    </button>
  </div>
</div>
