<div class="campaign-category" *ngFor="let detail of campaigns">
  <div class="card-body p-2">
    <div class="row no-gutters align-items-center body-detail" (click)="doNavigateVoucherDetail(detail.id)">
      <div class="col-6 col-md-6 nopadding">
        <img alt="" [src]="baseUrl + detail.imagePath + '?rand=123'" class="left-image"/>
      </div>
      <div class="col-6 col-md-6 align-self-start nopadding">
        <div class="body">
          <p class="title-name">{{detail.campaignName}}</p>
          <p class="title-merchant">{{detail.merchantName}}</p>
          <div class="align-text-bottom">
            <p class="title-point">{{detail.numberOfPoint}} {{'pages.member-card.point' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
