import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { AppRoutingModule } from '../../app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgProgressModule } from 'ngx-progressbar';
import { AppSharedModule } from '../../shared/shared.module';
import { LoginService } from '../../services/login.service';
import { NewLoginPageComponent } from './new-login.page';
import { ForgotPasswordPageComponent } from './forgot-password.page';
import { MailSentPageComponent } from './mail-sent.page';
import { ResetPasswordPageComponent } from './reset-password.page';
import { DialogNewAppComponent } from './dialog-new-app.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
  ],
  exports: [
    NewLoginPageComponent,
    ForgotPasswordPageComponent,
    MailSentPageComponent,
    ResetPasswordPageComponent,
  ],
  declarations: [
    NewLoginPageComponent,
    ForgotPasswordPageComponent,
    MailSentPageComponent,
    ResetPasswordPageComponent,
    DialogNewAppComponent,
  ],
  providers: [LoginService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppLoginModule {}
