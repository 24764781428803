<div class="fixActionRow">
  <div class="close-button d-flex justify-content-between">
    <b>Daftar Dokumen</b><button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
  </div>
  <mat-dialog-content class="mat-typography">
    <h4 class="text-center">{{data.productName}}</h4>
    <table class="table table-striped">
      <tbody>
      <tr>
        <td class="text-right"><span class="icon-file-pdf"></span></td>
        <td>Sertifikat Kolaborasi</td>
        <td class="text-right">
          <span class="icon-download3" (click)="documentDownload('cert')"></span>
        </td>
      </tr>

      <tr *ngIf="product.certificate.proposal">
        <td class="text-right"><span class="icon-file-pdf"></span></td>
        <td>Proposal</td>
        <td class="text-right">
          <span class="icon-download3" (click)="documentDownload('prop')"></span>
        </td>
      </tr>

      <tr *ngIf="product.certificate.term">
        <td class="text-right"><span class="icon-file-pdf"></span></td>
        <td>Syarat dan Ketentuan</td>
        <td class="text-right">
          <span class="icon-download3" (click)="documentDownload('term')"></span>
        </td>
      </tr>

      <tr *ngIf="product.certificate.perjanjian">
        <td class="text-right"><span class="icon-file-pdf"></span></td>
        <td>Perjanjian</td>
        <td class="text-right">
          <span class="icon-download3" (click)="documentDownload('agreement')"></span>
        </td>
      </tr>
      </tbody>
    </table>

  </mat-dialog-content>
  <div class="spacer"></div>
</div>
