<div class="full-screen registration-modal">
  <div class="full-height-page">
    <div class="top-logo">
      <img src="/assets/images/topbar/new-logo@2x.png" alt="Rame rame logo" />
    </div>
    <div class=" text-center">
      <img src="/assets/images/icons/mail-send.svg" alt="mail sent" />
      <h1 class="h3 mt-3 mb-4">Terima Kasih</h1>
      <button class="secondary-button text-black mb-4" mat-flat-button>{{data.email}}</button>
      <p>Proposal pendaftaran bisnis anda sudah kami terima</p>
      <p>Kami akan mempelajari proposal anda dan akan mengontak anda untuk pembicaraan lebih lanjut</p>
      <span  mat-dialog-close style="color: #fff; font-weight: bold; cursor: pointer; text-decoration: underline">Tutup</span>
    </div>
  </div>
</div>
