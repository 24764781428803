import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NotificationInterface} from '../interfaces/notification.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotificationService {
  constructor(private http: HttpClient) {
  }
  async getNotifications(): Promise<NotificationInterface[]>{
    return await this.http.get<NotificationInterface[]>(environment.url + '/users/notifications', {}).toPromise();
  }
  private messageSource = new BehaviorSubject<number>(null);
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: number): void {
    this.messageSource.next(message);
  }
}
