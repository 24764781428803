<div class="main-content full-screen grey-background cart-panel" style="min-height: initial">

  <div class="cart" *ngIf="cart && cart.imageUrl">
    <div class="item row">
      <div class="image col-4 col-md-2">
        <img [src]="env + cart.imageUrl" class="img-fluid" alt="Product image" />
      </div>
      <div class="description col-8 col-md-10">
        <h6 class="mb-2">{{cart.productName}}</h6>
        <small class="text-grey"></small>
        <p class="mt-2">
          <span class="h6" *ngIf="product && product.slotPrice">Rp {{ui.currencyFormat(product.slotPrice, locale)}}</span> <span class="h5 text-grey">/ SLOT</span>
        </p>
        <div class="actions">
          <button class="clear-transparent"  mat-flat-button (click)="removeItem()">
            <span class="icon-trash"></span>
          </button>
          <div class="qty-wrapper">
            <button [disabled]="disableAdjust" class="clear-transparent" mat-flat-button (click)="adjustQty('min')">
              <span class="icon-clarity_minus-circle-solid"></span>
            </button>
            <input type="number" class="qty text-center" [formControl]="quantityFC" (keyup)="qtyChange()" />
            <button [disabled]="disableAdjust" class="clear-transparent" mat-flat-button (click)="adjustQty('plus')">
              <span class="icon-plus"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cart-co row no-gutters">
    <div class="total col-6">
      <h6 class="text-grey text-weight-regular">Total Harga</h6>
      <h4 class="font-weight-bold">Rp. {{(cart && cart.amount)? ui.currencyFormat(cart.amount, locale) : 0}}</h4>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-12 px-2 white-background">
      <button [disabled]="disableCheckout" class="primary-button col-12" [routerLink]="['/member/cart-confirm']" mat-flat-button>Proses Checkout</button>
    </div>
  </div>
</div>
