import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE} from 'src/app/constants/route.constant';
import {InvestmentService} from '../../../services/investment.service';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {PurchaseInterface} from '../../../interfaces/investment.interface';
import {FileService} from '../../../services/file.service';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'app-page-my-business-detail-transaction',
  templateUrl: './detail-transaction.page.html'
})
export class MemberBusinessDetailTransactionPageComponent extends BaseComponent implements OnInit{
  public MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE = MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE;
  private readonly purchaseId: number;
  public myInvestment: PurchaseInterface;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);

  constructor(
    injector: Injector,
    private investmentService: InvestmentService,
    private fileManager: FileService,
    private clipboardService: ClipboardService
  ) {
    super(injector);
    this.purchaseId = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.investmentService.findOnePurchaseHistory(this.purchaseId).then((res) => {
      this.myInvestment = res;
    });
  }
  downloadEvidence(): void {
    this.fileManager.downloadEvidenceTransfer(this.purchaseId, this.myInvestment.attachment).then((file) => {
      this.ui.downloadFile(file, this.myInvestment.attachment);
    });
  }
  downloadInvoice(): void {
    this.fileManager.downloadInvoice(this.purchaseId).then((file) => {
      this.ui.downloadFile(file, 'invoice-pembelian-' + this.purchaseId + '.pdf');
    })
  }
  copyToClipboard(accountNo: string) {
    this.clipboardService.copyFromContent(accountNo);
    this.snackBar.open('No Rekening '+ accountNo +' berhasil disalin', '', {duration: 2000});
  }
}
