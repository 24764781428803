<div class="main-content pb-1" *ngIf="isSignedIn">
    <h1 class="h4">{{'common.hi' | translate}}, {{userProfile.basic.fullName}}</h1>
    <p>{{'pages.profile.complete-your-profile' | translate}}</p>
    <form [formGroup]="infoFG" (submit)="sendBasic()">
        <div class="form-group">
            <label for="profileFullName">{{'pages.profile.full-name' | translate}}</label>
            <input formControlName="fullNameFC" id="profileFullName" type="text" class="form-control" aria-describedby="Nama Lengkap" placeholder="{{'pages.profile.full-name-id' | translate}}" />
        </div>
        <div class="form-group">
            <label for="profileBornDate">{{'pages.profile.born-date' | translate}}</label>
            <div class="input-group">
                <input formControlName="bornDateFC" id="profileBornDate" type="text" matInput (click)="picker.open()" [matDatepicker]="picker" class="form-control" aria-describedby="date" placeholder="{{'pages.profile.date-format' | translate}}" />
                <div class="input-group-append">
                    <mat-datepicker-toggle class="" matSuffix [for]="picker"></mat-datepicker-toggle>
                </div>
            </div>
            <mat-datepicker #picker></mat-datepicker>
        </div>
        <div class="form-group">
            <label>{{'pages.profile.sex-type' | translate}}</label>
            <div class="">
                <mat-radio-button [value]="1" (change)="setSexTypeChecker($event)"  [checked]="sexTypeChecker == 1">{{'pages.profile.male' | translate}}</mat-radio-button>
            </div>
            <div class="">
                <mat-radio-button [value]="2" (change)="setSexTypeChecker($event)" [checked]="sexTypeChecker == 2">{{'pages.profile.female' | translate}}</mat-radio-button>
            </div>

        </div>
        <div class="form-group">
            <label for="profilePhone">{{'pages.profile.phone-no' | translate}}</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">+62</span>
                </div>
                <input id="profilePhone" formControlName="phoneFC" type="tel" class="form-control" aria-describedby="phone" placeholder="{{'pages.profile.phone-no-example' | translate}}" />
            </div>
        </div>
        <div class="form-group">
            <label for="profileAddress">{{'pages.profile.complete-address' | translate}}</label>
            <textarea formControlName="addressFC" id="profileAddress" class="form-control" placeholder="{{'pages.profile.write-address-here' | translate}}"></textarea>
        </div>
        <div class="form-group">
            <label>{{'location.province' | translate}}</label>
            <ng-select formControlName="provinceFC" [items]="provinces" (change)="getDistricts(1)"
                    bindLabel="label"
                    placeholder="{{'pages.profile.select-province' | translate}} "
                    bindValue="value"
                    class="ng-select"
                    >
            </ng-select>
        </div>
        <div class="form-group">
            <label>{{'location.district' | translate}}</label>
            <ng-select formControlName="districtFC" [items]="districts" (change)="getSubDistricts(1)"
                    bindLabel="label"
                    placeholder="{{'pages.profile.select-district' | translate}}"
                    bindValue="value"
                    class="ng-select"
                    >
            </ng-select>
        </div>
        <div class="form-group">
            <label>{{'location.sub-district' | translate}}</label>
            <ng-select formControlName="subDistrictFC" [items]="subDistricts" (change)="getVillages(1)"
                    bindLabel="label"
                    placeholder="{{'pages.profile.select-sub-district' | translate}}"
                    bindValue="value"
                    class="ng-select"
                    >
            </ng-select>
        </div>
        <div class="form-group">
            <label>{{'location.village' | translate}}</label>
            <ng-select formControlName="villageFC" [items]="villages"
                    bindLabel="label"
                    placeholder="{{'pages.profile.select-village' | translate}}"
                    bindValue="value"
                    class="ng-select"
                    >
            </ng-select>
        </div>
        <div class="form-group">
            <label for="profileZipCode">{{'location.zip-code' | translate}}</label>
            <input formControlName="zipCodeFC" id="profileZipCode" type="number" class="form-control" aria-describedby="phone" placeholder="{{'pages.profile.zip-code-example' | translate}}" />
        </div>
        <div class="form-group">
         <button [disabled]="infoFG.status != validForm" class="primary-button big-button" mat-flat-button>{{'common.save' | translate}}</button>
        </div>
    </form>
</div>
