import {AfterViewInit, Component, Injector, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_REDEEM_HISTORY_PAGE} from 'src/app/constants/route.constant';
import {RewardService} from '../../../services/reward.service';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {RewardInterface} from '../../../interfaces/reward.interface';
import {InvestmentService} from '../../../services/investment.service';
import {MyInvestmentInterface} from '../../../interfaces/product.interface';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {MD_MEMBERSHIP_INFO_CONTENT} from '../../../constants/json.constant';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-rewards',
  templateUrl: './rewards.page.html'
})

export class MemberRewardsComponent extends BaseComponent implements OnInit, AfterViewInit{
  public MEMBER_REDEEM_HISTORY_PAGE = MEMBER_REDEEM_HISTORY_PAGE;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public dateTime: string;
  public rewards: RewardInterface[] = [];
  public myInvestments: MyInvestmentInterface[] = [];
  public showCard = false;
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private rewardService: RewardService,
    private investmentService: InvestmentService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.rewardService.getRewards().then((res) => {
      this.rewards = res;
    });
    this.investmentService.getUserInvestments().then((res) => {
      this.myInvestments = res;
      this.showCard = true;
    });
  }
  ngAfterViewInit(): void {
    setInterval(() => {
      this.dateTime = this.date.getCurrentDateTime(this.locale);
    }, 1000);
  }
  showHelp(): void {
    const data: DialogInterface = {
      title : 'Membership ramerame.co.id',
      subtitle: 'Membership ramerame.co.id',
      markdownPath: MD_MEMBERSHIP_INFO_CONTENT,
      buttons: [
        {label: 'common.close', value: 1, className: 'primary-button big-button'},
      ]
    };
    this.dialog.open(DialogScrollComponent, {data});
  }
  showDetailReward(reward: RewardInterface): void {
    const data: DialogInterface = {
      title : reward.title,
      subtitle: reward.title,
      imageUrl: reward.imageUrl,
      imageAlt: reward.title,
      content: reward.description,
      buttons: [
        {label: 'common.close', value: 1, className: 'primary-button big-button'},
      ]
    };
    this.dialog.open(DialogScrollComponent, {data});
  }
}
