<div class="main-content">
  <form [formGroup]="brFG" (submit)="sendMail()">
    <h1 class="h4 text-weight-regular">Punya usaha yang ingin di bangun rame-rame?</h1>

    <div class="form-group">
        <label for="NamaLengkap">Nama Lengkap</label>
        <input id="NamaLengkap" formControlName="fullNameFC" class="form-control" aria-describedby="Nomor identitas"  />
    </div>
    <div class="form-group">
        <label for="AlamatEmail">Alamat Email</label>
        <input id="AlamatEmail" type="email" formControlName="emailFC" class="form-control" aria-describedby="Nomor identitas"  />
    </div>
    <div class="form-group">
        <label for="namaUsaha">Nama Usaha</label>
        <input id="namaUsaha" formControlName="businessNameFC" class="form-control" aria-describedby="Nomor identitas"  />
    </div>
    <div class="form-group">
        <label for="website">Website</label>
        <input id="website" formControlName="websiteFC" class="form-control" placeholder="https://" aria-describedby="Nomor identitas"  />
    </div>
    <div class="form-group">
        <label for="Socmed">Akun social media <span class="text-grey">(optional)</span></label>
        <input id="Socmed" formControlName="socialMediaFC" class="form-control" placeholder="https://" aria-describedby="Nomor identitas"  />
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Kategori Usaha <span class="text-grey">(Boleh lebih dari satu)</span></label>
        </div>
      </div>
    </div>
    <div class=" row">
        <div class="form-check col-6" *ngFor="let cat of categories" >
            <mat-checkbox (change)="pushSpliceCategory($event)" [value]="cat">{{cat}}</mat-checkbox>
        </div>
    </div>

    <div class="form-group mt-2">
        <label for="jumlahDana">Jumlah pendanaan yang kamu butuhkan</label>
        <input id="jumlahDana" prefix="Rp. " mask="separator.2" thousandSeparator="." formControlName="fundingNeededFC" class="form-control" placeholder="Rp" aria-describedby="Nomor identitas"  />
    </div>
    <div class="form-group">
        <label for="tentangTeam">Tentang Team</label>
        <textarea id="tentangTeam" formControlName="aboutTeamFC" class="form-control" placeholder="Ceritakan tentang team pendiri dan latar belakang mereka (maks 350 karakter)"></textarea>
    </div>

    <div class="form-group">
        <label for="pitch">Elevator pitch</label>
        <textarea id="pitch" class="form-control" formControlName="elevatorPitchFC" placeholder="Ceritakan lebih lanjut tentang mengapa, bagimana dan apa yang team kamu lakukan (maks 700 karakter)"></textarea>
    </div>

    <div class="form-group">
      <label for="driveLinkFC">Link Proposal Anda <span class="text-grey">(Google Drive: Share ke ramerame.biz@gmail.com)</span></label>
      <input id="driveLinkFC" formControlName="driveLinkFC" class="form-control" placeholder="https://" aria-describedby="Nomor identitas"  />
    </div>
    <div class="form-group">
        <button class="primary-button big-button" mat-flat-button>Kirim</button>
    </div>
  </form>
</div>
