import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {VOUCHER_BASE_URL} from '../constants/api.constant';
import {VoucherInterface} from '../interfaces/voucher.interface';

@Injectable({providedIn: 'root'})
export class VoucherService {
  constructor(private http: HttpClient) {}
  async verifyVoucherCode(voucherCode: string): Promise<VoucherInterface> {
    return await this.http.post<VoucherInterface>(environment.url + VOUCHER_BASE_URL + `/verify`, {voucherCode}, {}).toPromise();
  }
  async claimVoucherCode(voucherCode: string): Promise<VoucherInterface> {
    return await this.http.post<VoucherInterface>(environment.url + VOUCHER_BASE_URL + `/claim`, {voucherCode}, {}).toPromise();
  }
  async voucherHistory(): Promise<VoucherInterface[]> {
    return await this.http.get<VoucherInterface[]>(environment.url + VOUCHER_BASE_URL , {}).toPromise();
  }
}
