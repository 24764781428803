<div class="redeem-history">
    <div class="card">
        <div class="row">
            <div class="col-6">
                <h6>Kopi Satu - 30% Discount</h6>
                <p class="text-grey">
                    <span class="date d-block">#12312312331</span>
                </p>
                
            </div>
            <div class="col-6 text-right">
                <div class="ribbon static red">Success</div>
            </div>
        </div>
    </div>
</div>