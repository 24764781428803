import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {TeamMemberInterface} from '../../interfaces/common.interface';
import {ABOUT_US_PROFILES} from 'src/app/constants/common.constant';
import {ABOUT_US_DETAIL_PAGE} from '../../constants/route.constant';

@Component({
  selector: 'app-page-about-us',
  templateUrl: './about-us.page.html',
})
export class AboutUsPageComponent extends BaseComponent implements OnInit {
  public teamMembers: TeamMemberInterface[] = ABOUT_US_PROFILES;
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  toDetails(t: TeamMemberInterface): void {
    this.router.navigate([ABOUT_US_DETAIL_PAGE + '/' + t.short]).then(() => {});
  }
}
