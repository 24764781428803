import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {InvestmentService} from '../../services/investment.service';
import {UiHelper} from '../../helpers/ui.helper';
import {ProductInterface} from '../../interfaces/product.interface';

@Component({
  selector: 'app-file-list-cert',
  templateUrl: './file-list-certificate.component.html'
})
export class FileListCertificateComponent implements OnInit{
  private ui = UiHelper;
  public product: ProductInterface;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private investmentService: InvestmentService
  ) {
  }
  ngOnInit(): void {
    this.product = this.data.product as ProductInterface;
  }

  documentDownload(docType: string) {
    switch (docType) {
      case 'cert': {
        this.investmentService.getCertificate(this.data.product.id, 'certificate').then((file) => {
          this.ui.downloadFile(file, this.data.product.id + '_certificate.png');
        })
        break;
      }
      case 'term': {
        this.investmentService.getCertificate(this.data.product.id, 'term').then((file) => {
          this.ui.downloadFile(file, this.data.product.id + '_term.pdf');
        })
        break;
      }
      case 'prop': {
        this.investmentService.getCertificate(this.data.product.id, 'proposal').then((file) => {
          this.ui.downloadFile(file, this.data.product.id + '_proposal.pdf');
        })
        break;
      }
      case 'agreement': {
        this.investmentService.getCertificate(this.data.product.id, 'agreement').then((file) => {
          this.ui.downloadFile(file, this.data.product.id + '_agreement.pdf');
        })
        break;
      }
    }
  }
}
