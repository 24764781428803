import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-wallet-filter',
  templateUrl: './wallet-dialog-filter.component.html',
})
export class MemberWalletFilterDialogComponent{
  public onFilterSelected  = new EventEmitter();
  public selectedFilter: UntypedFormControl = new UntypedFormControl('ALL', []);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MemberWalletFilterDialogComponent>,) { }
  apply(): void {
    this.onFilterSelected.emit(this.selectedFilter.value);
  }
}
