<div class="foot-menu">
    <ul class="bottomNav">
      <ng-container *ngFor="let menu of menuBottom">
        <li [class.active]="state == menu.group">
            <a *ngIf="isLogin" [routerLink]="menu.url" class="clear-transparent">
                <span class="{{menu.icon}}"></span>
                {{menu.label | translate}}
            </a>
            <ng-container *ngIf="!isLogin">
              <a *ngIf="menu.group == 'wallet' || menu.group == 'reports'" [routerLink]="ui.absoluteUrl(loginUrl)" class="clear-transparent">
                <span class="{{menu.icon}}"></span>
                {{menu.label | translate}}
              </a>
              <a *ngIf="menu.group != 'wallet' && menu.group != 'reports'" [routerLink]="menu.url" class="clear-transparent">
                <span class="{{menu.icon}}"></span>
                {{menu.label | translate}}
              </a>
            </ng-container>
        </li>
      </ng-container>
    </ul>
</div>
