<div class="main-content full-screen usaha-detail" *ngIf="product.id">
    <div class="top-image">
      <div class="ribbon-group">
        <div *ngIf="product.status == 'RUN'"  class="ribbon-group-children gray">{{ 'status.run' | translate }}</div>
          <div *ngIf="product.status == 'AC'" class="ribbon-group-children orange">{{ 'status.active' | translate }}</div>
          <div *ngIf="product.status == 'ADM'"  class="ribbon-group-children green">{{ 'status.administration' | translate }}</div>
          <div *ngIf="product.status == 'DONE'"  class="ribbon-group-children blue">{{ 'status.done' | translate }}</div>
          <div *ngIf="product.insuranceId && productInsurances.length > 0" class="ribbon-group-children insurance-color">{{ 'pages.insurance.ribbon-title' | translate:{name:productInsurances[0].insuranceName} }}</div>
      </div>
      <div class="top-slider-wrapper">
            <owl-carousel-o [options]="upperSliderOptions" class="product-slider">
                <ng-template carouselSlide *ngFor="let img of product.otherImgs">
                    <div class="slider-content-wrapper bg-brand-color">
                        <img class="card-img-top" [src]="baseUrl + img.value" alt="Card image cap">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="card p-3">
        <div>
            <span class="ribbon static grey mb-3">{{product.productCategory}}</span>
        </div>
        <h4>{{product.productName}}</h4>
        <!-- <h6 class="text-grey text-weight-semi-bold">Bhinneka Djaja</h6> -->
        <span class="h4 mt-3 mb-3">{{'common.currency-idr' | translate}} {{ui.currencyFormat(product.totalAmount, locale)}}</span>
        <div class="well mt-4 mb-4 summary-info">
            <div class="row">
                <div class="col-6">
                  <span class="title">{{'pages.business-list.required-slot' | translate}}</span>
                  <span class="value">{{ui.toInteger(product.totalSlot + '')}} {{'common.slot' | translate}}</span>
                </div>
                <div class="col-6 text-right">
                  <span class="title">{{'pages.business-list.price-per-slot' | translate}}</span>
                  <span class="value">{{'common.currency-idr' | translate}}  {{ui.currencyFormat(product.slotPrice, locale)}}</span>
                </div>

            </div>
            <div class="row">
                <div class="col-6">
                  <span class="title">{{'pages.business-list.remaining-amount' | translate}}</span>
                  <span class="value">{{'common.currency-idr' | translate}} {{ui.currencyFormat(product.remainingFunding, locale)}}</span>
                </div>
                <div class="col-6 text-right">
                  <span class="title">{{'pages.business-list.remaining-slot' | translate}}</span>
                  <span class="value">{{product.remainingSlot}}</span>
                </div>

            </div>
            <div class="row">
                <div class="col-6">
                  <span class="title">{{'pages.business-list.investor-total' | translate}}</span>
                  <span class="value">{{product.totalInvestor}} {{'common.member' | translate}}</span>
                </div>
                <div class="col-6 text-right">
                  <span class="title">{{'pages.business-list.contract-duration' | translate}}</span>
                  <span class="value">{{product.investMonth}} {{'common.month' | translate}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <span class="title">{{'pages.business-list.funding-due' | translate}}</span>
                    <span class="value">{{date.localeDate(product.fundingDue, locale)}} </span>
                    <span class="value">({{(date.remainingDate(product.fundingDue) > 0)? date.remainingDate(product.fundingDue) : 0}} {{'common.day-more' | translate}})</span>
                </div>
                <div class="col-6 text-right">
                    <span class="title">{{'common.status' | translate}}</span>
                    <span class="value">{{product.productStatus}}</span>
                </div>
            </div>
            <div class="row">

            </div>
            <div class="row">
                <div class="col-12">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (((product.totalSlot - (product.remainingSlot))/product.totalSlot) * 100) + '%'}" aria-valuenow="(((product.totalSlot - (product.remainingSlot))/product.totalSlot)" aria-valuemin="0" aria-valuemax="100">{{((product.totalSlot - (product.remainingSlot))/product.totalSlot) * 100 | number}}%</div>
                      </div>
                </div>
            </div>

        </div>
        <h4 class="">{{'common.description' | translate}}</h4>
        <p class="card-text" [innerHTML]="product.longDescription | keepHtml"></p>
        <div *ngIf="product.insuranceId && productInsurances.length > 0">
          <h4 class="">{{'pages.insurance.title' | translate}}</h4>
          <h4>{{productInsurances[0].insuranceName}}</h4>
          <p class="card-text" [innerHTML]="productInsurances[0].insuranceDesc | keepHtml"></p>
        <div class="well mt-4 mb-4">
          <table class="table table-striped">
            <tbody>
            <tr *ngFor="let ins of productInsurances">
              <td class="text-right"><span class="icon-file-pdf"></span></td>
              <td>{{ins.title}}</td>
              <td class="text-right">
                <a class="icon-download3" href="{{baseUrl + ins.document}}" target="_blank"></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        </div>
        <!-- available after login -->
        <h4 class="mt-3">{{'location.location-project' | translate}}</h4>
        <iframe *ngIf="product.productMap" [src]="productMapSafe" width="100%" height="450" class="border-zero" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

        <button class="secondary-button big-button mt-3" mat-flat-button (click)="openDocumentDialog()">{{'pages.business-list.download-document' | translate}}</button>
        <button class="primary-button big-button mt-3" mat-flat-button (click)="openTosDialog()" *ngIf="product.status == 'AC'">{{'pages.business-list.buy-this-product' | translate}}</button>

    </div>
</div>
