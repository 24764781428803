export const TESTIMONIES_BASE_URL = '/testimonies';
export const PARTNERS_BASE_URL = '/partners';
export const LANDING_LABELS_BASE_URL = '/labels/landing';
export const PRODUCT_MERGE_SIMPLE_BASE_URL = '/products/simple/';
export const PRODUCT_MERGE_AFTER_LOGGED_IN_BASE_URL = '/products/complete/';
export const PRODUCT_BASE_URL = '/products';
export const LANDING_BANNER_BASE_URL = '/banner/landing';
export const LANDING_HOW_IT_WORKS_BASE_URL = '/landing/how-it-works';
export const LANDING_SETTINGS_BASE_URL = '/landing/settings';
export const AUTH_BASE_URL = '/authenticate';
export const CURRENT_USER_URL = '/users/current';
export const ONE_PRODUCT_BASE_URL = '/products/details/';
export const MASTER_PROVINCE_BASE_URL = '/masters/provinces';
export const MASTER_DISTRICT_BASE_URL = '/masters/districts';
export const MASTER_SUB_DISTRICT_BASE_URL = '/masters/sub-districts';
export const MASTER_VILLAGE_BASE_URL = '/masters/villages';
export const MASTER_BANKS_BASE_URL = '/masters/banks';
export const IMAGES_PROFILE_BASE_URL = '/images/avatar';
export const SUPPORT_TICKET_BASE_URL = '/users/support-tickets';
export const MY_INVESTMENT_BASE_URL = '/users/my-investments';
export const MY_PURCHASE_HISTORIES_BASE_URL = '/users/my-investments/purchase-histories';
export const ACTIVE_PRODUCT_BANK_ACCOUNT_BASE_URL = '/users/my-investments/banks/active';
export const WALLET_BASE_URL = '/users/wallets';
export const WITHDRAW_BASE_URL = '/users/withdraw';
export const REGISTER_BASE_URL = '/register';
export const REGISTER_TOKEN_VERIFY_BASE_URL = '/verify';
export const FORGOT_PASSWORD_BASE_URL = '/forgot-password';
export const RESET_PASSWORD_BASE_URL = '/reset-password';
export const REPORT_BASE_URL = '/users/reports/';
export const TRANSACTION_INVOICE_BASE_URL = '/users/transaction/';
export const SERVER_TIME_BASE_URL = '/server/time';
export const REWARD_BASE_URL = '/users/rewards';
export const SECURITY_CHANGE_PASSWORD_BASE_URL = '/users/change-password';
export const PROFILE_BASE_URL = '/users/profile';
export const VOUCHER_BASE_URL = '/vouchers';
export const SHOPPING_CART_BASE_URL = '/cart';
export const CAMPAIGN_BASE_URL = '/campaign';
export const MERCHANDISE_BASE_URL = '/merchandise';
export const CAMPAIGN_VOUCHER_BASE_URL = '/campaign-voucher';
export const MERCHANDISE_VOUCHER_BASE_URL = '/merchandise-voucher';
export const MERCHANT_BASE_URL = '/merchant';
export const WALLET_POINT_BASE_URL = '/wallet-point';
export const PRODUCT_INSURANCES_BASE_URL = '/product-insurances/list/';
export const CHECKING_TERM_CONDITION_BASE_URL = '/check-term';
export const SAVING_TERM_CONDITION_BASE_URL = '/accept-term';


