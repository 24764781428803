<div class="main-content">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label>Penjualan bulan</label>
                <ng-select [formControl]="monthFC" [items]="monthList"
                        bindLabel="id"
                        placeholder="Pilih Bulan"
                        bindValue="month"
                        class="ng-select"
                        >
                </ng-select>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Penjualan Tahun</label>
                <ng-select [formControl]="yearFC" [items]="yearList"
                        bindLabel="year"
                        placeholder="Pilih Tahun"
                        bindValue="year"
                        class="ng-select"
                        >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="well no-border">
        <h5>Total Omzet {{product.productName}}</h5>
        <span class="h3">Rp. {{ui.currencyFormat(total, locale)}}</span>
    </div>


    <div class="transactions">
        <div *ngFor="let ds of dailySales" class="transaction">
            <div class="card">
                <div class="row">
                    <div class="col-6">
                        <h5>{{date.localeDate(ds.salesDate, locale)}}</h5>
                    </div>
                    <div class="col-6 text-right">
                        <h6>Rp. {{ui.currencyFormat(ds.salesAmount, locale)}}</h6>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                        <h6></h6>
                    </div>
                    <div class="col-6 text-right">
                        <span *ngIf="ds.attachmentUrl" (click)="download(ds)">Unduh Laporan</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
