import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MONTHLY_REPORT_GRAPH_CONFIG} from 'src/app/constants/monthly-report.constant';
import {MyROIInterface, ProductInterface} from '../../../interfaces/product.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {FileService} from '../../../services/file.service';

@Component({
  selector: 'app-investment-monthly-report',
  templateUrl: './investment-montly-report.page.html'
})
export class MemberInvestmentMonthlyReportPageComponent extends BaseComponent implements OnInit, OnChanges{
  public graphConfig = MONTHLY_REPORT_GRAPH_CONFIG;
  @Input() rois: MyROIInterface[] = [];
  public roisCopy: MyROIInterface[] = [];
  @Input() product: ProductInterface = {};
  @Input() multi: any[] = [];
  @Input() total = 0;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  constructor(
    injector: Injector,
    private fileService: FileService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.roisCopy = JSON.parse(JSON.stringify(this.rois));
    this.roisCopy.reverse();
  }

  download(roi: MyROIInterface): void {
    this.fileService.downloadMonthlyReport(this.product.id, roi.attachment).then((file) => {
      this.ui.downloadFile(file, roi.attachment);
    });
  }
  invoice(roi: MyROIInterface): void {
    this.fileService.downloadInvoiceReturn(roi.userTransactionId).then((file) => {
      this.ui.downloadFile(file, 'invoice.pdf');
    });
  }
}
