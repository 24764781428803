import {CommonModule} from '@angular/common';
import {AppSharedModule} from '../../../shared/shared.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from '../../../app-routing.module';
import {NgProgressModule} from 'ngx-progressbar';
import {MemberChangePasswordPageComponent} from './member-change-password.page';
import {MaterialModule} from '../../../material/material.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    MaterialModule
  ],
  exports: [
    MemberChangePasswordPageComponent
  ],
  declarations: [
    MemberChangePasswordPageComponent
  ],
  providers: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMemberSecurity {}
