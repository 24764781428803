<div class="fixActionRow">

  <div class="close-button">
    <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="overflow-hidden">
      <iframe [cachedSrc]="data" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </mat-dialog-content>
</div>

