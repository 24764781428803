import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {LandingService} from '../../services/landing.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-page-contact-us',
  templateUrl: './contact-us.page.html',
})
export class ContactUsPageComponent extends BaseComponent implements OnInit{
  public labels: any = {};
  public mapSafe: SafeResourceUrl;
  public facebookSafe: SafeResourceUrl;
  public instagramSafe: SafeResourceUrl;
  public contactFG: UntypedFormGroup;
  constructor(
    injector: Injector,
    private landingService: LandingService,
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder
  ) {
    super(injector);
    this.contactFG = this.formBuilder.group({
      nameFC: ['', [Validators.required]],
      emailFC: ['', [Validators.required]],
      titleFC: ['', [Validators.required]],
      questionFC: ['', [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.landingService.getSiteIdentitiesAsync().then((res) => {
      this.labels = res;
      this.mapSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.labels.company_map);
      this.facebookSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.labels.facebook);
      this.instagramSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.labels.instagram);
      });
  }
  send(): void {
    // TODO:
  }
}
