<div class="main-content member-wallet">
  <div class="millionaire-card">
    <div class="background">
      <img
        class="img-fluid"
        src="/assets/images/member/member-card@2x.jpg"
        alt="millionaire member card"
      />
    </div>
    <div class="card-content">
      <h4 class="saldo-label">{{'pages.wallet.wallet-balance' | translate}}</h4>
      <h4 class="saldo">
        {{'common.currency-idr' | translate}}
        <ng-container *ngIf="!isRegistered()">
          {{ui.currencyFormat(wallet.balance ?? 0, locale) }}
        </ng-container>

        <ng-container *ngIf="isRegistered()"> - </ng-container>
      </h4>

      <div class="error-content" *ngIf="isRegistered()">
        <h3 class="error-title">
          <ng-container *ngIf="isRegistered()">
            {{'pages.wallet.error-title-is-registered' | translate}}
          </ng-container>
        </h3>
        <h4 class="error-description">
          <ng-container *ngIf="isRegistered()">
            {{'pages.wallet.error-description-is-registered' | translate}}
          </ng-container>
        </h4>
      </div>

      <div class="actions-button">
        <button
          class="secondary-button big-button inline"
          [routerLink]="!isRegistered() ? [ui.absoluteUrl(MEMBER_WALLET_WITHDRAW_PAGE)] : []"
          mat-flat-button
          (click)="isRegistered() && openRegisteredAccountDialog()"
        >
          <span class="icon-withdraw mr-2"></span>
          <span class="label">{{'pages.wallet.withdraw' | translate}}</span>
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isRegistered()">
    <div class="filter-panel mt-3 mb-3">
      <h4>{{'pages.wallet.wallet-history' | translate}}</h4>
      <button
        class="grey-button small-button"
        (click)="openFilter()"
        mat-flat-button
      >
        <span class="icon-filter mr-2"></span>
        <span class="">{{'common.filter' | translate}}: {{filterLabel}}</span>
      </button>
    </div>
    <div class="transactions row mt-3 grey-background">
      <ng-container *ngIf="walletFilter.length > 0">
        <ng-container *ngFor="let d of walletFilter">
          <div
            (click)="toMonthlyReport(d)"
            class="transaction col-12"
            *ngIf="d.debit != 0 || d.credit != 0"
          >
            <div class="card">
              <div class="row">
                <div class="col-6">
                  <h6>{{(d.status == 'KFR')? d.meta.description : d.label}}</h6>
                  <h6>
                    {{(d.meta && d.meta.productName)? d.meta.productName : ''}}
                    {{(d.meta && d.meta.voucherCode)? d.meta.voucherCode : ''}}
                  </h6>
                  <p class="text-grey">
                    <span class="date d-block"
                      >{{date.localeDate(d.walletDate, locale)}}</span
                    >
                  </p>
                </div>
                <div class="col-6 text-right">
                  <div class="ribbon static green" *ngIf="d.flag == 1">
                    Sukses
                  </div>
                  <div
                    class="ribbon static red"
                    *ngIf="d.flag == 2 && d.status != 'UP'"
                  >
                    Suspen
                  </div>
                  <div
                    class="ribbon static green"
                    *ngIf="d.flag == 2 && d.status == 'UP'"
                  >
                    Sukses
                  </div>
                  <div class="ribbon static orange" *ngIf="d.flag == 3">
                    Dikembalikan
                  </div>
                  <div
                    class="ribbon static green"
                    *ngIf="d.flag == 0 && d.status == 'DEP'"
                  >
                    Sukses
                  </div>
                  <div
                    class="ribbon static yellow"
                    *ngIf="d.flag == 0 && d.status != 'DEP'"
                  >
                    Menunggu
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <h6 *ngIf="d.credit != 0">
                    {{'common.currency-idr' | translate}}
                    {{ui.currencyFormat(d.credit, locale)}} (CR)
                  </h6>
                  <h6 *ngIf="d.debit != 0">
                    {{'common.currency-idr' | translate}}
                    {{ui.currencyFormat(d.debit, locale)}} (DB)
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="walletFilter.length == 0">
        <div class="transaction col-12">
          <div class="card">{{'pages.wallet.no-transaction' | translate}}</div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
