<div class="main-content">
  <div class="row mb-3">
    <div class="col-12">
      <div class="well grey2">
        <p>Hi, member ramerame.co.id, kamu dapat mengklaim voucher ramerame.co.id yang kamu bisa dapatkan dari keikutsertaan dalam event/kegiatan yang diadakan atau diikuti oleh ramerame.co.id</p>
      </div>
    </div>
  </div>
  <div  class="row mb-3">
    <div class="col-12">
      <mat-tab-group [selectedIndex]="0">
            <mat-tab label="Klaim Voucher">
              <div class="row no-gutters mt-2 align-items-center">
                <div class="col-12 px-3">
                  <div class="form-group">
                    <label>Kode Voucher</label>
                    <input [(ngModel)]="voucherCode" class="form-control" type="text" placeholder="masukkan kode voucher" aria-describedby="kode voucher" />
                  </div>
                </div>
              </div>
              <div class="row no-gutters align-items-center">
                <div class="col-12 px-3">
                  <button type="button" [disabled]="(voucherCode)? voucherCode.length < 3 : true" (click)="validate()" class="primary-button big-button" mat-flat-button>Validasi</button>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Histori Klaim">
              <div class="card mt-2" *ngFor="let h of history">
                <div class="card-body p-2">
                  <div class="row no-gutters mt-2 align-items-center">
                    <div class="col-12 px-3">
                      <h6>{{h.eventName}}</h6>
                      <b>Rp. {{ui.currencyFormat(h.amount, locale)}}</b>
                      <p>{{date.completeLocaleDateTime(h.claimedAt, locale)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
    </div>
  </div>
</div>
