<div class="main-content full-screen with-fixed-bottom-button">

    <div class="background" *ngIf="!failed">
        <img src="../../../../assets/images/member/alert-image4-min.png" alt="sedang proses" />
        <h3 class="mt-3 mb-3">Verifikasi sedang proses</h3>
        <p>Verifikasi identitas mu sedang diproses. Kamu akan menerima pemberitahuan setelah verifikasi identitasmu berhasil</p>
    </div>
    <div class="background" *ngIf="failed">
        <img src="../../../../assets/images/member/alert-image5-min.png" alt="sedang proses" />
        <h3 class="mt-3 mb-3">Maaf, Verifikasi mu gagal</h3>
        <p>Verifikasi identitas mu gagal. check kembali identitas mu dan coba ulangi kembali lagi</p>
    </div>
    <div class="footer-buttons">
        <button class="primary-button big-button" mat-button [routerLink]="ui.absoluteUrl(MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE)">Ulangi Verifikasi</button>
        <button class="primary-button big-button" mat-button (click)="failed = !failed">Toggle Background (DEMO)</button>
    </div>
</div>
