import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html'
})
export class BlankLayoutComponent implements OnInit{
  ngOnInit(): void {
  }
}
