<div class="close-button d-flex justify-content-between">
  <b>Pertanyaan baru</b> <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<mat-dialog-content class="mat-typography">
<form [formGroup]="askFG" (submit)="save()">
    <div class="form-group">
        <label>Judul</label>
        <input formControlName="titleFC" type="text" class="form-control" placeholder="Tulis Judul" aria-describedby="Berita" />
    </div>
    <div class="form-group">
        <label>Kategori</label>
        <select formControlName="categoryFC" class="form-control">
          <option *ngFor="let cat of CATEGORY_OPTIONS_MULTI_LANG" [value]="cat.value">{{cat.id}}</option>
        </select>
    </div>
    <div class="form-group has-error">
        <label>Pertanyaan</label>
        <textarea formControlName="contentFC" rows="10" class="form-control" placeholder="Tulis pertanyaanmu disini"></textarea>
    </div>
    <div class="mt-3">
        <button mat-button class="primary-button big-button">Kirim Pertanyaan</button>
    </div>
</form>
</mat-dialog-content>
