import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../../shared/shared.module';
import {MemberTicketFormPageComponent} from './member-ticket-form.page';
import {MemberTicketLandingPageComponent} from './member-ticket-landing.page';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TicketService} from '../../../services/ticket.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {MaterialModule} from '../../../material/material.module';
import {MemberTicketDetailsPageComponent} from './member-ticket-details.page';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    NgSelectModule,
    MaterialModule
  ],
  exports: [
    MemberTicketFormPageComponent,
    MemberTicketLandingPageComponent,
    MemberTicketDetailsPageComponent
  ],
  declarations: [
    MemberTicketFormPageComponent,
    MemberTicketLandingPageComponent,
    MemberTicketDetailsPageComponent
  ],
  providers: [
    TicketService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMemberTicketModule {}
