import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MatDialog} from '@angular/material/dialog';
import {MembercardService} from '../../../services/membercard.service';

@Component({
  selector: 'app-page-voucher-search',
  templateUrl: './voucher-search.page.html'
})
export class VoucherSearchComponent extends BaseComponent implements OnInit {
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private memberCardService: MembercardService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
