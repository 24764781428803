<div class="full-screen registration-modal">
  <div class="full-height-page">
    <div class="top-logo">
      <img [src]="logo" alt="Rame rame logo" />
    </div>
    <div class=" text-center">
      <img [src]="mailSentLogo" alt="mail sent" />
      <h1 class="h3 mt-3 mb-4">{{'pages.login.forgot-password-2' | translate}}</h1>
      <p>{{'pages.login.forgot-password-guide' | translate}}</p>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="input-group mb-3">
              <input [formControl]="emailFC" autocomplete="new-email" name="email" type="email" id="email" class="form-control" aria-label="Masukkan alamat email anda" placeholder="Masukkan alamat email anda">
            </div>
          </div>
        </div>
      </div>
      <button (click)="sendResetPassword()" class="secondary-button text-black mb-4" mat-flat-button>{{'pages.login.send-link-reset-password' | translate}}</button>
      <div class="mb-4">
        <span [routerLink]="['/sign-in']">{{'common.close' | translate}}</span>
      </div>

    </div>
  </div>
</div>
