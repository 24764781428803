<div class="main-content">
  <div class="row">
    <div class="col-12 px-5">
      <img [src]="profile.image" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center pt-3">
      <h3>{{profile.name}}</h3>
      <p>{{profile.position}} ({{profile.short}})</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p [innerHTML]="profile.bio | translate | keepHtml"></p>
    </div>
  </div>
</div>
