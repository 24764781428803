import {RegisterConfirmationPageComponent} from './register-confirmation.page';
import {RegisterWelcomePageComponent} from './register-welcome.page';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {MaterialModule} from '../../material/material.module';
import {NewRegisterPageComponent} from './new-register.page';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    MaterialModule
  ],
  exports: [
    RegisterConfirmationPageComponent,
    RegisterWelcomePageComponent,
    NewRegisterPageComponent
  ],
  declarations: [
    RegisterConfirmationPageComponent,
    RegisterWelcomePageComponent,
    NewRegisterPageComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRegisterModule {}
