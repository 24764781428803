export const DEFAULT_LANGUAGE = 'ID';
export const DEFAULT_LANDING_PRODUCT_LIMIT = 5;
export const LANDING_YOUTUBE_ID = 54;
export const FORM_GROUP_VALID = 'VALID';
export const JWT_TOKEN_KEY = 'token';
export const STORAGE_LANG_KEY = 'lang';
export const STORAGE_PROFILE_KEY = 'profile';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const APP_DATE_FORMAT = 'D MMMM YYYY';
export const APP_SHORT_DATE_FORMAT = 'D MMM YYYY';
export const APP_DATETIME_COMPLETE_FORMAT = 'dddd, D MMMM YYYY HH:mm:ss';
export const DEFAULT_NO_AVATAR = 'assets/images/others/noavatar.png';
export const MINIMUM_WALLET_USED_FOR_CHECKOUT = 100000;
export const RAMERAMECOID_LOGO = 'assets/images/topbar/new-logo@2x.png';
export const MAIL_SENT_LOGO = '/assets/images/icons/mail-send.svg';
export const PERIODE_DATE = 'periodeDate';
export const KSP_PERTIWI_URL = 'https://pertiwi.ramerame.co.id';

export const AVAILABLE_LANGUAGES = [
  { langCode: 'ID', langValue: 'Bahasa' },
  { langCode: 'EN', langValue: 'English' },
];
export const PICKER_DATE_FORMATS = {
  parse: { dateInput: 'LL' },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MONTH_LIST_MULTI_LANG = [
  { month: 1, id: 'Januari', en: 'January', short_id: 'Jan', short_en: 'Jan' },
  {
    month: 2,
    id: 'Februari',
    en: 'February',
    short_id: 'Feb',
    short_en: 'Feb',
  },
  { month: 3, id: 'Maret', en: 'March', short_id: 'Mar', short_en: 'Mar' },
  { month: 4, id: 'April', en: 'April', short_id: 'Apr', short_en: 'Apr' },
  { month: 5, id: 'Mei', en: 'May', short_id: 'Mei', short_en: 'May' },
  { month: 6, id: 'Juni', en: 'June', short_id: 'Jun', short_en: 'Jun' },
  { month: 7, id: 'Juli', en: 'July', short_id: 'Jul', short_en: 'Jul' },
  { month: 8, id: 'Agustus', en: 'August', short_id: 'Agust', short_en: 'Aug' },
  {
    month: 9,
    id: 'September',
    en: 'September',
    short_id: 'Sept',
    short_en: 'Sept',
  },
  { month: 10, id: 'Oktober', en: 'October', short_id: 'Okt', short_en: 'Oct' },
  {
    month: 11,
    id: 'November',
    en: 'November',
    short_id: 'Nov',
    short_en: 'Nov',
  },
  {
    month: 12,
    id: 'Desember',
    en: 'December',
    short_id: 'Des',
    short_en: 'Dec',
  },
];
export const VALID_YEARS = [
  { year: 2019 },
  { year: 2020 },
  { year: 2021 },
  { year: 2022 },
  { year: 2023 },
  { year: 2024 },
  { year: 2025 },
];
export const SUPPORT_TICKET_CATEGORIES = [
  { label: 'Billing', value: 'billing' },
  { label: 'Marketing', value: 'marketing' },
];
export const SUPPORT_TICKET_PRIORITIES = [
  { label: 'rendah', value: 1 },
  { label: 'sedang', value: 2 },
  { label: 'tinggi', value: 3 },
];
export const CAPITAL_VALUES_OPTIONS = [
  {
    value: '1,000,000 sampai dengan 3,000,000',
    label: '1,000,000 sampai dengan 3,000,000',
  },
  {
    value: '3,000,000 sampai dengan 6,000,000',
    label: '3,000,000 sampai dengan 6,000,000',
  },
  {
    value: '6,000,000 sampai dengan 9,000,000',
    label: '6,000,000 sampai dengan 9,000,000',
  },
  { value: 'di atas 9,000,000', label: 'di atas 9,000,000' },
];
export const ESCROW_ACCOUNT = {
  bankName: 'BCA',
  accountNumbers: '7730495000',
  accountOwner: 'P.T Indonesia Bersama Kita Bisa',
};
export const ERR_PROFILE_NOT_COMPLETE = 'profile_not_complete';
export const ABOUT_US_PROFILES = [
  {
    position: 'Chief Executive Officer',
    short: 'CEO',
    name: 'I Gusti Putu Bayu Susila, SH',
    image: 'https://office.ramerame.co.id/images/frontpage/team/ceo.png',
    bio: 'pages.bio.ceo',
  },
  {
    position: 'Chief Financial Officer',
    short: 'CFO',
    name: 'I K G Dharma Hariyadi Setiawan, SE, M.Fin, M.Comm',
    image: 'https://office.ramerame.co.id/images/frontpage/team/cfo.png',
    bio: 'pages.bio.cfo',
  },
  {
    position: 'Chief Technology Officer',
    short: 'CTO',
    name: 'I Komang Mardika, ST',
    image: 'https://office.ramerame.co.id/images/frontpage/team/programmer.png',
    bio: 'pages.bio.cto',
  },
  {
    position: 'Chief Compliance and Risk Officer',
    short: 'CCRO',
    name: 'Dr. Made Gde Subha Karma Resen, S.H., M. Kn',
    image:
      'https://office.ramerame.co.id/images/frontpage/team/head-of-legal.png',
    bio: 'pages.bio.ccro',
  },
  {
    position: 'Project Manager',
    short: 'PM',
    name: 'Putu Adiatma Putera Negara,SE',
    image: 'https://office.ramerame.co.id/images/frontpage/team/pm.png',
    bio: 'pages.bio.pm',
  },
  {
    position: 'Marketing Officer',
    short: 'MO',
    name: 'Sumarno, S.Kom',
    image: 'https://office.ramerame.co.id/images/frontpage/team/cmo.png',
    bio: 'pages.bio.mo',
  },
  {
    position: 'Legal Officer',
    short: 'LO',
    name: 'Kadek Agus Bram Rendrajaya, SH., M.Kn.',
    image:
      'https://office.ramerame.co.id/images/frontpage/team/legal-officer.png',
    bio: 'pages.bio.lo',
  },
  {
    position: 'UX/UI Designer',
    short: 'UX',
    name: 'Puja Arsana S, S.Kom',
    image:
      'https://office.ramerame.co.id/images/frontpage/team/ux-designer.png',
    bio: 'pages.bio.ux',
  },
];
export const VOUCHER_DETAIL_ACTION = {
  BUY: 'buy' /* khusus untuk campaign voucher detail yang akan ditebus -> select ke table campaigns */,
  REDEEM:
    'redeem' /* khusus untuk campaign voucher detail yang akan/sudah di claimed -> select ke table campaign_voucher */,
  USED: 'used' /* khusus untuk campaign voucher yg sudah terpakai */,
  EXPIRED: 'expired' /* khusus untuk campaign voucher yang sudah expired */,
};

export const CAMPAIGN_VOUCHER_STATUS = {
  BOOKED: 'BOOKED',
  CANCELLED: 'CANCELLED',
  CLAIMED: 'CLAIMED',
  EXPIRED: 'EXPIRED',
};
