<div class="fixActionRow">
  <div class="close-button d-flex justify-content-between">
    <b>Foto Profil</b><button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="form-group ">
      <div class="dropzone">
        <div class="img-preview">
          <img *ngIf="!data.imageUrl" class="img-fluid" src="assets/images/icons/image-placeholder@2x.png" alt="placeholder bukti transfer" />
          <img *ngIf="data.imageUrl" class="img-fluid" [src]="data.imageUrl" alt="placeholder bukti transfer" />
        </div>
        <caption>format yang diterima .jpg, .jpeg, .png</caption>
        <input type="file" style="display: none;" accept="image/*" #hiddenSelfie (change)="onAvatarChange($event)" />
        <button type="button" (click)="hiddenSelfie.click()" class="primary-button big-button inline">Upload Foto</button>
      </div>
    </div>
  </mat-dialog-content>
  <div class="spacer"></div>
  <mat-dialog-actions>
    <button (click)="changeAvatar()" class="primary-button big-button">Ganti Foto Profil Saya</button>
  </mat-dialog-actions>
</div>
