<div class="padding-top-60"></div>

<div *ngFor="let m of policies">
  <mat-nav-list *ngIf="!m.hasChildMenu && isMerchant == m.isMerchant">
    <mat-list-item class="mat-list-item">
      <p (click)="clickMenu(m.url, m.id)" *ngIf="!m.externalURL" matLine >{{ m.label | translate }}</p>
      <p *ngIf="m.externalURL" matLine (click)="externalLoad(m.url)">{{ m.label | translate }}</p>
    </mat-list-item>
  </mat-nav-list>
</div>

