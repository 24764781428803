import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  LANDING_BANNER_BASE_URL,
  LANDING_LABELS_BASE_URL,
  LANDING_SETTINGS_BASE_URL,
  PARTNERS_BASE_URL,
  PRODUCT_MERGE_AFTER_LOGGED_IN_BASE_URL,
  PRODUCT_MERGE_SIMPLE_BASE_URL,
  TESTIMONIES_BASE_URL
} from '../constants/api.constant';
import {LandingBannerInterface, PartnerInterface, TestimonyInterface} from '../interfaces/landing.interface';
import {ProductInterface} from '../interfaces/product.interface';
import {environment} from '../../environments/environment';
import {CommonKeyValueInterface} from '../interfaces/common.interface';

@Injectable({providedIn: 'root'})
export class LandingService {
  constructor(private http: HttpClient) {}

  async getPartnersAsync(): Promise<PartnerInterface[]> {
   return await this.http.get<PartnerInterface[]>(environment.url + PARTNERS_BASE_URL, {}).toPromise();
  }
  async getProductMergeSimpleAsync(limit = 5): Promise<ProductInterface[]> {
    return await this.http.get<ProductInterface[]>(environment.url + PRODUCT_MERGE_SIMPLE_BASE_URL + limit, {}).toPromise();
  }
  async getProductMergeCompleteAsync(limit = 5): Promise<ProductInterface[]> {
    return await this.http.get<ProductInterface[]>(environment.url + PRODUCT_MERGE_AFTER_LOGGED_IN_BASE_URL + limit, {}).toPromise();
  }
  async getTestimoniesAsync(): Promise<TestimonyInterface[]> {
    return this.http.get<TestimonyInterface[]>(environment.url + TESTIMONIES_BASE_URL, {}).toPromise();
  }
  async getLandingLabelAsync(id: number): Promise<CommonKeyValueInterface> {
    return await this.http.get<CommonKeyValueInterface>(environment.url + LANDING_LABELS_BASE_URL + '/' + id, {}).toPromise();
  }
  async getBannersAsync(): Promise<LandingBannerInterface[]> {
    return await this.http.get<LandingBannerInterface[]>(environment.url + LANDING_BANNER_BASE_URL, {}).toPromise();
  }
  async getLandingSettingsAsync(): Promise<CommonKeyValueInterface[]> {
    return await this.http.get<CommonKeyValueInterface[]>(environment.url + LANDING_SETTINGS_BASE_URL, {}).toPromise();
  }
  async getSiteIdentitiesAsync(): Promise<any> {
    return await this.http.get<any>(environment.url + LANDING_LABELS_BASE_URL, {}).toPromise();
  }
}
