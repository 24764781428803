<div class="merchant-outlet">
  <div class="outlet-header">
    <div class="text-center mt-5">
      <img src="/assets/images/icons/uil_qrcode-scan.svg"
           class="right figure-img img-fluid rounded center"
           alt="button scan qr">
    </div>
    <p class="outlet-text-center">{{'pages.member-card.qr-scan-instruction' | translate}}</p>
  </div>
  <div *ngIf="enableScan">
    <zxing-scanner (scanSuccess)="onCodeResult($event)"></zxing-scanner>
  </div>
</div>
