<div class="main-content full-screen registration-modal">
    <div class="full-height-page">
        <div class="mb-4 ">
          <img [src]="logo" alt="Rame rame logo" />
        </div>
        <div class="text-center" *ngIf="checkingToken === 1">
            <h1 class="h3">{{'pages.register.welcome-to' | translate}}<br /> {{'common.rameramecoid' | translate}}</h1>
            <p class="font-weight-bold mb-4 mt-4">{{'pages.register.case-one-title' | translate}}</p>
            <p>{{'pages.register.case-one' | translate}}</p>
            <button class="primary-button big-button" [routerLink]="['/sign-in']" mat-flat-button>{{'pages.register.enter-account' | translate}}</button>
        </div>
        <div class="text-center" *ngIf="checkingToken === 0">
          <h1 class="h3">{{'pages.register.welcome-to' | translate}}<br /> {{'common.rameramecoid' | translate}}</h1>
          <p class="font-weight-bold mb-4 mt-4">{{'pages.register.case-two' | translate}}</p>
        </div>
        <div class="text-center" *ngIf="checkingToken === 2">
          <h1 class="h3">{{'pages.register.welcome-to' | translate}}<br /> {{'common.rameramecoid' | translate}}</h1>
          <p class="font-weight-bold mb-4 mt-4"></p>
          <button [routerLink]="['/sign-in']" class="secondary-button text-black mb-4" mat-flat-button>{{'pages.register.back-to-login' | translate}}</button>
        </div>
    </div>
</div>
