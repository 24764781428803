import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppSharedModule} from '../../../shared/shared.module';
import {NgProgressModule} from 'ngx-progressbar';
import {MemberCartPageComponent} from './member-cart.page';
import {MemberCartConfirmPageComponent} from './member-cart-confirm.page';
import {NgSelectModule} from '@ng-select/ng-select';
import {ClipboardModule} from 'ngx-clipboard';
import {MaterialModule} from '../../../material/material.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    NgSelectModule,
    MaterialModule,
    ClipboardModule
  ],
  exports: [
    MemberCartPageComponent,
    MemberCartConfirmPageComponent
  ],
  declarations: [
    MemberCartPageComponent,
    MemberCartConfirmPageComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMemberCartModule {}
