import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {VoucherHistoryInterface} from '../../../interfaces/member-card.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {MERCHANT_OUTLET_QR_SCAN} from '../../../constants/route.constant';
import {MembercardService} from '../../../services/membercard.service';

@Component({
  selector: 'app-merchandise-outlet-voucher-history',
  templateUrl: './merchandise-outlet-voucher-history.html',
})
export class MerchandiseOutletVoucherHistoryComponent extends BaseComponent implements OnInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public voucherHistory: VoucherHistoryInterface[];

  constructor(injector: Injector,
              private membercardService : MembercardService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const id = this.activatedRoute.snapshot.params.id;
    this.membercardService.getVoucherMerchandiseHistory(id).then((res) => {
      this.voucherHistory = res;
    });
  }

  doNavigateScanQr(): void {
    this.router.navigate(['/' + MERCHANT_OUTLET_QR_SCAN]).then();
  }
}
