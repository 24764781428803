import {Component, Input, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-countdown-pipe-component',
  templateUrl: './count-down-pipe.component.html',
})
export class CountDownPipeComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  counter = 0;
  tick = 1000;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() withLabel: boolean = false;
  ngOnInit(): void {
    this.counter = isNaN(this.endDate.getTime() - this.startDate.getTime()) ? 0 : (this.endDate.getTime() - this.startDate.getTime()) / 1000;
    if (this.counter < 0) {
      this.counter = null;
    }
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter > 0) {
        --this.counter;
      } else {
        this.countDown = null;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.countDown != null) {
      this.countDown.unsubscribe();
    }
  }
}

@Pipe({
  name: 'formatSeconds',
  pure: false
})
export class FormatSecondsPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor((value % 3600) / 60);
    return ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}

@Pipe({
  name: 'formatMinutes',
  pure: false
})
export class FormatMinutesPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor((value % 3600) / 60);
    return ('00' + minutes).slice(-2);
  }
}

@Pipe({
  name: 'formatHours',
  pure: false
})
export class FormatHoursPipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    return ('00' + hours).slice(-2);
  }
}
