import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE,
  MEMBER_MY_BUSINESS_PAGE
} from 'src/app/constants/route.constant';
import {ProductService} from '../../../services/product.service';
import {ProductCCTVInterface, ProductInterface} from '../../../interfaces/product.interface';

@Component({
  selector: 'app-page-my-business-cctv',
  templateUrl: './detail-business-cctv.page.html'
})
export class MemberBusinessDetailCctvPageComponent extends BaseComponent implements OnInit{
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE = MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_PAGE = MEMBER_MY_BUSINESS_DETAIL_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE = MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE = MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE;
  public id: number;
  public product: ProductInterface = {};
  public cctv: ProductCCTVInterface = {};
  constructor(
    injector: Injector,
    private productService: ProductService
  ) {
    super(injector);
    this.id = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.productService.getOneProductAfterLoggedIn(this.id).then((product) => {
      this.product = product;
      this.productService.getProductCCTVAfterLoggedIn(this.product.id).then((cctv) => {
        this.cctv = cctv;
      }, () => {
        this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
      });
    }, () => {
      this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
    });
  }
}
