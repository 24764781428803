<div class="main-content full-screen registration-modal">
  <div class="full-height-page">
    <div class="mb-4 ">
      <img [src]="logo" alt="Rame rame logo" />
    </div>
    <div class="text-center">
      <h1 class="h4">{{'pages.login.please-insert-new-password' | translate}}</h1>
    </div>
    <form [formGroup]="registerFG" (submit)="resetPassword()">
      <div class="text-left no-gutters">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="email" class="text-white">{{'pages.login.email-address' | translate}}</label>
              <div class="input-group mb-3">
                <input autocomplete="new-email" name="email" type="email" id="email" formControlName="emailFC" class="form-control" aria-label="Masukkan alamat email anda" placeholder="Masukkan alamat email anda">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" id="input-group-password">
              <label for="password" class="text-white">{{'pages.login.password' | translate}}</label>
              <div class="input-group mb-3">
                <input autocomplete="new-password" name="password" type="password" *ngIf="!togglePassword" id="password" formControlName="passwordFC" class="form-control" aria-label="{{'pages.login.password' | translate}}" placeholder="{{'pages.login.password' | translate}}">
                <input autocomplete="new-password" name="password" type="text" *ngIf="togglePassword" formControlName="passwordFC" class="form-control" aria-label="{{'pages.login.password' | translate}}" placeholder="{{'pages.login.password' | translate}}">
                <div class="input-group-append">
                    <span *ngIf="!togglePassword" class="input-group-text" id="suffix_password_show" (click)="togglePassword = true">
                        <span class="icon-eye"></span>
                    </span>
                  <span *ngIf="togglePassword" class="input-group-text" id="suffix_password_hide" (click)="togglePassword = false">
                      <span class="icon-eye-blocked"></span>
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" id="input-group-password-confirmation">
              <label for="password-confirmation" class="text-white">{{'pages.register.password-confirmation' | translate}}</label>
              <div class="input-group mb-3">
                <input type="password" *ngIf="!toggleConfirmPassword" id="password-confirmation" formControlName="passwordConfirmationFC" class="form-control" aria-label="{{'pages.register.password-confirmation' | translate}}" placeholder="{{'pages.register.password-confirmation' | translate}}">
                <input type="text" *ngIf="toggleConfirmPassword" formControlName="passwordConfirmationFC" class="form-control" aria-label="{{'pages.register.password-confirmation' | translate}}" placeholder="{{'pages.register.password-confirmation' | translate}}">
                <div class="input-group-append">
                    <span *ngIf="!toggleConfirmPassword" class="input-group-text" id="suffix_password_confirmation_show" (click)="toggleConfirmPassword = true">
                        <span class="icon-eye"></span>
                    </span>
                  <span *ngIf="toggleConfirmPassword" class="input-group-text" id="suffix_password_confirmation_hide" (click)="toggleConfirmPassword = false">
                      <span class="icon-eye-blocked"></span>
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="secondary-button big-button mt-3" mat-flat-button>{{'pages.login.change-password' | translate}}</button>
      </div>
    </form>
  </div>
</div>
