<owl-carousel-o [options]="sliderOptions" class="homeslider1">
  <ng-template carouselSlide *ngFor="let banner of landingBanners">
    <div class="slider-content-wrapper bg-brand-color">
      <div>
        <h4 class="text-white" style="position: absolute; z-index: 999; left: 20px;">{{ banner.title }}</h4>
        <span class="text-white" style="position: absolute; z-index: 999; left: 20px; top: 90px; font-size: 14px; text-shadow: #373A3C">{{ banner.subtitle }}</span>
        <button *ngIf="banner.hasButton" mat-flat-button class="secondary-button invert"
                style="position: absolute; z-index: 999; left: 20px; top: 140px; height: 30px;"
        >{{'common.more' | translate}}</button>
      </div>
      <div>
        <img class="bottom-right" [src]="banner.imageUrl" alt="{{ banner.title }}" />
      </div>
    </div>
  </ng-template>
</owl-carousel-o>
