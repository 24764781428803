import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../app-routing.module';
import {MaterialModule} from '../material/material.module';
import {LandingBannerComponent} from './banner/landing-banner.component';
import {PartnerComponent} from './partner/partner.component';
import {TestimonyComponent} from './testimony/testimony.component';
import {ProductComponent} from './product/product.component';
import {LandingYoutubeComponent} from './youtube/landing-youtube.component';
import {HowToComponent} from './how-to/how-to.component';
import {TranslateModule} from '@ngx-translate/core';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {SuccessProfileVerificationComponent} from './success-profile-verification/success-profile-verification.component';
import {FailedProfileVerificationComponent} from './failed-profile-verification/failed-profile-verification.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountDownComponent} from './count-down/count-down.component';
import {CountDownPipeComponent, FormatHoursPipe, FormatMinutesPipe, FormatSecondsPipe} from './count-down/count-down-pipe.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule,
    CarouselModule,
    // AppSharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LandingBannerComponent,
    PartnerComponent,
    TestimonyComponent,
    ProductComponent,
    LandingYoutubeComponent,
    HowToComponent,
    SuccessProfileVerificationComponent,
    FailedProfileVerificationComponent,
    CountDownComponent,
    CountDownPipeComponent,
    FormatSecondsPipe,
    FormatMinutesPipe,
    FormatHoursPipe
  ],
  declarations: [
    LandingBannerComponent,
    PartnerComponent,
    TestimonyComponent,
    ProductComponent,
    LandingYoutubeComponent,
    HowToComponent,
    SuccessProfileVerificationComponent,
    FailedProfileVerificationComponent,
    CountDownComponent,
    CountDownPipeComponent,
    FormatSecondsPipe,
    FormatMinutesPipe,
    FormatHoursPipe
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponentModule {
}
