import {Component, Injector, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_MY_BUSINESS_PAGE, MEMBER_WALLET_LANDING_PAGE} from 'src/app/constants/route.constant';
import {DialogInterface} from 'src/app/shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {PurchaseInterface} from '../../../interfaces/investment.interface';
import {InvestmentService} from '../../../services/investment.service';
import {FileService} from '../../../services/file.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FORM_GROUP_VALID, PICKER_DATE_FORMATS, STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MasterService} from '../../../services/master.service';
import {SelectInterface} from '../../../interfaces/common.interface';

@Component({
  selector: 'app-page-member-business-payment-confirmation',
  templateUrl: './my-business-payment-confirmation.page.html',
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: PICKER_DATE_FORMATS},
  ],
})
export class MemberBusinessPaymentConfirmationPageComponent extends BaseComponent implements OnInit{
  public MEMBER_WALLET_LANDING_PAGE = MEMBER_WALLET_LANDING_PAGE;
  public showBackground = true;
  public myInvestment: PurchaseInterface;
  private purchaseId: number;
  public payFG: UntypedFormGroup;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public banks: SelectInterface[] = [];
  public bankDests: SelectInterface[] = [];
  public fileListTransfer: FileList;
  public imageUrlTransfer: any;
  public validForm = FORM_GROUP_VALID;
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private investmentService: InvestmentService,
    private fileManager: FileService,
    private formBuilder: UntypedFormBuilder,
    private masterService: MasterService,
    private adapter: DateAdapter<any>,
  ) {
    super(injector);
    this.purchaseId = this.activatedRoute.snapshot.params.id;
    this.payFG = this.formBuilder.group({
      amount: [0, [Validators.required]],
      transferDate: [null, [Validators.required]],
      sourceBank: [null, [Validators.required]],
      destinationBank: [null, [Validators.required]],
      onBehalfOf: [null, [Validators.required]],
      accountNumbers: [null, [Validators.required]],
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.adapter.setLocale(localStorage.getItem(STORAGE_LANG_KEY));
    this.payFG.controls.amount.disable({onlySelf: true});
    this.payFG.controls.destinationBank.disable({onlySelf: true});
    this.masterService.getBanksAsync().then((res) => {
      this.banks = res;
    });
    this.investmentService.findAllActiveInvestmentBankAccount(this.purchaseId).then((res) => {
      this.payFG.controls.destinationBank.setValue(res.value);
    });
    this.investmentService.findOnePurchaseHistory(this.purchaseId).then((res) => {
      this.myInvestment = res;
      this.payFG.controls.amount.setValue('Rp. ' + this.ui.currencyFormat(this.myInvestment.meta.mustPay, this.locale));
    });
  }
  openSuccessDialog(): void {
    const data: DialogInterface = {
      title : 'Terimakasih telah melakukan konfirmasi',
      content: 'Pembayaran anda akan segera kami proses dan mohon menunggu 1x24 jam status usaha yang anda beli akan berubah secara otomatis',
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {label: 'ok, terima kasih', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialog = this.dialog.open(DialogScrollComponent, {data});
    dialog.afterClosed().subscribe(() => {
      this.router.navigate([MEMBER_MY_BUSINESS_PAGE], {
        queryParams: {origin: 'transaction'}
      }).then(() => {});
    });
  }
  upload(): void {
    let fd = new FormData();
    const fileTransfer = this.fileListTransfer[0];
    fd.append('evidence',fileTransfer);
    this.investmentService.saveFileTransferEvidence(fd, this.myInvestment.id).then(() => {
     this.investmentService.saveTransferEvidence(
       this.myInvestment.id,
       this.payFG.controls.transferDate.value,
       this.payFG.controls.accountNumbers.value,
       this.payFG.controls.onBehalfOf.value,
       this.payFG.controls.sourceBank.value
     ).then(() => {
        this.openSuccessDialog();
     });
    });
  }
  onTransferEvidenceChange(event): void {
    this.fileListTransfer = event.target.files as FileList;
    const mime = this.fileListTransfer[0].type;
    if (!mime.match(/image\/*/)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileListTransfer[0]);
    reader.onload = () => {
      this.imageUrlTransfer = reader.result;
    }
  }
}
