import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MainComponent} from './main/main.component';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../app-routing.module';
import {SidenavComponent} from './sidenav/sidenav.component';
import {SidenavItemComponent} from './sidenav/sidenav-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgProgressModule} from 'ngx-progressbar';
import {BottomNavComponent} from './bottomnav/bottomnav.component';
import {BlankLayoutComponent} from './blank-layout/blank-layout.component';
import {SigninLayoutComponent} from './signin-layout/signin-layout.component';
import {TopNavLayoutComponent} from './top-nav-layout/top-nav.component';
import {MaterialModule} from '../material/material.module';
import {BlankWhiteComponent} from './blank-layout/blank-white.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    MaterialModule,
    NgProgressModule
  ],
  exports: [
    MainComponent,
    BlankLayoutComponent,
    SigninLayoutComponent,
    SidenavComponent,
    TopNavLayoutComponent,
    SidenavItemComponent,
    BottomNavComponent,
    BlankWhiteComponent
  ],
  declarations: [
    MainComponent,
    SidenavComponent,
    BlankLayoutComponent,
    SigninLayoutComponent,
    TopNavLayoutComponent,
    SidenavItemComponent,
    BottomNavComponent,
    BlankWhiteComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLayoutModule {}
