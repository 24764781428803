<div class="fixActionRow">
  <div class="col-12 d-flex justify-content-between with-insurance mb-3" *ngIf="dialogData.title">
    <table class="headTableInsurance table-borderless">
      <tr>
        <td class="headTableInsuranceImg">
          <img class="img-fluid" [src]="baseUrl + dialogData.logo + '?rand=123'" alt="Card image cap"/>
        </td>
        <td>
          <h4 class="change-insurance ml-1">{{dialogData.title}}</h4>
        </td>
        <td>
          <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
        </td>
      </tr>
    </table>

  </div>
  <mat-dialog-content class="mat-typography">
    <div (click)="setSelected(ins)" class="pb-3 unselectedInsurance"
         *ngFor="let ins of data.productInsurance; let i = index;"
         [class.selectedInsurance]="ins.selected">
      <div class="row">
        <div class="col-9">
          <h4 class="mb-1">{{ins.title}}</h4>
          {{ins.description}}
        </div>
        <div class="col-3">
          <a class="icon-file-pdf" href="{{baseUrl + ins.document}}" target="_blank"></a>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="spacer"></div>
  <mat-dialog-actions align="end">
    <ng-container *ngFor="let btn of dialogData.buttons">
      <button *ngIf="btn.focus" cdkFocusInitial [mat-dialog-close]="result" mat-button mat-dialog-close
              class="{{btn.className}}" style="{{btn.addStyle}}">{{btn.label | translate}}</button>
      <button *ngIf="!btn.focus" [mat-dialog-close]="result" mat-button mat-dialog-close class="{{btn.className}}"
              style="{{btn.addStyle}}">{{btn.label | translate}}</button>
    </ng-container>
  </mat-dialog-actions>
</div>
