import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {AppRoutingModule} from '../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {TosSlotBuyDialogComponent} from './dialog-tos-slot-buy.component';
import {CommonMailSentComponent} from './common-mail-sent.component';
import {FileListComponent} from './file-list.component';
import {AppSharedModule} from '../../shared/shared.module';
import {FileListCertificateComponent} from './file-list-certificate.component';
import {ThanksTocComponent} from './thanks-toc.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule
  ],
  exports: [
    TosSlotBuyDialogComponent,
    CommonMailSentComponent,
    FileListComponent,
    FileListCertificateComponent,
    ThanksTocComponent
  ],
  declarations: [
    TosSlotBuyDialogComponent,
    CommonMailSentComponent,
    FileListComponent,
    FileListCertificateComponent,
    ThanksTocComponent
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppDialogModule {}
