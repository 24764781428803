import {Component, Input, OnInit} from '@angular/core';
import {PartnerInterface} from '../../interfaces/landing.interface';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-partner-component',
  templateUrl: './partner.component.html',
})
export class PartnerComponent implements OnInit{
  @Input() partners: PartnerInterface[] = [];
  baseUrl = environment.base;
  ngOnInit(): void {
  }
}
