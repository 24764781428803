import {Component, OnInit,} from '@angular/core';
import {MAIL_SENT_LOGO, RAMERAMECOID_LOGO} from '../../constants/common.constant';
import {RegisterConfirmationService} from './register-confirmation.service';

@Component({
  selector: 'app-page-register-confirmation',
  templateUrl: './register-confirmation.page.html',
})
export class RegisterConfirmationPageComponent implements OnInit{
  public logo = RAMERAMECOID_LOGO;
  public mailSentLogo = MAIL_SENT_LOGO;
  public email = '';
  public name = '';
  constructor(
    private registerConfirmationService: RegisterConfirmationService
  ) {}
  ngOnInit(): void {
    this.registerConfirmationService.currentMessage.subscribe((res) => {
      this.email = res.email;
      this.name = res.name;
    });
  }
}
