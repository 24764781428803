<mat-tab-group>
  <mat-tab label="{{'pages.member-card.voucher-history' | translate}}">
    <div class="my-voucher" *ngFor="let voucher of campaignVouchers">
      <div class="card-body p-2">
        <div class="row no-gutters align-items-center c-body-detail">
          <div class="col-4 col-md-4 nopadding">
            <img alt="" [src]="baseUrl + voucher.imagePath + '?rand=123'" class="left-image i-claim"/>
          </div>
          <div class="col-8 col-md-8 nopadding">
            <div>
              <p class="d-inline-flex title-merchant">{{voucher.merchantName}}</p>
            </div>
            <div>
              <p class="title-campaign-claimed">{{voucher.campaignName}}</p>
            </div>
            <div class="d-inline-flex">
              <p class="title-expired">{{'pages.member-card.claimed-at' | translate}} {{date.dateTimeToLocaleDate(voucher.redeemAt, locale)}}</p>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{'pages.member-card.point-history' | translate}}">
    <div class="my-voucher" *ngFor="let p of walletPointsHistories">
      <div class="card-body p-2">
        <div class="row no-gutters align-items-center c-body-detail">
          <div class="col-4 col-md-4 nopadding">
            <img alt="" [src]="''" class="left-image i-claim"/>
          </div>
          <div class="col-8 col-md-8 nopadding">
            <div>
              <p class="d-inline-flex title-merchant">{{p.transaction}}</p>
            </div>
            <div>
              <p class="title-campaign-claimed">{{p.amount}} {{ 'common.point' | translate }}</p>
            </div>
            <div class="d-inline-flex">
              <p class="title-expired"> {{date.dateTimeToLocaleDate(p.date, locale)}}</p>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
