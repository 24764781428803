import {Component, OnInit} from '@angular/core';
import {NavService} from '../../nav/nav.service';
import {NavDataInterface} from '../../interfaces/nav-data.interface';
import {STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {UserInterface} from '../../interfaces/user.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.scss']
})
export class MainComponent implements OnInit {
  public navData: NavDataInterface = {};
  public isMerchant = (localStorage.getItem(STORAGE_PROFILE_KEY)) ? (JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional : null;
  constructor(private navService: NavService) {
  }
  ngOnInit(): void {

    this.navService.currentMessage.subscribe((res) => {
      const that = this;
      setTimeout(() => {
        that.navData = res as NavDataInterface;
      }, 100);
    });
  }
}
