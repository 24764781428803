import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DialogInterface} from '../../shared/dialog/dialog.interface';
import {MatDialog} from '@angular/material/dialog';
import {LabelHelper} from '../../helpers/label.helper';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CAPITAL_VALUES_OPTIONS, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {ProfileService} from '../../services/profile.service';
import {UserService} from '../../services/user.service';
import {UserBusinessInterface, UserInterface} from '../../interfaces/user.interface';
import {MEMBER_ACCOUNT_PANEL_PAGE} from 'src/app/constants/route.constant';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogScrollComponent} from '../../shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-failed-profile-verification-component',
  templateUrl: './failed-profile-verification.component.html',
})
export class FailedProfileVerificationComponent implements OnInit {
  private label = LabelHelper;
  @Output() toggleEditor = new EventEmitter<boolean>();
  public capitalValues = CAPITAL_VALUES_OPTIONS;
  public businessFG: UntypedFormGroup;
  public imageUrlNpwp: any;
  public imageUrlId: any;
  public imageUrlSelfie: any;
  public fileListNpwp: FileList = null;
  public fileListId: FileList = null;
  public fileListSelfie: FileList = null;
  public profile: UserInterface = {};
  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.businessFG = this.formBuilder.group({
      identityTypeFC: ['ktp', [Validators.required]],
      capitalValueFC: [null, []],
      identityNumbersFC: [null, [Validators.required]],
      npwpFC: [null, []]
    });
  }
  ngOnInit(): void {
  }
  async openThanksDialog(): Promise<any> {
    const data: DialogInterface = {
      title : await this.label.getLabel(this.translateService, 'pages.verification.verify-sent-identity') as string,
      imageUrl: '/assets/images/member/alert-image4-min.png',
      imageAlt: await this.label.getLabel(this.translateService, 'pages.verification.verification-sent') as string,
      content: await this.label.getLabel(this.translateService, 'pages.verification.verification-sent-content') as string,
      buttons: [
        {label: 'common.thank-you', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialogRef = this.dialog.open(DialogScrollComponent, {data});
    dialogRef.afterClosed().subscribe(() => {
      localStorage.removeItem(STORAGE_PROFILE_KEY);
      localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
      this.label.getLabel(this.translateService, 'toast.data-updated').then((notification) => {
        this.redirectTo(MEMBER_ACCOUNT_PANEL_PAGE);
        this.snackBar.open(notification as string, '', {
          duration: 2000
        });
      });
    });
  }
  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }
  currentUser(): void {
    this.userService.getCurrentUserAsync().then((res) => {
      this.profile = res;
      this.openThanksDialog();
    }, (err) => {
      console.log(err);
    });
  }
  hideEditor(): void {
    this.toggleEditor.emit(false);
  }
  onNpwpFileChange(event): void {
    this.fileListNpwp = event.target.files as FileList;
    const mime = this.fileListNpwp[0].type;
    if (!mime.match(/image\/*/)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileListNpwp[0]);
    reader.onload = () => {
      this.imageUrlNpwp = reader.result;
    }
  }
  onSelfieFileChange(event): void {
    this.fileListSelfie = event.target.files as FileList;
    const mime = this.fileListSelfie[0].type;
    if (!mime.match(/image\/*/)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileListSelfie[0]);
    reader.onload = () => {
      this.imageUrlSelfie = reader.result;
    }
  }
  onIdFileChange(event): void {
    this.fileListId = event.target.files as FileList;
    const mime = this.fileListId[0].type;
    if (!mime.match(/image\/*/)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileListId[0]);
    reader.onload = () => {
      this.imageUrlId = reader.result;
    }
  }
  updateProfile(): void {
    let fd = new FormData();
    const fileNpwp = (this.fileListNpwp)? this.fileListNpwp[0] : null;
    const fileIdentity = (this.fileListId)? this.fileListId[0] : null;
    const fileSelfie = (this.fileListSelfie)? this.fileListSelfie[0] : null;
    fd.append('npwp',fileNpwp);
    fd.append('identity', fileIdentity);
    fd.append('selfie', fileSelfie);
    const payload: UserBusinessInterface = {
      identityType: this.businessFG.controls.identityTypeFC.value,
      identityNo: this.businessFG.controls.identityNumbersFC.value,
      capitalValue: this.businessFG.controls.capitalValueFC.value,
      npwpNo: this.businessFG.controls.npwpFC.value
    };
    this.profileService.business(payload).then(() => {
      this.profileService.businessFiles(fd).then(() => {
        this.currentUser();
      });
    });
  }
}
