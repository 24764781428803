<div class="main-content business-detail">
    <div class="business-image">
        <div class="text-center">
          <img *ngIf="!product.imageUrl" src="http://placehold.it/1920x1080" class="img-fluid left-image" alt="" />
          <img *ngIf="product.imageUrl" alt="" [src]="baseUrl + product.imageUrl + '?rand=123'" class="img-fluid left-image" />
        </div>
    </div>
    <div class="menu mt-3 mb-3">
      <div class="row">
        <div class="col-3">
            <button class="secondary-button square-button active" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-laporan"></span>
            </button>
            <span class="button-label">Laporan</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-ask-admin"></span>
            </button>
            <span class="button-label">Tanya Admin</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-cctv"></span>
            </button>
            <span class="button-label">Monitoring CCTV</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-timeline"></span>
            </button>
            <span class="button-label">Informasi</span>
        </div>
      </div>
    </div>
    <div class="content">
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="pageActive">
            <mat-tab label="Laporan Harian">
              <ng-template matTabContent>
                <app-investment-daily-report [month]="currentMonth" [year]="currentYear" [total]="totalDailySales" [dailySales]="myDailySales" [product]="product"></app-investment-daily-report>
              </ng-template>
            </mat-tab>
            <mat-tab label="Laporan Bulanan">
              <ng-template matTabContent>
                <app-investment-monthly-report [total]="total" [multi]="multi" [product]="product" [rois]="myROI"></app-investment-monthly-report>
              </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
