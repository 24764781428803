import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {REWARD_BASE_URL, SERVER_TIME_BASE_URL} from '../constants/api.constant';
import {CommonKeyValueInterface} from '../interfaces/common.interface';
import {RewardInterface} from '../interfaces/reward.interface';

@Injectable({providedIn: 'root'})
export class RewardService {
  constructor(private http: HttpClient) {
  }
  async getServerTime(): Promise<CommonKeyValueInterface> {
    return await this.http.get<CommonKeyValueInterface>(environment.url + SERVER_TIME_BASE_URL, {}).toPromise();
  }
  async getRewards(): Promise<RewardInterface[]> {
    return await this.http.get<RewardInterface[]>(environment.url + REWARD_BASE_URL, {}).toPromise();
  }
}
