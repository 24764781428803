import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {TeamMemberInterface} from '../../interfaces/common.interface';
import {ABOUT_US_PROFILES} from '../../constants/common.constant';

@Component({
  selector: 'app-page-about-us-details',
  templateUrl: './about-us-details.page.html',
})
export class AboutUsDetailsPageComponent extends BaseComponent implements OnInit {
  private teamId: string;
  private teamList: TeamMemberInterface[] = ABOUT_US_PROFILES;
  public profile: TeamMemberInterface = {};
  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.teamId = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.profile = this.teamList.find(p => p.short == this.teamId);
  }
}
