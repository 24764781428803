import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../../../shared/base/base.component';
import { MEMBER_WALLET_LANDING_PAGE } from 'src/app/constants/route.constant';
import { MemberWalletWithdrawDialogComponent } from './wallet-dialog-withdraw.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WalletInterface } from '../../../interfaces/wallet.interface';
import { WalletService } from '../../../services/wallet.service';
import { MasterService } from '../../../services/master.service';
import { STORAGE_PROFILE_KEY } from 'src/app/constants/common.constant';
import { UserInterface } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-page-member-withdraw-wallet',
  templateUrl: './wallet-withdraw.page.html',
})
export class MemberWalletWithdrawPageComponent
  extends BaseComponent
  implements OnInit
{
  public MEMBER_WALLET_LANDING_PAGE = MEMBER_WALLET_LANDING_PAGE;
  public withdrawFG: UntypedFormGroup;
  public wallet: WalletInterface = {};
  public profile =
    (JSON.parse(
      localStorage.getItem(STORAGE_PROFILE_KEY) || 'null'
    ) as UserInterface) || null;

  private bankName = '';
  private accountNumbers = '';
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private walletService: WalletService,
    private masterService: MasterService
  ) {
    super(injector);
    this.withdrawFG = this.formBuilder.group({
      balance: [0, [Validators.required]],
      amount: [0, [Validators.required]],
      bankName: ['', [Validators.required]],
      onBehalfOf: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.withdrawFG.controls.balance.disable({ onlySelf: true });
    this.withdrawFG.controls.bankName.disable({ onlySelf: true });
    this.withdrawFG.controls.onBehalfOf.disable({ onlySelf: true });
    this.walletService.getWalletsAsync(this.pageNo).then((res) => {
      this.wallet = res;
      this.withdrawFG.controls.balance.setValue(Number(this.wallet.balance));
    });
    this.masterService.getBanksAsync().then((res) => {
      const b = res.find(
        (bank) => bank.value === this.userProfile.account.bankId
      );
      if (b) {
        this.withdrawFG.controls.bankName.setValue(
          b.label + '-' + this.userProfile.account.accountNumbers
        );
        this.withdrawFG.controls.onBehalfOf.setValue(
          this.userProfile.account.accountOwner
        );
        this.bankName = b.label;
        this.accountNumbers = this.userProfile.account.accountNumbers;
      }
    });
  }
  withdrawRequest(): void {
    this.dialog.open(MemberWalletWithdrawDialogComponent, {
      data: {
        amount: this.withdrawFG.controls.amount.value,
        onBehalfOf: this.withdrawFG.controls.onBehalfOf.value,
        accountNumbers: this.accountNumbers,
        bankName: this.bankName,
      },
    });
  }
  isRegistered() {
    return (
      this.profile.basic?.guid_user &&
      this.profile.basic?.guid_user !== '-' &&
      this.profile.basic?.is_registered_new_app?.toString() === 'true'
    );
  }
}
