import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-common-file-list',
  templateUrl: './file-list.component.html'
})
export class FileListComponent implements OnInit{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  ngOnInit(): void {
  }
}
