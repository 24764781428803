export const environments = {
    staging: {
        url: 'https://office.ramerame.co.id/api',
        base: 'https://office.ramerame.co.id',
        feUrl: 'https://ramerame.co.id',
        production: true,
    },
    production: {
        url: 'https://office.ramerame.co.id/api',
        base: 'https://office.ramerame.co.id',
        feUrl: 'https://ramerame.co.id',
        production: true,
    },
};

export const environment = environments.production;