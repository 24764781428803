import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {NotificationService} from '../../../services/notification.service';
import {NotificationInterface} from '../../../interfaces/notification.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';

@Component({
  selector: 'app-page-member-notification',
  templateUrl: './member-notification.page.html'
})
export class MemberNotificationPageComponent extends BaseComponent implements OnInit{
  public notifications: NotificationInterface[] = [];
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  constructor(
    injector: Injector,
    private notificationService: NotificationService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.notificationService.currentMessage.subscribe(() => {
      this.notificationService.getNotifications().then((res) => {
        this.notifications = res;
      });
    });
  }
}
