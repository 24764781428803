<div class="main-content member-info-panel" *ngIf="isSignedIn">
    <div class="avatar-info">
        <div class="avatar" (click)="changeAvatar()">
            <img *ngIf="imageUrl" [src]="imageUrl" alt="User avatar" />
            <img *ngIf="!imageUrl" [src]="noAvatarUrl" alt="User avatar" />
        </div>
        <div class="info">
            <h6 class="mb-1" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h6>
            <h6 class="text-grey text-weight-regular mb-1" style="text-transform: lowercase">{{userProfile.basic.email}}</h6>
            <span class="badge green" *ngIf="userProfile.basic.complete == 'ACCEPTED'">PROFIL LENGKAP</span>
            <span class="badge red" *ngIf="userProfile.basic.complete != 'ACCEPTED'">PROFIL BELUM LENGKAP</span>
        </div>
    </div>
    <div class="millionaire-verified-card mt-3">
        <div class="background">
            <img src="/assets/images/member/member-gold-card@2x.png" *ngIf="userProfile.basic.complete == 'ACCEPTED' && myInvestments.length == 0 && showCard" class="gold" alt="millionaire member card" />
            <img src="/assets/images/member/member-platinum-card@2x.png" *ngIf="userProfile.basic.complete == 'ACCEPTED' && myInvestments.length > 0 && showCard" class="platinum" alt="millionaire member card" />
            <img src="/assets/images/member/member-silver-card@2x.png" *ngIf="userProfile.basic.complete != 'ACCEPTED'" class="silver" alt="millionaire member card" />
        </div>
        <div class="card-content">
            <div class="card-owner">
                <h3 class="name" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h3>
                <h4 class="id">ID. {{userProfile.basic.id}}</h4>
            </div>
        </div>
    </div>
    <div class="mt-4">
        <button class="secondary-button big-button" *ngIf="false">
          {{'pages.profile.redeem-discount' | translate}}
        </button>
    </div>
    <div class="list-group mt-3 list-group-flush menu-panel">
        <a *ngFor="let m of policies" [routerLink]="ui.absoluteUrl(m.url)" class="list-group-item">
            <div class="left">
                <span class="{{ m.icon }}"></span>
                <span [ngStyle]="{color: m.color}" class="label">{{ m.label | translate }}</span>
            </div>
            <div class="right">
                <span class="icon-exclamation text-orange" *ngIf="m.hasExclamation && m.identifier == 'basic' && !userProfile.basic.complete"></span>

                <span class="icon-exclamation text-orange" *ngIf="m.hasExclamation && m.identifier == 'identity' && !userProfile.basic.complete"></span>

                <span class="icon-exclamation text-orange" *ngIf="m.hasExclamation && m.identifier == 'account' && !userProfile.basic.complete"></span>

                <span class="icon-chevron-right text-grey"></span>
            </div>
        </a>
        <div class="list-group-item mb-4" (click)="signOut()">
          <div class="left">
            <span class="icon-logout"></span>
            <span class="label">{{'member-menu.logout' | translate }}</span>
          </div>
          <div class="right">
            <span class="icon-chevron-right text-grey"></span>
          </div>
        </div>
    </div>
</div>
