import {MenuItemInterface} from '../interfaces/menu-item.interface';
import {
  MEMBER_ACCOUNT_CHANGE_PASSWORD_PAGE,
  MEMBER_ACCOUNT_VERIFICATION_PAGE,
  MEMBER_BANK_ACCOUNT_PAGE,
  MEMBER_MEMBER_INFO_PAGE,
  MEMBER_TICKETS_PAGE
} from './route.constant';

export const MEMBER_MENU_ITEMS: MenuItemInterface[] = [
    {
      identifier: 'basic',
      label: 'member-menu.personal-data',
      icon: 'icon-person',
      hasExclamation: true,
      color: '#000',
      url: MEMBER_MEMBER_INFO_PAGE
    },
    {
      identifier: 'identity',
      label: 'member-menu.verification-identity',
      hasExclamation: true,
      color: '#000',
      icon: 'icon-identitas',
      url: MEMBER_ACCOUNT_VERIFICATION_PAGE
    },
    {
      identifier: 'account',
      label: 'member-menu.bank-account',
      hasExclamation: true,
      color: '#000',
      icon: 'icon-card',
      url: MEMBER_BANK_ACCOUNT_PAGE
    },
    {
      identifier: 'security',
      label: 'member-menu.security',
      color: '#000',
      icon: 'icon-security',
      url: MEMBER_ACCOUNT_CHANGE_PASSWORD_PAGE
    },
    {
      identifier: 'ticket',
      label: 'member-menu.ticket',
      color: '#000',
      icon: 'icon-ticket',
      url: MEMBER_TICKETS_PAGE
    }
];
