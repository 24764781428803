import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CURRENT_USER_URL, IMAGES_PROFILE_BASE_URL} from '../constants/api.constant';
import {UserInterface} from '../interfaces/user.interface';

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(private http: HttpClient) {
  }
  async getCurrentUserAsync(): Promise<UserInterface> {
    return await this.http.get<UserInterface>(environment.url + CURRENT_USER_URL, {}).toPromise();
  }
  async getProfileAvatarAsync(): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    return await this.http.post(environment.url + IMAGES_PROFILE_BASE_URL, {type: 'avatar'}, {headers: httpHeaders, responseType: 'blob'})
      .toPromise();
  }
  async getProfileImageAsync(param: string): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    return await this.http.post(environment.url + IMAGES_PROFILE_BASE_URL, {type: param}, {headers: httpHeaders, responseType: 'blob'})
      .toPromise();
  }
}
