<div class="main-content pb-1">
    <h1 class="h4 text-center">Konfirmasi Pembayaran</h1>
    <form [formGroup]="payFG" (submit)="upload()" enctype="multipart/form-data">
        <div class="form-group">
            <label>Jumlah Transfer</label>
            <input formControlName="amount" type="text" class="form-control" aria-describedby="Jumlah Transfer" placeholder="Jumlah Transfer" />
            <small class="form-text"></small>
        </div>
        <div class="form-group">
            <label>Tanggal Transfer</label>
            <div class="input-group">
              <input readonly formControlName="transferDate" id="transferDate" type="text" matInput (click)="picker.open()" [matDatepicker]="picker" class="form-control" aria-describedby="date" placeholder="{{'pages.profile.date-format' | translate}}" />
              <div class="input-group-append">
                <mat-datepicker-toggle class="" matSuffix [for]="picker"></mat-datepicker-toggle>
              </div>
              <mat-datepicker #picker></mat-datepicker>
            </div>

            <!-- <small class="form-text">Helper class if needed</small> -->
        </div>
        <div class="form-group">
            <label>Transfer dari</label>
            <ng-select [items]="banks" formControlName="sourceBank"
                    bindLabel="label"
                    autofocus
                    placeholder="Pilih Bank"
                    bindValue="value"
                    class="ng-select"
                    >
            </ng-select>
        </div>
          <div class="form-group">
          <label>Nomer Rekening</label>
          <input formControlName="accountNumbers" type="text" class="form-control" aria-describedby="Berita" placeholder="Nomer Rekening Pengirim" />
        </div>
        <div class="form-group">
          <label>Atas Nama</label>
          <input formControlName="onBehalfOf" type="text" class="form-control" aria-describedby="Berita" placeholder="Pemilik Rekening" />
        </div>
        <div class="form-group">
            <label>Ke Bank</label>
            <input formControlName="destinationBank" type="text" class="form-control" aria-describedby="Bank Tujuan" placeholder="Bank Tujuan" />
        </div>

        <div class="form-group ">
            <label>Foto Bukti Transfer</label>
            <div class="dropzone">
                <div class="img-preview">
                  <img *ngIf="!imageUrlTransfer" class="img-fluid" src="assets/images/icons/image-placeholder@2x.png" alt="placeholder bukti transfer" />
                  <img *ngIf="imageUrlTransfer" class="img-fluid" [src]="imageUrlTransfer" alt="placeholder bukti transfer" />
                </div>
                <caption>format yang diterima .jpg, .jpeg, .png</caption>
                <input type="file" style="display: none;" accept="image/*" #hiddenSelfie (change)="onTransferEvidenceChange($event)" />
                <button type="button" (click)="hiddenSelfie.click()" class="primary-button big-button inline">Upload Foto</button>
            </div>
        </div>
        <div class="form-group">
            <button [disabled]="payFG.status != validForm || !imageUrlTransfer" class="primary-button big-button" mat-flat-button>Simpan</button>
        </div>
    </form>
</div>
