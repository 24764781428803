<div class="main-content">
    <h4>{{'pages.business-list.available-projects' | translate}}</h4>
    <div class="row">
        <div class="col-6 mb-4" *ngFor="let product of products">
            <div class="card business">
              <div class="ribbon-group">
                <div *ngIf="product.status == 'RUN'" class="ribbon-group-children grey">{{ 'status.run' | translate }}</div>
                <div *ngIf="product.status == 'AC'" class="ribbon-group-children orange">{{ 'status.active' | translate }}</div>
                <div *ngIf="product.status == 'ADM'" class="ribbon-group-children green">{{ 'status.administration' | translate }}</div>
                <div *ngIf="product.status == 'DONE'" class="ribbon-group-children blue">{{ 'status.done' | translate }}</div>
                <div *ngIf="product.insuranceId" class="ribbon-group-children grey"><span class="icon-aman"></span></div>
              </div>
                <img class="card-img-top" [src]="baseUrl + product.imageUrl + '?rand=123'" alt="{{product.productName}}">
                <div class="card-body">
                    <h4>{{product.productName}}</h4>
                    <p *ngIf="false" class="card-text">{{product.description}}</p>
                    <a [routerLink]="['/business-detail/' + product.id]">{{'common.details' | translate}}</a>
                </div>
            </div>
        </div>

    </div>

    <div class="row mt-4 mb-4">
        <div class="col-12 text-center">
          <app-pagination
            (goPage)="toPage($event)"
            [pagesToShow]="showPages"
            [page]="pageNo"
            [perPage]="pageSize"
            [count]="totalRow"></app-pagination>
        </div>
    </div>
</div>
