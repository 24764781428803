import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {environment} from '../../../environments/environment';
import {UserService} from '../../services/user.service';
import {UiHelper} from '../../helpers/ui.helper';
import {MERCHANT_OUTLET_PAGE} from '../../constants/route.constant';
import {UserInterface} from '../../interfaces/user.interface';

@Component({
  selector: 'app-social-auth',
  templateUrl:'./social-auth.page.html',
})
export class SocialAuthPageComponent implements OnInit{
  public ui = UiHelper;
  private token = '';
  public profile: UserInterface = {};
  constructor(
    public activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.token = this.activatedRoute.snapshot.params.token;
    localStorage.setItem(JWT_TOKEN_KEY, this.token);
  }
  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface;
    setTimeout(() => {
      this.currentUser();
    }, 1000);
  }
  currentUser(): void {
    this.userService.getCurrentUserAsync().then((res) => {
      localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(res));
      let next = localStorage.getItem('next');
      if (this.profile && this.profile.additional && this.profile.additional.roleId == 6) {
        next = '/' + MERCHANT_OUTLET_PAGE;
      } else {
        next = (!next)? '' : next;
      }
      window.location.href = environment.feUrl + next;
    }, (err) => {
      console.log(err);
    });
  }
}
