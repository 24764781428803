import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MY_INVESTMENT_BASE_URL, ONE_PRODUCT_BASE_URL, PRODUCT_BASE_URL} from '../constants/api.constant';
import {ProductCCTVInterface, ProductInterface} from '../interfaces/product.interface';
import {TimelineInterface} from '../interfaces/timeline.interface';
import {CertificateInterface} from '../interfaces/certificate.interface';

@Injectable({providedIn: 'root'})
export class ProductService {
  constructor(private http: HttpClient) {
  }
  async getProducts(pageNo = 1): Promise<HttpResponse<any>> {
    return await this.http.get<any>(environment.url + PRODUCT_BASE_URL + '?page=' + pageNo, {observe: 'response' as 'response'})
      .toPromise();
  }
  async getProductsAfterLoggedIn(pageNo = 1): Promise<HttpResponse<any>> {
    return await this.http.get<any>(environment.url + PRODUCT_BASE_URL + '?page=' + pageNo, {observe: 'response' as 'response'})
      .toPromise();
  }
  async getOneProductAfterLoggedIn(productId: number): Promise<ProductInterface> {
    return await this.http.get<ProductInterface>(environment.url + ONE_PRODUCT_BASE_URL + productId).toPromise();
  }
  async getProductCCTVAfterLoggedIn(productId: number): Promise<ProductCCTVInterface> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/' + productId + '/cctv';
    return await this.http.get<ProductCCTVInterface>(url).toPromise();
  }
  async getTimelineProduct(productId: number): Promise<TimelineInterface[]> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/information/' + productId;
    return await this.http.get<TimelineInterface[]>(url).toPromise();
  }
  async getCertificateProduct(productId: number): Promise<CertificateInterface> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/certificate/' + productId;
    return await this.http.get<CertificateInterface>(url).toPromise();
  }
}
