import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANGUAGE, JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from './constants/common.constant';
import {UserService} from './services/user.service';
import {UserInterface} from './interfaces/user.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ramerame.co.id';
  public profile: UserInterface = {};
  constructor(
    private translate: TranslateService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    let langCode = localStorage.getItem('lang');
    if (!langCode) {
      langCode = DEFAULT_LANGUAGE;
      localStorage.setItem('lang', langCode);
    }
    this.translate.setDefaultLang(langCode.toLocaleLowerCase());
    this.translate.use(langCode.toLocaleLowerCase());
  }

  ngAfterViewInit(): void {
    let token = localStorage.getItem(JWT_TOKEN_KEY);
    if(token && token.length > 5) {
      this.userService.getCurrentUserAsync().then((res) => {
        this.profile = res;
        localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
      }, (err) => {
        console.log(err);
      });
    }
  }
}
