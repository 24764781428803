<div class="main-content full-screen with-fixed-bottom-button ticket-page">
    <div *ngIf="supportTickets.length == 0 && flag == 1" class="background">
        <span class="icon-ticket mb-3 text-grey"></span>
        <p class="text-grey text-weight-regular">{{'pages.support-ticket.empty-ticket' | translate}}</p>
    </div>
    <div *ngIf="supportTickets.length > 0" class="ticket-list">
        <div *ngFor="let ticket of supportTickets" class="ticket card" (click)="toDetails(ticket)">
            <div class="card-body">
                <div class="row no-gutters">
                    <div class="col-3 col-md-2 label">{{'pages.support-ticket.ticket-id' | translate}}</div>
                    <div class="col-9 col-md-10 value">{{ticket.ticketId.toUpperCase()}}</div>
                </div>

                <div class="row no-gutters">
                    <div class="col-3 col-md-2 label">{{'common.date' | translate}}</div>
                    <div class="col-9 col-md-10 value">{{date.completeLocaleDateTime(ticket.date, 'id').toUpperCase()}}</div>
                </div>

                <div class="row no-gutters">
                    <div class="col-3 col-md-2 label">{{'common.title' | translate}}</div>
                    <div class="col-9 col-md-10 value">{{ticket.title.toUpperCase()}}</div>
                </div>

                <div class="row no-gutters">
                    <div class="col-3 col-md-2 label">{{'pages.support-ticket.division' | translate}}</div>
                    <div class="col-9 col-md-10 value">{{ticket.division.toUpperCase()}}</div>
                </div>
                <div class="row no-gutters">
                    <div class="col-3 col-md-2 label">{{'pages.support-ticket.priority' | translate}}</div>
                    <div class="col-9 col-md-10 value" *ngIf="ticket.priority == 1" style="text-transform: uppercase">{{'common.low' | translate}}</div>
                    <div class="col-9 col-md-10 value" *ngIf="ticket.priority == 2" style="text-transform: uppercase">{{'common.medium' | translate}}</div>
                    <div class="col-9 col-md-10 value" *ngIf="ticket.priority == 3" style="text-transform: uppercase">{{'common.high' | translate}}</div>
                </div>
                <div class="row no-gutters">
                    <div class="col-3 col-md-2 label">{{'common.status' | translate}}</div>
                    <div class="col-9 col-md-10 value">
                        <span class="ribbon orange static" *ngIf="ticket.status == 'O'" style="text-transform: uppercase">{{'pages.support-ticket.open-status' | translate}}</span>
                        <span class="ribbon grey-background static" *ngIf="ticket.status == 'C'" style="text-transform: uppercase">{{'pages.support-ticket.close-status' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-buttons">
        <button class="primary-button big-button" mat-button [routerLink]="ui.absoluteUrl(MEMBER_TICKET_FORM_PAGE)">{{'pages.support-ticket.open-new-ticket' | translate}}</button>
    </div>
</div>
