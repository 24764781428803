import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {OWL_OPTIONS_LANDING} from '../../constants/owl-options.constant';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {MatDialog} from '@angular/material/dialog';
import {ProductService} from '../../services/product.service';
import {ProductInterface} from '../../interfaces/product.interface';
import {DEFAULT_LANGUAGE, STORAGE_LANG_KEY} from '../../constants/common.constant';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileListComponent} from '../dialog/file-list.component';
import {environment} from '../../../environments/environment';
import {TosSlotBuyDialogComponent} from '../dialog/dialog-tos-slot-buy.component';
import {DialogInterface} from '../../shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../shared/dialog/dialog-scroll.component';
import {InsuranceService} from '../../services/insurance.service';
import {ProductInsurance} from '../../interfaces/shopping-cart.interface';

@Component({
  selector: 'app-page-business-detail',
  templateUrl: './business-detail.page.html',
})
export class BusinessDetailPageComponent extends BaseComponent implements OnInit{
  public productId = 0;
  public product: ProductInterface = {};
  public productInsurances: ProductInsurance[] = [];
  public upperSliderOptions: OwlOptions = OWL_OPTIONS_LANDING;
  public locale = DEFAULT_LANGUAGE;
  public productMapSafe: SafeResourceUrl;
  public descriptionSafe: SafeResourceUrl;
  public baseUrl:string = environment.base;
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private productService: ProductService,
    private domSanitizer: DomSanitizer,
    private insuranceService: InsuranceService
  ) {
    super(injector);
    this.productId = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.locale = localStorage.getItem(STORAGE_LANG_KEY);
    this.productService.getOneProductAfterLoggedIn(this.productId).then((res) => {
      this.product = res;
      this.productMapSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.product.productMap);
      this.descriptionSafe = this.domSanitizer.bypassSecurityTrustHtml(this.product.longDescription);
    }, (err) => {
      console.log('cannot find any product', err);
    });
    this.insuranceService.getProductInsurance(this.productId).then((res) => {
      this.productInsurances = res;
    }, (err) => {
      console.log('cannot find product insurances', err);
    });
  }
  openDocumentDialog(): void {
    this.dialog.open(FileListComponent, {
     data: {
       list: this.product.documents,
       productName: this.product.productName,
       baseUrl: environment.base
     },
    });
  }
  openTosDialog(): void {
    if(this.userProfile.basic.complete == 'INCOMPLETE') {
      const data: DialogInterface = {
        title : 'Profile Belum Lengkap',
        imageUrl: 'assets/images/member/alert-image2-min.png',
        content: 'Untuk dapat melakukan pembelian, mohon untuk melengkapi profil anda agar memudahkan kami dalam proses administrasi dan legalitas, anda dapat melakukannya dengan mengakses halaman profil, admin kami akan memvalidasi dan memeriksa kebenaran datanya terlebih dahulu. Notifikasi akan diberikan melalui email',
        buttons: [
          {label: 'OK, Tutup', value: 1, className: 'primary-button big-button'},
        ]
      };
      this.dialog.open(DialogScrollComponent, {data});
    }else {
      this.dialog.open(TosSlotBuyDialogComponent, {
        data: {
          content: this.product.term,
          product: this.product
        },
      });
    }

  }
}
