<div class="main-content full-screen" style="background-color: #FAFAFA">
      <mat-tab-group [selectedIndex]="activeTab">
        <mat-tab label="{{'pages.my-investment.title' | translate}}">
          <div *ngIf="myInvestments.length == 0 && flag == 1" class="background">
            <img alt="" src="assets/images/member/usaha@2x.png" class="ml-5 img-fluid" />
            <h4 class="text-grey text-weight-bold mt-2">{{'pages.my-investment.oops' | translate}}</h4>
            <p class="text-grey text-weight-regular">{{'pages.my-investment.empty-text' | translate}}</p>
          </div>
          <div *ngIf="myInvestments.length > 0">
            <div style="overflow-x: scroll">
              <div class="mb-3" style="width: 620px;">
                <button (click)="activeGroup = 0" [ngClass]="{'download-button': (activeGroup == 0), 'download-button-inactive': (activeGroup != 0)}" class="mr-2" mat-flat-button>Sedang Berjalan</button>
                <button (click)="activeGroup = 3" [ngClass]="{'download-button': (activeGroup == 3), 'download-button-inactive': (activeGroup != 3)}" class="mr-2" mat-flat-button>Sedang Ditawarkan</button>
                <button (click)="activeGroup = 1" [ngClass]="{'download-button': (activeGroup == 1), 'download-button-inactive': (activeGroup != 1)}" class="mr-2" mat-flat-button>Proses Admin</button>
                <button (click)="activeGroup = 2" [ngClass]="{'download-button': (activeGroup == 2), 'download-button-inactive mr-2': (activeGroup != 2)}" class="mr-2" mat-flat-button>Kontrak Berakhir</button>
              </div>
            </div>
            <ng-container *ngIf="activeGroup == 3">
              <div class="row mb-3" *ngFor="let invest of groupActive">
                <div class="col-12">
                  <div class="card" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + invest.id)">
                    <div class="card-body p-2">
                      <div class="row no-gutters align-items-center">
                        <div class="col-3 col-md-6 text-center">
                          <img alt="" [src]="baseUrl + invest.image" class="img-fluid left-image" />
                        </div>
                        <div class="col-9 col-md-6 px-3">
                          <h4>{{invest.productName}}</h4>
                        </div>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-6">
                          <h5 class="text-grey" style="text-transform: uppercase">{{'common.total-slot' | translate}}</h5>
                        </div>
                        <div class="col-6">
                          <h5>{{invest.ecUnit}}</h5>
                        </div>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-6">
                          <h5 class="text-grey" style="text-transform: uppercase">{{'pages.business-list.price-per-slot' | translate}}</h5>
                        </div>
                        <div class="col-6">
                          <h5>{{'common.currency-idr' | translate}} {{ui.currencyFormat(invest.ecRate, locale)}}</h5>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="activeGroup == 0">
              <div class="row mb-3" *ngFor="let invest of groupRun">
                <div class="col-12">
                  <div class="card" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + invest.id)">
                    <div class="card-body p-2">
                      <div class="row no-gutters align-items-center">
                        <div class="col-3 col-md-6 text-center">
                          <img alt="" [src]="baseUrl + invest.image" class="img-fluid left-image" />
                        </div>
                        <div class="col-9 col-md-6 px-3">
                          <h4>{{invest.productName}}</h4>
                          <div style="font-size: 10px;" *ngIf="invest.currentSales.length > 0">Pembaruan terakhir pada tanggal {{date.dateTimeToLocaleDate(invest.currentSales[0].salesDate, locale)}}</div>
                          <div style="font-size: 10px;" *ngIf="invest.currentSales.length == 0 && invest.prevSales.length > 0">Pembaruan terakhir pada tanggal {{date.dateTimeToLocaleDate(invest.prevSales[0].salesDate, locale)}}</div>
                          <div style="font-size: 10px;" *ngIf="invest.currentSales.length == 0 && invest.prevSales.length == 0">Pembaruan terakhir pada tanggal -</div>
                        </div>
                      </div>
                      <div class="row no-gutters align-items-center mt-3">
                        <div class="col-4">
                          <div style="font-size: 11px; font-weight: bold">Penjualan Harian</div>
                          <div style="font-size: 12px;" *ngIf="invest.currentSales.length > 0">{{ui.currencyFormat(invest.currentSales[0].salesAmount, locale)}}</div>
                          <div style="font-size: 12px;" *ngIf="invest.currentSales.length == 0 && invest.prevSales.length > 0">{{ui.currencyFormat(invest.prevSales[0].salesAmount, locale)}}</div>
                          <div style="font-size: 12px;" *ngIf="invest.currentSales.length == 0 && invest.prevSales.length == 0">'-'</div>
                        </div>
                        <div class="col-4">
                          <div style="font-size: 11px; font-weight: bold">Bulan Ini</div>
                          <div style="font-size: 12px;">{{ui.currencyFormat(invest.totalCurrent, locale)}}</div>
                        </div>
                        <div class="col-4">
                          <div style="font-size: 11px; font-weight: bold">Bulan Sebelumnya</div>
                          <div style="font-size: 12px;">{{ui.currencyFormat(invest.totalPrevious, locale)}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="activeGroup == 1">
              <div class="row mb-3" *ngFor="let invest of groupAdmin">
                <div class="col-12">
                  <div class="card" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + invest.id)">
                    <div class="card-body p-2">
                      <div class="row no-gutters align-items-center">
                        <div class="col-3 col-md-6 text-center">
                          <img alt="" [src]="baseUrl + invest.image" class="img-fluid left-image" />
                        </div>
                        <div class="col-9 col-md-6 px-3">
                          <h4>{{invest.productName}}</h4>
                        </div>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-6">
                          <h5 class="text-grey" style="text-transform: uppercase">{{'common.total-slot' | translate}}</h5>
                        </div>
                        <div class="col-6">
                          <h5>{{invest.ecUnit}}</h5>
                        </div>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-6">
                          <h5 class="text-grey" style="text-transform: uppercase">{{'pages.business-list.price-per-slot' | translate}}</h5>
                        </div>
                        <div class="col-6">
                          <h5>{{'common.currency-idr' | translate}} {{ui.currencyFormat(invest.ecRate, locale)}}</h5>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="activeGroup == 2">
              <div class="row mb-3" *ngFor="let invest of groupFinish">
                <div class="col-12">
                  <div class="card" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + invest.id)">
                    <div class="card-body p-2">
                      <div class="row no-gutters align-items-center">
                        <div class="col-3 col-md-6 text-center">
                          <img alt="" [src]="baseUrl + invest.image" class="img-fluid left-image" />
                        </div>
                        <div class="col-9 col-md-6 px-3">
                          <h4>{{invest.productName}}</h4>
                        </div>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-6">
                          <h5 class="text-grey" style="text-transform: uppercase">{{'common.total-slot' | translate}}</h5>
                        </div>
                        <div class="col-6">
                          <h5>{{invest.ecUnit}}</h5>
                        </div>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-6">
                          <h5 class="text-grey" style="text-transform: uppercase">{{'pages.business-list.price-per-slot' | translate}}</h5>
                        </div>
                        <div class="col-6">
                          <h5>{{'common.currency-idr' | translate}} {{ui.currencyFormat(invest.ecRate, locale)}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

          </div>
        </mat-tab>
        <mat-tab label="{{'pages.my-investment.history' | translate}}">
            <div *ngIf="myPurchases.length == 0" class="background">
              <img alt="" src="assets/images/member/usaha@2x.png" class="ml-5 img-fluid" />
              <h4 class="text-grey text-weight-bold mt-2">{{'pages.my-investment.oops' | translate}}</h4>
              <p class="text-grey text-weight-regular">{{'pages.my-investment.empty-text' | translate}}</p>
            </div>
            <div *ngIf="myPurchases.length > 0">
              <div  class="row mb-3" *ngFor="let purchase of myPurchases">
                <div class="col-12">
                  <div class="card" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE + '/' + purchase.purchaseId)">
                    <div class="card-body p-3">
                      <div class="row no-gutters">
                        <div class="col-6 col-md-6">
                          <div class="p-1">{{'pages.my-investment.purchase-id' | translate}}</div>
                          <div class="p-1 my-1"><b style="text-transform: uppercase;">{{purchase.uniqueId}}</b></div>
                          <div class="p-1">{{'pages.my-investment.purchase-date' | translate}}</div>
                          <div class="p-1 my-1"><b style="text-transform: uppercase;">{{date.dateTimeToLocaleDate(purchase.purchaseDate, locale)}}</b></div>
                          <div class="p-1">{{'pages.my-investment.more-info' | translate}} : </div>
                        </div>
                        <div class="col-6 col-md-6 text-right">
                          <div *ngIf="!purchase.paymentStatus" class="ribbon static green">{{ 'status.approved' | translate }}</div>
                          <div *ngIf="purchase.paymentStatus == 2 && !purchase.attachment" class="ribbon static orange">{{ 'status.payment-process' | translate }}</div>
                          <div *ngIf="purchase.paymentStatus == 2 && purchase.attachment" class="ribbon static blue">{{ 'status.payment-validation' | translate }}</div>
                          <div *ngIf="purchase.paymentStatus == 3" class="ribbon static red">{{ 'status.rejected' | translate }}</div>
                        </div>
                      </div>
                      <hr>
                      <div class="row no-gutters align-items-center">
                        <div class="col-12 col-md-12">
                          <div class="row no-gutters">
                            <div class="col-6 col-md-6 p-1">{{'pages.my-investment.product-name' | translate}}</div>
                            <div class="col-6 col-md-6 text-right p-1 font-weight-bold">
                              {{purchase.products[0]}}
                            </div>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-6 col-md-6 p-1">{{'pages.my-investment.unit-count' | translate}}</div>
                            <div class="col-6 col-md-6 text-right p-1 font-weight-bold">
                              {{purchase.count}} {{'common.slot' | translate}}
                            </div>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-6 col-md-6 p-1">{{'pages.my-investment.total' | translate}}</div>
                            <div class="col-6 col-md-6 text-right p-1 font-weight-bold">
                              {{'common.currency-idr' | translate}} {{ui.currencyFormat((purchase.total + (purchase.amtInsurance ? purchase.amtInsurance : 0)), locale)}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </mat-tab>
      </mat-tab-group>


</div>
