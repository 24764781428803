import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogInterface} from '../../shared/dialog/dialog.interface';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CAPITAL_VALUES_OPTIONS, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {UserInterface} from '../../interfaces/user.interface';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DialogScrollComponent} from '../../shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-success-profile-verification-component',
  templateUrl: './success-profile-verification.component.html',
})
export class SuccessProfileVerificationComponent implements OnInit {
  @Output() toggleEditor = new EventEmitter<boolean>();
  public profile: UserInterface = {};
  public capitalValues = CAPITAL_VALUES_OPTIONS;
  public businessFG: UntypedFormGroup;
  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private userService: UserService,
    private sanitizer: DomSanitizer
  ) {
    this.businessFG = this.formBuilder.group({
      identityTypeFC: [null, [Validators.required]],
      identityNumbersFC: [null, [Validators.required]],
      capitalValueFC: [null, [Validators.required]],
      npwpFC: [null,[]]
    });
  }
  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface;
    this.businessFG.setValue({
      identityTypeFC: (this.profile.business && this.profile.business.identityType) ? this.profile.business.identityType.toUpperCase() : null,
      identityNumbersFC: (this.profile.business && this.profile.business.identityNo) ? this.profile.business.identityNo : null,
      capitalValueFC: (this.profile.business && this.profile.business.capitalValue) ? this.profile.business.capitalValue : null,
      npwpFC: (this.profile.business && this.profile.business.npwpNo) ? this.profile.business.npwpNo : null
    });
    this.businessFG.controls.identityTypeFC.disable({onlySelf: true});
    this.businessFG.controls.identityNumbersFC.disable({onlySelf: true});
    this.businessFG.controls.capitalValueFC.disable({onlySelf: true});
  }
  showEditor(): void {
    this.toggleEditor.emit(true);
  }
  viewImage(param: string): void {
    let title = '';
    switch (param) {
      case 'id': title = 'Foto Identitas Diri'; break;
      case 'user-and-id': title = 'Swafoto dengan Identitas Diri'; break;
      case 'npwp': title = 'Nomer Pokok Wajib Pajak'; break;
    }
    this.userService.getProfileImageAsync(param).then((res) => {
      const urlCreator = window.URL;
      const object = urlCreator.createObjectURL(res);
      const imageUrl = this.sanitizer.bypassSecurityTrustUrl(object);
      const data: DialogInterface = {
        title,
        imageAlt: title,
        content: '',
        imageUrl: imageUrl as SafeResourceUrl
      };
      this.dialog.open(DialogScrollComponent, {data});

    });
  }
}
