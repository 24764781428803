<div class="main-content">
    <h1 class="h4">Ubah kata sandi</h1>
    <p>Jangan pernah memberitahu sandi akun anda kepada siapapun untuk menghindari kejadian yang tidak di inginkan</p>
    <form [formGroup]="pwdFG" (submit)="changePassword()">
        <div class="form-group">
            <label for="currentPassword">Kata sandi saat ini</label>
            <div class="input-group mb-3">
              <input *ngIf="!toggleCurrent" autocomplete="new-currentPassword" name="currentPassword" id="currentPassword" type="password" formControlName="currentPassword" class="form-control" aria-describedby="Masukan kata sandi mu saat ini" placeholder="Masukan kata sandi mu saat ini" />
              <input *ngIf="toggleCurrent" autocomplete="new-currentPasswordDup" name="currentPasswordDup" id="currentPasswordDup" type="text" formControlName="currentPassword" class="form-control" aria-describedby="Masukan kata sandi mu saat ini" placeholder="Masukan kata sandi mu saat ini" />
              <div class="input-group-append">
                <span *ngIf="!toggleCurrent" class="input-group-text" (click)="toggleCurrent = true">
                    <span class="icon-eye"></span>
                </span>
                <span *ngIf="toggleCurrent" class="input-group-text" (click)="toggleCurrent = false">
                  <span class="icon-eye-blocked"></span>
                </span>
              </div>
            </div>
        </div>
        <div class="form-group">
            <label for="newPassword">Kata sandi baru</label>
            <div class="input-group mb-3">
              <input *ngIf="!toggleNew" autocomplete="new-newPassword" name="newPassword" id="newPassword" type="password" formControlName="newPassword" class="form-control" aria-describedby="Buat kata sandi baru" placeholder="Buat kata sandi baru" />
              <input *ngIf="toggleNew" autocomplete="new-newPasswordDup" name="newPasswordDup" id="newPasswordDup" type="text" formControlName="newPassword" class="form-control" aria-describedby="Buat kata sandi baru" placeholder="Buat kata sandi baru" />
              <div class="input-group-append">
                <span *ngIf="!toggleNew" class="input-group-text" (click)="toggleNew = true">
                    <span class="icon-eye"></span>
                </span>
                <span *ngIf="toggleNew" class="input-group-text" (click)="toggleNew = false">
                  <span class="icon-eye-blocked"></span>
                </span>
              </div>
            </div>
        </div>
        <div class="form-group">
            <label for="passwordConfirmation">Konfirmasi kata sandi</label>
            <div class="input-group mb-3">
              <input *ngIf="!toggleConfirm" autocomplete="new-passwordConfirmation" name="passwordConfirmation" id="passwordConfirmation" type="password" formControlName="passwordConfirmation" class="form-control" aria-describedby="Masukan kembali kata sandi baru mu" placeholder="Masukan kembali kata sandi baru mu" />
              <input *ngIf="toggleConfirm" autocomplete="new-passwordConfirmationDup" name="passwordConfirmationDup" id="passwordConfirmationDup" type="text" formControlName="passwordConfirmation" class="form-control" aria-describedby="Masukan kembali kata sandi baru mu" placeholder="Masukan kembali kata sandi baru mu" />
              <div class="input-group-append">
                <span *ngIf="!toggleConfirm" class="input-group-text" (click)="toggleConfirm = true">
                    <span class="icon-eye"></span>
                </span>
                <span *ngIf="toggleConfirm" class="input-group-text" (click)="toggleConfirm = false">
                  <span class="icon-eye-blocked"></span>
                </span>
              </div>
            </div>
        </div>
        <div class="form-group">
            <button [disabled]="pwdFG.status != validForm" class="primary-button big-button" mat-flat-button>Ubah Kata Sandi</button>
        </div>
    </form>
</div>
