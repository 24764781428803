<div class="fixActionRow">
  <div class="close-button">
      <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="mt-2" [innerHTML]="data.content | keepHtml"></div>
    <mat-dialog-actions style="min-height: 200px;">
      <p>Dengan Mengklik tombol "Setuju & Beli SLOT" Saya menyetujui syarat dan ketentuan</p>
      <div class="width-100">
        <button mat-flat-button class="primary-button big-button" (click)="addToCart()">Setuju & Beli</button>
        <button mat-button mat-dialog-close class="clear-button big-button">Batal</button>
      </div>
    </mat-dialog-actions>
  </mat-dialog-content>
  <div class="spacer"></div>

</div>
