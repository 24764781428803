import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_ACCOUNT_PANEL_PAGE} from 'src/app/constants/route.constant';
import {MasterService} from '../../../services/master.service';
import {SelectInterface} from '../../../interfaces/common.interface';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../../services/profile.service';
import {UserAccountInterface, UserInterface} from '../../../interfaces/user.interface';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {STORAGE_PROFILE_KEY} from '../../../constants/common.constant';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-member-bank',
  templateUrl: './member-bank.page.html'
})
export class MemberBankPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;
  public banks: SelectInterface[] = [];
  public bankFG: UntypedFormGroup;
  public profile: UserInterface = {};
  constructor(
    injector: Injector,
    private masterService: MasterService,
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.bankFG = this.formBuilder.group({
      bankIdFC: [null, [Validators.required]],
      accountOwnerFC: ['', [Validators.required]],
      bankBranchFC: ['', [Validators.required]],
      accountNoFC: ['', [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.bankFG.setValue({
      bankIdFC       : (this.userProfile.account && this.userProfile.account.bankId) ? this.userProfile.account.bankId : null,
      accountOwnerFC : (this.userProfile.account && this.userProfile.account.accountOwner) ? this.userProfile.account.accountOwner : '',
      bankBranchFC   : (this.userProfile.account && this.userProfile.account.bankBranch) ? this.userProfile.account.bankBranch : '',
      accountNoFC    : (this.userProfile.account && this.userProfile.account.accountNumbers) ? this.userProfile.account.accountNumbers : ''
    });
    this.masterService.getBanksAsync().then((res) => {
      this.banks = res;
    });
  }
  saveBank(): void {
    const payload: UserAccountInterface = {
      bankId: this.bankFG.controls.bankIdFC.value,
      accountOwner: this.bankFG.controls.accountOwnerFC.value,
      bankBranch: this.bankFG.controls.bankBranchFC.value,
      accountNumbers: this.bankFG.controls.accountNoFC.value
    };
    this.profileService.bankAccount(payload).then(() => {
      this.currentUser();
    });
  }
  currentUser(): void {
    this.userService.getCurrentUserAsync().then((res) => {
      this.profile = res;
      this.openSuccessDialog();
    }, (err) => {
      console.log(err);
    });
  }
  openSuccessDialog(): void {
    const data: DialogInterface = {
      title : 'Profil Anda Berhasil Diubah',
      content: 'Kami akan melakukan review terhadap perubahan data anda. Tekan Tombol OK untuk menutup pesan ini',
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {label: 'OK, Tutup', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialogRef = this.dialog.open(DialogScrollComponent, {data});
    dialogRef.afterClosed().subscribe(() => {
      localStorage.removeItem(STORAGE_PROFILE_KEY);
      localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
      this.label.getLabel(this.translateService, 'toast.data-updated').then((notification) => {
        this.redirectTo(MEMBER_ACCOUNT_PANEL_PAGE);
        this.snackBar.open(notification as string, '', {
          duration: 2000
        });
      });
    });
  }
}
