import {Component, Input, OnInit} from '@angular/core';
import {LandingBannerInterface} from '../../interfaces/landing.interface';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-landing-banner-component',
  templateUrl: './landing-banner.component.html',
})
export class LandingBannerComponent implements OnInit{
  @Input() landingBanners: LandingBannerInterface[] = [];
  @Input() sliderOptions: OwlOptions;
  ngOnInit(): void {
  }
}
