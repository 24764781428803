import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {MD_PRIVACY_CONTENT} from '../../constants/json.constant';

@Component({
  selector: 'app-page-privacy',
  templateUrl: './privacy.page.html',
})
export class PrivacyPageComponent extends BaseComponent implements OnInit{
  public privacyPath: string;
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.privacyPath = MD_PRIVACY_CONTENT;
    super.ngOnInit();
  }
}
