import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../../shared/shared.module';
import {MemberNotificationPageComponent} from './member-notification.page';
import {MaterialModule} from '../../../material/material.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    NgProgressModule,
    AppSharedModule,
    MaterialModule
  ],
  exports: [
    MemberNotificationPageComponent
  ],
  declarations: [
    MemberNotificationPageComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMemberNotificationModule {}
