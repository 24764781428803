<div class="main-content business-detail">
    <div class="business-image">
        <div class="text-center">
          <img *ngIf="!product.imageUrl" src="http://placehold.it/1920x1080" class="img-fluid left-image" alt="" />
          <img *ngIf="product.imageUrl" alt="" [src]="baseUrl + product.imageUrl + '?rand=123'" class="img-fluid left-image" />
        </div>
    </div>
    <div class="menu mt-3 mb-3">
      <div class="row">
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-laporan"></span>
            </button>
            <span class="button-label">Laporan</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-ask-admin"></span>
            </button>
            <span class="button-label">Tanya Admin</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-cctv"></span>
            </button>
            <span class="button-label">Monitoring CCTV</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button active" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-timeline"></span>
            </button>
            <span class="button-label">Informasi</span>
        </div>
      </div>
    </div>
    <div class="content grey-background ">
        <div class="card" *ngIf="product.certificateId">
          <div class="card-body">
            <a (click)="getCertificate(product.id)">
              <div class="text-center">Click untuk mengunduh Dokumen Pendukung </div>
              <div class="text-center">{{product.productName}}</div>
            </a>
          </div>
        </div>
        <div class="timeline mt-3">
            <div class="month" *ngFor="let g of groupTimeline">
                <div class="title">
                    <h3>{{date.monthOnlyFromDate(g.groupDate, locale)}} <span class="text-grey">{{date.yearOnlyFromDate(g.groupDate, locale)}}</span></h3>
                    <div class=""></div>
                </div>
                <div class="dates">
                    <div class="date" *ngFor="let d of g.data" (click)="viewDetail(d)">
                        <div class="big-date">
                            <h4>{{date.dateOnlyFromDate(d.noticeDate, locale)}}</h4>
                            <h5 class="text-grey">{{date.dayOnlyFromDate(d.noticeDate, locale)}}</h5>
                        </div>
                        <div class="status-update">
                            <h5>{{d.title}}</h5>
                            <h5 class="text-grey" [innerHTML]="d.content.substring(0,100) + ' [...]'"></h5>
                            <button *ngIf="d.hasAttachment" (click)="getFileAttachment(d.attachment, d.id)" class="download-button small-button" mat-flat-button>Unduh lampiran</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
