import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemInterface} from '../../interfaces/menu-item.interface';
import {MENU_ITEMS} from '../../constants/menu.constant';
import {UiHelper} from '../../helpers/ui.helper';
import {Router} from '@angular/router';
import {JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {UserInterface} from '../../interfaces/user.interface';
import {MAIN_LANDING_PAGE} from '../../constants/route.constant';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LabelHelper} from '../../helpers/label.helper';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['sidenav.scss']
})
export class SidenavItemComponent implements OnInit {
  @Input() isExpanded: boolean;
  @Output() clicked = new EventEmitter<boolean>();
  ui = UiHelper;
  public menu = MENU_ITEMS;
  public policies: MenuItemInterface[] = [];
  public isMerchant = false;
  public snackBar: MatSnackBar;
  public label = LabelHelper;
  constructor(private router: Router, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if(localStorage.getItem(STORAGE_PROFILE_KEY)) {
      let add = (JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional;
      if(add && add.merchantId && add.roleId && add.roleId == 6) {
        this.isMerchant = true;
      }
    }

    this.policies = this.menu;
  }
  externalLoad(url: string): void {
    this.clicked.emit(true);
    window.open(url, '_blank');
  }
  clickMenu(url: string, id:string): void {
    this.clicked.emit(true);
    if(id === "logout") {
      this.signOut().then();
    } else {
      this.router.navigate([url]).then(() => {});
    }
  }

  async signOut(): Promise<any> {
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem('next');
    localStorage.removeItem(STORAGE_PROFILE_KEY);
    const notification = await this.label.getLabel(this.translateService, 'toast.signed-out');
    this.router.navigate([this.ui.absoluteUrl(MAIN_LANDING_PAGE)]).then(() => {
      this.snackBar.open(notification as string, '', {
        duration: 2000
      });
    });
  }
}
