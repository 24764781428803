import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KSP_PERTIWI_URL } from 'src/app/constants/common.constant';
import { WalletDialogRegisteredComponent } from '../member/wallet/wallet-dialog-registered.component';

@Component({
  selector: 'app-dialog-new-app',
  templateUrl: './dialog-new-app.component.html',
})
export class DialogNewAppComponent implements OnInit {
  public readonly KSP_PERTIWI_URL = KSP_PERTIWI_URL;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WalletDialogRegisteredComponent>
  ) {}

  ngOnInit(): void {}
}
