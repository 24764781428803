<div class="main-content full-screen with-fixed-bottom-button ticket-page">
  <div class="ticket-list">
    <div class="ticket card">
      <div class="card-body">
        <div class="row no-gutters">
          <div class="col-3 col-md-2 label">{{'pages.support-ticket.ticket-id' | translate}}</div>
          <div class="col-6 col-md-8 value" style="text-transform: uppercase">{{ticket.ticketId}}</div>
          <div class="col-3 col-md-2 value text-right">
            <span class="ribbon orange static" *ngIf="ticket.status == 'O'" style="text-transform: uppercase">{{'pages.support-ticket.open-status' | translate}}</span>
            <span class="ribbon grey-background static" *ngIf="ticket.status == 'C'" style="text-transform: uppercase">{{'pages.support-ticket.close-status' | translate}}</span>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-3 col-md-2 label">{{'common.date' | translate}}</div>
          <div class="col-9 col-md-10 value">{{date.completeLocaleDateTime(ticket.date, locale).toUpperCase()}}</div>
        </div>

        <div class="row no-gutters">
          <div class="col-3 col-md-2 label">{{'common.title' | translate}}</div>
          <div class="col-9 col-md-10 value" style="text-transform: uppercase">{{ticket.title}}</div>
        </div>

        <div class="row no-gutters">
          <div class="col-12 col-md-12 value text-right">
            <span class="ribbon green static mr-1" style="text-transform: uppercase">Divisi {{ticket.division}}</span>

            <span class="ribbon grey-background static" style="text-transform: uppercase" *ngIf="ticket.priority == 1"> Prioritas {{'common.low' | translate}}</span>
            <span class="ribbon orange static" style="text-transform: uppercase" *ngIf="ticket.priority == 2"> Prioritas {{'common.medium' | translate}}</span>
            <span class="ribbon red static" style="text-transform: uppercase" *ngIf="ticket.priority == 3"> Prioritas {{'common.high' | translate}}</span>
          </div>
        </div>

      </div>
    </div>

    <div class="ticket card" *ngFor="let msg of ticket.content">
      <div class="card-body">
        <div class="row no-gutters">
          <div class="col-12 col-md-12 label">{{msg.username}}</div>
        </div>
        <div class="row no-gutters">
          <div class="col-12 col-md-12 value" [innerHTML]="msg.message"></div>
        </div>
        <div class="row no-gutters">
          <div class="col-12 col-md-12 label text-right">{{date.completeLocaleDateTime(msg.postedAt, locale)}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-buttons">
    <textarea [(ngModel)]="replyMessage" class="form-control"></textarea>
    <button (click)="sendReply()" [disabled]="replyMessage.length == 0" class="primary-button" style="width: 65px; height: 65px; margin-right: 0" mat-flat-button>
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
