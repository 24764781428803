<div class="main-content point-exchange">
  <div class="row">
    <div class="col-8 col-sm-12 col-xs-12">
      <div class="my-point">
        <table border="0" class="width-100">
          <tbody>
          <tr>
            <td><i class="icon-coin"></i></td>
            <td>
              <div>{{ 'pages.member-card.ramerame-point' | translate }}</div>
              <div class="point-value">
                {{point}}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="merchandise-categories overflow-auto pt-3">
        <div class="justify-content-center align-items-center" style="width: 700px;">
          <ul class="buttons">
            <li>
              <div class="icon-wrapper justify-content-center align-items-center text-center" (click)="showAllCategories()">
                <i class="icon-Group-45 all"></i>
                <h6 class="label">{{ 'pages.member-card.all' | translate }}</h6>
              </div>
            </li>
            <li [ngStyle]="merchandiseCategory.isActive == 1 ? {'opacity':100} : {'opacity':0.5}"
                 *ngFor="let merchandiseCategory of merchandiseCategories">
              <div class="icon-wrapper justify-content-center align-items-center text-center" (click)="filterByCategory(merchandiseCategory.category)">
                <i [className]="merchandiseCategory.iconName"></i>
                <h6 class="label">{{ merchandiseCategory.category}}</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <div class="search-box">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="input-group mb-3">
                <input [(ngModel)]="searchToken" type="text" class="form-control" aria-label="Cari..." placeholder="Cari...">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <mat-slider (valueChange)="getValueSlider($event)"
        thumbLabel
        [displayWith]="formatLabel"
        step="10"
        min="0"
        max="10000" [value]="10000"

        aria-label="units"></mat-slider>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <div class="card mb-2" *ngFor="let m of merchandises">
        <div class="card-body" style="padding: 0 !important;" (click)="doNavigateVoucherDetail(m.id)">
          <div class="row no-gutters">
            <div class="col-6 no-gutters">
              <img [src]="baseUrl + m.imagePath" class="img-fluid">
            </div>
            <div class="col-6 no-gutters">
              <h2 class="merchandise-name">{{m.merchandiseName}}</h2>
              <h4 class="merchant-name">{{m.merchantName}}</h4>
              <h5 class="point-value">{{m.voucherPrice}} {{ 'pages.member-card.point' | translate }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
