import {BaseComponent} from '../../../shared/base/base.component';
import {Component, Injector, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {VoucherDetailsInterface} from '../../../interfaces/member-card.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {MembercardService} from '../../../services/membercard.service';

@Component({
  selector: 'app-use-voucher',
  templateUrl: './use-voucher.page.html'
})
export class UseVoucherComponent extends BaseComponent implements OnInit {

  public voucherId;
  public voucher: VoucherDetailsInterface = {};
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public hoursVoucherParam = 24;

  constructor(injector: Injector,
              private location: Location,
              private memberCardService: MembercardService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.voucherId = this.activatedRoute.snapshot.params.id;
    this.memberCardService.getCampaignVoucherDetails(this.voucherId).then((res) => {
      this.voucher = res;
    }).catch(err => console.error(err));
  }

  back(): void {
    this.location.back();
  }
}
