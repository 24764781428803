<div class="main-content" *ngIf="isSignedIn">
  <form [formGroup]="bankFG" (submit)="saveBank()">
    <p>{{'pages.bank-account.top-message' | translate}}</p>
    <div class="form-group">
        <label>{{'pages.bank-account.bank-name' | translate}}</label>
        <ng-select formControlName="bankIdFC" [items]="banks"
                bindLabel="label"
                autofocus
                placeholder="{{'pages.bank-account.select-bank' | translate}}"
                bindValue="value"
                class="ng-select"
                >
        </ng-select>
    </div>
    <div class="form-group">
        <label for="identity-id">{{'pages.bank-account.account-owner' | translate}}</label>
        <input formControlName="accountOwnerFC" id="identity-id" class="form-control" placeholder="{{'pages.bank-account.account-owner' | translate}}" aria-describedby="{{'pages.bank-account.account-owner' | translate}}"  />
    </div>
    <div class="form-group">
        <label for="bankAccount">{{'pages.bank-account.bank-branch' | translate}}</label>
        <input formControlName="bankBranchFC" id="bankAccount" class="form-control"  aria-describedby="{{'pages.bank-account.bank-branch' | translate}}" placeholder="{{'pages.bank-account.bank-branch' | translate}}"  />
    </div>
    <div class="form-group">
        <label for="accountNo">{{'pages.bank-account.account-numbers' | translate}}</label>
        <input formControlName="accountNoFC" id="accountNo" class="form-control" aria-describedby="{{'pages.bank-account.account-numbers' | translate}}" placeholder="{{'pages.bank-account.account-numbers' | translate}}"  />
    </div>
    <div class="form-group">
        <button class="primary-button big-button" mat-button>{{'common.save' | translate}}</button>
    </div>
  </form>
</div>
