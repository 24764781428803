import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MAIN_LANDING_PAGE, MEMBER_ACCOUNT_PANEL_PAGE, MEMBER_LOGOUT_PAGE, MEMBER_MEMBER_INFO_PAGE} from 'src/app/constants/route.constant';
import {MEMBER_MENU_ITEMS} from '../../../constants/member-menu.constant';
import {MenuItemInterface} from 'src/app/interfaces/menu-item.interface';
import {DEFAULT_NO_AVATAR, JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../../../constants/common.constant';
import {UserService} from '../../../services/user.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {InvestmentService} from '../../../services/investment.service';
import {MyInvestmentInterface} from '../../../interfaces/product.interface';
import {AvatarDialogComponent} from './avatar.dialog';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-page-member-account-panel',
  templateUrl: './member-account-panel.page.html'
})
export class MemberAccountPanelPageComponent extends BaseComponent implements OnInit{
  public MEMBER_MEMBER_INFO_PAGE = MEMBER_MEMBER_INFO_PAGE;
  public MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;
  public menu = MEMBER_MENU_ITEMS;
  public policies: MenuItemInterface[] = [];
  public logoutUrl = '';
  public imageUrl: SafeResourceUrl;
  public noAvatarUrl = DEFAULT_NO_AVATAR;
  public myInvestments: MyInvestmentInterface[] = [];
  public showCard = false;
  constructor(
    injector: Injector,
    private userService: UserService,
    private investmentService: InvestmentService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    super(injector);
    this.callAvatar();
  }
  callAvatar(): void {
    this.userService.getProfileAvatarAsync().then((res) => {
      const urlCreator = window.URL;
      const object = urlCreator.createObjectURL(res);
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(object);
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.logoutUrl = this.ui.absoluteUrl(MEMBER_LOGOUT_PAGE);
    this.policies = this.menu;
    for (let i = 0; i < this.policies.length; i++) {
     if (this.policies[i].identifier == 'basic' && parseInt(this.userProfile.info.approved) == 10) {
       this.policies[i].color = '#990000';
     }
      if (this.policies[i].identifier == 'identity' && parseInt(this.userProfile.business.approved) == 10) {
        this.policies[i].color = '#990000';
      }
      if (this.policies[i].identifier == 'account' && parseInt(this.userProfile.account.approved) == 10) {
        this.policies[i].color = '#990000';
      }
    }
    this.investmentService.getUserInvestments().then((res) => {
      this.myInvestments = res;
      this.showCard = true;
    });
  }
  changeAvatar(): void {
    const data = {imageUrl: this.imageUrl};
    const dialog = this.dialog.open(AvatarDialogComponent, {data});
    dialog.componentInstance.result.subscribe((res) => {
      if(res == 'success') {
        this.callAvatar();
      }
    })
  }
  async signOut(): Promise<any> {
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(STORAGE_PROFILE_KEY);
    localStorage.removeItem('next');
    const notification = await this.label.getLabel(this.translateService, 'toast.signed-out');
    this.router.navigate([this.ui.absoluteUrl(MAIN_LANDING_PAGE)]).then(() => {
      this.snackBar.open(notification as string, '', {
        duration: 2000
      });
    });
  }
}
