import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SecurityService} from '../../services/security.service';
import {RAMERAMECOID_LOGO} from '../../constants/common.constant';

@Component({
  selector: 'app-page-reset-password',
  templateUrl: './reset-password.page.html',
})
export class ResetPasswordPageComponent extends BaseComponent implements OnInit{
  public togglePassword = false;
  public toggleConfirmPassword = false;
  public token = '';
  public logo = RAMERAMECOID_LOGO;
  public registerFG: UntypedFormGroup;
  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private securityService: SecurityService
  ) {
    super(injector);
    this.token = this.activatedRoute.snapshot.params.token;
    this.registerFG = this.formBuilder.group({
      emailFC: ['', [Validators.required]],
      passwordFC: ['', [Validators.required, Validators.maxLength(6)]],
      passwordConfirmationFC: ['', [Validators.required, Validators.maxLength(6)]],
    });
  }
  ngOnInit(): void {
    console.log(this.token);
    super.ngOnInit();
  }
  resetPassword(): void {
    this.securityService.resetPassword(this.token, this.registerFG.controls.emailFC.value, this.registerFG.controls.passwordFC.value, this.registerFG.controls.passwordConfirmationFC.value)
      .then((res) => {
        this.snackBar.open('kata sandi berhasil diubah', '', {duration: 2000});
        this.router.navigate(['/sign-in']).then(() => {});
      }, () => {
        this.snackBar.open('Terjadi kesalahan silakan coba beberapa saat lagi atau periksa data anda', '', {duration: 2000});
      })
  }
}
