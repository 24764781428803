<div class="main-content business-detail">
    <div class="business-image">
        <div class="text-center">
          <img *ngIf="!product.imageUrl" src="http://placehold.it/1920x1080" class="img-fluid left-image" alt="" />
          <img *ngIf="product.imageUrl" alt="" [src]="baseUrl + product.imageUrl + '?rand=123'" class="img-fluid left-image" />
        </div>
    </div>
    <div class="menu mt-3 mb-3">
      <div class="row">
        <div class="col-3">
            <button class="secondary-button square-button " [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-laporan"></span>
            </button>
            <span class="button-label">Laporan</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-ask-admin"></span>
            </button>
            <span class="button-label">Tanya Admin</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button active" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-cctv"></span>
            </button>
            <span class="button-label">Monitoring CCTV</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-timeline"></span>
            </button>
            <span class="button-label">Informasi</span>
        </div>
      </div>
    </div>
    <div class="content" *ngIf="cctv && cctv.androidApp">
        <h3>{{product.productName}}</h3>
        <div class="well grey">
            <div class="row mb-3">
                <div class="col-6">Cloud Serial Number</div>
                <div class="col-6">: {{cctv.cloudSerial}}</div>
            </div>
            <div class="row mb-3">
                <div class="col-6">Cloud Username</div>
                <div class="col-6">: {{cctv.username}}</div>
            </div>
            <div class="row mb-3">
                <div class="col-6">Cloud Password</div>
                <div class="col-6">: {{cctv.password}}</div>
            </div>
            <div class="row">
                <div class="col-6">Panduan PDF</div>
                <div class="col-6">: <a href="{{cctv.attachment}}" target="_blank">Unduh Panduan</a></div>
            </div>

        </div>
        <p class="mt-3">Panduan Cepat</p>
        <p>{{cctv.guidance}}</p>
        <div class="row mb-5">
            <div class="col-6">
                <a href="{{cctv.androidApp}}" target="_blank">
                    <img src="/assets/images/member/google-play@2x.png" class="img-fluid" alt="Google Play" />
                </a>
            </div>
            <div class="col-6">
                <a href="{{cctv.iosApp}}" target="_blank">
                    <img src="/assets/images/member/app-store@2x.png" class="img-fluid" alt="App store" />
                </a>
            </div>
        </div>
    </div>

    <div class="card">
      <div class="card-body text-center" *ngIf="cctv && !cctv.androidApp">
        Tidak ada CCTV untuk project usaha ini
      </div>
    </div>
</div>
