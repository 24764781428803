<div class="main-content">
  <div class="row">
    <div class="col-12">
      <p class="line-height-23px">{{'pages.about-us.line-one' | translate}}</p>
        <p class="line-height-23px">{{'pages.about-us.line-two' | translate}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <h3>{{'pages.about-us.our-team' | translate}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mt-1 mb-2" *ngFor="let t of teamMembers" (click)="toDetails(t)">
      <div class="card grey-background">
        <div class="card-body">
          <div class="about-team-each">
            <div class="row">
              <div class="col-12 text-center p-1">
                <img [src]="t.image" class="img-fluid" alt="">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6>{{t.name}}</h6>
                <span>{{t.position}}</span>
              </div>
            </div>
          </div>
          <div class="row align-self-end">
            <div class="col-12">
              <a class="mt-2">{{'common.details' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
