import {Component, Injector, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseComponent} from '../../../shared/base/base.component';

@Component({
  selector: 'app-page-redeem-history',
  templateUrl: './redeem-history.page.html'
})

export class MemberRewardsHistoryComponent extends BaseComponent implements OnInit{
  constructor(
    injector: Injector,
    public dialog: MatDialog
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
}
