import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {ShoppingCartInterface} from '../../../interfaces/shopping-cart.interface';
import {ERR_PROFILE_NOT_COMPLETE, STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BUSINESS_LIST_PAGE, MEMBER_NOT_COMPLETE_REDIRECT_PAGE} from '../../../constants/route.constant';
import {environment} from '../../../../environments/environment';
import {ProductInterface} from '../../../interfaces/product.interface';
import {ProductService} from '../../../services/product.service';

@Component({
  selector: 'app-page-member-cart',
  templateUrl: './member-cart.page.html'
})
export class MemberCartPageComponent extends BaseComponent implements OnInit{
  public cart: ShoppingCartInterface = {};
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public quantityFC: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  public env = environment.base;
  public disableAdjust = false;
  public disableCheckout = true;
  public product: ProductInterface;
  constructor(
    injector: Injector,
    private shoppingCartService: ShoppingCartService,
    private productService: ProductService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    window.scroll(0,0);
    this.shoppingCartService.getCartData().then((res) => {
      this.cart = res;
      if (this.cart.productId) {
        this.disableCheckout = false;

        this.productService.getOneProductAfterLoggedIn(this.cart.productId).then((res) => {
          this.product = res as ProductInterface;
        });

      }
      this.quantityFC.setValue(this.cart.quantity);
    }, (err) => {
      if (err.error.error == ERR_PROFILE_NOT_COMPLETE) {
        this.router.navigate([MEMBER_NOT_COMPLETE_REDIRECT_PAGE]).then(() => {});
      }
    });
  }
  removeItem(): void {
    this.shoppingCartService.removeFromCart(this.cart.productId).then(() => {
      this.disableCheckout = true;
      this.snackBar.open(this.cart.productName +' Telah Dihapus dari Keranjang Belanja', '', {duration: 2000});
      this.router.navigate([BUSINESS_LIST_PAGE]).then(() => {});
    })
  }
  qtyChange(): void {
    this.disableAdjust = true;
    this.disableCheckout = true;
    this.shoppingCartService.adjustQuantity(this.cart.productId, this.quantityFC.value).then((res) => {
      this.cart = res;
      if (this.cart.productId) {
        this.disableCheckout = false;
      }
      this.disableAdjust = false;
    });
  }
  adjustQty(type: string): void {
    this.disableAdjust = true;
    if (type == 'min') {
      if (this.cart.quantity - 1 >= 1) {
        this.quantityFC.setValue(this.cart.quantity - 1);
        this.cart.quantity -= 1;
      }
    }else {
      this.quantityFC.setValue(this.cart.quantity + 1);
      this.cart.quantity += 1;
    }
    this.disableCheckout = true;
    this.shoppingCartService.adjustQuantity(this.cart.productId, this.quantityFC.value).then((res) => {
      this.cart = res;
      if (this.cart.productId) {
        this.disableCheckout = false;
      }
      this.disableAdjust = false;
    }, () => {
      this.disableAdjust = false;
    });
  }
}
