<ng-container>
  <form [formGroup]="businessFG" (submit)="updateProfile()">
    <p>{{'pages.profile.verification-note' | translate}}</p>
    <div class="form-group">
      <label>{{'pages.profile.option-identity-type' | translate}}</label>
      <div class="input-group">
        <mat-radio-group formControlName="identityTypeFC">
          <mat-radio-button value="ktp">{{'pages.profile.ktp' | translate}}</mat-radio-button>
          <mat-radio-button value="sim">{{'pages.profile.sim' | translate}}</mat-radio-button>
          <mat-radio-button value="paspor">{{'pages.profile.passport' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="form-group ">
      <label>{{'pages.profile.identity-photo' | translate}}</label>
      <div class="dropzone">
        <div class="img-preview">
          <img *ngIf="!imageUrlId" class="img-fluid" src="assets/images/member/ktp-placeholder@2x.jpg" alt="placeholder ktp" />
          <img *ngIf="imageUrlId" class="img-fluid" [src]="imageUrlId" alt="placeholder ktp" />
        </div>
        <caption *ngIf="!imageUrlId">{{'pages.profile.id-example' | translate}}</caption>
        <caption>{{'pages.profile.format-image' | translate}}</caption>
        <input type="file" class="d-none" #hiddenId accept="image/*" (change)="onIdFileChange($event)" />
        <button type="button" (click)="hiddenId.click()" class="primary-button big-button inline">{{'pages.profile.upload-photo' | translate}}</button>
      </div>
      <div class="helper mt-3">
        <ul>
          <li>{{'pages.profile.condition-id-one' | translate}}</li>
          <li>{{'pages.profile.condition-id-two' | translate}}</li>
          <li>{{'pages.profile.condition-id-three' | translate}}</li>
        </ul>
      </div>
    </div>
    <div class="form-group ">
      <label>{{'pages.profile.selfie' | translate}}</label>
      <div class="dropzone">
        <div class="img-preview">
          <img *ngIf="!imageUrlSelfie" class="img-fluid" src="assets/images/member/selfie-placeholder@2x.jpg" alt="placeholder ktp" />
          <img *ngIf="imageUrlSelfie" class="img-fluid" [src]="imageUrlSelfie" alt="placeholder ktp" />
        </div>
        <caption *ngIf="!imageUrlSelfie">{{'pages.profile.selfie-example' | translate}}</caption>
        <caption>{{'pages.profile.format-image' | translate}}</caption>
        <input type="file" class="d-none" accept="image/*" #hiddenSelfie (change)="onSelfieFileChange($event)" />
        <button type="button" (click)="hiddenSelfie.click()" class="primary-button big-button inline">{{'pages.profile.upload-photo' | translate}}</button>
      </div>
      <div class="helper mt-3">
        <ul>
          <li>{{'pages.profile.condition-selfie-one' | translate}}</li>
          <li>{{'pages.profile.condition-selfie-two' | translate}}</li>
        </ul>
      </div>
    </div>
    <div class="form-group">
      <label for="identity-id">{{'pages.profile.id-number' | translate}}</label>
      <input formControlName="identityNumbersFC" id="identity-id" class="form-control"  />
    </div>
    <div class="form-group">
      <label>{{'pages.profile.monthly-capital' | translate}}</label>
      <ng-select [items]="capitalValues" formControlName="capitalValueFC"
                 bindLabel="label"
                 autofocus
                 placeholder="Rentang Pendapatan per bulan"
                 bindValue="value"
                 class="ng-select"
      >
      </ng-select>
    </div>

    <div class="form-group">
      <label for="NPWP">{{'pages.profile.npwp' | translate}}</label>
      <input formControlName="npwpFC" id="NPWP" class="form-control" placeholder="NPWP" />
    </div>
    <div class="form-group ">
      <label>{{'pages.profile.photo-npwp' | translate}}</label>
      <div class="dropzone">
        <div class="img-preview">
          <img *ngIf="!imageUrlNpwp" class="img-fluid" src="assets/images/member/npwp-placeholder@2x.png" alt="placeholder ktp" />
          <img *ngIf="imageUrlNpwp" class="img-fluid" [src]="imageUrlNpwp" alt="placeholder ktp" />
        </div>
        <caption *ngIf="!imageUrlNpwp">{{'pages.profile.example-npwp' | translate}}</caption>
        <caption>{{'pages.profile.format-image' | translate}}</caption>
        <input type="file" class="d-none" accept="image/*" #hiddenNpwp (change)="onNpwpFileChange($event)" />
        <button type="button" (click)="hiddenNpwp.click()" class="primary-button big-button inline">{{'pages.profile.upload-photo' | translate}}</button>
      </div>
    </div>
    <div class="form-group">
      <button class="primary-button big-button" mat-button>{{'common.save' | translate}}</button>
      <button type="button" class="big-button mt-1" mat-button (click)="hideEditor()">{{'common.cancel' | translate}}</button>
    </div>
  </form>
</ng-container>
