<div class="main-content contact-us" *ngIf="labels.company_name">
    <h1 class="h4" *ngIf="false">{{'pages.contact-us.another-question' | translate}}</h1>
    <form [formGroup]="contactFG" (submit)="send()" *ngIf="false">
        <div class="form-group">
            <label for="name">{{'pages.contact-us.name' | translate}}</label>
            <input formControlName="nameFC" id="name" type="text" class="form-control"  aria-describedby="{{'pages.contact-us.name' | translate}}" placeholder="{{'pages.contact-us.enter-name' | translate}}" />
        </div>
        <div class="form-group">
            <label for="email">{{'pages.contact-us.email-address' | translate}}</label>
            <input formControlName="emailFC" id="email" type="email" class="form-control"  aria-describedby="{{'pages.contact-us.email-address' | translate}}" placeholder="{{'pages.contact-us.enter-email' | translate}}" />
        </div>
        <div class="form-group">
            <label for="title">{{'pages.contact-us.title' | translate}}</label>
            <input formControlName="titleFC" id="title" type="text" class="form-control"  aria-describedby="{{'pages.contact-us.title' | translate}}" placeholder="{{'pages.contact-us.enter-title' | translate}}" />
        </div>
        <div class="form-group">
            <label for="question">{{'pages.contact-us.question' | translate}}</label>
            <textarea formControlName="questionFC" id="question" class="form-control" placeholder="{{'pages.contact-us.enter-question' | translate}}"></textarea>
        </div>
        <div class="form-group">
            <button mat-flat-button class="primary-button big-button">{{'pages.contact-us.send-question' | translate}}</button>
        </div>
    </form>
    <div class="mt-1 address">
        <h4 class="mb-4 mt-1">{{labels.company_name.toUpperCase()}}</h4>
        <div class="d-flex mb-3">
            <span class="icon-pin-map mr-3 text-red"></span>
            <span>{{labels.company_address}}</span>
        </div>
        <div class="d-flex mb-3">
            <span class="icon-mail mr-3 text-orange"></span>
            <span>{{labels.site_email}}</span>
        </div>
        <div class="d-flex mb-3">
            <span class="icon-phone mr-3 text-green"></span>
            <span>{{labels.site_phone}} / {{labels.site_phone2}}</span>
        </div>
        <p>{{'pages.contact-us.follow-social-media' | translate}}</p>
        <div class="d-flex mb-3">
            <a [href]="facebookSafe" target="_blank" class="mr-4"><span class="icon-facebook blue"></span></a>
            <a [href]="instagramSafe" target="_blank" class="mr-4"><span class="icon-instagram text-red"></span></a>
        </div>
        <div class="">
            <iframe [src]="mapSafe" width="100%" height="450" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>
</div>
