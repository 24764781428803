import {Component, Inject, OnInit} from '@angular/core';
import {DialogInterface} from './dialog.interface';
import {DateHelper} from '../../helpers/date.helper';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-socmed',
  templateUrl: 'dialog-social-media.component.html'
})
export class DialogSocmedComponent implements OnInit{
  public dialogData: DialogInterface;
  public date = DateHelper;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInterface) { }

  ngOnInit(): void {
    this.dialogData = this.data;
  }
}
