import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CAMPAIGN_VOUCHER_BASE_URL, PROFILE_BASE_URL, REPORT_BASE_URL, TRANSACTION_INVOICE_BASE_URL} from '../constants/api.constant';

@Injectable({providedIn: 'root'})
export class FileService {
  constructor(private http: HttpClient) {
  }
  async downloadDailyReport(productId: number, filename: string): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const params = 'daily-sales/product/' + productId + '/file/' + filename;
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    // @ts-ignore
    return await this.http.get(environment.url + REPORT_BASE_URL + params, options).toPromise();
  }
  async downloadMonthlyReport(productId: number, filename: string): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const params = 'returns/product/' + productId + '/file/' + filename;
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    // @ts-ignore
    return await this.http.get(environment.url + REPORT_BASE_URL + params, options).toPromise();
  }
  async downloadInvoiceReturn(returnId: number): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const params = 'return/invoice/' + returnId;
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    // @ts-ignore
    return await this.http.get(environment.url + TRANSACTION_INVOICE_BASE_URL + params, options).toPromise();
  }
  async downloadInvoice(purchaseId: number): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    // @ts-ignore
    return await this.http.get(environment.url + TRANSACTION_INVOICE_BASE_URL + 'invoice/' + purchaseId, options).toPromise();
  }
  async downloadEvidenceTransfer(transactionId: number, filename: string): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const params = 'evidence/' + transactionId + '/' + filename;
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    // @ts-ignore
    return await this.http.get(environment.url + TRANSACTION_INVOICE_BASE_URL + params, options).toPromise();
  }
  async downloadInfoAttachment(productId: number, informationId: number, attachment: string): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const params = 'product-info-attachment/' + productId + '/' + informationId + '/' + attachment;
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    // @ts-ignore
    return await this.http.get(environment.url + ONE_PRODUCT_BASE_URL + params, options).toPromise();
  }
  async saveAvatar(formData: FormData): Promise<any> {
    const httpOptions = {
    };
    return this.http.post<any>(environment.url + PROFILE_BASE_URL + '/avatar' , formData, httpOptions).toPromise();
  }

  async downloadVoucherReport(merchantId : number, startDate : string, endDate : string) : Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/vnd.ms-excel,*/*');
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    const formData = {
      id : merchantId,
      startDate : startDate,
      endDate : endDate
    };
    // @ts-ignore
    return this.http.post<any>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/export' , formData, options).toPromise();
  }
}
