import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE,
  MEMBER_MY_BUSINESS_PAGE
} from 'src/app/constants/route.constant';
import {ProductInterface} from '../../../interfaces/product.interface';
import {ProductService} from '../../../services/product.service';
import {TimelineGroupInterface, TimelineInterface} from '../../../interfaces/timeline.interface';
import {DB_TIMESTAMP_FORMAT, STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {MatDialog} from '@angular/material/dialog';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {InvestmentService} from '../../../services/investment.service';
import {FileService} from '../../../services/file.service';
import {environment} from '../../../../environments/environment';
import {FileListCertificateComponent} from '../../dialog/file-list-certificate.component';
import * as moment from 'moment';

@Component({
  selector: 'app-page-my-business-detail-timeline',
  templateUrl: './detail-business-timeline.page.html'
})
export class MemberBusinessDetailTimelinePageComponent extends BaseComponent implements OnInit{
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE = MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_PAGE = MEMBER_MY_BUSINESS_DETAIL_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE = MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE = MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE;
  public id: number;
  public product: ProductInterface = {};
  public timeline: TimelineInterface[] = [];
  public groupTimeline: TimelineGroupInterface[] = [];
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  constructor(
    injector: Injector,
    private productService: ProductService,
    private investmentService: InvestmentService,
    private dialog: MatDialog,
    private fileManager: FileService
  ) {
    super(injector);
    this.id = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.productService.getOneProductAfterLoggedIn(this.id).then((product) => {
      this.product = product;
      this.getTimeline(this.id);
    }, () => {
      this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
    });
  }
  getCertificate(productId: number): void {
    this.dialog.open(FileListCertificateComponent, {
      data: {
        product: this.product,
        baseUrl: environment.base
      },
    });

  }
  getTimeline(productId: number): void {
    this.productService.getTimelineProduct(productId).then((timeline) => {
      timeline.forEach((tl) => {
        let group = this.groupTimeline.find((g) => g.groupDate == tl.periodGroup);
        if (!group) {
          this.groupTimeline.push({
            groupDate: tl.periodGroup,
            data: [tl]
          });

        }else {
          group.data.push(tl);
        }
      });


      this.groupTimeline.sort((a,b) => {
        if(a.groupDate < b.groupDate) {
          return -1;
        }

        if(a.groupDate > b.groupDate) {
          return 1;
        }

        return 0
      });

      this.groupTimeline.reverse();

      this.groupTimeline.forEach((item) => {
        item.data.sort((a,b) => {
          let a1 = moment(a.noticeDate, DB_TIMESTAMP_FORMAT).unix();
          let b1 = moment(b.noticeDate, DB_TIMESTAMP_FORMAT).unix();

          if(a1 < b1) {
            return -1;
          }
          if(a1 > b1) {
            return 1;
          }
          return 0;
        });

        item.data.reverse();
      })
    });
  }
  getFileAttachment(attachment: string, timelineId: number): void {
    this.fileManager.downloadInfoAttachment(this.product.id, timelineId, attachment).then((file) => {
      this.ui.downloadFile(file, attachment);
    });
  }
  async viewDetail(timeline: TimelineInterface): Promise<any>  {
    const data: DialogInterface = {
      title : timeline.title,
      subtitle: this.date.localeDate(timeline.noticeDate, this.locale),
      imageUrl: '',
      imageAlt: '',
      content: timeline.content,
      buttons: [
        {label: 'common.close', value: 1, className: 'primary-button big-button'},
      ]
    };
    this.dialog.open(DialogScrollComponent, {data});
  }
}
