import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {STORAGE_LANG_KEY, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {UserInterface} from '../../interfaces/user.interface';
import {DateHelper} from '../../helpers/date.helper';
import {InvestmentService} from '../../services/investment.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LabelHelper} from '../../helpers/label.helper';

@Component({
  selector: 'app-dialog-detail-ask-admin',
  templateUrl: './dialog-details-ask-admin.component.html'
})
export class DialogDetailsAskAdminComponent implements OnInit {
  public userProfile: UserInterface;
  public date = DateHelper;
  public label = LabelHelper;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public replyMessage = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tanyaService: InvestmentService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.userProfile = JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface;
    this.findOne();
  }
  sendReply(): void {
    this.tanyaService.replyProductTanyaAdmin(this.data.tanya.id, this.replyMessage).then((res) => {
      this.replyMessage = '';
      this.label.getLabel(this.translateService, 'pages.tanya-admin.save-success').then((message) => {
        this.snackBar.open(message as string, '', {duration: 2000});
        this.findOne();
      });
    });
  }
  findOne(): void {
    this.tanyaService.findOneProductTanyaAdmin(this.data.tanya.id).then((res) => {
      this.data.tanya = res;
      this.data.tanya.details.reverse();
    });
  }
}
