import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppSharedModule} from '../../../shared/shared.module';
import {NgProgressModule} from 'ngx-progressbar';
import {NgSelectModule} from '@ng-select/ng-select';
import {InvestmentService} from '../../../services/investment.service';
import {MemberMyBusinessLandingPageComponent} from './my-business-landing.page';
import {MemberBusinessPaymentConfirmationPageComponent} from './my-business-payment-confirmation.page';
import {MemberBusinessDetailTransactionPageComponent} from './detail-transaction.page';
import {MemberBusinessDetailPageComponent} from './detail-business.page';
import {MemberBusinessDetailAskAdminPageComponent} from './detail-business-ask-admin.page';
import {MemberBusinessDetailCctvPageComponent} from './detail-business-cctv.page';
import {MemberBusinessDetailTimelinePageComponent} from './detail-business-timeline.page';
import {MemberAskAdminDialogComponent} from '../../dialog/dialog-member-ask-admin.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MaterialModule} from '../../../material/material.module';
import {AppMemberReportModule} from '../report/member-report.module';
import {DialogDetailsAskAdminComponent} from '../../dialog/dialog-details-ask-admin.component';
import {ClipboardModule} from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    NgSelectModule,
    NgxChartsModule,
    MaterialModule,
    AppMemberReportModule,
    ClipboardModule
  ],
  exports: [
    MemberMyBusinessLandingPageComponent,
    MemberBusinessPaymentConfirmationPageComponent,
    MemberBusinessDetailTransactionPageComponent,
    MemberBusinessDetailPageComponent,
    MemberBusinessDetailAskAdminPageComponent,
    MemberBusinessDetailCctvPageComponent,
    MemberBusinessDetailTimelinePageComponent,
    MemberAskAdminDialogComponent,
    MemberBusinessDetailAskAdminPageComponent,
    MemberBusinessDetailCctvPageComponent,
    MemberBusinessDetailTimelinePageComponent,
    MemberAskAdminDialogComponent,
    DialogDetailsAskAdminComponent
  ],
  declarations: [
    MemberMyBusinessLandingPageComponent,
    MemberBusinessPaymentConfirmationPageComponent,
    MemberBusinessDetailTransactionPageComponent,
    MemberBusinessDetailPageComponent,
    MemberBusinessDetailAskAdminPageComponent,
    MemberBusinessDetailCctvPageComponent,
    MemberBusinessDetailTimelinePageComponent,
    MemberAskAdminDialogComponent,
    DialogDetailsAskAdminComponent
  ],
  providers: [
    InvestmentService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMyBusinessModule {}
