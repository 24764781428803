import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_ACCOUNT_PANEL_PAGE} from 'src/app/constants/route.constant';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MasterService} from '../../../services/master.service';
import {SelectInterface} from '../../../interfaces/common.interface';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {FORM_GROUP_VALID, PICKER_DATE_FORMATS, STORAGE_LANG_KEY, STORAGE_PROFILE_KEY} from 'src/app/constants/common.constant';
import {ProfileService} from '../../../services/profile.service';
import {UserInfoInterface, UserInterface} from '../../../interfaces/user.interface';
import {UserService} from '../../../services/user.service';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {MatDialog} from '@angular/material/dialog';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {MatRadioChange} from '@angular/material/radio';

@Component({
  selector: 'app-page-member-info',
  templateUrl: './member-info.page.html',
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: PICKER_DATE_FORMATS},
  ],
})
export class MemberInfoPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_PANEL_PAGE: string;
  public infoFG: UntypedFormGroup;
  public provinces: SelectInterface[] = [];
  public districts: SelectInterface[] = [];
  public subDistricts: SelectInterface[] = [];
  public villages: SelectInterface[] = [];
  public sexTypeChecker = 0;
  public validForm = FORM_GROUP_VALID;
  public profile: UserInterface = {};
  public counter = 0;
  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private masterService: MasterService,
    private adapter: DateAdapter<any>,
    private profileService: ProfileService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.infoFG = this.formBuilder.group({
      fullNameFC: ['', []],
      addressFC: ['', []],
      phoneFC: ['', []],
      bornDateFC: [null, []],
      provinceFC: [null, [Validators.required]],
      districtFC: [null, [Validators.required]],
      subDistrictFC: [null, [Validators.required]],
      villageFC: [null, [Validators.required]],
      zipCodeFC: ['', [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.counter++;
    this.adapter.setLocale(localStorage.getItem(STORAGE_LANG_KEY));
    this.infoFG.controls.fullNameFC.disable({onlySelf: true});
    this.infoFG.setValue({
      fullNameFC: this.userProfile.basic.fullName,
      addressFC: (this.userProfile.info.address)? this.userProfile.info.address : null,
      phoneFC: (this.userProfile.info.phoneNo)? this.userProfile.info.phoneNo : null,
      bornDateFC: this.date.toDatePicker((this.userProfile.info.bornDate)? this.userProfile.info.bornDate : null),
      provinceFC: (this.userProfile.info.provinceId)? this.userProfile.info.provinceId : null,
      districtFC: (this.userProfile.info.districtId)? this.userProfile.info.districtId : null,
      subDistrictFC: (this.userProfile.info.subDistrictId)? this.userProfile.info.subDistrictId : null,
      villageFC: (this.userProfile.info.villageId)? this.userProfile.info.villageId : null,
      zipCodeFC: (this.userProfile.info.zipcode)? this.userProfile.info.zipcode : null,
    });

    this.sexTypeChecker = this.userProfile.info.sexType;
    this.MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;

    this.masterService.getProvinceAsync().then((res) => {
      this.provinces = res;
      this.getDistricts(0);
      this.getSubDistricts(0);
      this.getVillages(0);
    });
  }
  getDistricts(flag: number): void {
    this.counter++;
    console.log(this.counter, flag, this.infoFG.controls.provinceFC.value);
    this.masterService.getDistrictAsync(this.infoFG.controls.provinceFC.value).then((res) => {
      if (this.counter == 2 && flag === 0 && this.infoFG.controls.provinceFC.value) {
        this.provinces = res;
      }else {
        this.masterService.getProvinceAsync().then((res) => {
          this.provinces = res;
          this.infoFG.controls.districtFC.setValue(null);
          this.infoFG.controls.subDistrictFC.setValue(null);
          this.infoFG.controls.villageFC.setValue(null);
        });
      }
      this.districts = res;
    });
  }
  getSubDistricts(flag: number): void {
    this.masterService.getSubDistrictAsync(this.infoFG.controls.districtFC.value).then((res) => {
      if (flag === 1) {
        this.infoFG.controls.subDistrictFC.setValue(null);
        this.infoFG.controls.villageFC.setValue(null);
      }
      this.subDistricts = res;
    });
  }
  getVillages(flag: number): void {
    this.masterService.getVillageAsync(this.infoFG.controls.subDistrictFC.value).then((res) => {
      if (flag === 1) { this.infoFG.controls.villageFC.setValue(null); }
      this.villages = res;
    });
  }
  setSexTypeChecker(event: MatRadioChange): void {
    this.sexTypeChecker = event.value;
  }
  sendBasic(): void {
    const date = new Date(this.infoFG.controls.bornDateFC.value);
    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }
    const x = date.toISOString().substring(0, 10);
    const payload: UserInfoInterface = {
      sexType: this.sexTypeChecker,
      bornDate: x,
      phoneNo: this.infoFG.controls.phoneFC.value,
      provinceId: this.infoFG.controls.provinceFC.value,
      districtId: this.infoFG.controls.districtFC.value,
      subDistrictId: this.infoFG.controls.subDistrictFC.value,
      villageId: this.infoFG.controls.villageFC.value,
      address: this.infoFG.controls.addressFC.value,
      zipcode: this.infoFG.controls.zipCodeFC.value,
    };
    this.profileService.basic(payload).then(() => {
      this.currentUser();
    });
  }
  currentUser(): void {
    this.userService.getCurrentUserAsync().then((res) => {
      this.profile = res;
      this.openSuccessDialog();
    }, (err) => {
      console.log(err);
    });
  }
  openSuccessDialog(): void {
    const data: DialogInterface = {
      title : 'Profil Anda Berhasil Diubah',
      content: 'Kami akan melakukan review terhadap perubahan data anda. Tekan Tombol OK untuk menutup pesan ini',
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {label: 'OK, Tutup', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialogRef = this.dialog.open(DialogScrollComponent, {data});
    dialogRef.afterClosed().subscribe(() => {
      localStorage.removeItem(STORAGE_PROFILE_KEY);
      localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
      this.label.getLabel(this.translateService, 'toast.data-updated').then((notification) => {
        this.redirectTo(MEMBER_ACCOUNT_PANEL_PAGE);
        this.snackBar.open(notification as string, '', {
          duration: 2000
        });
      });
    });
  }
}
