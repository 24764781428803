import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PRODUCT_INSURANCES_BASE_URL} from '../constants/api.constant';
import {ProductInsurance} from '../interfaces/shopping-cart.interface';

@Injectable({providedIn: 'root'})
export class InsuranceService {
  constructor(private http: HttpClient) {}
  async getProductInsurance(productId: number): Promise<ProductInsurance[]>{
    return await this.http.get<any>(environment.url + PRODUCT_INSURANCES_BASE_URL + productId).toPromise();
  }

}
