export const MAIN_LANDING_PAGE = '';
export const BUSINESS_LIST_PAGE = 'business-list';
export const BUSINESS_DETAIL_PAGE = 'business-detail';
export const CONTACT_US_PAGE = 'contact-us';
export const ABOUT_US_PAGE = 'about-us';
export const ABOUT_US_DETAIL_PAGE = 'about-us/team-detail';
export const PRIVACY_PAGE = 'privacy';
export const FAQ_PAGE = 'faq';
export const NEW_LOGIN_PAGE = 'sign-in';
export const BUSINESS_REGISTER_PAGE = 'business-register';

export const MEMBER_MEMBER_INFO_PAGE = 'member/member-info';
export const MEMBER_NOTIFICATION_PAGE = 'member/notification';
export const MEMBER_CART_PAGE = 'member/cart';
export const MEMBER_CART_CONFIRM_PAGE = 'member/cart-confirm';
export const MEMBER_REWARDS_PAGE = 'member/rewards';
export const MEMBER_REDEEM_HISTORY_PAGE = 'member/rewards-history';
export const MEMBER_CART_INVOICE_PAGE = 'member/cart-invoice';
export const MEMBER_ACCOUNT_PANEL_PAGE = 'member/panel';
export const MEMBER_ACCOUNT_CHANGE_PASSWORD_PAGE = 'member/change-password';
export const MEMBER_BANK_ACCOUNT_PAGE = 'member/bank-account';
export const MEMBER_TICKETS_PAGE = 'member/ticket';
export const MEMBER_TICKET_DETAILS_PAGE = 'member/ticket-details/';
export const MEMBER_TICKET_FORM_PAGE = 'member/ticket-form';
export const MEMBER_LOGOUT_PAGE = 'member/change-password';
export const MEMBER_ACCOUNT_VERIFICATION_PAGE = 'member/account-verification';
export const MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE = 'member/account-verification';
export const MEMBER_WALLET_LANDING_PAGE = 'member/wallet';
export const MEMBER_WALLET_WITHDRAW_PAGE = 'member/wallet-withdraw';
export const MEMBER_REPORT_PAGE = 'member/report';
export const MEMBER_REPORT_MONTHLY_PAGE = 'member/report-monthly';
export const MEMBER_REPORT_DAILY_PAGE = 'member/report-daily';

export const MEMBER_MY_BUSINESS_PAGE = 'member/my-investment';
export const MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE = 'member/payment-confirmation';
export const MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE = 'member/transaction-detail';
export const MEMBER_MY_BUSINESS_DETAIL_PAGE = 'member/my-investment/detail';
export const MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE = 'member/my-investment/detail-ask-admin';
export const MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE = 'member/my-investment/detail-timeline';
export const MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE = 'member/my-investment/detail-cctv';
export const MEMBER_VOUCHER_CLAIM_PAGE = 'member/voucher';
export const MEMBER_NOT_COMPLETE_REDIRECT_PAGE = 'member/not-complete';
export const REGISTER_CONFIRMATION_PAGE = 'register-confirmation';
export const REGISTER_WELCOME_PAGE = 'register-welcome';

export const FORGOT_PASSWORD_PAGE = 'forgot-password';
export const TERM_CONDITION_PAGE = 'term-and-condition';
export const MAIL_SENT_RESET_PASSWORD = 'mail-sent';
export const SOCIAL_AUTH_PAGE = 'auth-callback';

export const MEMBER_CARD_PAGE = 'member-card';
export const MEMBER_CARD_INFO = 'member-card/info';
export const MEMBER_CARD_VOUCHER_PAGE = 'member-card/my-voucher';
export const MEMBER_CARD_VOUCHER_HISTORY = 'member-card/voucher-history';
export const MEMBER_CARD_ALL_CAMPAIGN_PER_CATEGORY = 'member-card/all-campaign-per-category';
export const MERCHANDISE_ALL_CATEGORIES = 'merchandise';
export const MERCHANDISE_USE_VOUCHER = 'merchandise/use-voucher';
export const MEMBER_CARD_VOUCHER_DETAIL = 'member-card/voucher-detail';
export const MEMBER_CARD_USE_VOUCHER = 'member-card/use-voucher';
export const MEMBER_CARD_COMING_SOON = 'member-card';
export const MEMBER_CARD_SEARCH = 'member-card/search';

export const MERCHANT_OUTLET_PAGE = 'merchant-outlet';
export const MERCHANT_OUTLET_QR_SCAN = 'merchant-outlet/qr-scan';
export const MERCHANT_OUTLET_QR_SCAN_SUCCESS = 'merchant-outlet/qr-scan/success';
export const MERCHANDISE_OUTLET_QR_SCAN_SUCCESS = 'merchant-outlet/qr-scan-merchandise/success';
export const MERCHANT_OUTLET_VOUCHER_HISTORY = 'merchant-outlet/merchant-voucher-history';
export const MERCHANDISE_OUTLET_VOUCHER_HISTORY = 'merchant-outlet/merchandise-voucher-history';

