<div class="close-button">
    <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<mat-dialog-content class="mat-typography">
  <h4 class="text-center">{{'pages.wallet.filter-title' | translate}}</h4>
  <div class="form-group">
    <label *ngIf="false">{{'pages.wallet.filter-status' | translate}}</label>
    <mat-radio-group [formControl]="selectedFilter" class="radio-group">
      <ng-container *ngFor="let af of data.availableFilters">
        <mat-radio-button value="{{af.status}}">{{af.label}}</mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions >
  <button (click)="apply()" mat-button mat-dialog-close class="primary-button big-button">{{'pages.wallet.apply-filter' | translate}}</button>
</mat-dialog-actions>
