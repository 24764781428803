import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MatDialog} from '@angular/material/dialog';
import {MembercardService} from '../../../services/membercard.service';
import {MerchandiseCategoryInterface} from '../../../interfaces/member-card.interface';
import {MerchandiseInterface} from '../../../interfaces/merchandise.interface';
import {MERCHANDISE_ALL_CATEGORIES} from '../../../constants/route.constant';
import {VOUCHER_DETAIL_ACTION} from '../../../constants/common.constant';

@Component({
  selector: 'app-page-merchandise',
  templateUrl: './merchandise.page.html'
})
export class MerchandiseComponent extends BaseComponent implements OnInit{
  public point = 0;
  public merchandiseCategories: MerchandiseCategoryInterface[] = [];
  public searchToken = '';
  public merchandises: MerchandiseInterface[] = [];
  public allMerchandises: MerchandiseInterface[] = [];
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private memberCardService: MembercardService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.memberCardService.getMerchandiseCategories().then((res) => {
      this.merchandiseCategories = res;
    });

    this.memberCardService.getMerchandises().then((res) => {
      this.merchandises = res;
      this.allMerchandises = res;
    });

    if(this.userProfile && this.userProfile.basic) {
      this.memberCardService.getPointByUser(this.userProfile.basic.id).then((res:number) => {
        this.point = res;
      });
    }
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }

  doNavigateVoucherDetail(id): void {
    this.router.navigate(['/' + MERCHANDISE_ALL_CATEGORIES + '/' + id + '/' + VOUCHER_DETAIL_ACTION.BUY]).then();
  }

  filterByCategory(category: string): void {
    let newList:MerchandiseInterface[] = [];

    this.allMerchandises.forEach((x) => {
      if(category === x.merchandiseCategory) {
        newList.push(x);
      }
    });

    this.merchandises = newList;
  }

  showAllCategories(): void {
    this.merchandises = this.allMerchandises;
  }

  getValueSlider($event: number): void {
    let newList:MerchandiseInterface[] = [];

    this.allMerchandises.forEach((x) => {
      if(x.voucherPrice <= $event) {
        newList.push(x);
      }
    });

    this.merchandises = newList;
  }

}
