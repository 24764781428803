import {AfterViewInit, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {PERIODE_DATE, PICKER_DATE_FORMATS, STORAGE_LANG_KEY, STORAGE_PROFILE_KEY} from '../../../constants/common.constant';
import {MerchantOutletCampaignInterface, MerchantOutletCampaignListInterface} from '../../../interfaces/merchant-outlet.interface';
import {MERCHANT_OUTLET_QR_SCAN, MERCHANT_OUTLET_VOUCHER_HISTORY} from '../../../constants/route.constant';
import {MembercardService} from '../../../services/membercard.service';
import {UserInterface} from '../../../interfaces/user.interface';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-merchant-outlet',
  templateUrl: './merchant-outlet.page.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: PICKER_DATE_FORMATS},
  ]
})
export class MerchantOutletComponent extends BaseComponent implements OnInit, AfterViewInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public merchantOutletCampaign: MerchantOutletCampaignInterface;
  public campaignLists : MerchantOutletCampaignListInterface[];
  public merchantId = (JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional.merchantId;
  public periodFG: UntypedFormGroup;

  constructor(
    injector: Injector,
    private membercardService : MembercardService,
    private adapter: DateAdapter<any>,
    private formBuilder: UntypedFormBuilder
  ) {
    super(injector);
    this.periodFG = this.formBuilder.group({
      startDateFC: [null, []],
      endDateFC: [null, []]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.adapter.setLocale(localStorage.getItem(STORAGE_LANG_KEY));
    this.periodFG.valueChanges.subscribe((data) => {
      let periodeDate = {
        startDate : this.date.dateForBackend(this.periodFG.controls.startDateFC.value),
        endDate : this.date.dateForBackend(this.periodFG.controls.endDateFC.value)
      };
      localStorage.setItem(PERIODE_DATE, JSON.stringify(periodeDate));
    });
    this.periodFG.setValue({
      startDateFC: this.date.getCurrentDate(),
      endDateFC: this.date.getCurrentDate(),
    });


    this.reloadCampaign();
  }

  ngAfterViewInit(): void {
    setInterval(() => {
      let iframe = window.document.getElementsByTagName('iframe');

      for(let i = 0; i < iframe.length; i++) {
        iframe[i].remove();
      }

    }, 200);
  }

  doNavigateScanQr(): void {
    this.router.navigate(['/' + MERCHANT_OUTLET_QR_SCAN]).then();
  }


  doNavigateVoucherHistory(campaignId): void {
    this.router.navigate(['/' + MERCHANT_OUTLET_VOUCHER_HISTORY + '/' + campaignId] ).then();
  }

  reloadCampaign(): void {
    let startDate = this.date.dateForBackend(this.periodFG.controls.startDateFC.value);
    let endDate = this.date.dateForBackend(this.periodFG.controls.endDateFC.value);
    this.membercardService.getMerchantOutlet(this.merchantId, startDate, endDate).then((res) => {
      if (res) {
        this.merchantOutletCampaign = res;
        this.campaignLists = this.merchantOutletCampaign.campaignLists;
      }
    });
  }
}
