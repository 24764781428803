<aside class="sidenav">
  <mat-toolbar class="toolbar-top">
    <div class="toolbar-top-container">
      <div class="logo">
        <img *ngIf="!navData.hasBackButton" src="assets/images/topbar/logo.svg" class="main-logo" alt="ramerame.co.id" [routerLink]="ui.absoluteUrl(mainPage)" />
        <button class="clear-transparent" *ngIf="navData.hasBackButton" [routerLink]="ui.absoluteUrl(navData.backUrl)">
          <span class=" icon-arrow-back mr-2"></span>
          <span class="h4 text-white">{{navData.backTitle | translate}}</span>
        </button>
      </div>

      <div class="toggler" *ngIf="!this.isLogin" >
        <button class="menu-label" mat-button [matMenuTriggerFor]="menu" *ngIf="showMultiLangOpt">
          <span>{{currentLang}}</span>
        </button>
        <mat-menu #menu="matMenu" *ngIf="showMultiLangOpt">
          <button *ngFor="let lang of languages" mat-menu-item (click)="changeLanguage(lang.langCode)">{{ lang.langValue }}</button>
        </mat-menu>
        <button id="btn-toggle-icon" class="sidenav-toggle" mat-icon-button (click)="isExpanded = !isExpanded">
          <mat-icon id="toggle-icon">reorder</mat-icon>
        </button>
      </div>
      <div class="toggler" *ngIf="this.isLogin" >
        <button class="menu-label" mat-button [matMenuTriggerFor]="menu" *ngIf="showMultiLangOpt">
          <span>{{currentLang}}</span>
        </button>
        <mat-menu #menu="matMenu" *ngIf="showMultiLangOpt">
          <button *ngFor="let lang of languages" mat-menu-item (click)="changeLanguage(lang.langCode)">{{ lang.langValue }}</button>
        </mat-menu>
        <button class="clear-transparent" (click)="downloadReport()" mat-icon-button *ngIf="isMerchant">
          <span class="icon-download3"></span>
        </button>
        <button class="clear-transparent" [routerLink]="[this.cartUrl]" mat-icon-button *ngIf="!isMerchant">
          <span class="icon-cart"></span>
        </button>
        <button class="clear-transparent" [routerLink]="[this.notificationUrl]"  mat-icon-button *ngIf="!isMerchant">
          <span class="icon-notification"></span>
        </button>
        <button id="btn-toggle-icon-logged" class="sidenav-toggle clear-transparent" mat-icon-button (click)="isExpanded = !isExpanded">
          <span class="icon-menu" id="toggle-icon-logged"></span>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav class="mat-sidenav" [position]="'start'" [mode]="'over'" #sidenav
                [opened]="isExpanded">
      <app-sidenav-item (clicked)="isExpanded = false" [isExpanded]="isExpanded"></app-sidenav-item>
    </mat-sidenav>
    <mat-sidenav-content>
      <div [ngClass]="(navData.hasBackButton)?'container-fluid main-body has-back-button':'container-fluid main-body'">
          <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</aside>
