import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MAIN_LANDING_PAGE, MEMBER_ACCOUNT_PANEL_PAGE} from 'src/app/constants/route.constant';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SecurityService} from '../../../services/security.service';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {MatDialog} from '@angular/material/dialog';
import {FORM_GROUP_VALID, JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../../../constants/common.constant';
import {ERROR_INVALID_INPUT, ERROR_OLD_NEW_SIMILAR, ERROR_PASSWORD_NOT_MATCH} from '../../../constants/error.constant';
import {DialogScrollComponent} from 'src/app/shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-member-change-password',
  templateUrl: './member-change-password.page.html'
})
export class MemberChangePasswordPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;
  pwdFG: UntypedFormGroup;
  public validForm = FORM_GROUP_VALID;
  public toggleCurrent = false;
  public toggleNew = false;
  public toggleConfirm = false;
  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private securityService: SecurityService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.pwdFG = this.formBuilder.group({
      currentPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      passwordConfirmation: [null, [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  changePassword(): void {
    this.securityService.changePassword(
      this.pwdFG.controls.currentPassword.value,
      this.pwdFG.controls.newPassword.value,
      this.pwdFG.controls.passwordConfirmation.value).then(() => {
      this.openSuccessDialog();
    }, async (err) => {
      if (err.error.error) {
        switch (this.ui.errorMessageTransform(err.error.error)) {
          case ERROR_INVALID_INPUT: {
            const message = await this.label.getLabel(this.translateService, ERROR_INVALID_INPUT);
            this.snackBar.open(message as string, '', {duration: 2000});
            break;
          }
          case ERROR_OLD_NEW_SIMILAR: {
            const message = await this.label.getLabel(this.translateService, ERROR_OLD_NEW_SIMILAR);
            this.snackBar.open(message as string, '', {duration: 2000});
            break;
          }
          case ERROR_PASSWORD_NOT_MATCH: {
            const message = await this.label.getLabel(this.translateService, ERROR_PASSWORD_NOT_MATCH);
            this.snackBar.open(message as string, '', {duration: 2000});
            break;
          }
        }
      }else {
        const message = await this.label.getLabel(this.translateService, ERROR_INVALID_INPUT);
        this.snackBar.open(message as string, '', {duration: 2000});
      }
    });
  }
  openSuccessDialog(): void {
    const data: DialogInterface = {
      title : 'Kata Sandi Anda Berhasil Diubah',
      content: 'Tekan Tombol OK untuk melakukan login ulang ke akun member ramerame.co.id',
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {label: 'OK, Masuk Ulang', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialogRef = this.dialog.open(DialogScrollComponent, {data});
    dialogRef.afterClosed().subscribe(() => {
      localStorage.removeItem(JWT_TOKEN_KEY);
      localStorage.removeItem(STORAGE_PROFILE_KEY);
      this.label.getLabel(this.translateService, 'toast.signed-out').then((notification) => {
        this.router.navigate([this.ui.absoluteUrl(MAIN_LANDING_PAGE)]).then(() => {
          this.snackBar.open(notification as string, '', {
            duration: 2000
          });
        });
      });
    });
  }
}
