import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_ACCOUNT_PANEL_PAGE} from '../../../constants/route.constant';

@Component({
  selector: 'app-page-member-account-verification',
  templateUrl: './member-account-verification.page.html'
})
export class MemberAccountVerificationPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;
  public rejected = false;
  public toggleEdit = false;
  constructor(
    injector: Injector
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    console.log(this.userProfile.info);
    if (!this.userProfile.business.identityNo) {
      this.toggleEdit = true;
    }
  }
  setToggleEdit(event): void {
    this.toggleEdit = event;
  }
}
