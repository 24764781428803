import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../app-routing.module';
import {AppComponentModule} from '../components/component.module';
import {AppSharedModule} from '../shared/shared.module';
import {NgProgressModule} from 'ngx-progressbar';
import {AppLandingModule} from './landing/landing.module';
import {AppMemberCartModule} from './member/cart/member-cart.module';
import {AppMemberNotificationModule} from './member/notification/member-notification.module';
import {AppRegisterModule} from './register/register.module';
import {AppLoginModule} from './login/login.module';
import {AppFrontPageModule} from './front-pages/front-page.module';
import {AppMemberWalletModule} from './member/wallet/member-wallet.module';
import {AppMemberTicketModule} from './member/ticket/member-ticket.module';
import {AppMemberSecurity} from './member/security/member-security.module';
import {AppMemberReportModule} from './member/report/member-report.module';
import {AppMemberProfileModule} from './member/profile/member-profile.module';
import {AppMyBusinessModule} from './member/my-business/my-business.module';
import {AppDialogModule} from './dialog/dialog.module';
import {AppMemberRewardsModule} from './member/rewards/rewards.module';
import {AppSocialAuthModule} from './auth/social-auth.module';
import {AppVoucherModule} from './member/voucher/voucher.module';
import {AppMembercardModule} from './member-card/member-card.module';
import {QrCodeModule} from 'ng-qrcode';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    NgProgressModule,
    AppSharedModule,
    AppComponentModule,
    AppLandingModule,
    AppMemberCartModule,
    AppMemberNotificationModule,
    AppRegisterModule,
    AppLoginModule,
    AppFrontPageModule,
    AppMemberWalletModule,
    AppMemberTicketModule,
    AppMemberSecurity,
    AppMemberReportModule,
    AppMemberProfileModule,
    AppMyBusinessModule,
    AppDialogModule,
    AppMemberRewardsModule,
    AppSocialAuthModule,
    AppVoucherModule,
    AppMembercardModule,
    QrCodeModule
  ],
  exports: [
  ],
  declarations: [
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppPageModule {}
