import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SUPPORT_TICKET_BASE_URL} from '../constants/api.constant';
import {SupportTicketInterface} from '../interfaces/support-ticket.interface';

@Injectable({providedIn: 'root'})
export class TicketService {
  constructor(private http: HttpClient) {
  }
  async getSupportTickets(): Promise<SupportTicketInterface[]> {
    return await this.http.get<SupportTicketInterface[]>(environment.url + SUPPORT_TICKET_BASE_URL, {}).toPromise();
  }
  async getSupportTicketById(id: number): Promise<SupportTicketInterface> {
    return await this.http.get<SupportTicketInterface>(environment.url + SUPPORT_TICKET_BASE_URL + `/id/${id}`, {}).toPromise();
  }
  async openSupportTicket(title: string, category: string, priority: string, message: string): Promise<any> {
    const payload = { title, category, priority, message };
    return await this.http.post<any>(environment.url + SUPPORT_TICKET_BASE_URL + `/save`, payload, {}).toPromise();
  }
  async sendReplySupportTicket(ticketId: number, message: string): Promise<any> {
    return await this.http.post<any>(environment.url + SUPPORT_TICKET_BASE_URL + `/update`, {ticketId, message}, {}).toPromise();
  }
}
