<div class="main-content full-screen grey-background cart-panel with-fixed-bottom-button" *ngIf="myInvestment">
    <div class="cart invoice">
        <div class="invoice-header">
            <div class="item row">
                <div class="col-12 p-0">
                    <h1 class="h6 text-grey mt-3">{{'pages.my-investment.order-details' | translate}}</h1>
                    <div class="d-flex justify-content-between">
                        <h6 class="text-weight-regular">{{'pages.my-investment.purchase-id' | translate}}</h6>
                        <h6 style="text-transform: uppercase">{{myInvestment.purchaseId}}</h6>
                    </div>

                    <div class="d-flex justify-content-between">
                        <h6 class="text-weight-regular">{{'common.date' | translate}}</h6>
                        <h6>{{date.completeLocaleDateTime(myInvestment.purchaseDate, locale)}}</h6>
                    </div>

                    <div class="d-flex justify-content-between">
                        <h6 class="text-weight-regular">{{'common.status' | translate}}</h6>
                        <h6 *ngIf="myInvestment.status == 2 && !myInvestment.attachment">{{'status.payment-process' | translate}}</h6>
                        <h6 *ngIf="myInvestment.status == 2 && myInvestment.attachment">{{'status.payment-validation' | translate}}</h6>
                        <h6 *ngIf="myInvestment.status == 3">{{'status.rejected' | translate}}</h6>
                        <h6 *ngIf="!myInvestment.status">{{'status.approved' | translate}}</h6>
                    </div>
                </div>

            </div>
        </div>
        <div class="order-list">
            <div class="item row" *ngFor="let item of myInvestment.details">
                <div class="description col-12">
                    <h6 class="mb-2">{{item.productName}}</h6>
                    <p class="mt-2">
                        <span class="h6">{{'common.currency-idr' | translate}} {{ui.currencyFormat(item.ecRate, locale)}}</span> <span class="h5 text-grey">/ {{'common.slot' | translate}}</span>
                    </p>

                </div>
                <div class="col-12 d-flex justify-content-between p-0">
                    <h6 class="text-weight-regular">{{'pages.my-investment.quantity' | translate}}</h6>
                    <h6 class="text-weight-bold">{{item.ecUnit}} {{'common.slot' | translate}}</h6>
                </div>
                <div class="col-12 d-flex justify-content-between p-0">
                    <h6 class="text-weight-regular">{{'pages.my-investment.sub-total' | translate}}</h6>
                    <h6 class="text-weight-bold">{{'common.currency-idr' | translate}} {{ui.currencyFormat(item.ecUnit * item.ecRate, locale)}}</h6>
                </div>

            </div>

        </div>
        <div class="download-invoice">
            <div class="item row">

              <div class="col-12 p-0">
                    <div class="d-flex justify-content-between">
                        <span class="h6 text-weight-regular">{{'pages.my-investment.service-fee' | translate}}</span>
                        <span class="h6 text-weight-bold text-black">{{'common.currency-idr' | translate}} {{ui.currencyFormat(myInvestment.adminFeeSum, locale)}}</span>
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="myInvestment.amtInsurance">
                      <span class="h6 text-weight-regular">{{'pages.my-investment.insurance' | translate}}</span>
                      <span class="h6 text-weight-bold text-black">{{ui.currencyFormat(myInvestment.amtInsurance, locale)}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="h6 text-weight-regular">{{'pages.my-investment.unique-number' | translate}}</span>
                        <span class="h6 text-weight-bold text-black">{{myInvestment.meta.uniqueNum}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="h6 text-weight-regular">{{'pages.my-investment.total-price' | translate}}</span>
                        <span class="h6 text-weight-bold text-black">{{'common.currency-idr' | translate}} {{ui.currencyFormat((myInvestment.total + myInvestment.amtInsurance), locale)}}</span>
                    </div>
                </div>
                <hr class="form-separator mt-3 mb-3" />
                <div class="col-12 p-0">
                    <div class="d-flex justify-content-between">
                        <span class="h6 text-weight-regular">{{'pages.my-investment.wallet-used' | translate}}</span>
                        <span class="h6 text-weight-bold text-black">{{'common.currency-idr' | translate}} {{ui.currencyFormat(myInvestment.meta.paid, locale)}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="h6 text-weight-regular">{{'pages.my-investment.paid-with-transfer' | translate}}</span>
                        <span class="h6 text-weight-bold text-black">{{'common.currency-idr' | translate}} {{ui.currencyFormat(myInvestment.meta.mustPay, locale)}}</span>
                    </div>
                </div>
            </div>
            <div class="item row" *ngIf="myInvestment.status == 2 && myInvestment.attachment">
              <div class="col-12 p-0">
                <div class="d-flex justify-content-between">
                  <span class="h6 text-weight-regular">Bukti Transfer</span>
                  <a (click)="downloadEvidence()" class="h6 text-weight-bold">unduh bukti transfer</a>
                </div>
              </div>
            </div>
            <div class="item row" *ngIf="!myInvestment.status">
              <div class="col-12 p-0">
                <div class="d-flex justify-content-between">
                  <span class="h6 text-weight-regular">Invoice</span>
                  <a (click)="downloadInvoice()" class="h6 text-weight-bold">unduh invoice pembelian</a>
                </div>
              </div>
            </div>
            <div class="item row">
                <div class="col-12 p-0">
                    <p>{{'pages.my-investment.more-info' | translate}} :</p>
                    <p>{{myInvestment.paymentNoteLine1}}</p>
                  <p>{{myInvestment.paymentNoteLine2}}</p>
                </div>
            </div>
            <div class="item row">
                <div class="col-12 p-0">
                    <p>{{myInvestment.transferNote}}<span *ngIf="myInvestment && myInvestment.transferNote" class="btn-link" (click)="copyToClipboard(myInvestment.accountNo)">[copy no rekening]</span></p>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-buttons">
      <button *ngIf="myInvestment.status == 2" class="primary-button big-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE + '/' + myInvestment.id)" mat-flat-button>Unggah bukti pembayaran</button>
      <button (click)="downloadEvidence()" *ngIf="!myInvestment.status && myInvestment.attachment" class="primary-button big-button" mat-flat-button>Unduh bukti pembayaran</button>
    </div>
</div>
