<div class="close-button">
    <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<mat-dialog-content class="mat-typography">
    <h4 class="text-center">{{'pages.wallet.recheck-withdraw' | translate}}</h4>
    <div class="well mb-3">
        <p>{{'pages.wallet.amount' | translate}}<br />
            <strong>Rp {{ui.currencyFormat(data.amount, locale)}}</strong></p>
        <p class="mt-2">{{'pages.wallet.account' | translate}}<br />
            <strong>{{ data.bankName }}</strong> <br />
            <strong>{{data.accountNumbers}} </strong><br />
            <strong>a/n. {{data.onBehalfOf}}</strong></p>
    </div>
    <p>{{'pages.wallet.withdraw-guide' | translate}}</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
<ng-container>
    <button cdkFocusInitial  mat-button class="primary-button big-button mb-2" (click)="send()">{{'pages.wallet.withdraw-confirmation' | translate}}</button>
    <button [mat-dialog-close]="0" mat-button mat-dialog-close class="clear-button big-button">{{'common.cancel' | translate}}</button>
</ng-container>
</mat-dialog-actions>
