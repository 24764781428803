<div class="full-screen registration-modal">
    <div class="full-height-page">
        <div class="top-logo">
            <img [src]="logo" alt="Rame rame logo" />
        </div>
        <div class=" text-center">
            <img [src]="mailSentLogo" alt="mail sent" />
            <h1 class="h3 mt-3 mb-4">{{'pages.register.email-check' | translate}}</h1>
            <button class="secondary-button text-black mb-4" mat-flat-button>{{email}}</button>
            <p>{{'pages.register.confirmation-one' | translate}}</p>
            <p>{{'pages.register.confirmation-two' | translate}}</p>
        </div>
        <div class="text-center mt-2">
          <button [routerLink]="['/sign-in']" class="secondary-button text-black-50 mb-4" mat-flat-button>{{'common.close' | translate}}</button>
        </div>
    </div>
</div>
