import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SECURITY_CHANGE_PASSWORD_BASE_URL} from '../constants/api.constant';
import {environment} from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class SecurityService {
  constructor(private http: HttpClient) {
  }
  async changePassword(current: string, password: string, confirmation: string): Promise<any>{
    const payload = {current, password, password_confirmation: confirmation};
    return await this.http.post<any>(environment.url + SECURITY_CHANGE_PASSWORD_BASE_URL , payload, {}).toPromise();
  }
  async resetPassword(token: string, email: string, password: string, confirmation: string): Promise<any>{
    const payload = {token, email, password, password_confirmation: confirmation};
    return await this.http.post<any>(environment.url + '/users/reset-password' , payload, {}).toPromise();
  }
}
