import {Component, Injector, OnInit} from '@angular/core';
import {STORAGE_LANG_KEY} from 'src/app/constants/common.constant';
import {VoucherDetailsInterface} from 'src/app/interfaces/member-card.interface';
import {MembercardService} from 'src/app/services/membercard.service';
import {BaseComponent} from 'src/app/shared/base/base.component';
import {Location} from '@angular/common';

@Component({
  selector: 'app-use-merchandise-voucher',
  templateUrl: './use-merchandise-voucher.page.html'
})
export class UseMerchandiseVoucherComponent extends BaseComponent implements OnInit {

  public voucherId;
  public voucher: VoucherDetailsInterface = {};
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public hoursVoucherParam = 72;

  constructor(injector: Injector,
              private location: Location,
              private memberCardService: MembercardService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.voucherId = this.activatedRoute.snapshot.params.id;
    this.memberCardService.getMerchandiseVoucherDetails(this.voucherId).then((res) => {
      this.voucher = res;
      this.voucher.expiredDateD = this.date.newDateFromStringFormat(res.expiredDate);
      this.voucher.dateTimeNowD = this.date.newDateFromStringFormat(res.dateTimeNow);

    }).catch(err => console.error(err));
  }

  back(): void {
    this.location.back();
  }
}
