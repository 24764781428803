import {Injectable, Injector, OnDestroy, OnInit} from '@angular/core';
import {UiHelper} from '../../helpers/ui.helper';
import {LabelHelper} from '../../helpers/label.helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {NavService} from '../../nav/nav.service';
import {NavDataInterface} from '../../interfaces/nav-data.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserInterface} from '../../interfaces/user.interface';
import {JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateHelper} from '../../helpers/date.helper';
import {MAIN_LANDING_PAGE} from '../../constants/route.constant';
import {DialogInterface} from '../dialog/dialog.interface';
import {MD_PRIVACY_CONTENT} from '../../constants/json.constant';
import {DialogScrollComponent} from '../dialog/dialog-scroll.component';
import {MatDialog} from '@angular/material/dialog';
import {TermService} from '../../services/term.service';
import {ThanksTocComponent} from '../../pages/dialog/thanks-toc.component';

@Injectable()
export abstract class BaseComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe = new Subject();
  public ui = UiHelper;
  public label = LabelHelper;
  public date = DateHelper;
  public baseUrl = environment.base;
  public apiBaseUrl = environment.url;
  public pageState = '';
  public pageNo = 1;
  public pageSize = 4;
  public showPages = 3;
  public totalRow = 0;
  public router: Router;
  public translateService: TranslateService;
  public activatedRoute: ActivatedRoute;
  public navService: NavService;
  public jwtService: JwtHelperService;
  public snackBar: MatSnackBar;
  public isSignedIn: boolean;
  public userProfile: UserInterface = {};
  public dlg: MatDialog;
  public termSvc: TermService;
  protected constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.translateService = injector.get(TranslateService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.navService = injector.get(NavService);
    this.jwtService = injector.get(JwtHelperService);
    this.snackBar = injector.get(MatSnackBar);
    this.dlg = injector.get(MatDialog);
    this.termSvc = injector.get(TermService);
  }
  ngOnInit(): void {
    this.isSignedIn = !this.jwtService.isTokenExpired();
    this.userProfile = JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface;
    if(this.userProfile && !this.userProfile.basic) {
      localStorage.removeItem(JWT_TOKEN_KEY);
      localStorage.removeItem(STORAGE_PROFILE_KEY);
      this.router.navigate([this.ui.absoluteUrl(MAIN_LANDING_PAGE)]).then(() => {});
    }
    if (!this.isSignedIn) {
      localStorage.removeItem(JWT_TOKEN_KEY);
      localStorage.removeItem(STORAGE_PROFILE_KEY);
    }
    this.activatedRoute.data.subscribe((res: NavDataInterface) => {
      this.pageState = res.state;
      this.navService.changeMessage(res);
    });

    if(this.isSignedIn) {
      this.termSvc.checkUserAcceptTerm().then(res => {
        console.log('welcome to ramerame.co.id');
      }, () => {
        const data: DialogInterface = {
          title : '',
          subtitle: '',
          markdownPath: MD_PRIVACY_CONTENT,
          buttons: [
            {label: 'common.i-accept', value: 1, className: 'primary-button big-button'},
          ]
        };

        const dialog = this.dlg.open(DialogScrollComponent, {data});
        dialog.afterClosed().subscribe(res => {
          this.termSvc.saveUserAcceptedTerm(res).then(result => {
            this.dlg.open(ThanksTocComponent, {});
          }, err => {
            console.log(err);
          });
        });
      });

    }

  }
  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
