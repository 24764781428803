import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ProductInterface} from '../../interfaces/product.interface';
import {UiHelper} from '../../helpers/ui.helper';
import {STORAGE_LANG_KEY} from '../../constants/common.constant';
import {DateHelper} from '../../helpers/date.helper';
import {Router} from '@angular/router';
import {BUSINESS_DETAIL_PAGE} from '../../constants/route.constant';

@Component({
  selector: 'app-product-component',
  templateUrl: './product.component.html',
})
export class ProductComponent implements OnInit{
  @Input() products: ProductInterface[] = [];
  public baseUrl = environment.base;
  public insuranceLogo = '/assets/images/icons/insurance.svg';
  public isAuth = false;
  public ui = UiHelper;
  public date = DateHelper;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  constructor(private jwtHelper: JwtHelperService, private router: Router) {
  }
  ngOnInit(): void {
    this.isAuth = !this.jwtHelper.isTokenExpired();
  }
  toProductDetails(id: number) {
    this.router.navigate(['/' + BUSINESS_DETAIL_PAGE + '/' + id]);
  }
}
