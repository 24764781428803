import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {FAQContentInterface} from '../../interfaces/common.interface';
import {FrontPageService} from './services/front-page.service';
import {STORAGE_LANG_KEY} from '../../constants/common.constant';

@Component({
  selector: 'app-page-faq',
  templateUrl: './faq.page.html',
})
export class FaqPageComponent extends BaseComponent implements OnInit {
  public faqs: FAQContentInterface[] = [];
  constructor(
    injector: Injector,
    private fpService: FrontPageService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.fpService.getFAQ().then((res) => {
      const locale  = localStorage.getItem(STORAGE_LANG_KEY);
      const content = res.find((row) => row.lang === locale);
      this.faqs = content.content;
    });
  }
}
