import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MyVouchersInterface} from '../../../interfaces/member-card.interface';
import {STORAGE_LANG_KEY, VOUCHER_DETAIL_ACTION} from '../../../constants/common.constant';
import {MEMBER_CARD_VOUCHER_DETAIL, MERCHANDISE_ALL_CATEGORIES} from '../../../constants/route.constant';
import {MembercardService} from '../../../services/membercard.service';
import {MyMerchandiseInterface} from '../../../interfaces/voucher.interface';

@Component({
  selector: 'app-my-voucher',
  templateUrl: './my-voucher.page.html',
})
export class MyVoucherComponent extends BaseComponent implements OnInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public campaignVouchers: MyVouchersInterface[];
  public merchandiseVouchers: MyMerchandiseInterface[];
  public hoursVoucherParam: number = 1;

  constructor(
    injector: Injector,
    private memberCardService: MembercardService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.memberCardService.getVoucherByUserAndIsClaimed("0").then((res) => {
      this.campaignVouchers = res;
    });

    this.memberCardService.getMerchandiseByUserAndIsClaimed("0").then((res) => {
      this.merchandiseVouchers = res;
    });
  }

  doNavigateVoucherDetail(id): void {
    this.router.navigate(['/' + MEMBER_CARD_VOUCHER_DETAIL + '/' + id + '/' + VOUCHER_DETAIL_ACTION.REDEEM]).then();
  }

  doNavigateMerchantDetail(id): void {
    this.router.navigate(['/' + MERCHANDISE_ALL_CATEGORIES + '/' + id + '/' + VOUCHER_DETAIL_ACTION.REDEEM]).then();
  }
}
