<div class="timer" *ngIf="!withLabel else renderWithLabel">
  <span> {{counter | formatHours}} </span>
  <span> {{counter | formatMinutes}} </span>
  <span> {{counter | formatSeconds}} </span>
</div>
<ng-template #renderWithLabel>
  <div class="with-label" style="display: flex;">
    <span>{{counter | formatHours}}<br>{{'pages.member-card.hours' | translate}}</span>
    <span>{{counter | formatMinutes}}<br>{{'pages.member-card.minutes' | translate}}</span>
    <span>{{counter | formatSeconds}}<br>{{'pages.member-card.seconds' | translate}}</span>
  </div>
</ng-template>
