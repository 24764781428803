import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_ACCOUNT_PANEL_PAGE, MEMBER_TICKET_DETAILS_PAGE, MEMBER_TICKET_FORM_PAGE} from 'src/app/constants/route.constant';
import {MatDialog} from '@angular/material/dialog';
import {TicketService} from '../../../services/ticket.service';
import {SupportTicketInterface} from '../../../interfaces/support-ticket.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';

@Component({
  selector: 'app-page-ticket-landing',
  templateUrl: './member-ticket-landing.page.html'
})
export class MemberTicketLandingPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;
  public MEMBER_TICKET_FORM_PAGE = MEMBER_TICKET_FORM_PAGE;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public supportTickets: SupportTicketInterface[] = [];
  public flag = 0;
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private ticketService: TicketService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.ticketService.getSupportTickets().then((res) => {
      this.supportTickets = res;
      this.flag = 1;
    });
  }
  toDetails(ticket: SupportTicketInterface): void {
    this.router.navigate([MEMBER_TICKET_DETAILS_PAGE + ticket.id]).then(() => {});
  }
}
