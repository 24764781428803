import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../app-routing.module';
import {NgProgressModule} from 'ngx-progressbar';
import {SocialAuthPageComponent} from './social-auth.page';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    NgProgressModule,
  ],
  exports: [
    SocialAuthPageComponent
  ],
  declarations: [
    SocialAuthPageComponent
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppSocialAuthModule {}
