<div *ngIf="dialogData.imageUrl" class="text-center" style="position: relative">
  <img class="img-fluid" [alt]="dialogData.imageAlt" [src]="dialogData.imageUrl" [style]="dialogData.imageStyle"/>
  <button class="clear-transparent" mat-dialog-close><span class="icon-close-circle" style="font-size: 25px;"></span></button>
</div>
<mat-dialog-content class="mat-typography m-0">
  <h2 class="text-center">{{dialogData.title}}</h2>
  <p class="text-center" *ngIf="dialogData.content" [innerHTML]="dialogData.content | keepHtml"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngFor="let btn of dialogData.buttons">
    <button *ngIf="btn.focus" cdkFocusInitial [mat-dialog-close]="btn.value" mat-button mat-dialog-close
            class="{{btn.className}}" style="{{btn.addStyle}}">{{btn.label | translate}}</button>
    <button *ngIf="!btn.focus" [mat-dialog-close]="btn.value" mat-button mat-dialog-close class="{{btn.className}}"
            style="{{btn.addStyle}}">{{btn.label | translate}}</button>
  </ng-container>
</mat-dialog-actions>
