import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {InvestmentService} from '../../services/investment.service';
import {TanyaAdminInterface} from '../../interfaces/tanya-admin.interface';

@Component({
  selector: 'app-dialog-member-ask-admin',
  templateUrl: './dialog-member-ask-admin.component.html',
})
export class MemberAskAdminDialogComponent{
  public CATEGORY_OPTIONS_MULTI_LANG = [
    {id: 'Bantuan Umum', en: 'General Help', value: 'Bantuan Umum'},
    {id: 'Billing', en: 'Billing', value: 'Billing'},
    {id: 'Seputar Produk', en: 'About Product/Project', value: 'Seputar Produk'},
  ];
  public askFG: UntypedFormGroup;
  public result  = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<MemberAskAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private investmentService: InvestmentService
  ) {
    this.askFG = this.formBuilder.group({
      titleFC: ['', [Validators.required, Validators.maxLength(254)]],
      categoryFC: ['', [Validators.required]],
      contentFC: ['', [Validators.required]]
    });
  }
  save(): void {
    const payload: TanyaAdminInterface = {
      title: this.askFG.controls.titleFC.value,
      category: this.askFG.controls.categoryFC.value,
      content: this.askFG.controls.contentFC.value
    };
    this.investmentService.saveProductTanyaAdmin(this.data.product.id, payload).then(() => {
      this.result.emit('success');
      this.dialogRef.close();
    });
  }
}
