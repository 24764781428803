<form [formGroup]="qrScanFG" (submit)="submitVoucher()">
  <div style="margin-right: -15px; margin-left: -15px" *ngIf="voucherDetail">
    <img class="img-fluid" [src]="baseUrl + voucherDetail.imagePath + '?rand=123'">
  </div>
  <div class="voucher-detail" *ngIf="voucherDetail">
    <div class="pt-4 mb-3">
      <p class="vd-title">{{voucherDetail.campaignName}}</p>
      <h6>{{voucherDetail.merchantName}}</h6>
      <div class="d-flex justify-content-between">
        <span>{{voucherDetail.merchantAddress}}</span>

      </div>
    </div>
    <div *ngIf="voucherDetail.isClaim == 1"
         class="ribbon static used-btn">{{'pages.member-card.voucher-used' | translate }}</div>
    <div class="row justify-content-between">
      <div class="col-5">
        <p class="vd-title-dtl" *ngIf="voucherDetail.redeemWith == 'POINT' else priceTitle">
          {{'pages.member-card.point' | translate}}</p>
        <ng-template #priceTitle>
          <p class="vd-title-dtl">{{'pages.member-card.cash' | translate}}</p>
        </ng-template>
      </div>
      <div class="col-5">
        <p class="vd-title-dtl">{{'pages.member-card.expired-until' | translate}}</p>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-5">
        <p class="vd-title-point" *ngIf="voucherDetail.redeemWith == 'POINT' else renderPrice">
          {{voucherDetail.numberOfPoint}} {{'pages.member-card.point' | translate}}</p>
        <ng-template #renderPrice>
            <p class="vd-title-point">Rp.{{ui.currencyFormat(voucherDetail.voucherPrice, locale)}}</p>
        </ng-template>
      </div>
      <div class="col-5" *ngIf="voucherDetail.redeemWith == 'POINT'">
        <p>{{date.localeDate(voucherDetail.endDate, locale)}}</p>
      </div>
      <div class="col-5" *ngIf="voucherDetail.redeemWith == 'CASH'">
        <app-countdown-pipe-component [withLabel]="'true'" [startDate]="date.newDateFromStringFormat(voucherDetail.dateTimeNow)"
                                      [endDate]="date.newDateFromStringFormat(voucherDetail.expiredDate)">
        </app-countdown-pipe-component>
      </div>
    </div>
    <div>
      <p class="tnc">{{'pages.member-card.term-and-condition' | translate}}</p>
    </div>
    <p [innerHtml]="voucherDetail.description | keepHtml"></p>
  </div>
  <div class="foot-menu">
    <ul class="bottomNav">
      <button class="primary-button big-button" type="submit"
              mat-flat-button>{{'pages.member-card.next' | translate}}</button>
    </ul>
  </div>
</form>
