import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {ProductInsurance, ShoppingCartInterface} from '../../../interfaces/shopping-cart.interface';
import {environment} from '../../../../environments/environment';
import {ERR_PROFILE_NOT_COMPLETE, MINIMUM_WALLET_USED_FOR_CHECKOUT, STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {WalletService} from '../../../services/wallet.service';
import {WalletInterface} from '../../../interfaces/wallet.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE, MEMBER_NOT_COMPLETE_REDIRECT_PAGE} from '../../../constants/route.constant';
import {MatDialog} from '@angular/material/dialog';
import {DialogInsuranceInterface, DialogInterface} from '../../../shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {DialogInsuranceComponent} from '../../../shared/dialog/dialog-insurance.component';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'app-page-member-cart-confirm',
  templateUrl: './member-cart-confirm.page.html'
})
export class MemberCartConfirmPageComponent extends BaseComponent implements OnInit {
  public cart: ShoppingCartInterface = {};
  public env = environment.base;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public wallet: WalletInterface = {};
  public minimumWalletUsed = 0;
  private useWallet = false;
  public payNowDisable = false;
  public btnInsuranceLabel: string = 'pages.insurance.choice';
  public insuranceLabel: string = 'pages.insurance.label';
  public withInsurance = false;
  public productInsurance: ProductInsurance;
  public insuranceLogo = '/assets/images/icons/insurance.svg';
  @ViewChild('filterWithInsurance') filterWithInsurance: ElementRef;

  constructor(
    injector: Injector,
    private shoppingCartService: ShoppingCartService,
    private walletService: WalletService,
    public dialog: MatDialog,
    private clipboardService: ClipboardService
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.btnInsuranceLabel = await this.label.getLabel(this.translateService, "pages.insurance.choice");
    super.ngOnInit();
    this.shoppingCartService.checkout().then(async (res) => {
      this.cart = res;
    }, (err) => {
      if (err.error.error == ERR_PROFILE_NOT_COMPLETE) {
        this.router.navigate([MEMBER_NOT_COMPLETE_REDIRECT_PAGE]).then(() => {
        });
      }
    });
    this.walletService.getWalletsAsync(this.pageNo).then((res) => {
      this.wallet = res;
    });
  }

  toggleWalletUsage(event: MatCheckboxChange): void {
    if (this.wallet.balance >= this.cart.amount + this.cart.platformFee * this.cart.amount) {
      if (event.checked) {
        this.cart.uniqueNumbers = 0;
        this.minimumWalletUsed = this.cart.amount + this.cart.uniqueNumbers + this.cart.platformFee * this.cart.amount;
      } else {
        this.shoppingCartService.checkout().then((res) => {
          this.cart = res;
          this.minimumWalletUsed = 0;
        });
      }

    } else {
      if ((this.cart.amount + this.cart.platformFee * this.cart.amount) - this.wallet.balance < MINIMUM_WALLET_USED_FOR_CHECKOUT) {
        const remaining = this.wallet.balance % MINIMUM_WALLET_USED_FOR_CHECKOUT;
        this.minimumWalletUsed = (event.checked) ? this.wallet.balance - remaining : 0;
      } else {
        this.shoppingCartService.checkout().then((res) => {
          this.cart = res;
          this.minimumWalletUsed = (event.checked) ? Math.floor(this.wallet.balance) : 0;
          this.cart.uniqueNumbers = ((((this.cart.platformFee * this.cart.amount) + this.cart.amount) - this.minimumWalletUsed) % 1000 > 0) ? 0 : this.cart.uniqueNumbers;

        });
      }
    }
    this.useWallet = (event.checked);
  }

  payNow(): void {
    this.payNowDisable = true;
    this.shoppingCartService.done(this.useWallet, '',
      this.productInsurance ? this.productInsurance.id : null,
      this.productInsurance ? this.productInsurance.percentage : 0,
      this.productInsurance ? (this.cart.amount * this.productInsurance.percentage) : 0).then((res) => {
      this.payNowDisable = false;
      this.router.navigate([MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE + '/' + res.value]).then(() => {
        this.snackBar.open('Transaksi anda berhasil, menunggu approval dari admin', '', {duration: 2000});
      });
    }, (err) => {
      this.payNowDisable = false;
      this.snackBar.open('Terjadi Kesalahan : ' + err.error.error, '', {duration: 2000});
    });
  }

  toggleInsurances(event: MatCheckboxChange): void {
    if (event.checked) {
      this.withInsurance = true;
      this.openDocumentDialog();
    } else {
      this.withInsurance = false;
      this.productInsurance = null;
      this.cart.productsInsurance.forEach(ins => ins.selected = false);
      console.log("unchecked-> clear Penjaminan")
    }
  }

  async openDocumentDialog(): Promise<void> {
    const data: DialogInsuranceInterface = {
        title: await this.label.getLabel(this.translateService, "pages.insurance.insurance-choice") as string,
        logo: this.cart.insurance ? this.cart.insurance[0].logo : null,
        productInsurance: this.cart.productsInsurance,
        buttons: [
          {label: 'Pilih', value: 1, className: 'primary-button big-button'}
        ]
    };
    const pointNotif = this.dialog.open(DialogInsuranceComponent, {data});
    pointNotif.afterClosed().subscribe(async (rs) => {
      let title = "";
      this.cart.productsInsurance.forEach(ins => ins.selected = false);
      if (!rs && !this.productInsurance) {
        this.filterWithInsurance['checked'] = false;
        this.withInsurance = false;
        this.productInsurance = null;
        return;
      }
      if (rs) {
        this.productInsurance = rs;
        title = rs.title;
        this.cart.productsInsurance[this.cart.productsInsurance.findIndex(x => x.id == rs.id)].selected = true
      }
      if (!rs && this.productInsurance) {
        title = this.productInsurance.title;
        this.cart.productsInsurance[this.cart.productsInsurance.findIndex(x => x.id == this.productInsurance.id)].selected = true
      }
      this.insuranceLabel = await this.label.getLabelWithParam(this.translateService, "pages.insurance.label", {title: title});
    });

  }

  showInfos() {
    const data: DialogInterface = {
      title: 'Insurances Information',
      imageUrl: 'assets/images/member/alert-image2-min.png',
      content: 'Fill all text or stuff about this promo here..',
      buttons: [
        {label: 'OK, Tutup', value: 1, className: 'primary-button big-button'},
      ]
    };
    this.dialog.open(DialogScrollComponent, {data});
  }

  copyToClipboard(accountNo: string) {
    this.clipboardService.copyFromContent(accountNo);
    this.snackBar.open('No Rekening '+ accountNo +' berhasil disalin', '', {duration: 2000});
  }
}
