<div class="main-content homepage" *ngIf="ready == 6 && upperSliderOptions">
    <app-landing-banner-component [landingBanners]="landingBanners" [sliderOptions]="upperSliderOptions"></app-landing-banner-component>

    <div class="d-flex mt-5 mb-2" style="justify-content: space-between">
      <h6>Project Kolaborasi</h6>
      <a [routerLink]="[businessListUrl]">{{'pages.landing.more-products' | translate}}</a>
    </div>

    <app-product-component [products]="productOffered" ></app-product-component>

    <div class="d-flex mt-5 mb-2" style="justify-content: space-between">
      <h6>Tebus Murah Ramerame</h6>
      <a [routerLink]="[promoUrl]">{{'pages.landing.more-products' | translate}}</a>
    </div>
    <div class="mb-2">Loyalti program untuk member ramerame yang ingin mendapatkan maanfaat lebih</div>

    <div class="row sliding-campaigns" *ngIf="newOfferingCampaigns.length > 0">
      <div class="col-9 col-md-7 col-lg-5" [ngStyle]="{'margin-right': '12px'}"
           *ngFor="let campaign of newOfferingCampaigns">
        <div class="card business-infos" style="position: relative">
          <img class="card-img-top" [src]="baseUrl + campaign.imagePath + '?rand=123'">
          <span *ngIf="campaign.isHighlighted" class="highlight-ribbon">{{'pages.member-card.merchant-of-the-day' | translate}}</span>
          <div class="body">
            <p class="title-name">{{campaign.campaignName}}</p>
            <p class="title-merchant">{{campaign.merchantName}}</p>
            <div class="row width-100">
              <div class="col-8">
                <span *ngIf="campaign.voucherPriceBefore" class="title-point-strike mr-1" style="text-decoration: line-through">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPriceBefore, locale)}}</span>
                <span class="title-point">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPrice, locale)}}</span>
              </div>
              <div class="col-4 text-right p-0">
                <span class="point-chip">
                  <i class="icon-coin"></i> {{campaign.numberOfPoint}}  {{ 'pages.member-card.point' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row merchant-section mt-4 mx-1">
        <div class="card">
          <div class="position-relative d-flex">
            <img class="img-fluid" [src]="baseUrl + memberCardImage + '?rand=123'" style="border-top-left-radius: 6px; border-top-right-radius: 6px;" />
            <button (click)="toRegisterMerchant()" class="btn-sm position-absolute" style="bottom: 15px; left: 15px;">Selengkapnya</button>
          </div>

          <div class="body">
            <h6 class="mx-3 mt-2">Gabung dan tumbuh bersama merchant ramerame</h6>
            <div class="mx-3 mt-2 mb-2">Dapatkan kesempatan di serbu oleh 6000 lebih member ramerame.co.id</div>
          </div>
        </div>
    </div>

    <div class="partner-section mt-4 mb-3">
        <h3 class="text-center heading mb-3">{{ 'pages.landing.registered-at' | translate }}</h3>
        <div class="row d-flex justify-content-center align-items-center" >
          <div class="col-2 col-md-2 mb-2 mt-2">
            <img src="./assets/images/others/kominfo-blue.png" title="kominfo" class="img-fluid" alt="kominfo logo" />
          </div>
        </div>
        <h3 class="text-center heading mb-3 mt-2">{{ 'pages.landing.our-partners' | translate }}</h3>
        <app-partner-component [partners]="partners"></app-partner-component>
    </div>
</div>
