import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UiHelper } from '../../../helpers/ui.helper';
import { STORAGE_LANG_KEY } from '../../../constants/common.constant';
import { WalletService } from '../../../services/wallet.service';
import { DialogInterface } from '../../../shared/dialog/dialog.interface';
import {
  ERROR_BANK_ACCOUNT_NOT_FOUND,
  ERROR_BANK_ACCOUNT_NOT_VERIFIED,
  ERROR_MINIMUM_WITHDRAW,
  ERROR_WALLET_NOT_FOUND,
  ERROR_WITHDRAW_AMOUNT_GREATER_THAN_BALANCE,
  ERROR_WITHDRAW_AMOUNT_LESS_THAN_HUNDRED_THOUSAND,
} from '../../../constants/error.constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabelHelper } from '../../../helpers/label.helper';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MEMBER_WALLET_LANDING_PAGE } from '../../../constants/route.constant';
import { DialogScrollComponent } from '../../../shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-wallet-withdraw-dialog',
  templateUrl: './wallet-dialog-withdraw.component.html',
})
export class MemberWalletWithdrawDialogComponent {
  public ui = UiHelper;
  public label = LabelHelper;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  constructor(
    public dialogRef: MatDialogRef<MemberWalletWithdrawDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private walletService: WalletService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router
  ) {}
  send(): void {
    this.walletService.saveWithdrawRequest(Number(this.data.amount)).then(
      () => {
        this.dialogRef.close();
        this.openSuccessDialog();
      },
      async (err) => {
        if (err.error.error) {
          switch (this.ui.errorMessageTransform(err.error.error)) {
            case ERROR_WITHDRAW_AMOUNT_LESS_THAN_HUNDRED_THOUSAND: {
              const message = await this.label.getLabel(
                this.translateService,
                ERROR_WITHDRAW_AMOUNT_LESS_THAN_HUNDRED_THOUSAND
              );
              this.snackBar.open(message as string, '', { duration: 2000 });
              break;
            }
            case ERROR_WITHDRAW_AMOUNT_GREATER_THAN_BALANCE: {
              this.openInsuficientBalanceDialog();
              break;
            }
            case ERROR_BANK_ACCOUNT_NOT_VERIFIED: {
              const message = await this.label.getLabel(
                this.translateService,
                ERROR_BANK_ACCOUNT_NOT_VERIFIED
              );
              this.snackBar.open(message as string, '', { duration: 2000 });
              break;
            }
            case ERROR_WALLET_NOT_FOUND: {
              const message = await this.label.getLabel(
                this.translateService,
                ERROR_WALLET_NOT_FOUND
              );
              this.snackBar.open(message as string, '', { duration: 2000 });
              break;
            }
            case ERROR_BANK_ACCOUNT_NOT_FOUND: {
              const message = await this.label.getLabel(
                this.translateService,
                ERROR_BANK_ACCOUNT_NOT_FOUND
              );
              this.snackBar.open(message as string, '', { duration: 2000 });
              break;
            }
            default: {
              const message = await this.label.getLabel(
                this.translateService,
                ERROR_MINIMUM_WITHDRAW
              );
              this.snackBar.open(message as string, '', { duration: 2000 });
              break;
            }
          }
        } else {
          const message = await this.label.getLabel(
            this.translateService,
            ERROR_MINIMUM_WITHDRAW
          );
          this.snackBar.open(message as string, '', { duration: 2000 });
        }
        this.dialogRef.close();
      }
    );
  }

  openSuccessDialog(): void {
    const data: DialogInterface = {
      title:
        'Terimakasih telah melakukan kofirmasi Terimakasi telah melakukan penarikan dana',
      content:
        'Penarikan memerlukan proses pengecekan dan akan diproses dalam waktu 1x24jam. Dana mu kan otomatis masuk ke rekening kamu jika transaksi penarikan mu sudah di verifikasi oleh ramerame',
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {
          label: 'ok, terima kasih',
          value: 1,
          className: 'primary-button big-button',
        },
      ],
    };
    const dialogRef = this.dialog.open(DialogScrollComponent, { data });
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate([MEMBER_WALLET_LANDING_PAGE]).then(() => {});
    });
  }

  openInsuficientBalanceDialog(): void {
    const data: DialogInterface = {
      title: 'Saldo tidak mencukupi',
      content:
        'Transaksi penarikan tidak dapat di proses, saldo anda tidak mencukupi',
      buttons: [
        { label: 'Oke', value: 1, className: 'primary-button big-button' },
      ],
    };
    this.dialog.open(DialogScrollComponent, { data, panelClass: 'error-dialog' });
  }
}
