<div class="main-content">
  <form [formGroup]="ticketFG" (submit)="openTicket()">
    <h4>Buat tiket baru</h4>
    <p>Tiket bantuan digunakan sebagai media komunikasi dua arah antara member dan pihak ramerame.co.id yang direkam ke dalam basis data kami. Tim kami akan membalas tiket yang terbuka dan akan menganalisa terlebih dahulu segala pertanyaan atau pun keluhan dari investor. Notifikasi email akan diberikan ketika terdapat balasan.</p>

    <div class="form-group">
        <label for="questionTitle">Judul pertanyaan</label>
        <input id="questionTitle" formControlName="title" class="form-control" placeholder="Tulis judul pertanyaan disini" aria-describedby="Tulis judul pertanyaan disini"  />
    </div>
    <div class="form-group">
        <label>Layanan terkait</label>
        <ng-select [items]="categories" formControlName="category"
                bindLabel="label"
                autofocus
                placeholder="Layanan terkait"
                bindValue="value"
                class="ng-select"
                >
        </ng-select>
    </div>
    <div class="form-group">
        <label>Prioritas</label>
        <ng-select [items]="priorities" formControlName="priority"
                bindLabel="label"
                autofocus
                placeholder="Prioritas"
                bindValue="value"
                class="ng-select"
                >
        </ng-select>
    </div>
    <div class="form-group">
        <label for="message">Pesan</label>
        <textarea id="message" formControlName="message" class="form-control"  aria-describedby="Pesan" placeholder="Pesan"></textarea>
    </div>
    <div class="form-group">
        <button [disabled]="ticketFG.status != flagValidForm" class="primary-button big-button" mat-button>Buka Tiket</button>
    </div>
  </form>
</div>
