import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {BusinessDetailPageComponent} from './business-detail.page';
import {BusinessListPageComponent} from './business-list.page';
import {AboutUsPageComponent} from './about-us.page';
import {ContactUsPageComponent} from './contact-us.page';
import {FaqPageComponent} from './faq.page';
import {PrivacyPageComponent} from './privacy.page';
import {FrontPageService} from './services/front-page.service';
import {BusinessRegisterPageComponent} from './business-register.page';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {NgSelectModule} from '@ng-select/ng-select';
import {MarkdownModule} from 'ngx-markdown';
import {MaterialModule} from '../../material/material.module';
import {NgxMaskModule} from 'ngx-mask';
import {AboutUsDetailsPageComponent} from './about-us-details.page';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgSelectModule,
    CarouselModule,
    AppSharedModule,
    MarkdownModule.forChild(),
    NgxMaskModule.forRoot(),
    MaterialModule
  ],
  exports: [
    BusinessDetailPageComponent,
    BusinessListPageComponent,
    AboutUsPageComponent,
    ContactUsPageComponent,
    FaqPageComponent,
    PrivacyPageComponent,
    AboutUsDetailsPageComponent
  ],
  declarations: [
    BusinessDetailPageComponent,
    BusinessListPageComponent,
    AboutUsPageComponent,
    ContactUsPageComponent,
    FaqPageComponent,
    PrivacyPageComponent,
    BusinessRegisterPageComponent,
    AboutUsDetailsPageComponent
  ],
  providers: [
    FrontPageService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppFrontPageModule {}
