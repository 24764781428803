import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UserAccountInterface, UserBusinessInterface, UserInfoInterface} from '../interfaces/user.interface';
import {PROFILE_BASE_URL} from '../constants/api.constant';

@Injectable({providedIn: 'root'})
export class ProfileService {
  constructor(private http: HttpClient) {
  }
  async basic(profile: UserInfoInterface): Promise<UserInfoInterface> {
    const payload = {
      phone: profile.phoneNo,
      sex: profile.sexType,
      bornDate: profile.bornDate,
      provinceId: profile.provinceId,
      districtId: profile.districtId,
      subDistrictId: profile.subDistrictId,
      villageId: profile.villageId,
      zipcode: profile.zipcode,
      address: profile.address
    };
    return this.http.post<UserInfoInterface>(environment.url + PROFILE_BASE_URL + '/basic', payload, {}).toPromise();
  }
  async bankAccount(profile: UserAccountInterface): Promise<UserAccountInterface> {
    const payload = {
      bankId: profile.bankId,
      bankBranch: profile.bankBranch,
      accountOwner: profile.accountOwner,
      accountNumbers: profile.accountNumbers
    };
    return this.http.post<UserAccountInterface>(environment.url + PROFILE_BASE_URL + '/bank-account', payload, {}).toPromise();
  }
  async business(profile: UserBusinessInterface): Promise<UserBusinessInterface> {
    const payload = {
      identityType: profile.identityType,
      identityNumbers: profile.identityNo,
      npwpNumbers: profile.npwpNo,
      capitalValue: profile.capitalValue
    };
    return this.http.post<UserBusinessInterface>(environment.url + PROFILE_BASE_URL + '/business', payload, {}).toPromise();
  }
  async businessFiles(formData: FormData): Promise<any> {
    const httpOptions = {
    };
    return this.http.post<any>(environment.url + PROFILE_BASE_URL + '/business-files', formData, httpOptions).toPromise();
  }
}
