import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE,
  MEMBER_MY_BUSINESS_PAGE
} from 'src/app/constants/route.constant';
import {MONTHLY_REPORT_GRAPH_CONFIG} from '../../../constants/monthly-report.constant';
import {ProductService} from '../../../services/product.service';
import {InvestmentService} from '../../../services/investment.service';
import {MyROIInterface, ProductInterface} from '../../../interfaces/product.interface';
import {DailySalesInterface} from '../../../interfaces/investment.interface';

@Component({
  selector: 'app-page-my-business-detail',
  templateUrl: './detail-business.page.html'
})
export class MemberBusinessDetailPageComponent extends BaseComponent implements OnInit{
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE = MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_PAGE = MEMBER_MY_BUSINESS_DETAIL_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE = MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE = MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE;
  public multi = [
    {
      name: '',
      series: []
    }
  ];
  public graphConfig = MONTHLY_REPORT_GRAPH_CONFIG;
  public id: number;
  public pageActive = 0;
  public myROI: MyROIInterface[] = [];
  public myDailySales: DailySalesInterface[] = [];
  public product: ProductInterface = {};
  public total = 0;
  public totalDailySales = 0;
  public currentYear = this.date.getCurrentYear();
  public currentMonth = this.date.getCurrentMonth();
  constructor(
    injector: Injector,
    private investmentService: InvestmentService,
    private productService: ProductService,
  ) {
    super(injector);
    this.id = parseInt(this.activatedRoute.snapshot.params.id, 10);
    console.log(this.id);
    this.pageActive = (this.activatedRoute.snapshot.queryParams.page == 'monthly')? 1 : 0;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.investmentService.findOneInvestmentDetails(this.id).then((res) => {
      this.myROI = res;
      this.productService.getOneProductAfterLoggedIn(this.id).then((product) => {
        this.product = product;
        this.multi = [{
            name: '',
            series: []
        }];
        this.total = 0;
        this.myROI.forEach((item) => {
          this.multi[0].series.push({
            name: this.date.localeMonthFormat(item.periodMonth).short_id + ' ' + item.periodYear,
            value: item.amount
          });
          this.total += (Number(item.nettAmount));
        });
      }, () => {
        this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
      });
    }, () => {
      this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
    });
    this.investmentService.findOneInvestmentDailySales(this.id, this.date.getCurrentMonth() + 1, this.date.getCurrentYear()).then((res) => {
      this.myDailySales = res;
      this.totalDailySales = 0;
      this.myDailySales.forEach((item) => {
        this.totalDailySales += Number(item.salesAmount);
      });
    });
  }
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
