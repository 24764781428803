import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './pages/landing/landing.page';
import { BusinessListPageComponent } from './pages/front-pages/business-list.page';
import { BusinessDetailPageComponent } from './pages/front-pages/business-detail.page';
import { AboutUsPageComponent } from './pages/front-pages/about-us.page';
import { PrivacyPageComponent } from './pages/front-pages/privacy.page';
import { ContactUsPageComponent } from './pages/front-pages/contact-us.page';
import { FaqPageComponent } from './pages/front-pages/faq.page';
import { AppCustomPreloader } from './preloader.class';
import { MainComponent } from './layout/main/main.component';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { RegisterWelcomePageComponent } from './pages/register/register-welcome.page';
import { MemberInfoPageComponent } from './pages/member/profile/member-info.page';
import { MemberNotificationPageComponent } from './pages/member/notification/member-notification.page';
import { MemberCartPageComponent } from './pages/member/cart/member-cart.page';
import { MemberCartConfirmPageComponent } from './pages/member/cart/member-cart-confirm.page';
import { AuthGuard } from './auth/auth-guard.service';
import {
  ABOUT_US_DETAIL_PAGE,
  ABOUT_US_PAGE,
  BUSINESS_DETAIL_PAGE,
  BUSINESS_LIST_PAGE,
  BUSINESS_REGISTER_PAGE,
  CONTACT_US_PAGE,
  FAQ_PAGE,
  MEMBER_ACCOUNT_CHANGE_PASSWORD_PAGE,
  MEMBER_ACCOUNT_PANEL_PAGE,
  MEMBER_ACCOUNT_VERIFICATION_PAGE,
  MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE,
  MEMBER_BANK_ACCOUNT_PAGE,
  MEMBER_CARD_COMING_SOON,
  MEMBER_CARD_PAGE,
  MEMBER_CARD_SEARCH,
  MEMBER_CARD_USE_VOUCHER,
  MEMBER_CART_CONFIRM_PAGE,
  MEMBER_CART_PAGE,
  MEMBER_MEMBER_INFO_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE,
  MEMBER_MY_BUSINESS_PAGE,
  MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE,
  MEMBER_NOT_COMPLETE_REDIRECT_PAGE,
  MEMBER_NOTIFICATION_PAGE,
  MEMBER_REDEEM_HISTORY_PAGE,
  MEMBER_REWARDS_PAGE,
  MEMBER_TICKET_DETAILS_PAGE,
  MEMBER_TICKET_FORM_PAGE,
  MEMBER_TICKETS_PAGE,
  MEMBER_WALLET_LANDING_PAGE,
  MEMBER_WALLET_WITHDRAW_PAGE,
  MERCHANDISE_ALL_CATEGORIES,
  MERCHANDISE_OUTLET_QR_SCAN_SUCCESS,
  MERCHANDISE_OUTLET_VOUCHER_HISTORY,
  MERCHANDISE_USE_VOUCHER,
  MERCHANT_OUTLET_PAGE,
  MERCHANT_OUTLET_QR_SCAN,
  MERCHANT_OUTLET_QR_SCAN_SUCCESS,
  MERCHANT_OUTLET_VOUCHER_HISTORY,
  PRIVACY_PAGE,
  REGISTER_CONFIRMATION_PAGE,
  REGISTER_WELCOME_PAGE,
  SOCIAL_AUTH_PAGE,
  MEMBER_VOUCHER_CLAIM_PAGE,
  MEMBER_CARD_VOUCHER_DETAIL,
  MEMBER_CARD_VOUCHER_HISTORY,
  MEMBER_CARD_VOUCHER_PAGE,
  MEMBER_CARD_ALL_CAMPAIGN_PER_CATEGORY,
  MEMBER_CARD_INFO,
} from './constants/route.constant';
import { MemberAccountPanelPageComponent } from './pages/member/profile/member-account-panel.page';
import { MemberChangePasswordPageComponent } from './pages/member/security/member-change-password.page';
import { MemberAccountVerificationPageComponent } from './pages/member/profile/member-account-verification.page';
import { MemberBankPageComponent } from './pages/member/profile/member-bank.page';
import { MemberTicketLandingPageComponent } from './pages/member/ticket/member-ticket-landing.page';
import { MemberTicketFormPageComponent } from './pages/member/ticket/member-ticket-form.page';
import { MemberWalletPageComponent } from './pages/member/wallet/wallet-landing.page';
import { MemberWalletWithdrawPageComponent } from './pages/member/wallet/wallet-withdraw.page';
import { MemberMyBusinessLandingPageComponent } from './pages/member/my-business/my-business-landing.page';
import { MemberBusinessPaymentConfirmationPageComponent } from './pages/member/my-business/my-business-payment-confirmation.page';
import { MemberBusinessDetailTransactionPageComponent } from './pages/member/my-business/detail-transaction.page';
import { MemberBusinessDetailPageComponent } from './pages/member/my-business/detail-business.page';
import { BusinessRegisterPageComponent } from './pages/front-pages/business-register.page';
import { MemberAccountVerificationStatusPageComponent } from './pages/member/profile/member-account-verification-status.page';
import { MemberBusinessDetailAskAdminPageComponent } from './pages/member/my-business/detail-business-ask-admin.page';
import { MemberBusinessDetailCctvPageComponent } from './pages/member/my-business/detail-business-cctv.page';
import { MemberBusinessDetailTimelinePageComponent } from './pages/member/my-business/detail-business-timeline.page';
import { MemberRewardsComponent } from './pages/member/rewards/rewards.page';
import { MemberRewardsHistoryComponent } from './pages/member/rewards/redeem-history.page';
import { SigninLayoutComponent } from './layout/signin-layout/signin-layout.component';
import { NewLoginPageComponent } from './pages/login/new-login.page';
import { NewRegisterPageComponent } from './pages/register/new-register.page';
import { ForgotPasswordPageComponent } from './pages/login/forgot-password.page';
import { MemberTicketDetailsPageComponent } from './pages/member/ticket/member-ticket-details.page';
import { SocialAuthPageComponent } from './pages/auth/social-auth.page';
import { NotCompletePageComponent } from './pages/member/not-complete/not-complete.page';
import { AboutUsDetailsPageComponent } from './pages/front-pages/about-us-details.page';
import { ResetPasswordPageComponent } from './pages/login/reset-password.page';
import { MailSentPageComponent } from './pages/login/mail-sent.page';
import { RegisterConfirmationPageComponent } from './pages/register/register-confirmation.page';
import { MerchantOutletComponent } from './pages/member-card/merchant-outlet/merchant-outlet.page';
import { MerchantOutletQrScanComponent } from './pages/member-card/merchant-outlet/merchant-outlet-qr-scan';
import { QrScanSuccessComponent } from './pages/member-card/merchant-outlet/qr-scan-success';
import { MerchantOutletVoucherHistoryComponent } from './pages/member-card/merchant-outlet/merchant-outlet-voucher-history';
import { UseVoucherComponent } from './pages/member-card/my-voucher/use-voucher.page';
import { AuthGuardMerchant } from './auth/auth-guard-merchant.service';
import { MemberCardComingSoonPage } from './pages/member-card/member-card-coming-soon.page';
import { MerchandiseComponent } from './pages/member-card/point-merchandise/merchandise.page';
import { MerchandiseDetailsComponent } from './pages/member-card/point-merchandise/details/merchandise-details.page';
import { UseMerchandiseVoucherComponent } from './pages/member-card/point-merchandise/details/use-merchandise-voucher.page';
import { VoucherSearchComponent } from './pages/member-card/voucher-search/voucher-search.page';
import { QrScanSuccessMerchandiseComponent } from './pages/member-card/merchant-outlet/qr-scan-success-merchandise';
import { MerchandiseOutletVoucherHistoryComponent } from './pages/member-card/merchant-outlet/merchandise-outlet-voucher-history';
import { VoucherPageComponent } from './pages/member/voucher/voucher.page';
import { VoucherDetailPageComponent } from './pages/member-card/voucher-detail/voucher-detail.page';
import { VoucherHistoryComponent } from './pages/member-card/voucher-history/voucher-history.page';
import { MyVoucherComponent } from './pages/member-card/my-voucher/my-voucher.page';
import { CampaignCategoryComponent } from './pages/member-card/campaign-per-category/campaign-category.page';
import { MemberCardInfoComponent } from './pages/member-card/info/member-card-info.page';
import { MemberCardComponent } from './pages/member-card/member-card.page';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
        pathMatch: 'full',
        data: { state: 'home' },
      },
      {
        path: BUSINESS_DETAIL_PAGE + '/:id',
        component: BusinessDetailPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'project',
          hasBackButton: true,
          backTitle: 'menu.business-list',
          backUrl: BUSINESS_LIST_PAGE,
        },
      },
      {
        path: BUSINESS_LIST_PAGE,
        component: BusinessListPageComponent,
        canActivate: [AuthGuard],
        data: { state: 'project' },
      },
      {
        path: CONTACT_US_PAGE,
        component: ContactUsPageComponent,
        data: { state: 'contact-us' },
      },
      {
        path: ABOUT_US_PAGE,
        component: AboutUsPageComponent,
        data: { state: 'about-us' },
      },
      {
        path: ABOUT_US_DETAIL_PAGE + '/:id',
        component: AboutUsDetailsPageComponent,
        data: {
          state: 'about-us',
          hasBackButton: true,
          backTitle: 'Team Detail',
          backUrl: ABOUT_US_PAGE,
        },
      },
      {
        path: PRIVACY_PAGE,
        component: PrivacyPageComponent,
        data: { state: 'privacy' },
      },
      {
        path: FAQ_PAGE,
        component: FaqPageComponent,
        canActivate: [AuthGuard],
        data: { state: 'faq' },
      },
      {
        path: BUSINESS_REGISTER_PAGE,
        component: BusinessRegisterPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'Daftarkan Usaha',
          backUrl: '',
        },
      },
      {
        path: MEMBER_WALLET_LANDING_PAGE,
        component: MemberWalletPageComponent,
        canActivate: [AuthGuard],
        data: { state: 'wallet' },
      },
      {
        path: MEMBER_MEMBER_INFO_PAGE,
        component: MemberInfoPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.profile-personal',
          backUrl: MEMBER_ACCOUNT_PANEL_PAGE,
        },
      },
      {
        path: MEMBER_REWARDS_PAGE,
        component: MemberRewardsComponent,
        canActivate: [AuthGuard],
        data: { state: 'rewards' },
      },
      {
        path: MEMBER_REDEEM_HISTORY_PAGE,
        component: MemberRewardsHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'rewards',
          hasBackButton: true,
          backTitle: 'back-title.reward-landing',
          backUrl: MEMBER_REWARDS_PAGE,
        },
      },
      {
        path: MEMBER_NOTIFICATION_PAGE,
        component: MemberNotificationPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: '',
          hasBackButton: true,
          backTitle: 'back-title.notification',
          backUrl: '',
        },
      },
      {
        path: MEMBER_CART_PAGE,
        component: MemberCartPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: '',
          hasBackButton: true,
          backTitle: 'back-title.shopping-cart',
          backUrl: BUSINESS_LIST_PAGE,
        },
      },
      {
        path: MEMBER_CART_CONFIRM_PAGE,
        component: MemberCartConfirmPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: '',
          hasBackButton: true,
          backTitle: 'back-title.checkout',
          backUrl: MEMBER_CART_PAGE,
        },
      },
      {
        path: MEMBER_ACCOUNT_PANEL_PAGE,
        component: MemberAccountPanelPageComponent,
        canActivate: [AuthGuard],
        data: { state: 'account' },
      },
      {
        path: MEMBER_ACCOUNT_CHANGE_PASSWORD_PAGE,
        component: MemberChangePasswordPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.security',
          backUrl: MEMBER_ACCOUNT_PANEL_PAGE,
        },
      },
      {
        path: MEMBER_ACCOUNT_VERIFICATION_PAGE,
        component: MemberAccountVerificationPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.profile-business',
          backUrl: MEMBER_ACCOUNT_PANEL_PAGE,
        },
      },
      {
        path: MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE,
        component: MemberAccountVerificationStatusPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.profile-business',
          backUrl: MEMBER_ACCOUNT_PANEL_PAGE,
        },
      },
      {
        path: MEMBER_BANK_ACCOUNT_PAGE,
        component: MemberBankPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.profile-bank',
          backUrl: MEMBER_ACCOUNT_PANEL_PAGE,
        },
      },
      {
        path: MEMBER_TICKETS_PAGE,
        component: MemberTicketLandingPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.tickets',
          backUrl: MEMBER_ACCOUNT_PANEL_PAGE,
        },
      },
      {
        path: MEMBER_TICKET_DETAILS_PAGE + ':id',
        component: MemberTicketDetailsPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.tickets',
          backUrl: MEMBER_TICKETS_PAGE,
        },
      },
      {
        path: MEMBER_TICKET_FORM_PAGE,
        component: MemberTicketFormPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'account',
          hasBackButton: true,
          backTitle: 'back-title.tickets',
          backUrl: MEMBER_TICKETS_PAGE,
        },
      },
      {
        path: MEMBER_WALLET_WITHDRAW_PAGE,
        component: MemberWalletWithdrawPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'wallet',
          hasBackButton: true,
          backTitle: 'back-title.withdrawal',
          backUrl: MEMBER_WALLET_LANDING_PAGE,
        },
      },
      {
        path: MEMBER_MY_BUSINESS_PAGE,
        component: MemberMyBusinessLandingPageComponent,
        canActivate: [AuthGuard],
        data: { state: 'business-list' },
      },
      {
        path: MEMBER_NOT_COMPLETE_REDIRECT_PAGE,
        component: NotCompletePageComponent,
        canActivate: [AuthGuard],
        data: { state: 'not-complete' },
      },
      {
        path: MEMBER_MY_BUSINESS_PAYMENT_CONFIRMATION_PAGE + '/:id',
        component: MemberBusinessPaymentConfirmationPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'business-list',
          hasBackButton: true,
          backTitle: 'back-title.my-investment',
          backUrl: MEMBER_MY_BUSINESS_PAGE,
        },
      },
      {
        path: MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE + '/:id',
        component: MemberBusinessDetailTransactionPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'business-list',
          hasBackButton: true,
          backTitle: 'back-title.my-investment',
          backUrl: MEMBER_MY_BUSINESS_PAGE,
        },
      },
      {
        path: MEMBER_MY_BUSINESS_DETAIL_PAGE + '/:id',
        component: MemberBusinessDetailPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'business-list',
          hasBackButton: true,
          backTitle: 'back-title.my-investment-details',
          backUrl: MEMBER_MY_BUSINESS_PAGE,
        },
      },
      {
        path: MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE + '/:id',
        component: MemberBusinessDetailAskAdminPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'business-list',
          hasBackButton: true,
          backTitle: 'back-title.my-investment-details',
          backUrl: MEMBER_MY_BUSINESS_PAGE,
        },
      },
      {
        path: MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE + '/:id',
        component: MemberBusinessDetailCctvPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'business-list',
          hasBackButton: true,
          backTitle: 'back-title.my-investment-details',
          backUrl: MEMBER_MY_BUSINESS_PAGE,
        },
      },
      {
        path: MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE + '/:id',
        component: MemberBusinessDetailTimelinePageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'business-list',
          hasBackButton: true,
          backTitle: 'back-title.my-investment-details',
          backUrl: MEMBER_MY_BUSINESS_PAGE,
        },
      },
      /*
      {path: MEMBER_CARD_COMING_SOON, component: MemberCardComingSoonPage,
        data: {state: 'member-card'}
      },

       */

      {
        path: MEMBER_VOUCHER_CLAIM_PAGE,
        component: VoucherPageComponent,
        canActivate: [AuthGuard],
        data: { state: 'voucher', hasBackButton: false },
      },

      {
        path: MEMBER_CARD_PAGE,
        component: MemberCardComponent,
        data: { state: 'member-card' },
      },
      {
        path: MEMBER_CARD_INFO,
        component: MemberCardInfoComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.member-info',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MEMBER_CARD_ALL_CAMPAIGN_PER_CATEGORY + '/:id',
        component: CampaignCategoryComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.baverage',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MEMBER_CARD_VOUCHER_PAGE + '/:id',
        component: MyVoucherComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.my-voucher',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MEMBER_CARD_VOUCHER_HISTORY + '/:id',
        component: VoucherHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.transaction-history',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MEMBER_CARD_VOUCHER_DETAIL + '/:id/:action',
        component: VoucherDetailPageComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.voucher-detail',
          backUrl: MEMBER_CARD_PAGE,
        },
      },

      {
        path: MERCHANT_OUTLET_PAGE,
        component: MerchantOutletComponent,
        canActivate: [AuthGuardMerchant],
        data: { state: 'merchant-outlet' },
      },
      {
        path: MERCHANT_OUTLET_QR_SCAN,
        component: MerchantOutletQrScanComponent,
        canActivate: [AuthGuardMerchant],
        data: {
          state: 'merchant-outlet',
          hasBackButton: true,
          backTitle: 'back-title.qr-scan',
          backUrl: MERCHANT_OUTLET_PAGE,
        },
      },
      {
        path: MERCHANT_OUTLET_QR_SCAN_SUCCESS + '/:id',
        component: QrScanSuccessComponent,
        canActivate: [AuthGuardMerchant],
        data: {
          state: 'merchant-outlet',
          hasBackButton: true,
          backTitle: 'back-title.qr-scan',
          backUrl: MERCHANT_OUTLET_QR_SCAN,
        },
      },
      {
        path: MERCHANDISE_OUTLET_QR_SCAN_SUCCESS + '/:id',
        component: QrScanSuccessMerchandiseComponent,
        canActivate: [AuthGuardMerchant],
        data: {
          state: 'merchant-outlet',
          hasBackButton: true,
          backTitle: 'back-title.qr-scan',
          backUrl: MERCHANT_OUTLET_QR_SCAN,
        },
      },
      {
        path: MERCHANT_OUTLET_VOUCHER_HISTORY + '/:id',
        component: MerchantOutletVoucherHistoryComponent,
        canActivate: [AuthGuardMerchant],
        data: {
          state: 'merchant-outlet',
          hasBackButton: true,
          backTitle: 'back-title.details-campaign',
          backUrl: MERCHANT_OUTLET_PAGE,
        },
      },
      {
        path: MERCHANDISE_OUTLET_VOUCHER_HISTORY + '/:id',
        component: MerchandiseOutletVoucherHistoryComponent,
        canActivate: [AuthGuardMerchant],
        data: {
          state: 'merchant-outlet',
          hasBackButton: true,
          backTitle: 'back-title.details-campaign',
          backUrl: MERCHANT_OUTLET_PAGE,
        },
      },
      {
        path: MEMBER_CARD_USE_VOUCHER + '/:id',
        component: UseVoucherComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.use-voucher',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MERCHANDISE_USE_VOUCHER + '/:id',
        component: UseMerchandiseVoucherComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.use-voucher',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MERCHANDISE_ALL_CATEGORIES,
        component: MerchandiseComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'merchandise',
          hasBackButton: true,
          backTitle: 'back-title.point-exchange',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
      {
        path: MERCHANDISE_ALL_CATEGORIES + '/:id/:action',
        component: MerchandiseDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.voucher-detail',
          backUrl: MERCHANDISE_ALL_CATEGORIES,
        },
      },
      {
        path: MEMBER_CARD_SEARCH,
        component: VoucherSearchComponent,
        canActivate: [AuthGuard],
        data: {
          state: 'member-card',
          hasBackButton: true,
          backTitle: 'back-title.member-card',
          backUrl: MEMBER_CARD_PAGE,
        },
      },
    ],
  },
  {
    path: '',
    component: SigninLayoutComponent,
    children: [
      {
        path: 'sign-in',
        component: NewLoginPageComponent,
        pathMatch: 'full',
        data: { state: 'sign-in' },
      },
      // {path: 'sign-up', component: NewRegisterPageComponent, pathMatch: 'full',
      //   data: {state: 'sign-up'}
      // },
      {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent,
        pathMatch: 'full',
        data: { state: 'forgot-password' },
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordPageComponent,
        pathMatch: 'full',
        data: { state: 'reset-password' },
      },
      {
        path: 'mail-sent',
        component: MailSentPageComponent,
        pathMatch: 'full',
        data: { state: 'forgot-password' },
      },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: REGISTER_CONFIRMATION_PAGE,
        component: RegisterConfirmationPageComponent,
        pathMatch: 'full',
        data: { state: 'sign-up' },
      },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: REGISTER_WELCOME_PAGE + '/:token',
        component: RegisterWelcomePageComponent,
        pathMatch: 'full',
        data: { state: 'sign-up' },
      },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: SOCIAL_AUTH_PAGE + '/:token',
        component: SocialAuthPageComponent,
        pathMatch: 'full',
        data: { state: 'social-auth' },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [AppCustomPreloader],
})
export class AppRoutingModule {}
