<div class="main-content  full-screen notifications-panel">
    <div class="notifications">
        <div class="notification new" *ngFor="let n of notifications">
            <div class="icon">
                <span class="icon-exclamation" *ngIf="n.category == 'INFO'"></span>
                <span class="icon-akun" *ngIf="n.category == 'PROFILE'"></span>
                <span class="icon-dompet" *ngIf="n.category == 'WALLET'"></span>
                <span class="icon-ask-admin" *ngIf="n.category == 'ASK'"></span>
                <span class="icon-ticket" *ngIf="n.category == 'TICKET'"></span>
                <span class="icon-cart" *ngIf="n.category == 'CART'"></span>
                <span class="icon-reward" *ngIf="n.category == 'REWARD'"></span>
                <span class="text-grey">{{date.localeDateFromTs(n.createdAt, locale)}}</span>
            </div>
            <h6 class="mt-3">{{n.title}}</h6>
            <p class="content">{{n.content}}</p>
        </div>

    </div>

</div>
