<ng-container >
  <mat-tab-group>
    <mat-tab label="{{'common.cash' | translate}}">
      <div class="my-voucher" *ngFor="let voucher of campaignVouchers">
        <div class="card-body p-2" (click)="doNavigateVoucherDetail(voucher.id)">
          <div class="row no-gutters align-items-center c-body-detail">
            <div class="col-4 col-md-4 p-2">
              <img alt="" [src]="baseUrl + voucher.imagePath + '?rand=123'" class="left-image"/>
            </div>
            <div class="col-8 col-md-8 p-2">
                <p class="title-merchant">{{voucher.merchantName}}</p>
                <p class="title-campaign">{{voucher.campaignName}}</p>
              <div class="d-inline-flex">
                <p *ngIf="voucher.redeemWith == 'POINT' else contentcash" class="title-expired">{{'pages.member-card.expired-until' | translate}} {{date.dateTimeToLocaleDate(voucher.endDate, locale)}}</p>
                <ng-template #contentcash>
                  <p class="title-expired mr-2">{{'pages.member-card.expired-until' | translate}}</p>
                  <app-countdown-pipe-component [withLabel]="true" [startDate]="date.newDateFromStringFormat(voucher.dateTimeNow)" [endDate]="date.newDateFromStringFormat(voucher.expiredDate)"></app-countdown-pipe-component>
                </ng-template>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'common.point' | translate}}">
      <div class="my-voucher" *ngFor="let m of merchandiseVouchers">
        <div class="card-body p-2" (click)="doNavigateMerchantDetail(m.id)">
          <div class="row no-gutters align-items-center c-body-detail">
            <div class="col-4 col-md-4 p-2">
              <img alt="" [src]="baseUrl + m.imagePath + '?rand=123'" class="left-image"/>
            </div>
            <div class="col-8 col-md-8 p-2">
              <p class="title-merchant">{{m.merchantName}}</p>
              <p class="title-campaign">{{m.merchandiseName}}</p>
              <div class="d-inline-flex">
                  <p class="title-expired mr-2">{{'pages.member-card.expired-until' | translate}}</p>
                  <app-countdown-pipe-component [withLabel]="true" [startDate]="date.newDateFromStringFormat(m.dateTimeNow)" [endDate]="date.newDateFromStringFormat(m.expiredDate)"></app-countdown-pipe-component>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</ng-container>

<ng-container *ngIf="campaignVouchers && campaignVouchers.length == 0">
  <div class="row no-gutters align-items-center pt-5">
    <img alt="" src="assets/images/member/alert-image4-min.png" class="img-fluid" />
    <h4 class="text-grey text-weight-bold mt-2">Maaf saat ini anda tidak memiliki voucher</h4>
  </div>
</ng-container>

