import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AUTH_BASE_URL, FORGOT_PASSWORD_BASE_URL} from '../constants/api.constant';
import {TokenInterface} from '../interfaces/token.interface';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private http: HttpClient) {
  }
  getStatusLogin(): boolean {
      return true;
  }
  async verify(username: string, password: string): Promise<TokenInterface>{
    const payload = {email: username, password};
    console.log(environment.url);
    return await this.http.post<TokenInterface>(environment.url + AUTH_BASE_URL, payload , {}).toPromise();
  }
  async forgot(email: string): Promise<any>{
    return await this.http.post<any>(environment.url + FORGOT_PASSWORD_BASE_URL, {email}, {}).toPromise();
  }
}
