import {AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, PERIODE_DATE, STORAGE_LANG_KEY, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../../services/login.service';
import {NavService} from '../../nav/nav.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UiHelper} from '../../helpers/ui.helper';
import {MAIN_LANDING_PAGE, MEMBER_CART_PAGE, MEMBER_NOTIFICATION_PAGE} from '../../constants/route.constant';
import {NavDataInterface} from '../../interfaces/nav-data.interface';
import {NavigationEnd, Router} from '@angular/router';
import {UserInterface} from '../../interfaces/user.interface';
import {FileService} from '../../services/file.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidenavComponent implements OnInit, AfterViewChecked {
  public languages = AVAILABLE_LANGUAGES;
  public isExpanded: boolean;
  public currentLang = DEFAULT_LANGUAGE;
  public showMultiLangOpt = false;
  public isLogin: boolean;
  public ui = UiHelper;
  public cartUrl = '';
  public notificationUrl = '';
  public showBackButton = false;
  public navData: NavDataInterface = {state: ''};
  public toggleButton = false;
  public mainPage = MAIN_LANDING_PAGE;
  public isMerchant = false;
  public merchantId : number;
  public reportUrl = '';
  constructor(
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private loginService: LoginService,
    private navService: NavService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private fileManager: FileService
  ) {
    this.isExpanded = false;
    const lang = this.languages.find((row) => row.langCode === localStorage.getItem(STORAGE_LANG_KEY));
    this.currentLang = (lang) ? lang.langValue : this.currentLang;
    this.isLogin = false;
  }
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    if(localStorage.getItem(STORAGE_PROFILE_KEY)) {
      this.isMerchant = !!(JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional;
    }

    this.merchantId = this.isMerchant ? (JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional.merchantId : 0;

    this.cartUrl = this.ui.absoluteUrl(MEMBER_CART_PAGE);
    this.notificationUrl = this.ui.absoluteUrl(MEMBER_NOTIFICATION_PAGE);
    this.isLogin = !this.jwtHelper.isTokenExpired();
    this.navService.currentMessage.subscribe((res: NavDataInterface) => {
      this.isExpanded = false;
      this.navData = res;
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  changeLanguage(langCode: string): void {
    localStorage.setItem(STORAGE_LANG_KEY, langCode);
    this.translate.setDefaultLang(langCode.toLocaleLowerCase());
    this.translate.use(langCode.toLocaleLowerCase());
    const lang = this.languages.find((row) => row.langCode === langCode);
    this.currentLang = lang.langValue;
  }
  @HostListener('click', ['$event.target'])
  checkouts(el: any): void {
    if (el.id !== 'toggle-icon' && el.id !== 'btn-toggle-icon' && el.id !== 'btn-toggle-icon-logged' && el.id !== 'toggle-icon-logged') {
      if (this.isExpanded) { this.isExpanded = false; }
    }
  }
  downloadReport() {
    let periodeDate = JSON.parse(localStorage.getItem(PERIODE_DATE));
    this.fileManager.downloadVoucherReport(this.merchantId, periodeDate.startDate, periodeDate.endDate).then((file) => {
      this.ui.downloadFile(file, 'campaign-voucher-'+this.merchantId.toString()+'.xls');
    });
  }
}
