<div class="close-button">
  <button class="clear-transparent" mat-dialog-close>
    <span class="icon-close"></span>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="well mb-3 text-center">
    <h3>{{ "pages.login.disabled-register-title" | translate }}</h3>
    <p>
      {{ "pages.login.disabled-register-description" | translate }}
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container>
    <a
      [href]="KSP_PERTIWI_URL"
      target="_blank"
      rel="noopener noreferrer"
      style="width: 100%"
    >
      <button cdkFocusInitial mat-button class="primary-button big-button mb-2">
        {{ "pages.wallet.registered-popup-button" | translate }}
      </button>
    </a>
  </ng-container>
</mat-dialog-actions>
