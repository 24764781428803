import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {REGISTER_BASE_URL, REGISTER_TOKEN_VERIFY_BASE_URL} from '../constants/api.constant';
import {TokenInterface} from '../interfaces/token.interface';
import {RegisterInterface} from '../interfaces/register.interface';

@Injectable({providedIn: 'root'})
export class RegisterService {
  constructor(private http: HttpClient) {
  }
  async register(payload: RegisterInterface): Promise<TokenInterface>{
    return await this.http.post<TokenInterface>(environment.url + REGISTER_BASE_URL, payload , {}).toPromise();
  }
  async verify(token: string): Promise<any>{
    return await this.http.post<any>(environment.url + REGISTER_TOKEN_VERIFY_BASE_URL, {token}, {}).toPromise();
  }
}
