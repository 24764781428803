import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_ACCOUNT_PANEL_PAGE, MEMBER_TICKETS_PAGE} from 'src/app/constants/route.constant';
import {FORM_GROUP_VALID, SUPPORT_TICKET_CATEGORIES, SUPPORT_TICKET_PRIORITIES} from '../../../constants/common.constant';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TicketService} from '../../../services/ticket.service';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {MatDialog} from '@angular/material/dialog';
import {ERROR_INCOMPLETE_INPUT} from '../../../constants/error.constant';
import {DialogScrollComponent} from 'src/app/shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-member-ticket-form',
  templateUrl: './member-ticket-form.page.html'
})
export class MemberTicketFormPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_PANEL_PAGE = MEMBER_ACCOUNT_PANEL_PAGE;
  public categories = SUPPORT_TICKET_CATEGORIES;
  public priorities = SUPPORT_TICKET_PRIORITIES;
  public ticketFG: UntypedFormGroup;
  public flagValidForm = FORM_GROUP_VALID;
  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private ticketService: TicketService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.ticketFG = this.formBuilder.group({
      title: [null, [Validators.required]],
      category: [null, [Validators.required]],
      priority: [null, [Validators.required]],
      message: [null, [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  openTicket(): void {
    this.ticketService.openSupportTicket(
      this.ticketFG.controls.title.value,
      this.ticketFG.controls.category.value,
      this.ticketFG.controls.priority.value,
      this.ticketFG.controls.message.value).then((res) => {
        this.openSuccessDialog();
    }, () => {
      this.label.getLabel(this.translateService,  ERROR_INCOMPLETE_INPUT).then((msg) => {
        this.snackBar.open(msg as string, '', {duration: 2000});
      });
    });
  }
  openSuccessDialog(): void {
    const data: DialogInterface = {
      title : 'Tiket Bantuan Anda Berhasil Disimpan',
      content: 'Kami akan segera membalas pesan anda, notifikasi balasan akan dikirim ke email anda dalam waktu 1 x 24 jam pada hari kerja dan jam kerja',
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {label: 'ok, terima kasih', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialogRef = this.dialog.open(DialogScrollComponent, {data});
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([MEMBER_TICKETS_PAGE]).then(() => {});
    });
  }
}
