import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarkdownModule } from 'ngx-markdown';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardMerchant } from './auth/auth-guard-merchant.service';
import { AuthGuard } from './auth/auth-guard.service';
import { Auth } from './auth/auth.service';
import { AppLayoutModule } from './layout/layout.module';
import { NavService } from './nav/nav.service';
import { AppPageModule } from './pages/page.module';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter(): string {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [
          'ramerametwo.test',
          'ramerame.co.id',
          'office.mardika.com',
          'mardika.com',
          'office.ramerame.co.id',
          'localhost:8000',
          'localhost',
          'rame2.ganeshcomdemo.com',
          'rame2-api.ganeshcomdemo.com',
          'ramerame.co.id',
          'web.ramerame.co.id',
        ],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgProgressModule.withConfig({
      min: 20,
      meteor: true,
      color: '#ffa726',
    }),
    NgProgressHttpModule,
    MarkdownModule.forRoot(),
    AppLayoutModule,
    AppPageModule,
  ],
  providers: [NavService, Auth, AuthGuard, AuthGuardMerchant],
  bootstrap: [AppComponent],
})
export class AppModule {}
