import {AppRoutingModule} from '../../../app-routing.module';
import {NgProgressModule} from 'ngx-progressbar';
import {CommonModule} from '@angular/common';
import {AppSharedModule} from '../../../shared/shared.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MaterialModule} from '../../../material/material.module';
import {MemberInvestmentMonthlyReportPageComponent} from './investment-montly-report.page';
import {MemberInvestmentDailyReportPageComponent} from './investment-daily-report.page';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgSelectModule,
    NgxChartsModule,
    AppSharedModule,
    MaterialModule
  ],
  exports: [
    MemberInvestmentDailyReportPageComponent,
    MemberInvestmentMonthlyReportPageComponent
  ],
  declarations: [
    MemberInvestmentDailyReportPageComponent,
    MemberInvestmentMonthlyReportPageComponent
  ],
  providers: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMemberReportModule {}
