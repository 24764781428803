import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KSP_PERTIWI_URL } from 'src/app/constants/common.constant';

@Component({
  selector: 'app-wallet-dialog-registered',
  templateUrl: './wallet-dialog-registered.component.html',
})
export class WalletDialogRegisteredComponent implements OnInit {
  readonly KSP_PERTIWI_URL = KSP_PERTIWI_URL;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WalletDialogRegisteredComponent>
  ) {}

  ngOnInit(): void {}
}
