<ng-container>
  <form [formGroup]="businessFG">
  <p>{{'pages.profile.verification-note-2' | translate}}</p>
  <div class="form-group">
    <div class="form-group">
      <label>{{'pages.profile.option-identity-type' | translate}}</label>
      <div>{{(businessFG.controls.identityTypeFC.value)? businessFG.controls.identityTypeFC.value : '-'}}</div>
    </div>
  </div>
  <div class="form-group ">
    <label>{{'pages.profile.identity-photo' | translate}}</label>
    <a (click)="viewImage('id')" class="ml-3">{{'common.view-photo' | translate}}</a>
  </div>
  <div class="form-group ">
    <label>{{'pages.profile.selfie' | translate}}</label>
    <a (click)="viewImage('user-and-id')" class="ml-3">{{'common.view-photo' | translate}}</a>
  </div>
  <div class="form-group">
    <label>{{'pages.profile.id-number' | translate}}</label>
    <div>{{(businessFG.controls.identityNumbersFC.value)? businessFG.controls.identityNumbersFC.value : '-'}}</div>
  </div>
  <div class="form-group">
    <label>{{'pages.profile.monthly-capital' | translate}}</label>
    <div>{{(businessFG.controls.capitalValueFC.value)? businessFG.controls.capitalValueFC.value : '-'}}</div>
  </div>

  <div class="form-group">
    <label>{{'pages.profile.npwp' | translate}}</label>
    <div>{{(businessFG.controls.npwpFC.value)? businessFG.controls.npwpFC.value : '-'}}</div>
  </div>
  <div class="form-group ">
    <label>{{'pages.profile.photo-npwp' | translate}}</label>
    <a (click)="viewImage('npwp')" class="ml-3">{{'common.view-photo' | translate}}</a>
  </div>
  <div class="form-group">
    <button type="button" class="primary-button big-button" mat-button (click)="showEditor()">{{'common.renew' | translate}}</button>
  </div>
  </form>
</ng-container>
