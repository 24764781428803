import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {ProductInterface} from '../../interfaces/product.interface';
import {UiHelper} from '../../helpers/ui.helper';
import {MEMBER_CART_PAGE, MEMBER_NOT_COMPLETE_REDIRECT_PAGE} from '../../constants/route.constant';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ERR_PROFILE_NOT_COMPLETE} from '../../constants/common.constant';

@Component({
  selector: 'app-slot-buy-dialog-dialog',
  templateUrl: './dialog-tos-slot-buy.component.html',
})
export class TosSlotBuyDialogComponent implements OnInit{
  private ui = UiHelper;
  constructor(
    public dialogRef: MatDialogRef<TosSlotBuyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shoppingCartService: ShoppingCartService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {}
  addToCart(): void {
    const product = this.data.product as ProductInterface;
    this.shoppingCartService.addToCart(product.id).then(() => {
      this.snackBar.open(product.productName +' Telah Ditambahkan ke Keranjang Belanja', '', {duration: 2000});
      this.dialogRef.close();
      this.router.navigate([MEMBER_CART_PAGE]).then(() => {});
    }, (err) => {
      if (err.error.error == ERR_PROFILE_NOT_COMPLETE) {
        this.router.navigate([MEMBER_NOT_COMPLETE_REDIRECT_PAGE]).then(() => {});
      }
    })
  }
}
