import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MERCHANT_OUTLET_VOUCHER_HISTORY} from '../../../constants/route.constant';
import {MembercardService} from '../../../services/membercard.service';
import {VoucherWithCampaignInterface} from '../../../interfaces/merchant-outlet.interface';
import {VoucherDetailsInterface} from '../../../interfaces/member-card.interface';

@Component({
  selector: 'app-scan-my-voucher',
  templateUrl: './qr-scan-success.html',
})
export class QrScanSuccessComponent extends BaseComponent implements OnInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public campaign: VoucherWithCampaignInterface;
  public qrScanFG: UntypedFormGroup;
  public voucherDetail: VoucherDetailsInterface;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private membercardService : MembercardService,
    injector: Injector) {
    super(injector);
    this.qrScanFG = this.formBuilder.group({
      voucherPriceFC: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    const id = this.activatedRoute.snapshot.params.id;
    this.membercardService.getVoucherWithCampaign(id).then((res) => {
      if (res) {
        this.campaign = res[0];
        this.qrScanFG.setValue({
          voucherPriceFC : (this.campaign && this.campaign.voucherPrice) ? this.campaign.voucherPrice :null
        })
      }
    });
    this.qrScanFG.controls.voucherPriceFC.disable({onlySelf: true});
    this.membercardService.getCampaignVoucherDetails(id).then((res) => {
      this.voucherDetail = res;
    });
  }

  submitVoucher() {
    const id = this.activatedRoute.snapshot.params.id;
    this.membercardService.updateClaimCampaignVoucher(id).then((res) => {
      if (res) {
        this.doNavigateSubmitVoucher(this.campaign.campaignId);
      }
    }, err => {
      console.log(err);
    });
  }

  doNavigateSubmitVoucher(id:number): void {
    this.router.navigate(['/' + MERCHANT_OUTLET_VOUCHER_HISTORY + '/' + id]).then(() => {

    });
  }
}
