import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MyVouchersInterface} from '../../../interfaces/member-card.interface';
import {CAMPAIGN_VOUCHER_STATUS, STORAGE_LANG_KEY, VOUCHER_DETAIL_ACTION} from '../../../constants/common.constant';
import {MEMBER_CARD_VOUCHER_DETAIL} from '../../../constants/route.constant';
import {MembercardService} from '../../../services/membercard.service';
import {WalletPointInterface} from '../../../interfaces/wallet-point.interface';

@Component({
  selector: 'app-voucher-history',
  templateUrl: './voucher-history.page.html',
})
export class VoucherHistoryComponent extends BaseComponent implements OnInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public campaignVouchers: MyVouchersInterface[];
  public hoursVoucherParam: number = 1;
  public walletPointsHistories: WalletPointInterface[] = [];

  constructor(injector: Injector,
              private memberCardService: MembercardService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.memberCardService.getVoucherByUserAndIsClaimed("1").then((res) => {
      this.campaignVouchers = res;
    });

    this.query();
  }

  query(): void {
    this.memberCardService.getWalletPointHistory().then((res) => {
      this.walletPointsHistories = res;
    });
  }

  doNavigateVoucherDetail(id, status): void {
    let action = '';
    if(status === CAMPAIGN_VOUCHER_STATUS.EXPIRED){
      action = VOUCHER_DETAIL_ACTION.EXPIRED;
    }else if(status === CAMPAIGN_VOUCHER_STATUS.CLAIMED){
      action = VOUCHER_DETAIL_ACTION.USED;
    }
    this.router.navigate(['/' + MEMBER_CARD_VOUCHER_DETAIL + '/' + id + '/' + action]).then();
  }
}
