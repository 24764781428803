<div class="main-content member-info-panel">
  <div class="millionaire-verified-card" style="margin-top: -50px;" *ngIf="userProfile && userProfile.basic">
    <div class="background">
      <img src="/assets/images/member/gold-member-2x.png"
           *ngIf="userProfile.basic.complete == 'ACCEPTED' && myInvestments.length == 0 && showCard" class="gold"
           alt="millionaire member card"/>
      <img src="/assets/images/member/platinum-member-2x.png"
           *ngIf="userProfile.basic.complete == 'ACCEPTED' && myInvestments.length > 0 && showCard" class="platinum"
           alt="millionaire member card"/>
      <img src="/assets/images/member/silver-member-2x.png" *ngIf="userProfile.basic.complete != 'ACCEPTED'"
           class="silver" alt="millionaire member card"/>
    </div>
    <div class="card-content">
      <div class="card-owner" style="bottom: 32% !important;">
        <h3 class="name" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h3>
        <h4 class="id">ID. {{userProfile.basic.id}}</h4>
      </div>
      <div class="card-point">
        <h3 class="title">{{'pages.member-card.ramerame-point' | translate}}</h3>
        <h3 class="point">{{userPoint}} {{'pages.member-card.point' | translate}}</h3>
      </div>
      <div class="info">
        <i class="icon-information" [routerLink]="ui.absoluteUrl(MEMBER_CARD_INFO)"></i>
      </div>
    </div>
  </div>
  <div class="list-group mt-3 campaign-menu" *ngIf="userProfile && userProfile.basic">
    <a (click)="doNavigateMyVoucher()" class="list-group-item">
      <span class="icon-my-voucher"></span>
      <span class="center">{{'pages.member-card.my-voucher' | translate}}</span>
      <span class="right icon-chevron-right"></span>
    </a>
    <a (click)="doNavigateVoucherHistory()" class="list-group-item">
      <span class="icon-voucher-history"></span>
      <span class="center">{{'pages.member-card.transaction-history' | translate}}</span>
      <span class="right icon-chevron-right"></span>
    </a>
  </div>

  <div class="row d-flex justify-content-center align-items-center category-menu-section">
    <div class="col-3 col-md-3 mb-2 mt-2" [ngStyle]="campaignCategory.isActive == 1 ? {'opacity':100} : {'opacity':0.5}"
         *ngFor="let campaignCategory of campaignCategories">
      <div class="icon-wrapper" (click)="doNavigateMenu(campaignCategory.id, campaignCategory.isActive)">
        <i [className]="campaignCategory.iconName"></i>
        <h6 class="label">{{ campaignCategory.category}}</h6>
      </div>
    </div>
    <!--
    <div class="col-3 col-md-3 mb-2 mt-2" style="opacity: 100" *ngIf="showIconPoint">
      <div class="icon-wrapper" (click)="foo()">
        <i class="icon-icon-points"></i>
        <h6 class="label">{{ 'pages.member-card.point-exchange' | translate}}</h6>
      </div>
    </div>
    -->
  </div>
<!--
  <div class="search-voucher-btn mb-3" (click)="toVoucherSearch()"><i class="icon-location"></i> Cari Lokasi, Voucher dan Kategori</div>
-->
  <div>
    <h6 *ngIf="newOfferingCampaign.length > 0">{{'pages.member-card.interesting-promo' | translate}}</h6>
    <div class="row sliding-campaigns">
      <div class="col-9 col-md-7 col-lg-5" [ngStyle]="{'margin-right': '12px'}"
           *ngFor="let campaign of merchantOfTheDays">
        <div class="card business-infos" (click)="doNavigateVoucherDetail(campaign.id)" style="position: relative">
          <img class="card-img-top" [src]="baseUrl + campaign.imagePath + '?rand=123'">
          <span *ngIf="campaign.isHighlighted" class="highlight-ribbon">{{'pages.member-card.merchant-of-the-day' | translate}}</span>
          <div class="body">
            <p class="title-name">{{campaign.campaignName}}</p>
            <p class="title-merchant">{{campaign.merchantName}}</p>
            <div class="row width-100">
              <div class="col-8">
                <span *ngIf="campaign.voucherPriceBefore" class="title-point-strike mr-1" style="text-decoration: line-through">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPriceBefore, locale)}}</span>
                <span class="title-point">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPrice, locale)}}</span>
              </div>
              <div class="col-4 text-right p-0">
                <span class="point-chip">
                  <i class="icon-coin"></i> {{campaign.numberOfPoint}}  {{ 'pages.member-card.point' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngStyle]="{'margin': '25px 0'}">
    <h6 *ngIf="newOfferingCampaign.length > 0">{{'pages.member-card.new-offering' | translate}}</h6>
    <div class="row sliding-campaigns">
      <div class="col-9 col-md-7 col-lg-5" [ngStyle]="{'margin-right': '12px'}"
           *ngFor="let campaign of newOfferingCampaign">
        <div class="card business-infos" (click)="doNavigateVoucherDetail(campaign.id)" style="position: relative">
          <img class="card-img-top" [src]="baseUrl + campaign.imagePath + '?rand=123'">
          <span *ngIf="campaign.isHighlighted" class="highlight-ribbon">{{'pages.member-card.merchant-of-the-day' | translate}}</span>
          <div class="body">
            <p class="title-name">{{campaign.campaignName}}</p>
            <p class="title-merchant">{{campaign.merchantName}}</p>
            <div class="row width-100">
              <div class="col-8">
                <span *ngIf="campaign.voucherPriceBefore" class="title-point-strike mr-1" style="text-decoration: line-through">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPriceBefore, locale)}}</span>
                <span class="title-point">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPrice, locale)}}</span>
              </div>
              <div class="col-4 text-right p-0">
                <span class="point-chip">
                  <i class="icon-coin"></i> {{campaign.numberOfPoint}}  {{ 'pages.member-card.point' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngStyle]="{'margin': '25px 0'}" *ngFor="let campaignWithCategory of campaignsWithCategories">
    <div class="general-stuff">
      <h6>{{campaignWithCategory.category}}</h6>
      <span class="end-link"
            (click)="doShowAllCampaigns(campaignWithCategory.id, campaignWithCategory.category)"><h6>{{'pages.member-card.show-all' | translate}}</h6></span>
    </div>
    <div class="row sliding-campaigns">
      <div class="col-9 col-md-7 col-lg-5" [ngStyle]="{'margin-right': '12px'}"
           *ngFor="let campaign of campaignWithCategory.campaigns">
        <div class="card business-infos" (click)="doNavigateVoucherDetail(campaign.id)" style="position: relative">
          <img class="card-img-top" [src]="baseUrl + campaign.imagePath + '?rand=123'">
          <span *ngIf="campaign.isHighlighted" class="highlight-ribbon">{{'pages.member-card.merchant-of-the-day' | translate}}</span>
          <div class="body">
            <p class="title-name">{{campaign.campaignName}}</p>
            <p class="title-merchant">{{campaign.merchantName}}</p>
            <div class="row width-100">
              <div class="col-8">
                <span *ngIf="campaign.voucherPriceBefore" class="title-point-strike mr-1" style="text-decoration: line-through">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPriceBefore, locale)}}</span>
                <span class="title-point">{{ 'common.currency-idr' | translate}} {{ui.currencyFormat(campaign.voucherPrice, locale)}}</span>
              </div>
              <div class="col-4 text-right p-0">
                <span class="point-chip">
                  <i class="icon-coin"></i> {{campaign.numberOfPoints}}  {{ 'pages.member-card.point' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
