import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../../app-routing.module';
import {LandingPageComponent} from './landing.page';
import {TranslateModule} from '@ngx-translate/core';
import {LandingService} from '../../services/landing.service';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {AppComponentModule} from '../../components/component.module';
import {MaterialModule} from '../../material/material.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    NgProgressModule,
    CarouselModule,
    AppSharedModule,
    MaterialModule,
    AppComponentModule
  ],
  exports: [
    LandingPageComponent
  ],
  declarations: [
    LandingPageComponent
  ],
  providers: [
    LandingService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLandingModule {}
