export const MONTHLY_REPORT_GRAPH_CONFIG = {
  legend         : false,
  showLabels     : false,
  animations     : true,
  xAxis          : true,
  yAxis          : true,
  showYAxisLabel : false,
  showXAxisLabel : false,
  xAxisLabel     : 'Year',
  yAxisLabel     : 'Return',
  timeline       : true,
  colorScheme    : {
    domain: ['#5AA454']
  }
};
