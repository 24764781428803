import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItemInterface} from '../../interfaces/menu-item.interface';
import {BOTTOM_MENU_ITEMS} from '../../constants/bottom-menu.constant';
import {NavService} from '../../nav/nav.service';
import {UiHelper} from '../../helpers/ui.helper';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottomnav.component.html',
  styleUrls: ['bottomnav.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BottomNavComponent implements OnInit, AfterViewInit{
  public menuBottom: MenuItemInterface[] = BOTTOM_MENU_ITEMS;
  public policies: MenuItemInterface[] = [];
  public state = 'home';
  public loginUrl = '/sign-in';
  public ui = UiHelper;
  public isLogin = false;
  constructor(
    private navService: NavService,
    private jwtHelper: JwtHelperService
  ) {
  }
  ngOnInit(): void {
    this.isLogin = !this.jwtHelper.isTokenExpired();
    this.policies = this.menuBottom;
  }
  ngAfterViewInit(): void {
    this.navService.currentMessage.subscribe((res) => {
      const that = this;
      setTimeout(() => {
        that.state = res.state;
      }, 100);

    });
  }

}


