import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_PAGE,
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE,
  MEMBER_MY_BUSINESS_PAGE
} from 'src/app/constants/route.constant';
import {MemberAskAdminDialogComponent} from '../../dialog/dialog-member-ask-admin.component';
import {MatDialog} from '@angular/material/dialog';
import {ProductInterface} from '../../../interfaces/product.interface';
import {ProductService} from '../../../services/product.service';
import {InvestmentService} from '../../../services/investment.service';
import {TanyaAdminInterface} from '../../../interfaces/tanya-admin.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {DialogDetailsAskAdminComponent} from '../../dialog/dialog-details-ask-admin.component';

@Component({
  selector: 'app-page-my-business-detail-ask-admin',
  templateUrl: './detail-business-ask-admin.page.html'
})
export class MemberBusinessDetailAskAdminPageComponent extends BaseComponent implements OnInit{
  MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE = MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_PAGE = MEMBER_MY_BUSINESS_DETAIL_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE = MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE;
  MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE = MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE;
  public product: ProductInterface = {};
  public tanyaAdmins: TanyaAdminInterface[] = [];
  public id: number;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private productService: ProductService,
    private investmentService: InvestmentService
  ) {
    super(injector);
    this.id = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.productService.getOneProductAfterLoggedIn(this.id).then((product) => {
      this.product = product;
      this.investmentService.getProductTanyaAdminAfterLoggedIn(this.product.id).then((tanyas) => {
        this.tanyaAdmins = tanyas;
      }, () => {
        this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
      });
    }, () => {
      this.redirectTo(MEMBER_MY_BUSINESS_PAGE);
    });
  }
  openDetails(tanya: TanyaAdminInterface): void {
    this.dialog.open(DialogDetailsAskAdminComponent, {
      data: {tanya},
    });
  }
  openDialog(): void{
    const dialog = this.dialog.open(MemberAskAdminDialogComponent, {
      data: { product: this.product},
    });
    dialog.componentInstance.result.subscribe((res: string) => {
      if (res && res === 'success') {
        this.label.getLabel(this.translateService, 'pages.tanya-admin.save-success').then((message) => {
          this.snackBar.open(message as string, '', {duration: 2000});
          this.redirectTo(MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE + '/' + this.product.id);
        });
      }
    });
  }
}
