import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-common-mail-sent',
  templateUrl: './common-mail-sent.component.html'
})
export class CommonMailSentComponent{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
