import {MemberInfoPageComponent} from './member-info.page';
import {MemberAccountPanelPageComponent} from './member-account-panel.page';
import {MemberBankPageComponent} from './member-bank.page';
import {MemberAccountVerificationPageComponent} from './member-account-verification.page';
import {MemberAccountVerificationStatusPageComponent} from './member-account-verification-status.page';
import {AppRoutingModule} from '../../../app-routing.module';
import {NgProgressModule} from 'ngx-progressbar';
import {CommonModule} from '@angular/common';
import {AppSharedModule} from '../../../shared/shared.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MasterService} from '../../../services/master.service';
import {UserService} from '../../../services/user.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppComponentModule} from '../../../components/component.module';
import {MaterialModule} from '../../../material/material.module';
import {AvatarDialogComponent} from './avatar.dialog';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgSelectModule,
    AppSharedModule,
    AppComponentModule,
    MaterialModule
  ],
  exports: [
    MemberInfoPageComponent,
    MemberAccountPanelPageComponent,
    MemberBankPageComponent,
    MemberAccountVerificationPageComponent,
    MemberAccountVerificationStatusPageComponent,
    AvatarDialogComponent
  ],
  declarations: [
    MemberInfoPageComponent,
    MemberAccountPanelPageComponent,
    MemberBankPageComponent,
    MemberAccountVerificationPageComponent,
    MemberAccountVerificationStatusPageComponent,
    AvatarDialogComponent
  ],
  providers: [
    MasterService,
    UserService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMemberProfileModule {}
