import {environment} from '../../environments/environment';
import {
  MASTER_BANKS_BASE_URL,
  MASTER_DISTRICT_BASE_URL,
  MASTER_PROVINCE_BASE_URL,
  MASTER_SUB_DISTRICT_BASE_URL,
  MASTER_VILLAGE_BASE_URL
} from '../constants/api.constant';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SelectInterface} from '../interfaces/common.interface';

@Injectable({providedIn: 'root'})
export class MasterService {
  constructor(private http: HttpClient) {
  }
  async getProvinceAsync(): Promise<SelectInterface[]> {
    return await this.http.get<SelectInterface[]>(environment.url + `${MASTER_PROVINCE_BASE_URL}`, {}).toPromise();
  }
  async getDistrictAsync(provinceId: number): Promise<SelectInterface[]> {
    return await this.http.get<SelectInterface[]>(environment.url + `${MASTER_DISTRICT_BASE_URL}/${provinceId}`, {}).toPromise();
  }
  async getSubDistrictAsync(districtId: number): Promise<SelectInterface[]> {
    return await this.http.get<SelectInterface[]>(environment.url + `${MASTER_SUB_DISTRICT_BASE_URL}/${districtId}`, {}).toPromise();
  }
  async getVillageAsync(subDistrictId: number): Promise<SelectInterface[]> {
    return await this.http.get<SelectInterface[]>(environment.url + `${MASTER_VILLAGE_BASE_URL}/${subDistrictId}`, {}).toPromise();
  }
  async getBanksAsync(): Promise<SelectInterface[]> {
    return await this.http.get<SelectInterface[]>(environment.url + `${MASTER_BANKS_BASE_URL}`).toPromise();
  }
}
