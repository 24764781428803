import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Auth} from './auth.service';
import {JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../constants/common.constant';
import {UiHelper} from '../helpers/ui.helper';
import {NEW_LOGIN_PAGE} from '../constants/route.constant';
import {UserInterface} from '../interfaces/user.interface';

@Injectable({providedIn: 'root'})
export class AuthGuardMerchant implements CanActivate {
  private ui = UiHelper;

  constructor(private auth: Auth, private router: Router) {
  }

  canActivate(x: ActivatedRouteSnapshot, y: RouterStateSnapshot): boolean {
    const isMerchant = (JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional
      && ((JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional.roleId == 6);
    if (this.auth.loggedIn() && isMerchant) {
      return true;
    } else {
      if (localStorage.getItem(JWT_TOKEN_KEY)) {
        localStorage.removeItem(JWT_TOKEN_KEY);
      }
      this.router.navigate([this.ui.absoluteUrl(NEW_LOGIN_PAGE)], {queryParams: {next: y.url}}).then(() => {
        window.location.reload();
      });
    }
    return false;
  }
}
