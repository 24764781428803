<div class="main-content pb-1">
  <ng-container *ngIf="isRegistered() else withdrawForm">
    <div style="text-align: center" *ngIf="isFetchDataError || isRegistered()">
      <h3>
        <ng-container *ngIf="isRegistered() else errorTitle">
          {{'pages.wallet.error-title-is-registered' | translate}}
        </ng-container>
        <ng-template #errorTitle>
          {{'pages.wallet.error-title' | translate}}
        </ng-template>
      </h3>
      <h4>
        <ng-container *ngIf="isRegistered() else errorDescription">
          {{'pages.wallet.error-description-is-registered' | translate}}
        </ng-container>
        <ng-template #errorDescription>
          {{'pages.wallet.error-description' | translate}}
        </ng-template>
      </h4>
    </div>
  </ng-container>

  <ng-template #withdrawForm>
    <h1 class="h4 mb-3">{{'pages.wallet.withdraw-title' | translate}}</h1>
    <p>{{'pages.wallet.guide' | translate}}</p>
    <form [formGroup]="withdrawFG" (submit)="withdrawRequest()">
      <div class="form-group">
        <label for="balance">{{'pages.wallet.your-balance' | translate}}</label>
        <input
          id="balance"
          formControlName="balance"
          prefix="Rp. "
          mask="separator.2"
          thousandSeparator="."
          decimalMarker=","
          type="text"
          class="form-control"
          aria-describedby="Masukan jumlah uang yang akan kamu tarik"
        />
      </div>
      <div class="form-group">
        <label for="bankName"
          >{{'pages.wallet.account-destination' | translate}}</label
        >
        <input
          id="bankName"
          formControlName="bankName"
          type="text"
          class="form-control"
          aria-describedby="Masukan jumlah uang yang akan kamu tarik"
          placeholder="Masukan jumlah uang yang akan kamu tarik"
        />
      </div>
      <div class="form-group">
        <label for="onBehalfOf">{{'pages.wallet.receiver' | translate}}</label>
        <input
          id="onBehalfOf"
          formControlName="onBehalfOf"
          type="text"
          class="form-control"
          aria-describedby="Masukan jumlah uang yang akan kamu tarik"
          placeholder="Masukan jumlah uang yang akan kamu tarik"
        />
      </div>
      <div class="form-group">
        <label for="amount">{{'pages.wallet.amount' | translate}}</label>
        <input
          id="amount"
          formControlName="amount"
          prefix="Rp. "
          mask="separator.2"
          thousandSeparator="."
          type="text"
          class="form-control"
          aria-describedby="Masukan jumlah uang yang akan kamu tarik"
          placeholder="Masukan jumlah uang yang akan kamu tarik"
        />
      </div>
      <div class="form-group">
        <button class="primary-button big-button" mat-flat-button>
          {{'common.save' | translate}}
        </button>
      </div>
    </form>
  </ng-template>
</div>
