import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {UntypedFormControl, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ERROR_UNKNOWN_OR_NETWORK} from '../../constants/error.constant';
import {MatDialog} from '@angular/material/dialog';
import {MAIL_SENT_RESET_PASSWORD} from '../../constants/route.constant';
import {MAIL_SENT_LOGO, RAMERAMECOID_LOGO} from 'src/app/constants/common.constant';

@Component({
  selector: 'app-page-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['../register/register.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordPageComponent extends BaseComponent implements OnInit{
  public emailFC: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  public logo = RAMERAMECOID_LOGO;
  public mailSentLogo = MAIL_SENT_LOGO;
  constructor(
    injector: Injector,
    private jwtHelper: JwtHelperService,
    private loginService: LoginService,
    public dialog: MatDialog
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  sendResetPassword(): void {
    this.loginService.forgot(this.emailFC.value).then((res) => {
     if (res.status === 'OK') {
         this.router.navigate([MAIL_SENT_RESET_PASSWORD]).then(() => {});
     }
    }, () => {
      this.label.getLabel(this.translateService, ERROR_UNKNOWN_OR_NETWORK).then((message) => {
        this.snackBar.open(message as string, '', {duration: 2000});
      });
    });
  }
}
