import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileService} from '../../../services/file.service';

@Component({
  selector: 'app-avatar-dialog',
  templateUrl: './avatar.dialog.html'
})
export class AvatarDialogComponent implements OnInit{
  public fileListAvatar: FileList;
  public result  = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<AvatarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileService: FileService
  ) {
  }
  ngOnInit(): void {
  }
  onAvatarChange(event: any): void {
    this.fileListAvatar = event.target.files as FileList;
    const mime = this.fileListAvatar[0].type;
    if (!mime.match(/image\/*/)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileListAvatar[0]);
    reader.onload = () => {
      this.data.imageUrl = reader.result;
    }
  }
  changeAvatar(): void {
    let fd = new FormData();
    const fileTransfer = this.fileListAvatar[0];
    fd.append('avatar',fileTransfer);
    this.fileService.saveAvatar(fd).then(() => {
      this.result.emit('success');
      this.dialogRef.close();
    });
  }
}
