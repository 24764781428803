import * as moment from 'moment';
import {
  APP_DATE_FORMAT,
  APP_DATETIME_COMPLETE_FORMAT,
  APP_SHORT_DATE_FORMAT,
  DB_DATE_FORMAT,
  DB_TIMESTAMP_FORMAT,
  MONTH_LIST_MULTI_LANG
} from '../constants/common.constant';
import {LocaleMonthInterface} from '../interfaces/common.interface';

export class DateHelper {

  static compareTwoDates(dateStart: string, dateEnd: string): number {
    const startDate   = moment(dateStart, DB_DATE_FORMAT);
    const currentDate = moment(dateEnd, DB_DATE_FORMAT);
    return parseInt(startDate.diff(currentDate, 'days').toString(), 10);
  }

  static remainingDate(dateStart): number {
    const startDate   = moment(dateStart, DB_DATE_FORMAT);
    const currentDate = moment().format(DB_DATE_FORMAT);
    return parseInt(startDate.diff(currentDate, 'days').toString(), 10);
  }
  static localeDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString();
  }
  static localeShortDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format(APP_SHORT_DATE_FORMAT).toString();
  }
  static completeLocaleDateTime(date: string, locale: string): string {
    return moment(date, DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATETIME_COMPLETE_FORMAT).toString();
  }
  static localeDateFromTs(date: string, locale: string): string {
    return moment(date, DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString();
  }
  static monthOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('MMMM').toString();
  }
  static dateOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('D').toString();
  }
  static dayOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('dddd').toString();
  }
  static yearOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('YYYY').toString();
  }
  static dateTimeToLocaleDate(date: string, locale: string): string {
    return moment(date, DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString();
  }
  static toDatePicker(date: string): any {
    return moment(date, DB_DATE_FORMAT);
  }
  static localeMonthFormat(month: number): LocaleMonthInterface {
    const m: LocaleMonthInterface[] = MONTH_LIST_MULTI_LANG as LocaleMonthInterface[];
    return m.find((x) => x.month === month);
  }
  static getCurrentMonth(): number {
    return parseInt(moment().month().toString(), 10);
  }
  static getCurrentYear(): number {
    return parseInt(moment().year().toString(), 10);
  }
  static getCurrentDateTime(locale: string): string {
    return moment().locale(locale).format(APP_DATETIME_COMPLETE_FORMAT);
  }
  static carbonToMoment(date: string, locale: string): any {
    const dateAndTime = date.split(' ');
    const timeAndTz = dateAndTime[1].split('.');
    return {
      // tslint:disable-next-line:max-line-length
      completeDateTime: moment(dateAndTime[0] + ' ' + timeAndTz[0], DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATETIME_COMPLETE_FORMAT).toString(),
      completeDate:  moment(dateAndTime[0], DB_DATE_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString(),
      date: dateAndTime[0],
      time: timeAndTz[0]
    };
  }
  static stringToDateFormat(strDate: string): Date {
    return new Date(strDate);
  }
  static dateAddHours(date: string, hours: number): Date {
    const newDate = moment(date, DB_TIMESTAMP_FORMAT).add(hours, 'h');
    return new Date(newDate.format(DB_TIMESTAMP_FORMAT).toString());
  }

  static getCurrentDate() : Date {
    return moment().toDate();
  }

  static dateForBackend(dateString:string) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }
    return date.toISOString().substring(0, 10);
  }

  static newDateFromStringFormat(strDate: string): Date {
    if (strDate != null) {
      let arr = strDate.split(/[- :]/);
      return new Date(Number(arr[0]), Number(arr[1]) - 1, Number(arr[2]), Number(arr[3]), Number(arr[4]), Number(arr[5]));
    } else {
      return new Date();
    }
  }
}
