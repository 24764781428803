import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {AppRoutingModule} from '../../app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {MembercardService} from '../../services/membercard.service';
import {MemberCardComponent} from './member-card.page';
import {TranslateModule} from '@ngx-translate/core';
import {CampaignCategoryComponent} from './campaign-per-category/campaign-category.page';
import {MyVoucherComponent} from './my-voucher/my-voucher.page';
import {VoucherHistoryComponent} from './voucher-history/voucher-history.page';
import {VoucherDetailPageComponent} from './voucher-detail/voucher-detail.page';
import {AppComponentModule} from '../../components/component.module';
import {MerchantOutletComponent} from './merchant-outlet/merchant-outlet.page';
import {MemberCardInfoComponent} from './info/member-card-info.page';
import {MarkdownModule} from 'ngx-markdown';
import {MerchantOutletQrScanComponent} from './merchant-outlet/merchant-outlet-qr-scan';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {QrScanSuccessComponent} from './merchant-outlet/qr-scan-success';
import {UseVoucherComponent} from './my-voucher/use-voucher.page';
import {QrCodeModule} from 'ng-qrcode';
import {MerchantOutletVoucherHistoryComponent} from './merchant-outlet/merchant-outlet-voucher-history';
import {MapMerchantDialog} from './voucher-detail/map-merchant.dialog';
import {MemberCardComingSoonPage} from './member-card-coming-soon.page';
import {MerchandiseComponent} from './point-merchandise/merchandise.page';
import {MerchandiseDetailsComponent} from './point-merchandise/details/merchandise-details.page';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {UseMerchandiseVoucherComponent} from './point-merchandise/details/use-merchandise-voucher.page';
import {VoucherSearchComponent} from './voucher-search/voucher-search.page';
import {QrScanSuccessMerchandiseComponent} from './merchant-outlet/qr-scan-success-merchandise';
import {MerchandiseOutletVoucherHistoryComponent} from './merchant-outlet/merchandise-outlet-voucher-history';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    AppComponentModule,
    MarkdownModule,
    ZXingScannerModule,
    QrCodeModule,
    CarouselModule,
  ],
  exports: [
    MemberCardComponent,
    CampaignCategoryComponent,
    MyVoucherComponent,
    VoucherHistoryComponent,
    MemberCardInfoComponent,
    VoucherDetailPageComponent,
    MerchantOutletComponent,
    MerchantOutletQrScanComponent,
    QrScanSuccessComponent,
    UseVoucherComponent,
    MerchantOutletVoucherHistoryComponent,
    MapMerchantDialog,
    MemberCardComingSoonPage,
    MerchandiseComponent,
    MerchandiseDetailsComponent,
    UseMerchandiseVoucherComponent,
    VoucherSearchComponent,
    QrScanSuccessMerchandiseComponent,
    MerchandiseOutletVoucherHistoryComponent
  ],
  declarations: [
    MemberCardComponent,
    CampaignCategoryComponent,
    MyVoucherComponent,
    VoucherHistoryComponent,
    MemberCardInfoComponent,
    VoucherDetailPageComponent,
    MerchantOutletComponent,
    MerchantOutletQrScanComponent,
    QrScanSuccessComponent,
    UseVoucherComponent,
    MerchantOutletVoucherHistoryComponent,
    MapMerchantDialog,
    MemberCardComingSoonPage,
    MerchandiseComponent,
    MerchandiseDetailsComponent,
    UseMerchandiseVoucherComponent,
    VoucherSearchComponent,
    QrScanSuccessMerchandiseComponent,
    MerchandiseOutletVoucherHistoryComponent
  ],
  providers: [
    MembercardService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppMembercardModule {}
