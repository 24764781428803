import {Component, Injector, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_MY_BUSINESS_DETAIL_PAGE, MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE} from 'src/app/constants/route.constant';
import {InvestmentService} from '../../../services/investment.service';
import {MyInvestmentInterface} from '../../../interfaces/product.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';

@Component({
  selector: 'app-page-member-my-business-landing',
  templateUrl: './my-business-landing.page.html'
})
export class MemberMyBusinessLandingPageComponent extends BaseComponent implements OnInit{
  public MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE = MEMBER_MY_BUSINESS_DETAIL_TRANSACTION_PAGE;
  public MEMBER_MY_BUSINESS_DETAIL_PAGE = MEMBER_MY_BUSINESS_DETAIL_PAGE;
  public myInvestments: MyInvestmentInterface[] = [];

  public myPurchases: MyInvestmentInterface[] = [];
  public groupActive: MyInvestmentInterface[] = [];
  public groupRun: MyInvestmentInterface[] = [];
  public groupAdmin: MyInvestmentInterface[] = [];
  public groupFinish: MyInvestmentInterface[] = [];

  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public flag = 0;
  public activeTab = 0;
  public activeGroup = 0;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private investmentService: InvestmentService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.queryParams.subscribe((res) => {
      this.activeTab = (res.origin && res.origin == 'transaction')? 1 : 0;
    });

    this.investmentService.getUserInvestments().then((res) => {
      this.myInvestments = res;
      this.flag = 1;

      this.myInvestments.forEach((i) => {
        switch (i.status) {
          case 'AC':
            this.groupActive.push(i);
            break;
          case 'RUN':

            i.totalCurrent  = 0;
            i.totalPrevious = 0;

            i.currentSales.forEach(j => {
                i.totalCurrent += Number(j.salesAmount);
            });

            i.prevSales.forEach(j => {
              i.totalPrevious += Number(j.salesAmount);
            });

            this.groupRun.push(i);
            break;
          case 'ADM':
            this.groupAdmin.push(i);
            break;
          case 'DONE':

            i.totalCurrent  = 0;
            i.totalPrevious = 0;

            i.currentSales.forEach(j => {
              i.totalCurrent += Number(j.salesAmount);
            });

            i.prevSales.forEach(j => {
              i.totalPrevious += Number(j.salesAmount);
            });

            this.groupFinish.push(i);
            break;
        }
      });

    });

    this.investmentService.getPurchaseHistories().then((res) => {
      this.myPurchases = res;
      for (let i = 0; i < res.length; i++) {
        if (res[i].meta) {
          this.myPurchases[i].meta = JSON.parse(res[i].meta);
        }
      }
    });
  }

}
