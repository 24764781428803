import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {RegisterService} from '../../services/register.service';
import {ERROR_INVALID_VERIFY_TOKEN, ERROR_UNKNOWN_OR_NETWORK} from '../../constants/error.constant';
import {RAMERAMECOID_LOGO} from '../../constants/common.constant';

@Component({
  selector: 'app-page-register-welcome',
  templateUrl: './register-welcome.page.html',
})
export class RegisterWelcomePageComponent extends BaseComponent implements OnInit{
  public checkingToken = 0;
  public token = '';
  public logo = RAMERAMECOID_LOGO;
  constructor(
    injector: Injector,
    private registerService: RegisterService
  ) {
    super(injector);
    this.token = this.activatedRoute.snapshot.params.token;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.registerService.verify(this.token).then((res) => {
      this.checkingToken = 1;
    }, async (err) => {
      if (err.error.error) {
        switch (this.ui.errorMessageTransform(err.error.error)) {
          case ERROR_INVALID_VERIFY_TOKEN: {
            const message = await this.label.getLabel(this.translateService, ERROR_INVALID_VERIFY_TOKEN);
            this.snackBar.open(message as string, '', {duration: 2000});
            break;
          }
        }
      }else {
        const message = await this.label.getLabel(this.translateService, ERROR_UNKNOWN_OR_NETWORK);
        this.snackBar.open(message as string, '', {duration: 2000});
      }
      this.checkingToken = 2;
    });
  }
}
