import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {WALLET_BASE_URL, WITHDRAW_BASE_URL} from '../constants/api.constant';
import {WalletInterface} from '../interfaces/wallet.interface';

@Injectable({providedIn: 'root'})
export class WalletService {
  constructor(private http: HttpClient) {}
  async getWalletsAsync(pageNo = 1): Promise<WalletInterface> {
    return await this.http.get<WalletInterface>(environment.url + WALLET_BASE_URL + '/all' + '?page=' + pageNo, {}).toPromise();
  }
  async saveWithdrawRequest(amount: number): Promise<any> {
    return await this.http.post<any>(environment.url + WITHDRAW_BASE_URL + '/save', {amount}).toPromise();
  }
}
