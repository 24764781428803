<div class="sliding-card row" *ngIf="!isAuth">
  <div class="col-9 col-md-7 col-lg-5" *ngFor="let offered of products">
    <div class="card business">
      <div class="ribbon-group ">
        <div *ngIf="offered.status == 'RUN'" class="ribbon-group-children grey">{{ 'status.run' | translate }}</div>
        <div *ngIf="offered.status == 'AC'" class="ribbon-group-children orange">{{ 'status.active' | translate }}</div>
        <div *ngIf="offered.status == 'ADM'" class="ribbon-group-children green">{{ 'status.administration' | translate }}</div>
        <div *ngIf="offered.status == 'DONE'" class="ribbon-group-children blue">{{ 'status.done' | translate }}</div>
        <div *ngIf="offered.insuranceId" class="ribbon-group-children"><span class="icon-aman"></span></div>
      </div>

      <img class="card-img-top" [src]="baseUrl + offered.imageUrl + '?rand=123'" alt="Card image cap">
      <div class="card-body">
        <h4>{{offered.productName}}</h4>
      </div>
    </div>
  </div>
</div>

<div class="sliding-card row" *ngIf="isAuth">
  <div class="col-9 col-md-7 col-lg-5" *ngFor="let offered of products">
    <div class="card business" (click)="toProductDetails(offered.id)">

      <div class="ribbon-group ">
        <div *ngIf="offered.status == 'RUN'" class="ribbon-group-children grey">{{ 'status.run' | translate }}</div>
        <div *ngIf="offered.status == 'AC'" class="ribbon-group-children orange">{{ 'status.active' | translate }}</div>
        <div *ngIf="offered.status == 'ADM'" class="ribbon-group-children green">{{ 'status.administration' | translate }}</div>
        <div *ngIf="offered.status == 'DONE'" class="ribbon-group-children blue">{{ 'status.done' | translate }}</div>
        <div *ngIf="offered.insuranceId" class="ribbon-group-children"><span class="icon-aman"></span></div>
      </div>

      <img class="card-img-top" [src]="baseUrl + offered.imageUrl + '?rand=123'" alt="Card image cap">
      <div class="card-body">
        <h4>{{offered.productName}}</h4>
        <h5>{{'common.currency-idr' | translate}} {{ui.currencyFormat(offered.totalAmount, locale)}}</h5>
        <div class="business-infos">
          <div class="item">
            <span class=" icon-jumlah-slot"></span>
            <span class="title">{{'common.total-slot' | translate}}</span>
            <span class="value">{{ui.toInteger(offered.totalSlot + '')}}</span>
          </div>
          <div class="item">
            <span class=" icon-investor"></span>
            <span class="title">{{'common.investor' | translate}}</span>
            <span class="value">{{offered.totalInvestor}}</span>
          </div>
          <div class="item">
            <span class=" icon-date"></span>
            <span class="title">{{'common.funding-due' | translate}}</span>
            <span class="value" *ngIf="date.remainingDate(offered.fundingDue) > 0">{{date.remainingDate(offered.fundingDue)}} {{'common.day-more' | translate}}</span>
            <span class="value" *ngIf="date.remainingDate(offered.fundingDue) <= 0">{{'common.zero-day' | translate}}</span>
          </div>
          <div class="item">
            <span class=" icon-exclamation"></span>
            <span class="title">{{'common.status' | translate}}</span>
            <span class="value">{{offered.productStatus}}</span>
          </div>
          <div class="item">
            <span class=" icon-tag"></span>
            <span class="title">{{'common.category' | translate}}</span>
            <span class="value">{{offered.productCategory}}</span>
          </div>
        </div>

        <div class="progress mt-2">
          <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (((offered.totalSlot - (offered.remainingSlot))/offered.totalSlot) * 100) + '%'}" aria-valuenow="{{((offered.totalSlot - (offered.remainingSlot))/offered.totalSlot)}}" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
</div>
