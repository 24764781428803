import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-thanks-toc',
  templateUrl: './thanks-toc.component.html'
})
export class ThanksTocComponent{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
