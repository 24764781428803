import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {STORAGE_LANG_KEY, STORAGE_PROFILE_KEY} from '../../../constants/common.constant';
import {MERCHANDISE_OUTLET_QR_SCAN_SUCCESS, MERCHANT_OUTLET_QR_SCAN_SUCCESS} from '../../../constants/route.constant';
import {CampaignVoucher} from '../../../interfaces/merchant-outlet.interface';
import {MembercardService} from '../../../services/membercard.service';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {MatDialog} from '@angular/material/dialog';
import {UserInterface} from '../../../interfaces/user.interface';

@Component({
  selector: 'app-merchant-outlet-qr-scan',
  templateUrl: './merchant-outlet-qr-scan.html',
})
export class MerchantOutletQrScanComponent extends BaseComponent implements OnInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  resultQrScan : string;
  campaignVoucher : CampaignVoucher;
  enableScan : boolean = true;
  public merchantId = (JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional.merchantId;

  constructor(injector: Injector,
              private dialog: MatDialog,
              private membercardService : MembercardService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onCodeResult(resultString : string){
    this.enableScan = false;
    let codeQr = resultString.substr(0, 2);
    console.log(codeQr);
    if (codeQr == 'VC') { //in case ada qr scan type lain selain voucher
      this.membercardService.getVoucherByVoucherCode(resultString).then((res) => {
        if (res) {
          this.campaignVoucher = res[0];
          this.doNavigateSuccessScan(this.campaignVoucher.id);
        }
      }, (err) => {
        this.pointSuccessDialog(err ? err.error[0] : null).then();
        console.log(err);
      });
    } else if(codeQr == 'MC') {
      this.membercardService.getMerchandiseByVoucherCode(resultString).then((res) => {
        if (res) {
          this.campaignVoucher = res;
          this.doNavigateSuccessScanMerchandise(this.campaignVoucher.id);
        }
      }, (err) => {
        this.pointSuccessDialog(err ? err.error[0] : null).then();
        console.log(err);
      });
    }
  }

  doNavigateSuccessScan(id): void {
    this.router.navigate(['/' + MERCHANT_OUTLET_QR_SCAN_SUCCESS + '/' + id]).then();
  }

  doNavigateSuccessScanMerchandise(id): void {
    this.router.navigate(['/' + MERCHANDISE_OUTLET_QR_SCAN_SUCCESS + '/' + id]).then();
  }

  async pointSuccessDialog(message: string): Promise<void> {
    const data: DialogInterface = {
      title: await this.label.getLabel(this.translateService, "pages.member-card.error-voucher.".concat(message)) as string,
      imageUrl: 'assets/images/icons/exclamation-red.png',
      imageStyle: 'max-width: 15%',
      buttons: [
        {label: 'OK', value: 1, className: 'primary-button big-button'}
      ]
    };
    const pointNotif = this.dialog.open(DialogScrollComponent, {data});
    pointNotif.afterClosed().subscribe((resCash) => {
      this.enableScan = true;
    });
  }
}
