import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {STORAGE_LANG_KEY, VOUCHER_DETAIL_ACTION} from '../../../constants/common.constant';
import {VoucherDetailsInterface} from '../../../interfaces/member-card.interface';
import {
  MEMBER_CARD_USE_VOUCHER,
  MEMBER_CARD_VOUCHER_DETAIL,
  MEMBER_CARD_VOUCHER_PAGE,
  NEW_LOGIN_PAGE
} from '../../../constants/route.constant';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {MatDialog} from '@angular/material/dialog';
import {UiHelper} from '../../../helpers/ui.helper';
import {MembercardService} from '../../../services/membercard.service';
import {DialogSocmedComponent} from '../../../shared/dialog/dialog-social-media.component';
import {DomSanitizer} from '@angular/platform-browser';
import {MapMerchantDialog} from './map-merchant.dialog';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {OWL_OPTIONS_LANDING} from '../../../constants/owl-options.constant';


@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.page.html',
})
export class VoucherDetailPageComponent extends BaseComponent implements OnInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public voucherDetail: VoucherDetailsInterface;
  public isDisableButton: boolean = true;
  public btnLabel: string = 'pages.member-card.buy-this-voucher';
  public upperSliderOptions: OwlOptions = OWL_OPTIONS_LANDING;

  action: string = VOUCHER_DETAIL_ACTION.BUY;
  id: number;
  showMap = false;
  flag = false;
  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private memberCardService: MembercardService,
    public sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.action = this.activatedRoute.snapshot.params.action;
    this.id = this.activatedRoute.snapshot.params.id;
    super.ngOnInit();
    if (this.action == VOUCHER_DETAIL_ACTION.BUY) {
      this.memberCardService.getCampaignDetails(this.id).then((res) => {
        this.voucherDetail = res;
        this.checkForButton();

        if(this.date.compareTwoDates(this.voucherDetail.currentDate, this.voucherDetail.startDate)) {
          this.flag = true;
        }

      });
    } else if (this.action == VOUCHER_DETAIL_ACTION.REDEEM || this.action == VOUCHER_DETAIL_ACTION.USED
      || this.action == VOUCHER_DETAIL_ACTION.EXPIRED) {
      this.memberCardService.getCampaignVoucherDetails(this.id).then((res) => {
        this.voucherDetail = res;
        this.checkForButton();
        if(this.date.compareTwoDates(this.voucherDetail.currentDate, this.voucherDetail.startDate)) {
          this.flag = true;
        }
      })
    }
  }

  checkForButton() {
    switch (this.action) {
      case VOUCHER_DETAIL_ACTION.BUY:
        this.isDisableButton = this.date.compareTwoDates(this.voucherDetail.currentDate, this.voucherDetail.startDate) < 0;
        this.btnLabel = 'pages.member-card.buy-this-voucher';
        break;
      case VOUCHER_DETAIL_ACTION.REDEEM:
        this.isDisableButton = this.voucherDetail.isClaim == 1;
        this.btnLabel = 'pages.member-card.use-this-voucher';
        break;
      case VOUCHER_DETAIL_ACTION.USED:
        this.isDisableButton = true;
        this.btnLabel = 'pages.member-card.voucher-used';
        break;
      case VOUCHER_DETAIL_ACTION.EXPIRED:
        this.isDisableButton = true;
        this.btnLabel = 'pages.member-card.voucher-expired';
        break;
    }
  }

  useVoucher(): void {
    switch (this.action) {
      case VOUCHER_DETAIL_ACTION.BUY:
        this.openDialog(this.voucherDetail);
        break;
      case VOUCHER_DETAIL_ACTION.REDEEM:
        this.memberCardService.validateVoucher(this.voucherDetail.id).then((res) => {
          if (res.isValid) {
            this.router.navigate([MEMBER_CARD_USE_VOUCHER + '/' + this.voucherDetail.id]).then();
          } else {
            this.warningDialog('voucher-expired', res.status).then();
          }
        });
        break;
    }
  }

  openMap() {
    this.dialog.open(MapMerchantDialog, {data: this.voucherDetail.merchantMap});
    this.showMap = true;
  }

  openDialog(voucher: VoucherDetailsInterface): void {
    const data: DialogInterface = {
      title: 'Voucher Ditemukan',
      content: ('Kamu bisa dapatkan voucher ini dengan ')
        .concat(' menebus sebesar Rp').concat(UiHelper.currencyFormat(voucher.voucherPrice, this.locale)),
      extraText: 'Valid hingga ' + this.date.localeDate(voucher.endDate, this.locale),
      buttons: [
        {
          label: 'Tebus dengan Cash',
          value: 2,
          className: 'download-button big-button mt-2',
          addStyle: 'margin-left: 0 !important'
        },
      ]
    };
    const dialog = this.dialog.open(DialogScrollComponent, {data});
    dialog.afterClosed().subscribe((res) => {
      if (res === 1 || res === 2) {
        this.memberCardService.doRedeemVoucher(voucher.id, (res === 1) ? "POINT" : "CASH").then((data) => {
          if (res === 1) {
            data['numberOfPoint'] = voucher.numberOfPoint;
            this.pointSuccessDialog(data);
          } else if (res === 2) {
            this.cashSuccessDialog(data);
          }
        }, err => {
          console.error(err.error[0]);
          this.warningDialog(err ? err.error[0] : null);
        });
      }
    });
  }

  async cashSuccessDialog(voucher: VoucherDetailsInterface): Promise<void> {
    const data: DialogInterface = {
      title: await this.label.getLabel(this.translateService, "pages.member-card.success-redeem-voucher-title") as string,
      imageUrl: 'assets/images/icons/success@2x.png',
      imageStyle: 'max-width: 15%',
      buttons: [
        {label: 'OK', value: 1, className: 'primary-button big-button'}
      ],
      showCountdown: 1,
      countdown: {
        redeemAt: voucher.redeemAt,
        expiredDate: voucher.expiredDate,
        extraText: 'Gunakan voucher ini sebelum',
        content: await this.label.getLabel(this.translateService, "pages.member-card.success-redeem-voucher-content") as string
      },
      isMemberCard: 1
    };
    const dialogCash = this.dialog.open(DialogScrollComponent, {data});
    dialogCash.afterClosed().subscribe((resCash) => {
      if (resCash === 1) {
        this.openSosmedDialog(voucher);
      }
    });
  }

  async pointSuccessDialog(voucher: VoucherDetailsInterface): Promise<void> {
    const data: DialogInterface = {
      title: await this.label.getLabel(this.translateService, "pages.member-card.success-redeem-voucher-title") as string,
      content: await this.label.getLabel(this.translateService, "pages.member-card.success-redeem-voucher-content") as string,
      extraText: 'Gunakan voucher ini sebelum ' + this.date.localeDate(voucher.endDate, this.locale),
      imageUrl: 'assets/images/icons/success@2x.png',
      imageStyle: 'max-width: 15%',
      buttons: [
        {label: 'OK', value: 1, className: 'primary-button big-button'}
      ],
      isMemberCard: 1
    };
    const pointNotif = this.dialog.open(DialogScrollComponent, {data});
    pointNotif.afterClosed().subscribe((resPoint) => {
      this.openSosmedDialog(voucher);
    });
  }

  async warningDialog(message: string, status ?: string): Promise<void> {
    const data: DialogInterface = {
      title: await this.label.getLabel(this.translateService, "pages.member-card.".concat(message.concat("-title"))) as string,
      content: await this.label.getLabel(this.translateService, "pages.member-card.".concat(message.concat("-content"))) as string,
      imageUrl: 'assets/images/icons/exclamation-red.png',
      imageStyle: 'max-width: 15%',
      buttons: [
        {label: 'OK', value: 1, className: 'primary-button big-button'}
      ]
    };
    const warnDialog = this.dialog.open(DialogScrollComponent, {data});
    warnDialog.afterClosed().subscribe(res => {
      if (res == 1 && status != null) {
        if (!(status == 'CANCELLED' || status == "EXPIRED")) {
          this.memberCardService.updateVoucherStatus(this.voucherDetail.id).then((res) => {
            if (res) {
              this.router.navigate(['/' + MEMBER_CARD_VOUCHER_PAGE + '/' + this.userProfile.basic.id]);
            }
          });
        } else {
          this.router.navigate(['/' + MEMBER_CARD_VOUCHER_PAGE + '/' + this.userProfile.basic.id]);
        }
      }
    });
  }

  openSosmedDialog(voucher: VoucherDetailsInterface) {
    const data: DialogInterface = {
      content: "Yuk share voucher ini ke teman-temanmu sebelum kehabisan!",
      imageUrl: this.baseUrl + this.voucherDetail.imagePath,
    };
    const promoAction = this.dialog.open(DialogSocmedComponent, {
      data,
      panelClass: 'dialog-container-custom'
    });
    promoAction.afterClosed().subscribe(() => {
      this.redirectTo(MEMBER_CARD_VOUCHER_DETAIL + '/' + voucher.id + '/' + VOUCHER_DETAIL_ACTION.REDEEM);
    })
  }

  toLogin(): void {
    this.router.navigate([NEW_LOGIN_PAGE]).then();
  }
}

