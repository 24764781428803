import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SHOPPING_CART_BASE_URL} from '../constants/api.constant';
import {ShoppingCartInterface} from '../interfaces/shopping-cart.interface';
import {StatusValueInterface} from '../interfaces/common.interface';

@Injectable({providedIn: 'root'})
export class ShoppingCartService {
  constructor(private http: HttpClient) {
  }

  async addToCart(productId: number): Promise<any> {
    return await this.http.post<any>(environment.url + SHOPPING_CART_BASE_URL + `/add-to-cart`, {productId}, {}).toPromise();
  }

  async getCartData(): Promise<ShoppingCartInterface> {
    return await this.http.post<ShoppingCartInterface>(environment.url + SHOPPING_CART_BASE_URL, {}, {}).toPromise();
  }

  async adjustQuantity(productId: number, quantity: number): Promise<ShoppingCartInterface> {
    return await this.http.post<ShoppingCartInterface>(environment.url + SHOPPING_CART_BASE_URL + `/edit-quantity`, {
      productId,
      quantity
    }, {}).toPromise();
  }

  async removeFromCart(productId: number): Promise<any> {
    return await this.http.post<any>(environment.url + SHOPPING_CART_BASE_URL + `/remove-from-cart`, {productId}, {}).toPromise();
  }

  async checkout(): Promise<ShoppingCartInterface> {
    return await this.http.post<ShoppingCartInterface>(environment.url + SHOPPING_CART_BASE_URL + `/checkout`, {}, {}).toPromise();
  }

  async done(useWallet: boolean, referal: string, productInsuranceId?: number, pctInsurance?: number, amtInsurance?: number): Promise<StatusValueInterface> {
    return await this.http.post<StatusValueInterface>(environment.url + SHOPPING_CART_BASE_URL + `/final`, {
      useWallet,
      referal,
      productInsuranceId,
      pctInsurance,
      amtInsurance
    }, {}).toPromise();
  }
}
