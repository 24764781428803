<div class="fixActionRow">
<div class="close-button d-flex justify-content-between" *ngIf="dialogData.subtitle">
  <b>{{dialogData.subtitle}}</b>
  <button *ngIf="dialogData.hideCloseButton" class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<div class="close-button" *ngIf="!dialogData.subtitle">
  <button *ngIf="dialogData.hideCloseButton" class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<mat-dialog-content class="mat-typography">
  <div *ngIf="dialogData.imageUrl" class="text-center top-image">
    <img [alt]="dialogData.imageAlt" [src]="dialogData.imageUrl" [style]="dialogData.imageStyle" />
  </div>
  <h4 class="title text-center" [ngClass]="dialogData.isMemberCard == 1 ? 'dialog-box-custom-size' : ''">{{dialogData.title}}</h4>
  <p class="content" *ngIf="dialogData.content" [innerHTML]="dialogData.content | keepHtml"></p>
  <p>
    <markdown *ngIf="dialogData.markdownPath" [src]="dialogData.markdownPath"></markdown>
  </p>
  <div *ngIf="dialogData.extraText"  class="well red mb-3">
    {{ dialogData.extraText }}
  </div>
  <p *ngIf="dialogData.footContent" >{{dialogData.content}}</p>
  <div *ngIf="dialogData.showCountdown == 1" class="text-center">
    <p [innerHTML]="dialogData.countdown.content | keepHtml"></p>
    <p class="text-weight-bold" [innerHTML]="dialogData.countdown.extraText | keepHtml" [ngClass]="dialogData.isMemberCard == 1 ? 'dialog-box-custom-size' : ''"></p>
    <app-countdown-pipe-component [withLabel]="'true'" [startDate]="date.newDateFromStringFormat(dialogData.countdown.redeemAt)" [endDate]="date.newDateFromStringFormat(dialogData.countdown.expiredDate)"></app-countdown-pipe-component>
  </div>
</mat-dialog-content>
<div class="spacer"></div>
<mat-dialog-actions align="end">
  <ng-container *ngFor="let btn of dialogData.buttons">
    <button *ngIf="btn.focus" cdkFocusInitial [mat-dialog-close]="btn.value" mat-button mat-dialog-close class="{{btn.className}}" style="{{btn.addStyle}}">{{btn.label | translate}}</button>
    <button *ngIf="!btn.focus" [mat-dialog-close]="btn.value" mat-button mat-dialog-close class="{{btn.className}}" style="{{btn.addStyle}}">{{btn.label | translate}}</button>
  </ng-container>
</mat-dialog-actions>
</div>
