<div class="fixActionRow">
<div class="close-button d-flex justify-content-between">
  <b>Daftar Dokumen</b><button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<mat-dialog-content class="mat-typography">
  <h4 class="text-center">{{data.productName}}</h4>
  <table class="table table-striped">
    <tbody>
      <tr *ngFor="let l of data.list; let i = index;">
        <td class="text-right"><span class="icon-file-pdf"></span></td>
        <td>{{l.key}}</td>
        <td class="text-right">
          <a class="icon-download3" href="{{data.baseUrl + '/docs/' + l.extra + '/' + l.key}}" target="_blank"></a>
        </td>
      </tr>
    </tbody>
  </table>

</mat-dialog-content>
<div class="spacer"></div>
</div>
