import {AfterViewInit, Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {MatDialog} from '@angular/material/dialog';
import {MyInvestmentInterface} from '../../interfaces/product.interface';
import {InvestmentService} from '../../services/investment.service';
import {
  CampaignCategoryInterface,
  CampaignSelectionInterface,
  CampaignWithCategoryInterface,
} from '../../interfaces/member-card.interface';
import {STORAGE_LANG_KEY, VOUCHER_DETAIL_ACTION} from '../../constants/common.constant';
import {
  MEMBER_CARD_ALL_CAMPAIGN_PER_CATEGORY,
  MEMBER_CARD_INFO,
  MEMBER_CARD_PAGE,
  MEMBER_CARD_SEARCH,
  MEMBER_CARD_VOUCHER_DETAIL,
  MEMBER_CARD_VOUCHER_HISTORY,
  MEMBER_CARD_VOUCHER_PAGE,
  MERCHANDISE_ALL_CATEGORIES
} from '../../constants/route.constant';
import {NavDataInterface} from '../../interfaces/nav-data.interface';
import {MembercardService} from '../../services/membercard.service';

@Component({
  selector: 'app-page-member-card',
  templateUrl: './member-card.page.html'
})
export class MemberCardComponent extends BaseComponent implements OnInit, AfterViewInit {
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public dateTime: string;
  public myInvestments: MyInvestmentInterface[] = [];
  public showCard = false;
  public campaignCategories: CampaignCategoryInterface[] = [];
  public newOfferingCampaign: CampaignSelectionInterface[] = [];
  public campaignsWithCategories: CampaignWithCategoryInterface[];
  public merchantOfTheDays: CampaignWithCategoryInterface[];
  public MEMBER_CARD_INFO = MEMBER_CARD_INFO;
  public userPoint: number = 0;
  public showIconPoint = false;


  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private investmentService: InvestmentService,
    private memberCardService: MembercardService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.investmentService.getUserInvestments().then((res) => {
      this.myInvestments = res;
      this.showCard = true;
    });
    this.memberCardService.getCampaignCategories().then((res) => {
      this.campaignCategories = res;
      this.showIconPoint = true;
    });
    this.memberCardService.getNewOfferingCampaigns(5).then((res) => {
      this.newOfferingCampaign = res;
    });
    this.memberCardService.getMerchantOfTheDaysCampaigns().then((res) => {
      this.merchantOfTheDays = res;
    });
    this.memberCardService.getCampaignWithCategory().then((res) => {
      this.campaignsWithCategories = res;
    });
    if(this.userProfile && this.userProfile.basic) {
      this.memberCardService.getPointByUser(this.userProfile.basic.id).then((res:number) => {
        this.userPoint = res;
      });
    }

  }

  ngAfterViewInit(): void {
    setInterval(() => {
      this.dateTime = this.date.getCurrentDateTime(this.locale);
    }, 1000);
  }

  doNavigateMenu(id, isActive): void {
    if (isActive) {
      this.router.navigate(['/' + MEMBER_CARD_ALL_CAMPAIGN_PER_CATEGORY + '/' + id]).then();
    }
  }

  foo(): void {
    this.router.navigate(['/' + MERCHANDISE_ALL_CATEGORIES]).then();
  }

  doShowAllCampaigns(id, category): void {
    const navMessage: NavDataInterface = {
      state: 'member-card',
      backTitle: category,
      hasBackButton: true,
      backUrl: MEMBER_CARD_PAGE
    };
    this.navService.changeMessage(navMessage);
    this.router.navigate(['/' + MEMBER_CARD_ALL_CAMPAIGN_PER_CATEGORY + '/' + id]).then();
  }

  doNavigateMyVoucher(): void {
    this.router.navigate(['/' + MEMBER_CARD_VOUCHER_PAGE + '/' + this.userProfile.basic.id]).then();
  }

  doNavigateVoucherHistory(): void {
    this.router.navigate(['/' + MEMBER_CARD_VOUCHER_HISTORY + '/' + this.userProfile.basic.id]).then();
  }

  doNavigateVoucherDetail(id): void {
    this.router.navigate(['/' + MEMBER_CARD_VOUCHER_DETAIL + '/' + id + '/' + VOUCHER_DETAIL_ACTION.BUY]).then();
  }

  toVoucherSearch(): void {
    this.router.navigate(['/' + MEMBER_CARD_SEARCH]).then();
  }
}
