import {Component, Inject, OnInit} from '@angular/core';
import {DialogInsuranceInterface} from './dialog.interface';
import {DateHelper} from '../../helpers/date.helper';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProductInsurance} from '../../interfaces/shopping-cart.interface';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-insurance-promo',
  templateUrl: 'dialog-insurance.component.html'
})
export class DialogInsuranceComponent implements OnInit {
  public dialogData: DialogInsuranceInterface;
  public date = DateHelper;
  public result: any;
  public baseUrl = environment.base;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInsuranceInterface) {
  }

  ngOnInit(): void {
    this.dialogData = this.data;
  }

  setSelected(ins: ProductInsurance) {
    this.dialogData.productInsurance.forEach(p => p.selected = false);
    this.dialogData.productInsurance[this.dialogData.productInsurance.findIndex(x => x.id == ins.id)].selected = true;
    this.result = ins;
  }
}
