import {Component, Injector, OnInit} from '@angular/core';
import {CampaignSelectionInterface} from '../../../interfaces/member-card.interface';
import {MEMBER_CARD_VOUCHER_DETAIL} from '../../../constants/route.constant';
import {BaseComponent} from '../../../shared/base/base.component';
import {VOUCHER_DETAIL_ACTION} from '../../../constants/common.constant';
import {MembercardService} from '../../../services/membercard.service';

@Component({
  selector: 'app-campaign-category',
  templateUrl: './campaign-category.page.html',
})
export class CampaignCategoryComponent extends BaseComponent implements OnInit {
  public campaigns: CampaignSelectionInterface[];

  constructor(injector: Injector, private memberCardService: MembercardService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.memberCardService.getListCampaignByCategory(this.activatedRoute.snapshot.params.id).then((res) => {
      this.campaigns = res;
    });
  }

  doNavigateVoucherDetail(id): void {
    this.router.navigate(['/' + MEMBER_CARD_VOUCHER_DETAIL + '/' + id + '/' + VOUCHER_DETAIL_ACTION.BUY]);
  }
}
