import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UiHelper} from 'src/app/helpers/ui.helper';
import {BaseComponent} from '../../../shared/base/base.component';
import {VoucherService} from '../../../services/voucher.service';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogInterface} from '../../../shared/dialog/dialog.interface';
import {VoucherInterface} from '../../../interfaces/voucher.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';

@Component({
  selector: 'app-voucher',
  templateUrl:'./voucher.page.html',
})
export class VoucherPageComponent extends BaseComponent implements OnInit{
  public ui = UiHelper;
  public voucherCode: string;
  public voucher: VoucherInterface = {};
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public history: VoucherInterface[] = [];
  constructor(
    injector: Injector,
    public activatedRoute: ActivatedRoute,
    private voucherService: VoucherService,
    private dialog: MatDialog
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.query();
  }
  validate(): void {
    this.voucherService.verifyVoucherCode(this.voucherCode).then((res) => {
      this.voucher = res;
      this.openDialog(this.voucher);
    })
  }
  query(): void {
    this.voucherService.voucherHistory().then((res) => {
      this.history = res.reverse();
    });
  }
  openDialog(voucher: VoucherInterface): void {
    const data: DialogInterface = {
      title : 'Voucher Ditemukan',
      content: voucher.eventName,
      extraText: 'Valid hingga ' + this.date.localeDate(voucher.endDate, this.locale),
      imageUrl: 'assets/images/icons/success@2x.png',
      buttons: [
        {label: 'Klaim Voucher', value: 1, className: 'primary-button big-button'},
      ]
    };
    const dialog = this.dialog.open(DialogScrollComponent, {data});
    dialog.afterClosed().subscribe((res) => {
      if (res == 1) {
       this.voucherService.claimVoucherCode(this.voucherCode).then(() => {
         this.snackBar.open('Voucher Berhasil Diklaim', '', {});
         this.query();
       });
      }
    })
  }
}
