<div class="main-content align-middle">
  <div class="row">
    <div class="col-12 use-voucher">
      <div class="row no-gutters mt-2 align-items-center">
        <div class="col-12 px-3">
          <p class="title">{{voucher.merchandiseName}}</p>
        </div>
      </div>
      <div class="row no-gutters align-items-center">
        <div class="col-12 px-3">
          <p class="sub-title">{{'pages.member-card.expired-until' | translate}}</p>
          <app-countdown-pipe-component *ngIf="voucher.expiredDateD" [withLabel]="true" [startDate]="voucher.dateTimeNowD"
                                        [endDate]="voucher.expiredDateD"></app-countdown-pipe-component>
        </div>
      </div>
      <div class="row no-gutters align-items-center">
        <div class="col-12 px-3">
          <qr-code value="{{voucher.voucherCode}}"
                   size="150"
                   errorCorrectionLevel="H"
          >
          </qr-code>
        </div>
      </div>
      <div class="row no-gutters align-items-center">
        <div class="col-12 px-3">
          <p class="text-grey">{{'pages.member-card.redemption-term' | translate}}</p>
        </div>
      </div>

      <div class="row no-gutters align-items-center">
        <div class="col-12 px-3">
          <p class="sub-title">{{'pages.member-card.redemption-point' | translate}}</p>
          <div class="voucher-price">
            <span class="label text-center">{{ui.currencyFormat(voucher.voucherPrice, locale)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="foot-menu">
      <button type="button" class="primary-button big-button" mat-flat-button
              (click)="back()">{{'pages.member-card.back-button' | translate}}</button>
    </div>
  </div>
</div>
