<div class="main-content">
    <div class="text-center mt-4 mb-4">
        <h1 class="h6">{{'pages.faq.title' | translate}}</h1>
    </div>
    <mat-accordion>
      <ng-container *ngFor="let faq of faqs">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title><b>{{faq.question}}</b></mat-panel-title>
            </mat-expansion-panel-header>
            <p [innerHTML]="faq.answer | keepHtml"></p>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
</div>
