<div class="main-content full-height-page sign-in">
  <div class="logo">
    <img
      src="./assets/images/others/logo-white.png?rand=123"
      alt="ramerame.co.id"
      class="logo"
    />
  </div>
  <div class="box">
    <h2>{{'pages.login.sign-in-to-account' | translate}}</h2>
    <form [formGroup]="loginFG" (submit)="signIn()">
      <div class="row">
        <div class="col-md-12">
          <div
            [ngClass]="{'has-error':(errorPayload.length > 0 && errorPayload[0].input == 'email'), 'form-group': true}"
          >
            <label for="email"
              >{{'pages.login.email-address' | translate}}</label
            >
            <div class="input-group mb-3">
              <input
                type="email"
                id="email"
                formControlName="usernameFC"
                class="form-control"
                placeholder="Masukkan alamat email anda"
              />
            </div>
            <small
              class="form-text"
              *ngIf="errorPayload.length > 0 && errorPayload[0].input == 'email'"
              >{{errorPayload[0].message | translate}}</small
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            [ngClass]="{'has-error':(errorPayload.length > 0 && errorPayload[0].input == 'password'), 'form-group': true}"
            id="input-group-password"
          >
            <label for="password">{{'pages.login.password' | translate}}</label>
            <div class="input-group mb-3">
              <input
                type="password"
                *ngIf="!togglePassword"
                id="password"
                formControlName="passwordFC"
                class="form-control"
                placeholder="{{'pages.login.password' | translate}}"
              />
              <input
                type="text"
                *ngIf="togglePassword"
                formControlName="passwordFC"
                class="form-control"
                placeholder="{{'pages.login.password' | translate}}"
              />
              <div class="input-group-append">
                <span
                  *ngIf="!togglePassword"
                  class="input-group-text"
                  id="suffix_password_show"
                  (click)="togglePassword = true"
                >
                  <span class="icon-eye"></span>
                </span>
                <span
                  *ngIf="togglePassword"
                  class="input-group-text"
                  id="suffix_password_hide"
                  (click)="togglePassword = false"
                >
                  <span class="icon-eye-blocked"></span>
                </span>
              </div>
            </div>
            <small
              class="form-text"
              *ngIf="errorPayload.length > 0 && errorPayload[0].input == 'password'"
              >{{errorPayload[0].message | translate}}</small
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <a [routerLink]="['/forgot-password']" class="forgot-link"
            >{{'pages.login.forgot-password' | translate}}</a
          >
        </div>
      </div>
      <div class="row margin-top-14px">
        <div class="col-md-12">
          <button
            mat-flat-button
            class="mt-2 primary-button big-button"
            [disabled]="loginFG.status != flagValidForm"
          >
            {{'pages.login.sign-in' | translate}}
          </button>
        </div>
      </div>
    </form>

    <div class="form-separator margin-top-14px">
      <span class="middle-text text-grey"
        >{{'pages.login.or-sign-in-with' | translate}}</span
      >
    </div>

    <div class="row">
      <div class="col-md-12 d-flex margin-top-15px">
        <div class="soclite">
          <a href="https://office.ramerame.co.id/auth/google">
            <img
              class="soc-google"
              src="./assets/images/others/grommet-icons_google.png"
              alt="google"
            />
          </a>
        </div>
        <div class="soclite">
          <a href="https://office.ramerame.co.id/auth/facebook">
            <img
              class="soc-fb"
              src="./assets/images/others/cib_facebook-f.png"
              alt="google"
            />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="register-link">
    <span>{{'pages.login.has-no-account' | translate}}</span>
    <span class="link" (click)="openRegisteredAccountDialog()"
      >{{'pages.login.register-here' | translate}}</span
    >
  </div>

  <div class="registered-at">
    <span>{{'common.registered-at' | translate}}</span
    ><img
      class="kominfo"
      src="./assets/images/others/kominfo.png"
      alt="kominfo"
    />
  </div>
</div>
