import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../app-routing.module';
import {MaterialModule} from '../material/material.module';
import {PaginationComponent} from './pagination/pagination.component';
import {TranslateModule} from '@ngx-translate/core';
import {EscapeHtmlPipe} from '../pipes/escape-html.pipe';
import {MarkdownModule} from 'ngx-markdown';
import {DialogScrollComponent} from './dialog/dialog-scroll.component';
import {AppComponentModule} from '../components/component.module';
import {DialogPromoComponent} from './dialog/dialog-promo.component';
import {DialogSocmedComponent} from './dialog/dialog-social-media.component';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {CachedSrcDirective} from '../directives/iframe.directive';
import {DialogInsuranceComponent} from './dialog/dialog-insurance.component';

@NgModule({
    imports: [
        CommonModule,
        AppRoutingModule,
        MaterialModule,
        TranslateModule,
        MarkdownModule.forChild(),
        AppComponentModule,
        ShareButtonsModule.withConfig({
          debug: true
        }),
        ShareIconsModule
    ],
  exports: [
    PaginationComponent,
    DialogScrollComponent,
    EscapeHtmlPipe,
    CachedSrcDirective,
    DialogPromoComponent,
    DialogSocmedComponent,
    DialogInsuranceComponent
  ],
  declarations: [
    PaginationComponent,
    DialogScrollComponent,
    EscapeHtmlPipe,
    CachedSrcDirective,
    DialogPromoComponent,
    DialogSocmedComponent,
    DialogInsuranceComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppSharedModule {}
