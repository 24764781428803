import {
  ABOUT_US_PAGE,
  BUSINESS_LIST_PAGE,
  BUSINESS_REGISTER_PAGE,
  CONTACT_US_PAGE,
  FAQ_PAGE,
  MEMBER_VOUCHER_CLAIM_PAGE,
  PRIVACY_PAGE
} from './route.constant';

export const MENU_ITEMS = [
  {
    id: 'my-investment',
    group: 'my-investment',
    label: 'menu.business-list',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: BUSINESS_LIST_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'aboutUs',
    group: 'about-us',
    label: 'menu.about-us',
    icon: 'supervised_user_circle',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: ABOUT_US_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'contactUs',
    group: 'contact-us',
    label: 'menu.contact-us',
    icon: 'question_answer',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: CONTACT_US_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'blog',
    group: 'blog',
    label: 'menu.blog',
    icon: 'question_answer',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: true,
    url: 'https://blog.ramerame.co.id',
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'faq',
    group: 'faq',
    label: 'menu.faq',
    icon: 'question_answer',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: FAQ_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'voucher',
    group: 'voucher-claim',
    label: 'menu.voucher-claim',
    icon: 'loyalty',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: MEMBER_VOUCHER_CLAIM_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'business-register',
    group: 'business-register',
    label: 'menu.business-register',
    icon: 'question_answer',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: BUSINESS_REGISTER_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'privacy',
    group: 'privacy',
    label: 'menu.privacy',
    icon: 'privacy_tip',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: PRIVACY_PAGE,
    childMenu: [],
    isMerchant:false
  },
  {
    id: 'logout',
    group: 'logout',
    label: 'menu.logout',
    icon: 'logout_tip',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: '',
    childMenu: [],
    isMerchant:true
  }];
