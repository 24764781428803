<div class="main-content member-info-panel">
    <div class="millionaire-verified-card">
        <div class="background">
          <img src="/assets/images/member/member-gold-card@2x.png" *ngIf="userProfile.basic.complete == 'ACCEPTED' && myInvestments.length == 0 && showCard" class="gold" alt="millionaire member card" />
          <img src="/assets/images/member/member-platinum-card@2x.png" *ngIf="userProfile.basic.complete == 'ACCEPTED' && myInvestments.length > 0 && showCard" class="platinum" alt="millionaire member card" />
          <img src="/assets/images/member/member-silver-card@2x.png" *ngIf="userProfile.basic.complete != 'ACCEPTED'" class="silver" alt="millionaire member card" />
        </div>
        <div class="card-content">
            <div class="card-owner">
                <h3 class="name" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h3>
                <h4 class="id">ID. {{userProfile.basic.id}}</h4>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="false">
        <button class="secondary-button big-button">
          <!-- {{'pages.profile.redeem-discount' | translate}} -->
          Redeem
        </button>
    </div>
    <div class="well member-points-well mt-3">
      <span class="icon-poin"></span>
      <div class="points">
        <span class="h6">{{dateTime}}</span>
        <h3> <small class="text-grey"></small></h3>
      </div>
      <div class="points text-right" (click)="showHelp()">
        <span class="h6 icon-information text-orange"></span>
      </div>
    </div>
    <div class="well member-points-well mt-3" *ngIf="false">
        <span class="icon-poin"></span>
        <div class="points">
            <span class="h6">Point</span>
            <h3>150 <small class="text-grey">Pts</small></h3>
        </div>
        <button class="history-button" mat-flat-button [routerLink]="ui.absoluteUrl(MEMBER_REDEEM_HISTORY_PAGE)"><span class="icon-history"></span></button>
    </div>
    <h4 class="mt-3">Promo Member</h4>
    <div class="member-promo mb-4">
        <div class="card mb-3" *ngFor="let r of rewards" (click)="showDetailReward(r)">
            <img class="card-img-top" [src]="r.imageUrl" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">{{r.title}}</h5>
              <p class="card-text">{{r.shortDescription}}</p>
              <a *ngIf="r.hasButtonLink" href="#" class="primary-button big-button" mat-flat-button>Go somewhere</a>
            </div>
        </div>
    </div>
</div>
