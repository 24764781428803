import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {
  ACTIVE_PRODUCT_BANK_ACCOUNT_BASE_URL,
  MY_INVESTMENT_BASE_URL,
  MY_PURCHASE_HISTORIES_BASE_URL,
  SHOPPING_CART_BASE_URL
} from '../constants/api.constant';
import {MyInvestmentInterface, MyROIInterface} from '../interfaces/product.interface';
import {DailySalesInterface, PurchaseInterface} from '../interfaces/investment.interface';
import {TanyaAdminInterface} from '../interfaces/tanya-admin.interface';

@Injectable({providedIn: 'root'})
export class InvestmentService {
  constructor(private http: HttpClient) {
  }
  async getUserInvestments(): Promise<MyInvestmentInterface[]> {
    return await this.http.get<MyInvestmentInterface[]>(environment.url + MY_INVESTMENT_BASE_URL, {}).toPromise();
  }
  async getPurchaseHistories(): Promise<MyInvestmentInterface[]> {
    return await this.http.get<MyInvestmentInterface[]>(environment.url + MY_PURCHASE_HISTORIES_BASE_URL, {}).toPromise();
  }
  async findOnePurchaseHistory(purchaseId: number): Promise<PurchaseInterface> {
    return await this.http.get<PurchaseInterface>(environment.url + MY_PURCHASE_HISTORIES_BASE_URL + `/${purchaseId}`, {}).toPromise();
  }
  async findAllActiveInvestmentBankAccount(purchaseId: number): Promise<any> {
    return await this.http.get<any>(environment.url + ACTIVE_PRODUCT_BANK_ACCOUNT_BASE_URL + `/${purchaseId}`, {}).toPromise();
  }
  async findOneInvestmentDetails(productId: number): Promise<MyROIInterface[]> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/details';
    return await this.http.post<MyROIInterface[]>(url, {id: productId}, {}).toPromise();
  }
  async findOneInvestmentDailySales(productId: number, month: number, year: number): Promise<DailySalesInterface[]> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/daily-sales';
    return await this.http.post<DailySalesInterface[]>(url, {productId, month, year}, {}).toPromise();
  }
  async getProductTanyaAdminAfterLoggedIn(productId: number): Promise<TanyaAdminInterface[]> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/tanya-admin';
    return await this.http.post<TanyaAdminInterface[]>(url, {productId}, {}).toPromise();
  }
  async saveProductTanyaAdmin(productId: number, data: TanyaAdminInterface): Promise<TanyaAdminInterface[]> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/tanya-admin/save';
    const payload = {
      productId,
      content: data.content,
      category: data.category,
      title: data.title
    };
    return await this.http.post<TanyaAdminInterface[]>(url, payload, {}).toPromise();
  }
  async replyProductTanyaAdmin(tanyaAdminId: number, message: string): Promise<TanyaAdminInterface[]> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/tanya-admin/reply';
    const payload = {
      tanyaAdminId,
      message
    };
    return await this.http.post<TanyaAdminInterface[]>(url, payload, {}).toPromise();
  }
  async findOneProductTanyaAdmin(tanyaAdminId: number): Promise<TanyaAdminInterface> {
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/tanya-admin/id/' + tanyaAdminId;
    return await this.http.get<TanyaAdminInterface>(url, {}).toPromise();
  }
  async saveTransferEvidence(id: number, transferDate: string, originAccount: string, onBehalfOf: string, bankId: number): Promise<any> {
    const payload = {id, transferDate, originAccount, onBehalfOf, bankId};
    return await this.http.post<any>(environment.url + SHOPPING_CART_BASE_URL + '/upload-data-evidence', payload, {}).toPromise();
  }
  async saveFileTransferEvidence(formData: FormData, id: number): Promise<any> {
    const httpOptions = {};
    return this.http.post(environment.url + SHOPPING_CART_BASE_URL + '/upload-file-evidence?transactionId='+id, formData, httpOptions).toPromise();
  }
  async getCertificate(productId: number, type: string): Promise<any> {
    const httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    const options = {observe: 'response', headers: httpHeaders, responseType: 'blob'};
    const url = environment.url + MY_INVESTMENT_BASE_URL + '/products/certificate/' + productId + '/' + type;
    // @ts-ignore
    return this.http.get(url, options).toPromise();
  }
}
