import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE} from '../../../constants/route.constant';
import {MatDialog} from '@angular/material/dialog';
import {DialogInterface} from 'src/app/shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../../shared/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-member-account-verification-status',
  templateUrl: './member-account-verification-status.page.html'
})
export class MemberAccountVerificationStatusPageComponent extends BaseComponent implements OnInit{
  public MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE = MEMBER_ACCOUNT_VERIFICATION_STATUS_PAGE;
  public failed = true;
  constructor(
    injector: Injector,
    public dialog: MatDialog
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  openThanksDialog(): void {
    const data: DialogInterface = {
      title : 'Verifikasi identitas di kirim',
      imageUrl: '/assets/images/member/alert-image4-min.png',
      imageAlt: 'Verifikasi dikirim',
      content: 'Verifikasi identitas mu akan diproses dalam waktu 24jam. Kamu akan menerima pemberitahuan setelah verifikasi identitasmu berhasil',
      buttons: [
        {label: 'common.thank-you', value: 1, className: 'primary-button big-button'},
      ]
    };
    this.dialog.open(DialogScrollComponent, {data});
  }
}
