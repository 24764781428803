import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NavDataInterface} from '../interfaces/nav-data.interface';

@Injectable({providedIn: 'root'})
export class NavService {

  private messageSource = new BehaviorSubject<NavDataInterface>({});
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: NavDataInterface): void {
    this.messageSource.next(message);
  }

}
