import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FrontPageService} from './services/front-page.service';
import {BusinessRegisterInterface} from '../../interfaces/business-register.interface';
import {ERROR_UNKNOWN_OR_NETWORK} from '../../constants/error.constant';
import {MEMBER_ACCOUNT_PANEL_PAGE} from '../../constants/route.constant';
import {MatDialog} from '@angular/material/dialog';
import {CommonMailSentComponent} from '../dialog/common-mail-sent.component';

@Component({
  selector: 'app-page-business-register',
  templateUrl: './business-register.page.html',
})
export class BusinessRegisterPageComponent extends BaseComponent implements OnInit {
  public brFG: UntypedFormGroup;
  public categories = ['Retail', 'Kuliner', 'Online Shop', 'Perikanan', 'Konser', 'Pameran', 'Festival'];
  public selectedCategories = [];
  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private frontPageService: FrontPageService
  ) {
    super(injector);
    this.brFG = this.formBuilder.group({
      fullNameFC     : ['', [Validators.required]],
      emailFC        : ['', [Validators.required]],
      businessNameFC : ['', [Validators.required]],
      websiteFC      : ['', [Validators.required]],
      socialMediaFC  : ['', []],
      fundingNeededFC: ['', [Validators.required]],
      aboutTeamFC    : ['', [Validators.required]],
      elevatorPitchFC: ['', Validators.required],
      driveLinkFC    : ['', [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  sendMail(): void {
    const payload: BusinessRegisterInterface = {
      fullName      : this.brFG.controls.fullNameFC.value,
      email         : this.brFG.controls.emailFC.value,
      website       : this.brFG.controls.websiteFC.value,
      socialMedia   : this.brFG.controls.socialMediaFC.value,
      businessName  : this.brFG.controls.businessNameFC.value,
      fundingAmount : this.brFG.controls.fundingNeededFC.value,
      aboutTeam     : this.brFG.controls.aboutTeamFC.value,
      elevatorPitch : this.brFG.controls.elevatorPitchFC.value,
      driveLink     : this.brFG.controls.driveLinkFC.value,
      categories    : JSON.stringify(this.selectedCategories)
    };
    this.frontPageService.sendBusinessProposal(payload).then(() => {
      const dialog = this.dialog.open(CommonMailSentComponent, {
        data: {email: this.brFG.controls.emailFC.value},
      });
      dialog.afterClosed().subscribe(() => {
        this.router.navigate([MEMBER_ACCOUNT_PANEL_PAGE]).then(() => {});
      });
    }, () => {
      this.label.getLabel(this.translateService, ERROR_UNKNOWN_OR_NETWORK).then((message) => {
        this.snackBar.open(message as string, '', {duration: 2000});
      });
    });
  }
  pushSpliceCategory(event: MatCheckboxChange): void {
    const findIndex = this.selectedCategories.findIndex((x) => x === event.source.value);
    if (event.checked) {
      if (findIndex === -1) {
        this.selectedCategories.push(event.source.value);
      }
    }else {
      if (findIndex >= 0) {
        this.selectedCategories.splice(findIndex, 1);
      }
    }
  }
}
