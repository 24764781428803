<div class="merchant-outlet">
  <div class="row outlet-header">
    <div class="col-12 d-flex">
      <img alt="" [src]="merchantOutletCampaign ? baseUrl + merchantOutletCampaign.merchantLogo + '?rand=123' : '' "
           style="width: 36px; height: 36px"/>
      <h4 class="ml-2 mt-1">{{merchantOutletCampaign ? merchantOutletCampaign.merchantName : ''}}</h4>
    </div>
  </div>
  <div class="mb-1">
    <form [formGroup]="periodFG">
      <div class="form-group">
        <label for="campaignStartDate">{{'pages.member-card.outlet-period' | translate}}</label>
        <div class="input-group col-md-6">
          <input formControlName="startDateFC" id="campaignStartDate" type="text" matInput
                 (click)="pickerStart.open()" [matDatepicker]="pickerStart" class="form-control"
                 aria-describedby="date"/>
          <div class="input-group-append">
            <mat-datepicker-toggle class="" matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          </div>
        </div>
        <mat-datepicker #pickerStart></mat-datepicker>
        <div class="input-group col-md-6">
          <input formControlName="endDateFC" id="campaignEndDate" type="text" matInput (click)="pickerEnd.open()"
                 [matDatepicker]="pickerEnd" class="form-control" aria-describedby="date"/>
          <div class="input-group-append">
            <mat-datepicker-toggle class="" matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          </div>
        </div>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </div>
      <div class="mt-2 mb-3">
        <button class="primary-button big-button" (click)="reloadCampaign()"
                mat-flat-button>{{'pages.member-card.reload-campaign' | translate}}</button>
      </div>
    </form>
  </div>
  <mat-tab-group>
    <mat-tab label="{{'common.cash' | translate}}">
      <div class="outlet-claim">
        <div class="outlet-text-claim">
          <div>
            <h3>{{merchantOutletCampaign ? merchantOutletCampaign.countVoucher : 0}}</h3>
          </div>
          <div>
            <p class="">{{'pages.member-card.voucher-claimed' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="outlet-title">
        <h4>{{'pages.member-card.outlet-campaign-title' | translate}}</h4>
      </div>
      <div class="outlet-detail mb-2" *ngFor="let campaign of campaignLists">
        <div class="card-body p-2">
          <div class="row " (click)="doNavigateVoucherHistory(campaign.id)">
            <div class="col-2 mt-3 text-center">
              <span class="icon-my-voucher" style="font-size: 20px"></span>
            </div>
            <div class="col-8">
              <div>
                <p class="title-campaign">{{campaign.campaignName}}</p>
              </div>
              <div>
                <p class="title-expired"
                   *ngIf="date.getCurrentDate() <= date.stringToDateFormat(campaign.endDate) else campaignExpired">
                  Periode {{date.dateTimeToLocaleDate(campaign.startDate, locale)}}
                  - {{date.dateTimeToLocaleDate(campaign.endDate, locale)}}</p>
                <ng-template #campaignExpired>
                  <p class="title-expired mr-2">{{'pages.member-card.expired' | translate}}</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="{{'common.point' | translate}}">
      <div class="outlet-claim">
        <div class="outlet-text-claim">
          <div>
            <h3>{{merchantOutletCampaign ? merchantOutletCampaign.countVoucher : 0}}</h3>
          </div>
          <div>
            <p class="">{{'pages.member-card.voucher-claimed' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="outlet-title">
        <h4>{{'pages.member-card.outlet-campaign-title' | translate}}</h4>
      </div>
      <div class="outlet-detail mb-2" *ngFor="let campaign of campaignLists">
        <div class="card-body p-2">
          <div class="row " (click)="doNavigateVoucherHistory(campaign.id)">
            <div class="col-2 mt-3 text-center">
              <span class="icon-my-voucher" style="font-size: 20px"></span>
            </div>
            <div class="col-8">
              <div>
                <p class="title-campaign">{{campaign.campaignName}}</p>
              </div>
              <div>
                <p class="title-expired"
                   *ngIf="date.getCurrentDate() <= date.stringToDateFormat(campaign.endDate) else campaignExpired">
                  Periode {{date.dateTimeToLocaleDate(campaign.startDate, locale)}}
                  - {{date.dateTimeToLocaleDate(campaign.endDate, locale)}}</p>
                <ng-template #campaignExpired>
                  <p class="title-expired mr-2">{{'pages.member-card.expired' | translate}}</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div>
    <img src="/assets/images/icons/qr-scan-button.svg"
         class="right figure-img img-fluid rounded outlet-button-scan"
         (click)="doNavigateScanQr()"
         alt="button scan qr">
  </div>
</div>
