<div class="main-content business-detail ask-admin with-fixed-bottom-button ">
    <div class="business-image">
        <div class="text-center">
          <img *ngIf="!product.imageUrl" src="http://placehold.it/1920x1080" class="img-fluid left-image" alt="" />
          <img *ngIf="product.imageUrl" alt="" [src]="baseUrl + product.imageUrl + '?rand=123'" class="img-fluid left-image" />
        </div>
    </div>
    <div class="menu mt-3 mb-3">
      <div class="row">
        <div class="col-3">
            <button class="secondary-button square-button " [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-laporan"></span>
            </button>
            <span class="button-label">Laporan</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button active" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_ASK_ADMIN_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-ask-admin"></span>
            </button>
            <span class="button-label">Tanya Admin</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_CCTV_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-cctv"></span>
            </button>
            <span class="button-label">Monitoring CCTV</span>
        </div>
        <div class="col-3">
            <button class="secondary-button square-button" [routerLink]="ui.absoluteUrl(MEMBER_MY_BUSINESS_DETAIL_TIMELINE_PAGE + '/' + product.id)" mat-flat-button>
                <span class="icon-timeline"></span>
            </button>
            <span class="button-label">Informasi</span>
        </div>
      </div>
    </div>
    <div class="content grey-background">
        <div class="well grey2">
            <p>Hi Kolaborator ramerame.co.id, Kamu sekarang dapat mengajukan pertanyaan terkait project usaha yang kamu miliki dengan Project Manager yang menangani langsung project usaha tersebut. Notifikasi balasan akan dikirim ke email akun kamu, refresh untuk memperbarui pesan yang masuk.</p>
        </div>
        <div class="ticket card" *ngFor="let tanya of tanyaAdmins">
            <div class="card-content" (click)="openDetails(tanya)">
                <div class="row">
                    <div class="col-6">
                        <h5>{{tanya.ticketHash}}</h5>
                        <h5>{{date.localeDate(tanya.createdAt, locale)}}</h5>
                    </div>
                    <div class="col-6 text-right">
                        <span class="ribbon green static">{{(tanya.status == 1)? 'terbuka' : 'selesai'}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <h5>{{tanya.category}}</h5>
                        <p>{{tanya.title}}</p>
                    </div>
                    <div class="col-6 text-right">
                        <h5>Dijawab terakhir oleh</h5>
                        <p>{{tanya.details[0].username}}</p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-buttons">
        <button class="primary-button big-button" mat-button (click)="openDialog()" >Buat Pertanyaan Baru</button>
    </div>
</div>
