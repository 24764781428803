import { MemberWalletFilterDialogComponent } from './wallet-dialog-filter.component';
import { MemberWalletWithdrawDialogComponent } from './wallet-dialog-withdraw.component';
import { PendingWithdrawDialogComponent } from './pending-withdraw-dialog.component';
import { MemberWalletPageComponent } from './wallet-landing.page';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgProgressModule } from 'ngx-progressbar';
import { AppSharedModule } from '../../../shared/shared.module';
import { AppRoutingModule } from '../../../app-routing.module';
import { MemberWalletWithdrawPageComponent } from './wallet-withdraw.page';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from '../../../material/material.module';
import { NgxMaskModule } from 'ngx-mask';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { WalletDialogRegisteredComponent } from './wallet-dialog-registered.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgSelectModule,
    AppSharedModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    ClipboardModule,
  ],
  exports: [
    MemberWalletFilterDialogComponent,
    MemberWalletWithdrawDialogComponent,
    MemberWalletPageComponent,
    MemberWalletWithdrawPageComponent,
  ],
  declarations: [
    MemberWalletFilterDialogComponent,
    MemberWalletWithdrawDialogComponent,
    MemberWalletPageComponent,
    MemberWalletWithdrawPageComponent,
    PendingWithdrawDialogComponent,
    WalletDialogRegisteredComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppMemberWalletModule {}
