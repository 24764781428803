import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Auth} from './auth.service';
import {JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../constants/common.constant';
import {UiHelper} from '../helpers/ui.helper';
import {NEW_LOGIN_PAGE} from '../constants/route.constant';
import {UserInterface} from '../interfaces/user.interface';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  private ui = UiHelper;
  constructor(private auth: Auth, private router: Router) {}
  canActivate(x: ActivatedRouteSnapshot, y: RouterStateSnapshot): boolean {
    if (this.auth.loggedIn() && !(JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY)) as UserInterface).additional) {
      let x = localStorage.getItem(STORAGE_PROFILE_KEY) as UserInterface;
      if(x.status) {
        localStorage.removeItem(JWT_TOKEN_KEY);
        localStorage.removeItem(STORAGE_PROFILE_KEY);
        this.router.navigate([this.ui.absoluteUrl(NEW_LOGIN_PAGE)], {queryParams: {next: y.url}}).then(() => {
          window.location.reload();
        });
      }
      return true;
    }else {
      if (localStorage.getItem(JWT_TOKEN_KEY)) {
        localStorage.removeItem(JWT_TOKEN_KEY);
      }
      this.router.navigate([this.ui.absoluteUrl(NEW_LOGIN_PAGE)], {queryParams: {next: y.url}}).then(() => {
        window.location.reload();
      });
    }
  }
}
