<div style="margin-right: -15px; margin-left: -15px" *ngIf="voucherDetail">
  <div class="top-slider-wrapper" style="position: relative">
    <owl-carousel-o [options]="upperSliderOptions" class="product-slider">
      <ng-template carouselSlide>
        <div class="slider-content-wrapper bg-brand-color">
          <img class="card-img-top" [src]="baseUrl + voucherDetail.imagePath" alt="Card image cap">
        </div>
      </ng-template>
      <ng-template carouselSlide *ngFor="let img of voucherDetail.additionalImage">
        <div class="slider-content-wrapper bg-brand-color">
          <img class="card-img-top" [src]="baseUrl + img.path" alt="Card image cap">
        </div>
      </ng-template>
    </owl-carousel-o>

  </div>
</div>
<div class="voucher-detail" *ngIf="voucherDetail">
  <div class="pt-4 mb-3">
    <p class="vd-title">{{voucherDetail.merchandiseName}}</p>
    <h6>{{voucherDetail.merchantName}}</h6>
    <div class="d-flex justify-content-between">
      <span>{{voucherDetail.merchantAddress}}</span>
      <b class="text-green" (click)="openMap()">Lihat Map</b>
    </div>
  </div>

  <div *ngIf="voucherDetail.status == 'CLAIMED' || action == 'used'"
       class="ribbon static used-btn">{{'pages.member-card.voucher-used' | translate }}</div>
  <div *ngIf="voucherDetail.status == 'EXPIRED'"
       class="ribbon static used-btn">{{'pages.member-card.voucher-expired' | translate }}</div>
  <div class="row justify-content-between">
    <div class="col-5">
      <p class="vd-title-dtl">{{'pages.member-card.point' | translate}}</p>
    </div>
    <div class="col-5">
      <p *ngIf="voucherDetail.status == 'CLAIMED' || action == 'used'"
         class="vd-title-dtl">{{'pages.member-card.claimed-at' | translate}}</p>
      <p *ngIf="voucherDetail.status == 'EXPIRED' || action == 'expired'"
         class="vd-title-dtl">{{'pages.member-card.canceled-at' | translate}}</p>
      <p *ngIf="action == 'redeem' || action == 'buy'"
         class="vd-title-dtl">{{'pages.member-card.effective-date' | translate}}
      </p>
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="col-5">
      <p class="vd-title-point">{{voucherDetail.voucherPrice}} {{'pages.member-card.point' | translate}}</p>
    </div>
    <div class="col-5" *ngIf="action == 'buy'">
      <p style="line-height: 25px; font-size: 12px;">{{date.localeShortDate(voucherDetail.startDate, locale)}} hingga {{date.localeShortDate(voucherDetail.endDate, locale)}}</p>
    </div>
    <div class="col-5" *ngIf="action == 'used'">
      <p>{{date.dateTimeToLocaleDate(voucherDetail.redeemAt, locale)}}</p>
    </div>
    <div class="col-5" *ngIf="action == 'redeem'">
      <app-countdown-pipe-component [withLabel]="'true'"
                                    [startDate]="date.newDateFromStringFormat(voucherDetail.dateTimeNow)"
                                    [endDate]="date.newDateFromStringFormat(voucherDetail.expiredDate)">
      </app-countdown-pipe-component>
    </div>
  </div>
  <div>
    <p class="tnc">{{'pages.member-card.term-and-condition' | translate}}</p>
  </div>
  <p [innerHtml]="voucherDetail.description | keepHtml"></p>
</div>
<div class="row">
  <div class="foot-menu">
    <button *ngIf="userProfile && userProfile.basic" type="button" class="primary-button big-button" (click)="useVoucher()" [disabled]="isDisableButton"
            mat-flat-button>{{btnLabel | translate}}</button>
    <button *ngIf="!userProfile.basic" type="button" class="primary-button big-button" (click)="toLogin()"
            mat-flat-button>Login untuk Mendapatkan</button>
  </div>
</div>
