import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MONTH_LIST_MULTI_LANG, STORAGE_LANG_KEY, VALID_YEARS} from '../../../constants/common.constant';
import {DailySalesInterface} from '../../../interfaces/investment.interface';
import {ProductInterface} from '../../../interfaces/product.interface';
import {UntypedFormControl, Validators} from '@angular/forms';
import {InvestmentService} from '../../../services/investment.service';
import {REPORT_BASE_URL} from '../../../constants/api.constant';
import {FileService} from '../../../services/file.service';

@Component({
  selector: 'app-investment-daily-report',
  templateUrl: './investment-daily-report.page.html'
})
export class MemberInvestmentDailyReportPageComponent extends BaseComponent implements OnInit{
  public monthList = MONTH_LIST_MULTI_LANG;
  public yearList = VALID_YEARS;
  @Input() dailySales: DailySalesInterface[] = [];
  @Input() total = 0;
  @Input() product: ProductInterface = {};
  @Input() month = this.date.getCurrentMonth() + 1;
  @Input() year = this.date.getCurrentYear();
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public yearFC: UntypedFormControl;
  public monthFC: UntypedFormControl;
  public reportBaseUrl = REPORT_BASE_URL;
  constructor(
    injector: Injector,
    private investmentService: InvestmentService,
    private fileService: FileService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.yearFC = new UntypedFormControl(this.year, [Validators.required]);
    this.monthFC = new UntypedFormControl(this.month + 1, [Validators.required]);
    this.yearFC.valueChanges.subscribe((year) => {
      if (year) {
        this.investmentService.findOneInvestmentDailySales(this.product.id, this.monthFC.value, year).then((res) => {
          this.dailySales = res;
          this.total = 0;
          this.dailySales.forEach((item) => {
            this.total += Number(item.salesAmount);
          });
        });
      }
    });
    this.monthFC.valueChanges.subscribe((month) => {
      if (month) {
        this.investmentService.findOneInvestmentDailySales(this.product.id, month, this.yearFC.value).then((res) => {
          this.dailySales = res;
          this.total = 0;
          this.dailySales.forEach((item) => {
            this.total += Number(item.salesAmount);
          });
        });
      }
    });
  }
  download(ds: DailySalesInterface): void {
    this.fileService.downloadDailyReport(this.product.id, ds.attachmentUrl).then((file) => {
      this.ui.downloadFile(file, ds.attachmentUrl);
    });
  }
}
