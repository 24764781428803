<div class="merchant-outlet pt-3">
  <div class="outlet-detail mb-2" *ngFor="let voucher of voucherHistory">
    <div class="card-body" style="padding: 1px 1px;">
      <div class="row">
        <div class="col-2 mt-1 text-center">
          <span class="icon-my-voucher" style="font-size: 20px"></span>
        </div>
        <div class="col-8">
          <div>
            <p class="title-campaign">{{voucher.voucherCode}}</p>
          </div>
          <div>
            <p class="title-expired">{{voucher.claimedAt}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <img src="/assets/images/icons/qr-scan-button.svg"
         class="right figure-img img-fluid rounded outlet-button-scan"
         (click)="doNavigateScanQr()"
         alt="button scan qr">
  </div>
</div>
