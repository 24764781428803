export const OWL_OPTIONS_LANDING = {
  loop: true,
  mouseDrag: false,
  touchDrag: true,
  pullDrag: false,
  dots: true,
  navSpeed: 700,
  margin: 10,
  navText: ['', ''],
  responsiveClass: true,
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 1
    },
    940: {
      items: 1
    }
  },
  nav: false
};
