
    <div class="well mb-3 mt-2  no-border">
        <h5>Total Return On Investment</h5>
        <span class="h3">Rp. {{ui.currencyFormat(total, locale)}}</span>
    </div>
        <h4>Grafik Perkembangan</h4>
        <div class="chart mt-3" #containerRef>
            <ngx-charts-line-chart
                [scheme]="graphConfig.colorScheme"
                [legend]="graphConfig.legend"
                [showXAxisLabel]="graphConfig.showXAxisLabel"
                [showYAxisLabel]="graphConfig.showYAxisLabel"
                [xAxis]="graphConfig.xAxis"
                [yAxis]="graphConfig.yAxis"
                [xAxisLabel]="graphConfig.xAxisLabel"
                [yAxisLabel]="graphConfig.yAxisLabel"
                [timeline]="graphConfig.timeline"
                [results]="multi"
                [view]="[containerRef.offsetWidth, 400]"
                >
                </ngx-charts-line-chart>
        </div>
        <div class="report mt-3">
            <h4>Detail Penerimaan Atas Pendanaan Usaha {{product.productName}}</h4>
            <div class=" table-responsive">
                <table class="table fixed">
                  <colgroup>
                    <col width="150" />
                    <col width="150" />
                    <col width="150" />
                    <col width="150" />
                    <col width="150" />
                    <col width="150" />
                    <col width="150" />
                    <col width="100" />
                    <col width="150" />
                  </colgroup>

                  <thead>
                    <tr>
                        <th scope="col" style="white-space: nowrap">Periode</th>

                        <th scope="col" style="white-space: nowrap" class="text-right">Laba usaha</th>
                        <th scope="col" style="white-space: nowrap" class="text-right">Return per slot</th>
                        <th scope="col" style="white-space: nowrap" class="text-right">Service Fee</th>
                        <th scope="col" style="white-space: nowrap" class="text-right">Return Bersih per slot</th>
                        <th scope="col" style="white-space: nowrap" class="text-right">Jumlah Slot</th>
                        <th scope="col" style="white-space: nowrap" class="text-right">Total Return Diterima</th>
                        <th scope="col" style="white-space: nowrap"></th>
                        <th scope="col" style="white-space: nowrap"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let roi of roisCopy">
                            <th scope="row">{{date.localeMonthFormat(roi.periodMonth).id + ' ' + roi.periodYear}}</th>
                            <td class="text-right">{{ui.currencyFormat(roi.totalAmount, locale)}}</td>
                            <td class="text-right">{{ui.currencyFormat(roi.amount / roi.quantity, locale)}}</td>
                            <td class="text-right">{{ui.currencyFormat(roi.serviceFee / roi.quantity, locale)}}</td>
                            <td class="text-right">{{ui.currencyFormat((roi.amount / roi.quantity) - (roi.serviceFee / roi.quantity), locale)}}</td>
                            <td class="text-right">{{ui.currencyFormat(roi.quantity, locale)}}</td>
                            <td class="text-right">{{ui.currencyFormat(roi.quantity * ((roi.amount / roi.quantity) - (roi.serviceFee / roi.quantity)), locale)}}</td>
                            <td><span (click)="invoice(roi)">Invoice</span></td>
                            <td><span *ngIf="roi.attachment" (click)="download(roi)">Laporan laba rugi</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
