import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CHECKING_TERM_CONDITION_BASE_URL, SAVING_TERM_CONDITION_BASE_URL} from '../constants/api.constant';

@Injectable({providedIn: 'root'})
export class TermService {
  constructor(private http: HttpClient) {
  }

  async checkUserAcceptTerm(): Promise<any> {
    return await this.http.get<any>(environment.url + CHECKING_TERM_CONDITION_BASE_URL , {}).toPromise();
  }

  async saveUserAcceptedTerm(val: number): Promise<any> {
    return await this.http.post<any>(environment.url + SAVING_TERM_CONDITION_BASE_URL, {accept: val}, {}).toPromise();
  }
}
