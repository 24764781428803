import {Component, Injector, OnInit} from '@angular/core';
import {LandingService} from '../../services/landing.service';
import {BaseComponent} from '../../shared/base/base.component';
import {LandingBannerInterface, LandingHowItWorksInterface, PartnerInterface, TestimonyInterface} from '../../interfaces/landing.interface';
import {ProductInterface} from '../../interfaces/product.interface';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CommonKeyValueInterface} from '../../interfaces/common.interface';
import {DEFAULT_LANDING_PRODUCT_LIMIT, LANDING_YOUTUBE_ID, VOUCHER_DETAIL_ACTION} from '../../constants/common.constant';
import {OWL_OPTIONS_LANDING} from '../../constants/owl-options.constant';
import {FrontPageService} from '../front-pages/services/front-page.service';
import {ABOUT_US_PAGE, BUSINESS_LIST_PAGE, MEMBER_CARD_PAGE, MEMBER_CARD_VOUCHER_DETAIL} from '../../constants/route.constant';
import {DialogInterface} from '../../shared/dialog/dialog.interface';
import {DialogScrollComponent} from '../../shared/dialog/dialog-scroll.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogPromoComponent} from '../../shared/dialog/dialog-promo.component';
import {MembercardService} from '../../services/membercard.service';
import {CampaignSelectionInterface} from '../../interfaces/member-card.interface';

@Component({
  selector: 'app-page-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.scss']
})
export class LandingPageComponent extends BaseComponent implements OnInit {
  public partners: PartnerInterface[] = [];
  public testimonies: TestimonyInterface[] = [];
  public productOffered: ProductInterface[] = [];
  public landingBanners: LandingBannerInterface[] = [];
  public howItWorks: LandingHowItWorksInterface[] = [];
  public youtube: CommonKeyValueInterface = {key: '', value: ''};
  public settings: CommonKeyValueInterface[] = [];
  public youtubeSafe: SafeResourceUrl;
  public whyUsShow = false;
  public introShow = false;
  public ready = 0;
  public labelYoutubeId = LANDING_YOUTUBE_ID;
  public upperSliderOptions: OwlOptions;
  public businessListUrl = '';
  public promoUrl = MEMBER_CARD_PAGE;
  public aboutUsUrl = '';
  public newOfferingCampaign: CampaignSelectionInterface;
  public newOfferingCampaigns: CampaignSelectionInterface[] = [];
  public memberCardImage = '/banners/Banner.png';

  constructor(
    injector: Injector,
    private landingService: LandingService,
    private fpService: FrontPageService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private memberCardService: MembercardService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.businessListUrl = this.ui.absoluteUrl(BUSINESS_LIST_PAGE);
    this.aboutUsUrl = this.ui.absoluteUrl(ABOUT_US_PAGE);

    if (this.userProfile && this.userProfile && this.userProfile.basic && this.userProfile.basic.complete == 'ACCEPTED') {
      this.memberCardService.getNewOfferingCampaigns(1).then((res) => {
        if (res && res.length > 0) {
          this.newOfferingCampaign = res[0];
          this.openPromoDialog(this.newOfferingCampaign);
        }
      });
    }

    if (this.userProfile && this.userProfile.basic.complete == 'INCOMPLETE') {
      this.openProfileDialog();
    }

    this.memberCardService.getNewOfferingCampaigns(5).then((res) => {
      this.newOfferingCampaigns = res;
    });

    this.landingService.getPartnersAsync().then((res) => {
      this.partners = res;
      this.ready++;
    });

    this.landingService.getProductMergeSimpleAsync(DEFAULT_LANDING_PRODUCT_LIMIT).then((res) => {
      this.productOffered = res;
      this.ready++;
    });
    this.landingService.getBannersAsync().then((res) => {
      this.landingBanners = res;
      this.upperSliderOptions = OWL_OPTIONS_LANDING;
      this.ready++;
    });
    this.fpService.getHowTo().then((res) => {
      this.howItWorks = res;
      this.ready++;
    });
    this.landingService.getLandingLabelAsync(this.labelYoutubeId).then((res) => {
      this.youtube = res;
      this.youtubeSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.youtube.value);
      this.ready++;
    });
    this.landingService.getLandingSettingsAsync().then((res) => {
      this.settings = res;
      const whyUs = this.settings.find((row) => row.key === 'enableWhyUs');
      const intro = this.settings.find((row) => row.key === 'enableIntro');
      this.whyUsShow = (whyUs) ? whyUs.value : this.whyUsShow;
      this.introShow = (intro) ? intro.value : this.introShow;
      this.ready++;
    });
  }

  async openProfileDialog(): Promise<void> {
    const data: DialogInterface = {
      title: await this.label.getLabel(this.translateService, "pages.landing.diaglog-promotion-title") as string,
      imageUrl: 'assets/images/member/alert-image2-min.png',
      content: await this.label.getLabel(this.translateService, "pages.landing.diaglog-promotion-content") as string,
      buttons: [
        {label: 'OK, Tutup', value: 1, className: 'primary-button big-button'},
      ]
    };
    this.dialog.open(DialogScrollComponent, {data});
  }

  openPromoDialog(campaign: CampaignSelectionInterface) {
    const data: DialogInterface = {
      title: "Hanya untuk ".concat(campaign.numberOfVoucher.toString()).concat(" orang paling gercep! Yakin ga mau?"),
      imageUrl: this.baseUrl + campaign.imagePath,
      buttons: [
        {label: 'Ambil Voucher', value: 1, className: 'primary-button big-button'}
      ]
    };
    const promoAction = this.dialog.open(DialogPromoComponent, {
      data,
      panelClass: 'dialog-container-custom'
    });
    promoAction.afterClosed().subscribe(rest => {
      if (rest === 1) {
        this.router.navigate(['/' + MEMBER_CARD_VOUCHER_DETAIL + '/' + campaign.id + '/' + VOUCHER_DETAIL_ACTION.BUY]).then();
      }
    })
  }

  toRegisterMerchant(): void {
    if(this.userProfile && this.userProfile.basic && this.userProfile.basic.email) {
      window.open(this.baseUrl + '/merchant/register?origin=' + this.userProfile.basic.email, '_blank');
    }else {
      window.open(this.baseUrl + '/merchant/register?origin=', '_blank');
    }

  }
}
