import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {UntypedFormBuilder} from '@angular/forms';
import {TicketService} from '../../../services/ticket.service';
import {SupportTicketInterface} from '../../../interfaces/support-ticket.interface';
import {STORAGE_LANG_KEY} from '../../../constants/common.constant';
import {ERROR_INCOMPLETE_INPUT} from '../../../constants/error.constant';

@Component({
  selector: 'app-page-member-ticket-details',
  templateUrl: './member-ticket-details.page.html'
})
export class MemberTicketDetailsPageComponent extends BaseComponent implements OnInit{
  private ticketId = 0;
  public ticket: SupportTicketInterface = {};
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public replyMessage = '';
  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private ticketService: TicketService
  ) {
    super(injector);
    this.ticketId = this.activatedRoute.snapshot.params.id;
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.findOne();
  }
  sendReply(): void {
    this.ticketService.sendReplySupportTicket(this.ticket.id, this.replyMessage).then(() => {
      this.replyMessage = '';
      this.findOne();
    }, () => {
      this.label.getLabel(this.translateService,  ERROR_INCOMPLETE_INPUT).then((msg) => {
        this.snackBar.open(msg as string, '', {duration: 2000});
      });
    });
  }
  findOne(): void {
    this.ticketService.getSupportTicketById(this.ticketId).then((res) => {
      this.ticket = res;
      this.ticket.content = this.ticket.content.reverse();
    });
  }
}
