import {MenuItemInterface} from '../interfaces/menu-item.interface';
import {
  BUSINESS_LIST_PAGE,
  MEMBER_ACCOUNT_PANEL_PAGE,
  MEMBER_CARD_COMING_SOON, MEMBER_CARD_PAGE,
  MEMBER_MY_BUSINESS_PAGE,
  MEMBER_WALLET_LANDING_PAGE
} from './route.constant';

export const BOTTOM_MENU_ITEMS: MenuItemInterface[] = [
    {
      group: 'project',
      label: 'menu.business-list',
      icon: 'icon-ic_round-view-list',
      url: BUSINESS_LIST_PAGE
    },
    {
      group: 'business-list',
      label: 'menu.my-work',
      icon: 'icon-usaha-saya',
      url: MEMBER_MY_BUSINESS_PAGE
    },
    {
      group: 'member-card',
      label: 'menu.member-card',
      icon: 'icon-awards',
      url: MEMBER_CARD_PAGE
    },
    {
      group: 'wallet',
      label: 'menu.wallet',
      icon: 'icon-dompet',
      url: MEMBER_WALLET_LANDING_PAGE
    },
    {
      group: 'account',
      label: 'menu.account',
      icon: 'icon-akun',
      url: MEMBER_ACCOUNT_PANEL_PAGE
    }
];
