import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../../../shared/base/base.component';
import { MemberWalletFilterDialogComponent } from './wallet-dialog-filter.component';
import {
  MEMBER_MY_BUSINESS_DETAIL_PAGE,
  MEMBER_REPORT_MONTHLY_PAGE,
  MEMBER_REPORT_PAGE,
  MEMBER_WALLET_WITHDRAW_PAGE,
} from 'src/app/constants/route.constant';
import { WalletService } from '../../../services/wallet.service';
import {
  WalletInterface,
  WalletDetailsInterface,
} from '../../../interfaces/wallet.interface';
import { DateHelper } from '../../../helpers/date.helper';
import {
  STORAGE_LANG_KEY,
  STORAGE_PROFILE_KEY,
} from '../../../constants/common.constant';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { WalletDialogRegisteredComponent } from './wallet-dialog-registered.component';

@Component({
  selector: 'app-page-member-wallet',
  templateUrl: './wallet-landing.page.html',
})
export class MemberWalletPageComponent extends BaseComponent implements OnInit {
  public MEMBER_WALLET_WITHDRAW_PAGE = MEMBER_WALLET_WITHDRAW_PAGE;
  public wallet: WalletInterface = {};
  public walletFilter: WalletDetailsInterface[] = [];
  public date = DateHelper;
  public locale = localStorage.getItem(STORAGE_LANG_KEY);
  public profile =
    (JSON.parse(
      localStorage.getItem(STORAGE_PROFILE_KEY) || 'null'
    ) as UserInterface) || null;

  public currentFilter = 'ALL';
  public filterLabel = 'Semua';
  private availableFilters = [
    { status: 'ALL', label: 'Semua', i18n: 'pages.wallet.filter-all' },
    { status: 'UP', label: 'Pembelian Slot', i18n: 'pages.wallet.filter-up' },
    { status: 'MTT', label: 'Transfer Slot', i18n: 'pages.wallet.filter-mtt' },
    { status: 'DEP', label: 'Deposit', i18n: 'pages.wallet.filter-dep' },
    { status: 'WD', label: 'Penarikan', i18n: 'pages.wallet.filter-wd' },
    {
      status: 'WRF',
      label: 'Refund Penarikan',
      i18n: 'pages.wallet.filter-wrf',
    },
    { status: 'QW', label: 'Pemenang Quiz', i18n: 'pages.wallet.filter-qw' },
    {
      status: 'KFR',
      label: 'Pembatalan Proyek',
      i18n: 'pages.wallet.filter-kfr',
    },
    {
      status: 'MR',
      label: 'Pengembalian Dana',
      i18n: 'pages.wallet.filter-mr',
    },
    { status: 'VC', label: 'Klaim Voucher', i18n: 'pages.wallet.filter-vc' },
    {
      status: 'EOMC',
      label: 'Recall Return',
      i18n: 'pages.wallet.filter-eomc',
    },
    {
      status: 'EOM',
      label: 'Pembagian Return',
      i18n: 'pages.wallet.filter-eom',
    },
  ];
  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private walletService: WalletService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();

    if (this.isRegistered()) {
      this.openRegisteredAccountDialog();
    } else {
      this.walletService.getWalletsAsync(this.pageNo).then((res) => {
        this.wallet = res;
        if (this.wallet) {
          this.walletFilter = this.wallet.details.reverse();
          this.currentFilter = 'ALL';
        }
      });
    }
  }

  openFilter(): void {
    const dialog = this.dialog.open(MemberWalletFilterDialogComponent, {
      data: {
        filter: this.currentFilter,
        availableFilters: this.availableFilters,
      },
    });
    dialog.componentInstance.onFilterSelected.subscribe((res) => {
      this.walletFilter = [];
      const y = this.availableFilters.find((x) => x.status === res);
      this.filterLabel = y.label;
      if (res === 'ALL') {
        this.walletFilter = this.wallet.details;
      } else {
        this.wallet.details.forEach((item) => {
          if (item.status === res) {
            this.walletFilter.push(item);
          }
        });
      }
    });
  }

  toMonthlyReport(d: WalletDetailsInterface) {
    if (d.status == 'EOM') {
      this.router
        .navigate(
          ['/' + MEMBER_MY_BUSINESS_DETAIL_PAGE + '/' + d.meta.productId],
          {
            queryParams: {
              page: 'monthly',
            },
          }
        )
        .then(() => {});
    }
  }

  openRegisteredAccountDialog() {
    this.dialog.open(WalletDialogRegisteredComponent, {
      data: {},
    });
  }

  isRegistered() {
    return (
      this.profile.basic?.guid_user &&
      this.profile.basic?.guid_user !== '-' &&
      this.profile.basic?.is_registered_new_app?.toString() === 'true'
    );
  }
}
