import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {
  CAMPAIGN_BASE_URL,
  CAMPAIGN_VOUCHER_BASE_URL,
  MERCHANDISE_BASE_URL,
  MERCHANDISE_VOUCHER_BASE_URL,
  MERCHANT_BASE_URL,
  WALLET_POINT_BASE_URL
} from '../constants/api.constant';
import {
  CampaignCategoryInterface,
  CampaignSelectionInterface,
  CampaignWithCategoryInterface,
  MerchandiseCategoryInterface,
  MyVouchersInterface,
  VoucherDetailsInterface,
  VoucherHistoryInterface,
  VoucherValidateInterface
} from '../interfaces/member-card.interface';
import {CampaignVoucher, MerchantOutletCampaignInterface, VoucherWithCampaignInterface} from '../interfaces/merchant-outlet.interface';
import {MerchandiseInterface} from '../interfaces/merchandise.interface';
import {WalletPointInterface} from '../interfaces/wallet-point.interface';
import {MyMerchandiseInterface} from '../interfaces/voucher.interface';

@Injectable({providedIn: 'root'})
export class MembercardService {
  constructor(private http: HttpClient) {
  }

  async getCampaignCategories(): Promise<CampaignCategoryInterface[]> {
    return await this.http.get<CampaignCategoryInterface[]>(environment.url + CAMPAIGN_BASE_URL + '/categories').toPromise();
  }

  async getMerchandiseCategories(): Promise<MerchandiseCategoryInterface[]> {
    return await this.http.get<MerchandiseCategoryInterface[]>(environment.url + MERCHANDISE_BASE_URL + '/categories').toPromise();
  }

  async getMerchandises(): Promise<MerchandiseInterface[]> {
    return await this.http.get<MerchandiseInterface[]>(environment.url + MERCHANDISE_BASE_URL).toPromise();
  }

  async getMerchantOutlet(id: number, startDate: string, endDate: string): Promise<MerchantOutletCampaignInterface> {
    const payload = {
      id : id,
      startDate : startDate,
      endDate : endDate
    };
    return this.http.post<MerchantOutletCampaignInterface>(environment.url + MERCHANT_BASE_URL + '/merchant-outlet', payload, {}).toPromise();
  }

  async getVoucherHistory(id: number): Promise<VoucherHistoryInterface[]> {
    return await this.http.get<VoucherHistoryInterface[]>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/voucher-history/' + id).toPromise();
  }

  async getVoucherMerchandiseHistory(id: number): Promise<VoucherHistoryInterface[]> {
    return await this.http.get<VoucherHistoryInterface[]>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/history/' + id).toPromise();
  }

  async getVoucherWithCampaign(id: number): Promise<VoucherWithCampaignInterface> {
    return await this.http.get<VoucherWithCampaignInterface>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/qr-success/' + id).toPromise();
  }

  async getMerchandiseWithCampaign(id: number): Promise<VoucherWithCampaignInterface> {
    return await this.http.get<VoucherWithCampaignInterface>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/qr-success/' + id).toPromise();
  }

  async updateClaimCampaignVoucher(id: number): Promise<any> {
    return await this.http.post<any>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/claim-campaign-voucher', {id}, {}).toPromise();
  }

  async updateClaimMerchandiseVoucher(id: number): Promise<any> {
    return await this.http.post<any>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/claim', {id}, {}).toPromise();
  }

  async getNewOfferingCampaigns(limit: number): Promise<CampaignSelectionInterface[]> {
    return await this.http.get<CampaignSelectionInterface[]>(environment.url + CAMPAIGN_BASE_URL + '/new-offering/' + limit).toPromise();
  }

  async getMerchantOfTheDaysCampaigns(): Promise<CampaignSelectionInterface[]> {
    return await this.http.get<CampaignSelectionInterface[]>(environment.url + CAMPAIGN_BASE_URL + '/merchant-of-the-day').toPromise();
  }

  async getVoucherByVoucherCode(code : string): Promise<CampaignVoucher> {
    return await this.http.get<CampaignVoucher>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/qr-scan/' + code).toPromise();
  }

  async getMerchandiseByVoucherCode(code : string): Promise<CampaignVoucher> {
    return await this.http.get<CampaignVoucher>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/qr-scan/' + code).toPromise();
  }

  async getCampaignWithCategory(): Promise<CampaignWithCategoryInterface[]> {
    return await this.http.get<CampaignWithCategoryInterface[]>(environment.url + CAMPAIGN_BASE_URL + '/campaign-with-category').toPromise();
  }

  async getCampaignDetails(id: number): Promise<VoucherDetailsInterface> {
    return await this.http.get<VoucherDetailsInterface>(environment.url + CAMPAIGN_BASE_URL + '/details/' + id).toPromise();
  }

  async getMerchandiseDetails(id: number): Promise<VoucherDetailsInterface> {
    return await this.http.get<VoucherDetailsInterface>(environment.url + MERCHANDISE_BASE_URL + '/' + id).toPromise();
  }

  async getCampaignVoucherDetails(id: number): Promise<VoucherDetailsInterface> {
    return await this.http.get<VoucherDetailsInterface>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/details/' + id).toPromise();
  }

  async getMerchandiseVoucherDetails(id: number): Promise<VoucherDetailsInterface> {
    return await this.http.get<VoucherDetailsInterface>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/' + id).toPromise();
  }

  async getListCampaignByCategory(categoryId: number): Promise<CampaignSelectionInterface[]> {
    return await this.http.get<CampaignWithCategoryInterface[]>(environment.url + CAMPAIGN_BASE_URL + '/detail-by-category/' + categoryId).toPromise();
  }

  async getPointByUser(userId: number): Promise<number> {
    return await this.http.get<number>(environment.url + WALLET_POINT_BASE_URL + '/get-point-by-user/' + userId).toPromise();
  }

  async doRedeemVoucher(campaignId: number, redeemWith: string): Promise<VoucherDetailsInterface> {
    const payload = {
      campaignId: campaignId,
      redeemWith: redeemWith
    };
    return this.http.post<VoucherDetailsInterface>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/redeem-voucher', payload, {}).toPromise();
  }

  async doRedeemMerchandise(campaignId: number, redeemWith: string): Promise<VoucherDetailsInterface> {
    const payload = {
      merchandiseId: campaignId,
      redeemWith: redeemWith
    };
    return this.http.post<VoucherDetailsInterface>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/redeem', payload, {}).toPromise();
  }

  async getVoucherByUserAndIsClaimed(isClaimed: string): Promise<any> {
    return await this.http.post<MyVouchersInterface[]>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/get-voucher-by-user-and-is-claimed', {isClaimed}, {}).toPromise();
  }

  async getMerchandiseByUserAndIsClaimed(isClaimed: string): Promise<MyMerchandiseInterface[]> {
    return await this.http.post<MyMerchandiseInterface[]>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/by-user', {isClaimed}, {}).toPromise();
  }

  async getWalletPointHistory(): Promise<WalletPointInterface[]> {
    return await this.http.get<WalletPointInterface[]>(environment.url + WALLET_POINT_BASE_URL + '/history').toPromise();
  }

  async validateVoucher(id: number): Promise<VoucherValidateInterface> {
    return await this.http.get<VoucherValidateInterface>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/validate/' + id).toPromise();
  }

  async validateMerchandise(id: number): Promise<VoucherValidateInterface> {
    return await this.http.get<VoucherValidateInterface>(environment.url + MERCHANDISE_VOUCHER_BASE_URL + '/validate/' + id).toPromise();
  }

  async updateVoucherStatus(id: number): Promise<any> {
    const payload = {
      id: id
    };
    return await this.http.post<any>(environment.url + CAMPAIGN_VOUCHER_BASE_URL + '/update-voucher-status', payload, {}).toPromise();
  }
}
