import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {ProductService} from '../../services/product.service';
import {ProductInterface} from '../../interfaces/product.interface';
import {MEMBER_NOT_COMPLETE_REDIRECT_PAGE} from '../../constants/route.constant';
import {ERR_PROFILE_NOT_COMPLETE} from 'src/app/constants/common.constant';

@Component({
  selector: 'app-page-business-list',
  templateUrl: './business-list.page.html',
})
export class BusinessListPageComponent extends BaseComponent implements OnInit{
  public products: ProductInterface[] = [];
  public insuranceLogo = '/assets/images/icons/insurance.svg';
  constructor(
    injector: Injector,
    private productService: ProductService,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.query();
  }
  query(): void {
    this.productService.getProducts(this.pageNo).then((res) => {
      this.totalRow = parseInt(res.headers.get('X-Header-ContentSize'), 10);
      this.products = res.body as ProductInterface[];
    }, (err) => {
      if (err.error.error == ERR_PROFILE_NOT_COMPLETE) {
        this.router.navigate([MEMBER_NOT_COMPLETE_REDIRECT_PAGE]).then(() => {});
      }
    });
  }
  toPage(p: number): void {
    this.pageNo = p;
    this.query();
  }
}
