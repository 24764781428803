import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {MD_GOLD_MEMBER, MD_PLATINUM_MEMBER, MD_SILVER_MEMBER} from '../../../constants/json.constant';

@Component({
  selector: 'app-page-member-card-info',
  templateUrl: './member-card-info.page.html'
})
export class MemberCardInfoComponent extends BaseComponent implements OnInit {

  public silverPath: string;
  public goldPath: string;
  public platinumPath: string;
  public activeGroup = 1;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.silverPath = MD_SILVER_MEMBER;
    this.goldPath = MD_GOLD_MEMBER;
    this.platinumPath = MD_PLATINUM_MEMBER;
    super.ngOnInit();
  }
}
