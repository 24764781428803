import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FAQInterface} from '../../../interfaces/common.interface';
import {JSON_FAQ_CONTENT, JSON_HOWTO_CONTENT} from '../../../constants/json.constant';
import {LandingHowItWorksInterface} from '../../../interfaces/landing.interface';
import {environment} from '../../../../environments/environment';
import {BusinessRegisterInterface} from '../../../interfaces/business-register.interface';

@Injectable({providedIn: 'root'})
export class FrontPageService {
  constructor(private http: HttpClient) {
  }
  async getFAQ(): Promise<FAQInterface[]> {
    return await this.http.get<FAQInterface[]>(JSON_FAQ_CONTENT, {}).toPromise();
  }
  async getHowTo(): Promise<LandingHowItWorksInterface[]> {
    return await this.http.get<LandingHowItWorksInterface[]>(JSON_HOWTO_CONTENT, {}).toPromise();
  }
  async sendBusinessProposal(payload: BusinessRegisterInterface): Promise<any> {
    return await this.http.post<any>(environment.url + '/users/business-register', payload, {}).toPromise();
  }
}
