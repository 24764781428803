<div class="main-content member-info-panel bg-white pt-4">
  <div style="overflow-x: scroll">
    <div class="ml-3 mb-3" style="width: 620px;">
      <button (click)="activeGroup = 1"
              [ngClass]="{'download-button mc-btn-active': (activeGroup == 1), 'download-button-inactive mc-btn-inactive': (activeGroup != 1)}"
              style="margin-right: 10px;" mat-flat-button>{{'pages.member-card.silver-member' | translate}}
      </button>
      <button (click)="activeGroup = 2"
              [ngClass]="{'download-button mc-btn-active': (activeGroup == 2), 'download-button-inactive mc-btn-inactive': (activeGroup != 2)}"
              style="margin-right: 10px;" mat-flat-button>{{'pages.member-card.gold-member' | translate}}
      </button>
      <button (click)="activeGroup = 3"
              [ngClass]="{'download-button mc-btn-active': (activeGroup == 3), 'download-button-inactive mc-btn-inactive': (activeGroup != 3)}"
              style="margin-right: 10px;" mat-flat-button>{{'pages.member-card.platinum-member' | translate}}
      </button>
    </div>
  </div>
  <ng-container *ngIf="activeGroup == 1">
    <div class="millionaire-verified-card">
      <div class="background">
        <img src="/assets/images/member/member-silver-card@2x.png"
             class="figure-img img-fluid rounded"
             alt="silver member card">
      </div>
      <div class="card-content">
        <div class="card-owner">
          <h3 class="name" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h3>
          <h4 class="id">ID. {{userProfile.basic.id}}</h4>
        </div>
      </div>
    </div>
    <div class="member-card-info">
      <markdown [src]="silverPath"></markdown>
    </div>
  </ng-container>
  <ng-container *ngIf="activeGroup == 2">
    <div class="millionaire-verified-card">
      <div class="background">
        <img src="/assets/images/member/member-gold-card@2x.png"
             class="figure-img img-fluid rounded"
             alt="gold member card">
      </div>
      <div class="card-content">
        <div class="card-owner">
          <h3 class="name" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h3>
          <h4 class="id">ID. {{userProfile.basic.id}}</h4>
        </div>
      </div>
    </div>
    <div class="member-card-info">
      <markdown [src]="goldPath"></markdown>
    </div>
  </ng-container>
  <ng-container *ngIf="activeGroup == 3">
    <div class="millionaire-verified-card">
      <div class="background">
        <img src="/assets/images/member/member-platinum-card@2x.png"
             class="figure-img img-fluid rounded"
             alt="platinum member card">
      </div>
      <div class="card-content">
        <div class="card-owner">
          <h3 class="name" style="text-transform: capitalize">{{userProfile.basic.fullName}}</h3>
          <h4 class="id">ID. {{userProfile.basic.id}}</h4>
        </div>
      </div>
    </div>
    <div class="member-card-info">
      <markdown [src]="platinumPath"></markdown>
    </div>
  </ng-container>
</div>
