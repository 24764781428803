import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  DEFAULT_LANGUAGE,
  FORM_GROUP_VALID,
  JWT_TOKEN_KEY,
  STORAGE_LANG_KEY,
  STORAGE_PROFILE_KEY,
} from '../../constants/common.constant';
import { UserInterface } from '../../interfaces/user.interface';
import { ErrorInterface } from '../../interfaces/common.interface';
import {
  MEMBER_ACCOUNT_PANEL_PAGE,
  MERCHANT_OUTLET_PAGE,
  NEW_LOGIN_PAGE,
} from '../../constants/route.constant';
import {
  ERROR_COULD_NOT_CREATE_TOKEN,
  ERROR_EMAIL_NOT_FOUND,
  ERROR_INVALID_CREDENTIAL,
  ERROR_UNKNOWN_OR_NETWORK,
} from '../../constants/error.constant';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewAppComponent } from './dialog-new-app.component';

@Component({
  selector: 'app-page-new-login',
  templateUrl: './new-login.page.html',
  styleUrls: ['../register/register.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewLoginPageComponent extends BaseComponent implements OnInit {
  public loginFG: UntypedFormGroup;
  public flagValidForm = FORM_GROUP_VALID;
  public profile: UserInterface = {};
  public loggedInError = '';
  public errorPayload: ErrorInterface[] = [];
  private isAuth = false;
  public togglePassword = false;
  public height = '';
  private next = '';

  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private userService: UserService,
    private jwtHelper: JwtHelperService,
    public dialog: MatDialog
  ) {
    super(injector);

    this.next = this.activatedRoute.snapshot.queryParams.next;

    this.loginFG = this.formBuilder.group({
      usernameFC: ['', [Validators.required, Validators.maxLength(254)]],
      passwordFC: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isAuth = !this.jwtHelper.isTokenExpired();
    if (this.isAuth) {
      if (
        this.profile &&
        this.profile.additional &&
        this.profile.additional.roleId == 6
      ) {
        this.router.navigate(['/' + MERCHANT_OUTLET_PAGE]).then(() => {});
      } else {
        this.router.navigate([MEMBER_ACCOUNT_PANEL_PAGE]).then(() => {});
      }
    } else {
      localStorage.clear();
      localStorage.setItem(STORAGE_LANG_KEY, DEFAULT_LANGUAGE);

      if (this.next == '' || !this.next) {
        this.router.navigate([NEW_LOGIN_PAGE]).then(() => {});
      } else {
        this.router
          .navigate([NEW_LOGIN_PAGE], { queryParams: { next: this.next } })
          .then(() => {});
      }

      localStorage.setItem('next', this.next);
    }
  }

  signIn(): void {
    this.loggedInError = '';
    this.errorPayload = [];
    if (this.loginFG.status === FORM_GROUP_VALID) {
      this.loginService
        .verify(
          this.loginFG.controls.usernameFC.value,
          this.loginFG.controls.passwordFC.value
        )
        .then(
          (res) => {
            localStorage.setItem(JWT_TOKEN_KEY, res.token);
            this.currentUser();
          },
          async (err) => {
            if (err.error.error) {
              switch (this.ui.errorMessageTransform(err.error.error)) {
                case ERROR_EMAIL_NOT_FOUND: {
                  this.errorPayload.push({
                    hasError: true,
                    message: ERROR_EMAIL_NOT_FOUND,
                    input: 'email',
                  });
                  this.loginFG.controls.usernameFC.hasError('any');
                  break;
                }
                case ERROR_INVALID_CREDENTIAL: {
                  this.errorPayload.push({
                    hasError: true,
                    message: ERROR_INVALID_CREDENTIAL,
                    input: 'password',
                  });
                  break;
                }
                case ERROR_COULD_NOT_CREATE_TOKEN: {
                  const message = await this.label.getLabel(
                    this.translateService,
                    ERROR_COULD_NOT_CREATE_TOKEN
                  );
                  this.snackBar.open(message as string, '', { duration: 2000 });
                  break;
                }
                default: {
                  const message = await this.label.getLabel(
                    this.translateService,
                    ERROR_UNKNOWN_OR_NETWORK
                  );
                  this.snackBar.open(message as string, '', { duration: 2000 });
                  break;
                }
              }
            } else {
              const message = await this.label.getLabel(
                this.translateService,
                ERROR_UNKNOWN_OR_NETWORK
              );
              this.snackBar.open(message as string, '', { duration: 2000 });
            }
          }
        );
    }
  }

  currentUser(): void {
    this.userService.getCurrentUserAsync().then(
      (res) => {
        this.profile = res;
        localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
        if (
          this.profile &&
          this.profile.additional &&
          this.profile.additional.roleId == 6
        ) {
          this.next = '/' + MERCHANT_OUTLET_PAGE;
        } else {
          this.next = !this.next ? '' : this.next;
        }
        window.location.href = environment.feUrl + this.next;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openRegisteredAccountDialog() {
    this.dialog.open(DialogNewAppComponent, {
      data: {},
    });
  }
}
